import { Component } from "react";
import { SelectMenu } from "../SelectMenu";

export class OrganizationSelector extends Component {
  constructor(props) {
    super(props);

    this.state = {
      selectedOrg: this.props.value
    };
  }

  onChange(e) {
    const { onChange } = this.props;
    this.setState({ selectedOrg: e.target.value }, (state) => {
      onChange(e);
    });
  }

  compareItems(a, b) {
    return a.key === b.key;
  }

  getItemLabel(item) {
    return item.filterLabel;
  }

  render() {
    const { sx, items, className, disabled } = this.props;
    const { selectedOrg } = this.state;

    return (
      <SelectMenu
        value={selectedOrg}
        className={className}
        label='Organization'
        placeholder='Select'
        firstValueDefault={false}
        groups={["Organizations", "Demo organizations"]}
        clearable={false}
        disabled={disabled}
        items={
          items
            ? Object.entries(
                items.map((o, index) => {
                  return {
                    key: index,
                    label: o,
                    group: o.endsWith("(DEMO)") ? "Demo organizations" : "Organizations"
                  };
                })
              )
            : []
        }
        onChange={this.onChange.bind(this)}
        sx={sx}
      />
    );
  }
}
