export class ErrorModel {
  static defaultMessage = "An error occured. Please contact Klayo support.";

  static Severity = Object.freeze({
    INFO: {},
    FAIL: {}
  });

  constructor(type, e, source) {
    this.type = type;
    this.httpStatus = null;
    this.source = source;
    this.message = ErrorModel.parseServerError(e, this);

    console.log("ErrorModel");
    console.log(this);
    console.log(this);
  }

  isFail() {
    return this.type === ErrorModel.Severity.FAIL;
  }

  static parseServerError(e, model) {
    if (typeof e === "object") {
      if (e.response) {
        if (model) model.httpStatus = e.response.status;

        if (e.response.data) {
          if (model) model.httpStatus = e.response.data.status;
          if (e.response.data.type !== undefined) {
            let errorMsg = "";
            if (e.response.data.errors)
              Object.values(e.response.data.errors).forEach((e) => {
                errorMsg += e[0] + ". ";
              });
            else errorMsg = e.response.data.title;
            return errorMsg;
          } else {
            const error = e.response.data;
            console.log(error);
            console.log(error.details);

            if (model) model.details = error.details;
            return error.errorMessage;
          }
        } else return ErrorModel.defaultMessage;
      } else {
        if (e.message === "Network Error" && model) model.isNetworkError = true;
        return e.message;
      }
    } else if (typeof e === "string") return e;
    else return ErrorModel.defaultMessage;
  }
}
