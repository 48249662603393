import { AbstractAttribute } from "./AbstractAttribute";
import { EmployeeAttribute } from "./EmployeeAttribute";
import { JobAttribute } from "./JobAttribute";
export class GapAttribute extends AbstractAttribute {
  static reason = {
    ok: {
      label: "OK"
    },
    lowProficiency: {
      label: "Low proficiency"
    },
    missing: {
      label: "Missing"
    }
  };

  constructor(index, data) {
    super(index, data);

    this.employeeAttributeId = data.employeeAttributeId;
    this.attributeName = data.attributeDefinitionName;
    this.category = data.attributeDefinitionCategory;
    this.required = data.jobAttributeRequirementType === 1;
    this.mandatory = data.jobAttributeRequirementType === 2;
    this.currentProficiency = data.employeeAttributeProficiencyValue;
    this.requiredProficiency = data.jobAttributeProficiencyMinimum;
    if (data.employeeAttributeValidationRequestState != null) {
      this.validationRequestId = data.employeeAttributeValidationRequestId;
      this.validationRequestState = Object.values(AbstractAttribute.status)[
        data.employeeAttributeValidationRequestState
      ];
      this.requestedProficiency = data.employeeAttributeValidationRequestProficiencyValue;
    }
    this.reason = Object.values(GapAttribute.reason)[data.gapReason];
    this.reasonLabel = this.reason.label;
    this.trainingCourses = data.trainingCourses;
    if (data.validationRequest) {
      this.requestedProficiency = data.validationRequest.proficiencyValue;
    }

    if (this.mandatory) this.type = AbstractAttribute.type.mandatory.label;
    else if (this.required) this.type = "Required";
    else this.type = "Desirable";
  }

  hasValidationRequest() {
    return this.validationRequestState;
  }

  isNeedsFeedback() {
    return (
      this.validationRequestState &&
      this.validationRequestState === AbstractAttribute.status.feedback
    );
  }

  isLowProficiency() {
    return this.reason === GapAttribute.reason.lowProficiency;
  }

  isMissing() {
    return this.reason === GapAttribute.reason.missing;
  }

  isGap() {
    return true;
  }
}
