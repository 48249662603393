export class Document {
  constructor(data) {
    this.documentId = data.documentId;
    this.type = data.documentData ?? data.type;
    this.documentName = data.documentName;
    this.isDeleted = data.isDeleted === true;
    this.pendingDelete = data.pendingDelete === true;
    this.createDate = data.createDate;
  }
}
