import { Component } from "react";
import { Stack } from "@mui/material";
import { PaperOrDialog } from "../../components/PaperOrDialog";
import { TextField } from "../../components/TextField";
import { Alert } from "../../components/Alert";
import { EditTemplate } from "klayowebshared";
import { SettingsAttributeCategory } from "../../data/settings/SettingsAttributeCategory";

export class AddEditCategoryView extends Component {
  constructor(props) {
    super(props);

    this.state = {
      category: props.category,
      valid: this.isValid(props.category.name),
      saving: false,
      error: null,
      hasEdit: false
    };

    this.existingCategory = new SettingsAttributeCategory(props.category);
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.category !== this.props.category) {
      this.setState({
        category: this.props.category,
        valid: this.isValid(this.props.category.name)
      });
    }
  }

  isValid(categoryName) {
    return categoryName != null && categoryName.length > 2;
  }

  onNameChange(e) {
    const { category } = this.state;
    category.name = e.target.value;
    this.setState({ category, valid: this.isValid(category.name) });
  }

  onSave() {
    const { onSave, onBlockNavigation } = this.props;
    const { category } = this.state;

    if (onSave) {
      if (onBlockNavigation) onBlockNavigation(false, "Category");
      this.setState({ saving: true });
      onSave(category);
    }
  }

  onEditStateChange(hasEdit) {
    this.setState({ hasEdit });
  }

  onNavigation(e, callback) {
    const { onNavigation } = this.props;
    return onNavigation && onNavigation(e, callback);
  }

  onCancel(e, source) {
    if (source === "back") this.onNavigation(e, this.stopBlockingNavAndClose.bind(this));
    else this.stopBlockingNavAndClose(e);
  }

  stopBlockingNavAndClose(e) {
    const { onCancel, onBlockNavigation } = this.props;
    if (onBlockNavigation) onBlockNavigation(false, "Category");
    if (onCancel) onCancel(e);
  }

  render() {
    const {
      organization,
      user,
      theme,
      editMode,
      onCancel,
      onCreate,
      dialogMode,
      error,
      onAllowNavigation,
      onBlockNavigation
    } = this.props;
    const { category, valid, hasEdit, saving } = this.state;

    return (
      <EditTemplate
        theme={theme}
        name='Category'
        onAllowNavigation={onAllowNavigation}
        onBlockNavigation={onBlockNavigation}
        onEditStateChange={this.onEditStateChange.bind(this)}
        detectEdit={!saving}
        compare={{
          existing: this.existingCategory,
          editing: category,
          members: [{ name: "name" }]
        }}
      >
        <PaperOrDialog
          theme={theme}
          dialog={dialogMode}
          dialogToBreakpoint='md'
          paperPadding='60px'
          title={
            editMode
              ? "Competency category details"
              : dialogMode
                ? "Create new competency category"
                : "Create competency category"
          }
          titleSx={{ fontSize: { xs: "20px", md: "30px" }, fontWeight: "bold" }}
          titleSize={1}
          backButton={editMode}
          onBack={this.onCancel.bind(this)}
          actions={[
            {
              label: editMode ? "Save" : "Create",
              primary: true,
              disabled: !valid || (editMode && !hasEdit) || saving,
              onClick: this.onSave.bind(this)
            },
            {
              label: "Cancel",
              onClick: this.onCancel.bind(this)
            }
          ]}
          justifyActions='start'
        >
          <Stack direction='column' spacing={4}>
            {error && (
              <Alert severity='error' sx={{ width: "100%" }}>
                {error}
              </Alert>
            )}
            <TextField
              label='Category name'
              autoComplete={false}
              autoFocus={true}
              clearable={false}
              sx={{ width: "100%" }}
              value={category.name}
              onChange={this.onNameChange.bind(this)}
              validationMethod={(e, value) => this.isValid(category.name)}
              validationText='Please enter a valid name (more than 2 characters)'
            />
          </Stack>
        </PaperOrDialog>
      </EditTemplate>
    );
  }
}
