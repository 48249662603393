import { React, Component } from "react";
import { MultiSelector } from "./MultiSelector";

export class MultiCategorySelector extends Component {
  static defaultProps = {
    label: "Competency categories"
  };

  constructor(props) {
    super(props);

    this.state = {};
  }

  render() {
    return <MultiSelector {...this.props} hasNewItem={true} />;
  }
}
