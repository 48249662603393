import React, { Component } from "react";
import { Box, Grid, Stack, Typography } from "@mui/material";
import { CategoryDonutChart } from "../../components/charts/CategoryDonutChart";
import { AttributeBubbleChart } from "../../components/charts/AttributeBubbleChart";
import { Theme, BreakpointTemplate, Delayed } from "klayowebshared";
import { AttributeTable } from "../../components/table/AttributeTable";
import { Paper } from "../../components/Paper";
import { SortSelector } from "../../components/selectors/SortSelector";
import { ReactComponent as DownloadIcon } from "../../resources/images/icons-download.svg";
import { Button } from "../../components/Button";

export class TeamGapsSection extends Component {
  static defaultProps = {
    allowExport: true,
    heading: "Team gaps"
  };

  constructor(props) {
    super(props);

    this.state = {
      sortModel: [
        {
          field: "attribute",
          sort: props.sortOrder.method || "asc"
        }
      ]
    };

    this.tableRef = React.createRef();
  }

  onSortOrderChange(e, sortOrder) {
    const { onSortOrderChange } = this.props;

    this.onSortModelChange([
      {
        field: "attribute",
        sort: sortOrder.method
      }
    ]);

    if (onSortOrderChange) onSortOrderChange(e, sortOrder);
  }

  onSortModelChange(model, details) {
    this.setState({ sortModel: model });
    this.tableRef.current.setSortModel(model);
  }

  render() {
    const {
      organization,
      user,
      theme,
      attributes,
      allowExport,
      onAttributeSelect,
      onShowExportDialog,
      sortOrder,
      heading
    } = this.props;
    const { sortModel } = this.state;

    return (
      <Grid container spacing={4}>
        <Grid item xs={12} md={12} lg={8}>
          <Paper
            title={heading}
            floatingTitle={true}
            padding={0}
            sx={{ height: "350px" }}
            overflow='visible'
          >
            <Delayed waitBeforeShow={600}>
              <AttributeBubbleChart
                theme={theme}
                attributes={attributes ? attributes.classificationsSummary : null}
                onAttributeSelect={onAttributeSelect}
                bubbleColor={Theme.getStyleVar("--torch-red-tr-050")}
                bubbleTextColor={Theme.getStyleVar("--grey-gr-900")}
                sx={{ height: "350px" }}
              />
            </Delayed>
          </Paper>
        </Grid>
        <Grid item xs={12} md={4} lg={4} sx={{ display: { xs: "none", lg: "block" } }}>
          <Paper title='Gap categories' sx={{ padding: "20px", height: "310px" }}>
            <CategoryDonutChart
              categories={attributes ? attributes.categorySummary : null}
              sx={{ height: "280px", marginTop: "-16px" }}
            />
          </Paper>
        </Grid>

        <Grid item xs={12} md={12} lg={12}>
          <Stack direction='row' spacing={2} justifyContent='space-between' alignItems='center'>
            <BreakpointTemplate
              theme={theme}
              breakpoint='md'
              to={
                <SortSelector
                  dense={true}
                  value={sortOrder}
                  onChange={this.onSortOrderChange.bind(this)}
                  sx={{ width: "100%" }}
                />
              }
              from={
                <div className='klayo_teampeople_rowcount'>
                  {attributes ? attributes.attributes.length + " records" : ""}
                </div>
              }
              sx={{ width: "100%" }}
            />
            <Stack direction='row' spacing={2} alignItems='center'>
              {allowExport && (
                <Button
                  size='md'
                  label='Export'
                  theme={theme}
                  tooltip='Export your filtered team data'
                  showLabelFromBreakpoint='md'
                  onClick={onShowExportDialog}
                  disabled={(attributes && attributes.attributes.length === 0) || false}
                  startIcon={<DownloadIcon />}
                />
              )}
            </Stack>
          </Stack>
        </Grid>

        <Grid item xs={12} md={12} lg={12}>
          <Delayed>
            <AttributeTable
              ref={this.tableRef}
              rows={attributes ? attributes.attributes : null}
              theme={theme}
              showHeaderFromBreakpoint='md'
              columns={[
                { type: AttributeTable.columns.summaryWithPeople },
                {
                  type: AttributeTable.columns.attribute,
                  label: "Competency",
                  visibleFromBreakpoint: "md"
                },
                { type: AttributeTable.columns.category, visibleFromBreakpoint: "lg" },
                {
                  type: AttributeTable.columns.averageProficiency,
                  label: "Average Proficiency",
                  visibleFromBreakpoint: "md"
                },
                { type: AttributeTable.columns.employees, visibleFromBreakpoint: "md" }
              ]}
              onAttributeSelect={onAttributeSelect}
              showProficiency={organization && organization.showProficiency}
              sortModel={sortModel}
              onSortModelChange={this.onSortModelChange.bind(this)}
              noRowsMessage='Looking awesome, no gaps!'
            />
          </Delayed>
        </Grid>
      </Grid>
    );
  }
}
