import { Table } from "./Table";

export class SingleColumnTable extends Table {
  static columns = {
    attributes: {
      field: "attributes",
      label: "Competencies"
    },
    regulations: {
      field: "regulations",
      label: "Regulations"
    },
    jobDefinitions: {
      field: "jobDefinitions",
      label: "Job Definitions"
    },
    task: {
      field: "task",
      label: "Tasks"
    }
  };

  static defaultProps = {
    ...Table.defaultProps,
    noRowsMessage: "No rows, yet"
  };

  constructor(props) {
    super(props);

    this.state = {
      ...this.state
    };
  }

  onRowClick(params, e) {
    const { onAttributeSelect } = this.props;
    if (onAttributeSelect) onAttributeSelect(e, params.row);
  }

  initColumns() {
    const { columns, sortable } = this.props;

    this.columns = [];

    columns.forEach((c) => {
      if (this.hasColumn(c)) {
        if (c.type === SingleColumnTable.columns.attributes)
          this.columns.push({
            field: "attributes",
            headerName: c.label || c.type.label,
            sortable: sortable,
            filterable: false,
            disableReorder: true,
            flex: 1,
            valueGetter: (params) => params.row.name,
            cellRenderer: (params) => <div>{params.row.name}</div>
          });
        if (c.type === SingleColumnTable.columns.regulations)
          this.columns.push({
            field: "regulations",
            headerName: c.label || c.type.label,
            sortable: sortable,
            filterable: false,
            disableReorder: true,
            flex: 1,
            valueGetter: (params) => params.row.name,
            cellRenderer: (params) => <div>{params.row.name}</div>
          });
        if (c.type === SingleColumnTable.columns.jobDefinitions)
          this.columns.push({
            field: "jobDefinitions",
            headerName: c.label || c.type.label,
            sortable: sortable,
            filterable: false,
            disableReorder: true,
            flex: 1,
            valueGetter: (params) => params.row.name,
            cellRenderer: (params) => <div>{params.row.name}</div>
          });

        if (c.type === SingleColumnTable.columns.task)
          this.columns.push({
            field: "task",
            headerName: c.label || c.type.label,
            sortable: sortable,
            filterable: false,
            disableReorder: true,
            flex: 1,
            valueGetter: (params) => params.row.name,
            cellRenderer: (params) => <div>{params.row.name}</div>
          });
      }
    });
  }
}
