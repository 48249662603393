import { Stack, Tooltip } from "@mui/material";
import { GridActionsCellItem } from "@mui/x-data-grid-pro";
import { ReactComponent as MoreVertIcon } from "../../resources/images/icons-more-vertical.svg";
import { ReactComponent as DeleteIcon } from "../../resources/images/icons-delete-blue.svg";
import { AttributeProgressBar } from "../AttributeProgressBar";
import { ComplianceBadge } from "../ComplienceBadge";
import { ProfilePicture } from "../ProfilePicture";
import { SelectMenu } from "../SelectMenu";
import { Table } from "./Table";

export class EmployeeTable extends Table {
  static columns = {
    summary: {
      field: "summary",
      label: "",
      visibleToBreakpoint: "md"
    },
    profilePic: {
      field: "profilePic",
      label: ""
    },
    fullName: {
      field: "fullName",
      label: "Name"
    },
    jobTitle: {
      field: "jobTitle",
      label: "Job"
    },
    fullNameJobTitle: {
      field: "fullNameJobTitle",
      label: "Employee"
    },
    profilePicFullName: {
      field: "profilePicFullName",
      label: "Employee"
    },
    profilePicFullNameJobTitle: {
      field: "profilePicFullNameJobTitle",
      label: "Employee"
    },
    isCompliant: {
      field: "isCompliant",
      label: "Compliant"
    },
    employeeAttributesCount: {
      field: "employeeAttributesCount",
      label: "Competencies",
      visibleFromBreakpoint: "md"
    },
    jobAttributesCount: {
      field: "jobAttributesCount",
      label: "Job Competencies",
      visibleFromBreakpoint: "md"
    },
    gapAttributesCount: {
      field: "gapAttributesCount",
      label: "Gaps",
      visibleFromBreakpoint: "md"
    },
    averageProficiency: {
      field: "averageProficiency",
      label: "Average proficiency",
      visibleFromBreakpoint: "md"
    },
    status: {
      field: "status",
      label: "Status"
    },
    actions: {
      field: "actions",
      label: "Actions"
    },
    removeAction: {
      field: "remove",
      label: null,
      tooltip: "Delete"
    },
    userDetails: {
      field: "userDetails",
      label: "Details"
    },
    groupLevelType: {
      field: "groupLevelType",
      label: "Permission"
    },
    groupNotificationType: {
      field: "groupNotificationType",
      label: "Notifications"
    }
  };

  static defaultProps = {
    ...Table.defaultProps,
    columns: [EmployeeTable.columns.fullName],
    noRowsMessage: "No rows, yet",
    showProficiency: false,
    rowHasAction: null
  };

  constructor(props) {
    super(props);

    this.state = {
      ...this.state,
      sortModel: this.props.sortModel
        ? this.props.sortModel
        : [
            {
              field: "fullName",
              sort: "asc"
            }
          ]
    };
  }

  onRowClick(params, e) {
    const { onEmployeeSelect } = this.props;
    if (onEmployeeSelect) onEmployeeSelect(e, params.row);
  }

  onRemoveAction(e, row) {
    const { onRemove } = this.props;
    if (onRemove) {
      onRemove(e, row);
    }
  }

  getRowId(e) {
    return this.tableId + "_" + e.employeeId;
  }

  initColumns() {
    const {
      showProficiency,
      columns,
      sortable,
      onEmployeeAction,
      onGroupLevelTypeChange,
      onGroupNotificationTypesChange,
      disabled
    } = this.props;

    this.columns = [];

    columns.forEach((c) => {
      if (this.hasColumn(c)) {
        if (c.type === EmployeeTable.columns.summary)
          this.columns.push({
            field: "summary",
            headerName: c.label || c.type.label,
            sortable: sortable,
            visibleFromBreakpoint: c.visibleFromBreakpoint || c.type.visibleFromBreakpoint,
            visibleToBreakpoint: c.visibleToBreakpoint || c.type.visibleToBreakpoint,
            filterable: false,
            width: "100%",
            flex: 1,
            numRows: 4,
            rowHeight: "124px",
            disableReorder: true,
            renderCell: (params) => (
              <div className='klayo-table_multilinerow'>
                <div className='klayo-employeetable_multilinerow_row1'>{params.row.fullName}</div>
                <div className='klayo-employeetable_multilinerow_row2'>{params.row.jobTitle}</div>
                <div className='klayo-employeetable_multilinerow_row3'>
                  <Stack
                    direction='row'
                    spacing={2}
                    justifyContent='space-between'
                    alignItems='baseline'
                  >
                    <div>
                      {params.row.jobAttributesCount} competencies, {params.row.gapAttributesCount}{" "}
                      gaps
                    </div>
                    <ComplianceBadge isCompliant={params.row.isCompliant} />
                  </Stack>
                </div>
                <div className='klayo-employeetable_multilinerow_row4'>
                  <AttributeProgressBar
                    label='Job Match Overview'
                    showLabel={false}
                    barHeight={8}
                    /*met={metAttributesCount} total={params.row.employeeAttributesCount}*/
                    met={params.row.numberOfMetMandatoryAndRequiredAttributes}
                    total={params.row.totalNumberOfMandatoryAndRequiredAttributesForJob}
                  />
                </div>
              </div>
            )
          });

        if (c.type === EmployeeTable.columns.profilePic)
          this.columns.push({
            field: "profilePic",
            sortable: sortable,
            headerName: c.label || c.type.label,
            visibleFromBreakpoint: c.visibleFromBreakpoint || c.type.visibleFromBreakpoint,
            width: 70,
            filterable: false,
            disableReorder: true,
            renderCell: (params) => (
              <ProfilePicture
                user={params.row}
                size='sm'
                onClick={(e) => this.onUserClick(e, params.row)}
              />
            )
          });

        if (c.type === EmployeeTable.columns.fullName)
          this.columns.push({
            field: "fullName",
            sortable: sortable,
            headerName: c.label || c.type.label,
            visibleFromBreakpoint: c.visibleFromBreakpoint || c.type.visibleFromBreakpoint,
            visibleToBreakpoint: c.visibleToBreakpoint || c.type.visibleToBreakpoint,
            flex: 1,
            filterable: false,
            disableReorder: true
          });

        if (c.type === EmployeeTable.columns.jobTitle)
          this.columns.push({
            field: "jobTitle",
            sortable: sortable,
            headerName: c.label || c.type.label,
            visibleFromBreakpoint: c.visibleFromBreakpoint || c.type.visibleFromBreakpoint,
            flex: 1,
            filterable: false,
            disableReorder: true
          });

        if (c.type === EmployeeTable.columns.userDetails)
          this.columns.push({
            field: "userDetails",
            sortable: sortable,
            headerName: "",
            align: "right",
            visibleFromBreakpoint: c.visibleFromBreakpoint || c.type.visibleFromBreakpoint,
            width: 250,
            filterable: false,
            disableReorder: true,
            valueGetter: (params) => (params.row.isTeamLead ? "Team lead" : "")
          });

        if (c.type === EmployeeTable.columns.fullNameJobTitle)
          this.columns.push({
            field: "fullNameJobTitle",
            sortable: sortable,
            headerName: c.label || c.type.label,
            visibleFromBreakpoint: c.visibleFromBreakpoint || c.type.visibleFromBreakpoint,
            visibleToBreakpoint: c.visibleToBreakpoint || c.type.visibleToBreakpoint,
            width: 250,
            filterable: false,
            disableReorder: true,
            valueGetter: (params) => params.row.fullName,
            renderCell: (params) => (
              <div>
                <div className='klayo_employeetable_name'>{params.row.fullName}</div>
                <div className='klayo_employeetable_job'>{params.row.jobTitle}</div>
              </div>
            )
          });

        if (c.type === EmployeeTable.columns.profilePicFullName)
          this.columns.push({
            field: "profilePicFullName",
            sortable: sortable,
            headerName: c.label || c.type.label,
            visibleFromBreakpoint: c.visibleFromBreakpoint || c.type.visibleFromBreakpoint,
            flex: 1,
            filterable: false,
            disableReorder: true,
            valueGetter: (params) => params.row.fullName,
            renderCell: (params) => (
              <div className='klayo_employeetable_pic klayo_employeetable_picname'>
                <ProfilePicture
                  user={params.row}
                  size='sm'
                  onClick={(e) => this.onUserClick(e, params.row)}
                />
                <div>
                  <div className='klayo_employeetable_name'>{params.row.fullName}</div>
                </div>
                {params.row.role && params.row.isOrganizationAdmin && (
                  <div className='klayo_employeetable_adminbadge'>Admin</div>
                )}
                {params.row.role && params.row.isBilling && (
                  <div className='klayo_employeetable_billingbadge'>Billing</div>
                )}
                {params.row.role && params.row.isInstructor && (
                  <div className='klayo_employeetable_instructorbadge'>Instructor</div>
                )}
              </div>
            )
          });

        if (c.type === EmployeeTable.columns.profilePicFullNameJobTitle)
          this.columns.push({
            field: "profilePicFullNameJobTitle",
            sortable: sortable,
            headerName: c.label || c.type.label,
            visibleFromBreakpoint: c.visibleFromBreakpoint || c.type.visibleFromBreakpoint,
            flex: 1,
            filterable: false,
            disableReorder: true,
            valueGetter: (params) => params.row.fullName,
            renderCell: (params) => (
              <div className='klayo_employeetable_pic klayo_employeetable_picjobname'>
                <ProfilePicture
                  user={params.row}
                  size='sm'
                  onClick={(e) => this.onUserClick(e, params.row)}
                />
                <div>
                  <div className='klayo_employeetable_name'>{params.row.fullName}</div>
                  <div className='klayo_employeetable_job'>{params.row.jobTitle}</div>
                </div>
                {params.row.isOrganizationAdmin && (
                  <div className='klayo_employeetable_adminbadge'>Admin</div>
                )}
                {params.row.role && params.row.isBilling && (
                  <div className='klayo_employeetable_billingbadge'>Billing</div>
                )}
                {params.row && params.row.isInstructor && (
                  <div className='klayo_employeetable_instructorbadge'>Instructor</div>
                )}
              </div>
            )
          });

        if (c.type === EmployeeTable.columns.isCompliant)
          this.columns.push({
            field: "isCompliant",
            sortable: sortable,
            headerName: c.label || c.type.label,
            visibleFromBreakpoint: c.visibleFromBreakpoint || c.type.visibleFromBreakpoint,
            filterable: false,
            disableReorder: true,
            width: 170,
            valueGetter: (params) => params.row.isCompliant,
            renderCell: (params) =>
              params.row.isCompliant ? (
                <div className='klayo_employeetable_compliantbadge'>Yes</div>
              ) : (
                <div className='klayo_employeetable_noncompliantbadge'>No</div>
              )
          });

        if (c.type === EmployeeTable.columns.employeeAttributesCount)
          this.columns.push({
            field: "employeeAttributesCount",
            sortable: sortable,
            headerName: c.label || c.type.label,
            visibleFromBreakpoint: c.visibleFromBreakpoint || c.type.visibleFromBreakpoint,
            filterable: false,
            disableReorder: true,
            width: 140,
            type: "number"
          });

        if (c.type === EmployeeTable.columns.jobAttributesCount)
          this.columns.push({
            field: "jobAttributesCount",
            sortable: sortable,
            headerName: c.label || c.type.label,
            visibleFromBreakpoint: c.visibleFromBreakpoint || c.type.visibleFromBreakpoint,
            filterable: false,
            disableReorder: true,
            width: 140,
            type: "number"
          });

        if (c.type === EmployeeTable.columns.status)
          this.columns.push({
            field: "status",
            sortable: sortable,
            headerName: c.label || c.type.label,
            visibleFromBreakpoint: c.visibleFromBreakpoint || c.type.visibleFromBreakpoint,
            filterable: false,
            disableReorder: true,
            valueGetter: (params) => params.row.statusLabel,
            renderCell: (params) => params.row.statusLabel,
            width: 170
          });

        if (c.type === EmployeeTable.columns.gapAttributesCount)
          this.columns.push({
            field: "gapAttributesCount",
            sortable: sortable,
            headerName: c.label || c.type.label,
            visibleFromBreakpoint: c.visibleFromBreakpoint || c.type.visibleFromBreakpoint,
            filterable: false,
            disableReorder: true,
            width: 140,
            type: "number"
          });

        if (c.type === EmployeeTable.columns.averageProficiency && showProficiency)
          this.columns.push({
            field: "averageProficiency",
            sortable: sortable,
            headerName: c.label || c.type.label,
            visibleFromBreakpoint: c.visibleFromBreakpoint || c.type.visibleFromBreakpoint,
            filterable: false,
            disableReorder: true,
            type: "number",
            width: 200,
            valueGetter: (params) => EmployeeTable.calculateOverallProficiency(params.row),
            renderCell: (params) => {
              const metAttributesCount =
                params.row.employeeAttributesCount - params.row.gapAttributesCount;
              return (
                <AttributeProgressBar
                  label='Job Match Overview'
                  showLabel={false}
                  barHeight={8}
                  /*met={metAttributesCount} total={params.row.employeeAttributesCount}*/
                  met={params.row.numberOfMetMandatoryAndRequiredAttributes}
                  total={params.row.totalNumberOfMandatoryAndRequiredAttributesForJob}
                />
              );
            },
            renderHeader: (params) => (
              <Tooltip
                title={
                  <div>
                    Percentage of Compliance <br />
                    and Required competencies
                  </div>
                }
                placement='top'
              >
                <div className='MuiDataGrid-columnHeaderTitle'>{params.colDef.headerName}</div>
              </Tooltip>
            )
          });

        if (c.type === EmployeeTable.columns.actions)
          this.columns.push({
            field: "actions",
            type: "actions",
            filterable: false,
            disableReorder: true,
            align: "right",
            getActions: (params) => [
              <GridActionsCellItem
                icon={<MoreVertIcon />}
                label='Actions'
                disabled={disabled}
                onClick={(e) => onEmployeeAction(e, params.row)}
                key={Math.random().toString(36).substring(7)}
              />
            ]
          });

        if (c.type === EmployeeTable.columns.removeAction)
          this.columns.push({
            field: "removeAction",
            type: "actions",
            filterable: false,
            disableReorder: true,
            align: "right",
            getActions: (params) => [
              <GridActionsCellItem
                icon={<DeleteIcon />}
                disabled={disabled}
                onClick={(e) => this.onRemoveAction(e, params.row)}
                key={Math.random().toString(36).substring(7)}
              />
            ]
          });

        if (c.type === EmployeeTable.columns.groupLevelType)
          this.columns.push({
            field: "groupLevelType",
            headerName: c.label || c.type.label,
            filterable: false,
            disableReorder: true,
            width: 160,
            renderCell: (params) => (
              <SelectMenu
                className='klayo-table_select'
                clearable={false}
                dense={true}
                noSelectionError={true}
                placeholder='Select'
                value={c.groupLevelTypes.find((t) => t.id === params.row.groupLevelType)}
                items={Object.entries(c.groupLevelTypes)}
                onChange={(e, value) => onGroupLevelTypeChange(e, params.row)}
                disabled={disabled}
                sx={{ width: "100%" }}
              />
            )
          });

        if (c.type === EmployeeTable.columns.groupNotificationType)
          this.columns.push({
            field: "groupNotificationType",
            headerName: c.label || c.type.label,
            filterable: false,
            disableReorder: true,
            width: 160,
            renderCell: (params) => (
              <SelectMenu
                className='klayo-table_select'
                clearable={false}
                dense={true}
                noSelectionError={true}
                placeholder='Select'
                value={c.groupNotificationTypes.find(
                  (t) => t.id === params.row.groupNotificationType
                )}
                items={Object.entries(c.groupNotificationTypes)}
                onChange={(e, value) => onGroupNotificationTypesChange(e, params.row)}
                disabled={disabled}
                sx={{ width: "100%" }}
              />
            )
          });
      }
    });
  }

  static calculateOverallProficiency(e) {
    return AttributeProgressBar.calculatePercent(
      e.numberOfMetMandatoryAndRequiredAttributes,
      e.totalNumberOfMandatoryAndRequiredAttributesForJob
    );
  }

  static comparator(sortModel, e1, e2) {
    if (!sortModel[0]) return;

    let sortCol = sortModel[0].field;
    const sortDir = sortModel[0].sort;

    if (sortCol === "profilePicFullNameJobTitle" || sortCol === "fullNameJobTitle")
      sortCol = "fullName";

    if (sortCol === "employeeAttributesCount") {
      return sortDir === "asc"
        ? e1.employeeAttributesCount - e2.employeeAttributesCount
        : e2.employeeAttributesCount - e1.employeeAttributesCount;
    } else if (sortCol === "jobAttributesCount") {
      return sortDir === "asc"
        ? e1.jobAttributesCount - e2.jobAttributesCount
        : e2.jobAttributesCount - e1.jobAttributesCount;
    } else if (sortCol === "gapAttributesCount") {
      return sortDir === "asc"
        ? e1.gapAttributesCount - e2.gapAttributesCount
        : e2.gapAttributesCount - e1.gapAttributesCount;
    } else if (sortCol === "averageProficiency") {
      const e1Prof = EmployeeTable.calculateOverallProficiency(e1);
      const e2Prof = EmployeeTable.calculateOverallProficiency(e2);
      return sortDir === "asc" ? e1Prof - e2Prof : e2Prof - e1Prof;
    } else
      return sortDir === "asc"
        ? String(e1[sortCol]).localeCompare(e2[sortCol])
        : String(e2[sortCol]).localeCompare(e1[sortCol]);
  }
}
