import React, { Component } from "react";
import { Route, Switch, withRouter } from "react-router-dom";
import { CurrentUser } from "./data/users/CurrentUser";
import { Feedback } from "./views/feedback/Feedback";
import { Profile } from "./views/profile/Profile";
import { Team } from "./views/team/Team";
import { LicenseInfo } from "@mui/x-license-pro";
import { Organization } from "./data/Organization";
import { AppContext } from "./common/AppContext";
import { AppBar } from "./components/AppBar";
import { SwitchUserDialog } from "./views/other/SwitchUserDialog";
import { Utils } from "./common/Utils";
import { LoadingModel } from "./common/models/LoadingModel";
import { LoadingBar } from "./components/LoadingBar";
import { Fade, Box } from "@mui/material";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { ErrorModel } from "./common/models/ErrorModel";
import { ErrorPage } from "./views/other/ErrorPage";
import { NotificationDrawer } from "./components/NotificationDrawer";
import { NotificationPopup } from "./components/NotificationPopup";
import { DashboardData } from "./data/DashboardData";
import { NotificationsData } from "./data/notifications/NotificationsData";
import { Theme, Analytics, Environment, BreakpointIndicator } from "klayowebshared";
import { Data } from "./data/Data";
import { ImpersonateUser } from "./data/users/ImpersonateUser";
import { EventBus } from "./common/EventBus";
import _ from "lodash";
import { DevDialog } from "./views/other/DevDialog";
import { Settings } from "./views/settings/Settings";
import { Notification } from "./data/notifications/Notification";
import axios from "axios";
import { InactiveView } from "./views/other/InactiveView";
import { BillingBanner } from "./components/BillingBanner";
import { ContactUsDialog } from "./views/other/ContactUsDialog";
import { UnavailableView } from "./views/other/UnavailableView";
import { Snackbar } from "./components/Snackbar";
import { ErrorBoundary } from "./views/other/ErrorBoundary";
import { AppDrawer } from "./components/AppDrawer";
import { ReactComponent as ProfileIcon } from "./resources/images/icons-user.svg";
import { ReactComponent as TeamIcon } from "./resources/images/icons-users-1.svg";
import { ReactComponent as CompanyIcon } from "./resources/images/icons-company2.svg";
import { ReactComponent as FeedbackIcon } from "./resources/images/icons-feedback.svg";
import { OrgSignupView } from "./views/signup/OrgSignupView";
import { CompanyView } from "./views/company/CompanyView";
import { Group } from "./views/team/Group";
import { GroupsList } from "./data/groups/GroupsList";
import { Practicals } from "./views/practicals/Practicals";
export class App extends Component {
  static defaultProps = {
    settingLandingEnabled: false
  };

  static pages = {
    dashboard: {
      name: "Dashboard",
      slug: "dashboard",
      label: "Dashboard",
      enabled: false,
      hasAccess: (user, organization) =>
        user &&
        (!organization || (organization && organization.status !== Organization.status.inactive)),
      developerOnly: true,
      component: null,
      ref: React.createRef()
    },
    profile: {
      name: "My profile",
      slug: "profile",
      paths: ["/profile"],
      //getSlug: (user, organization) => user && !user.hasJob ? 'profile/competencies' : 'profile',
      label: "My profile",
      isDefault: () => true,
      icon: <ProfileIcon />,
      hasAccess: (user, organization) =>
        user &&
        (!organization || (organization && organization.status !== Organization.status.inactive)),
      component: Profile,
      ref: React.createRef()
    },
    team: {
      name: "My team",
      slug: "team",
      paths: ["/team"],
      label: "My team",
      icon: <TeamIcon />,
      hasAccess: (user, organization) =>
        user &&
        (user.isTeamLead || user.isOrganizationAdmin) &&
        (!organization || (organization && organization.status !== Organization.status.inactive)),
      component: Team,
      admin: true,
      ref: React.createRef()
    },
    company: {
      name: "My company",
      slug: "company",
      paths: ["/company"],
      label: "My company",
      icon: <CompanyIcon />,
      hasAccess: (user) => user,
      component: CompanyView,
      showInNav: true,
      ref: React.createRef()
    },
    groups: {
      name: "My groups",
      slug: "groups",
      paths: ["/groups"],
      label: "My groups",
      icon: <CompanyIcon />,
      hasAccess: (user) => user && user.hasGroup,
      hasChildren: true,
      component: Group,
      showInNav: true,
      ref: React.createRef()
    },
    practicals: {
      name: "Practicals",
      slug: "practicals",
      paths: ["/practicals", "/practicals/new"],
      label: "Practicals",
      icon: <CompanyIcon />,
      hasAccess: (user) => user && (user.isInstructor || user.isParticipant),
      component: Practicals,
      showInNav: true,
      ref: React.createRef()
    },
    feedback: {
      name: "Feedback",
      slug: "feedback",
      paths: ["/feedback"],
      label: "Feedback",
      icon: <FeedbackIcon />,
      hasAccess: (user, organization) =>
        user &&
        (user.isTeamLead || user.hasGroup || user.isOrganizationAdmin) &&
        (!organization || (organization && organization.status !== Organization.status.inactive)),
      component: Feedback,
      admin: true,
      ref: React.createRef()
    },
    settings: {
      name: "Settings",
      slug: "settings",
      paths: ["/settings"],
      label: "Settings",
      hasAccess: (user) => user && (user.isOrganizationAdmin || user.isBillingUser),
      hasChildren: true,
      component: Settings,
      admin: true,
      showInNav: true,
      seperate: true,
      ref: React.createRef()
    },
    signup: {
      name: "Sign up",
      slug: "signup",
      paths: ["/signup"],
      label: "Sign up",
      hasAccess: (user) => true,
      component: OrgSignupView,
      admin: true,
      showInNav: false,
      ref: React.createRef()
    }
  };

  static defaultPage = App.pages.profile;
  static allowDevBar = true;

  constructor(props) {
    super(props);

    const themeName = "v2"; //window.localStorage.getItem('theme')
    this.urlParams = new URLSearchParams(window.location.search);

    this.state = {
      loading: 0,
      error: null,
      admin: false,
      organization: null,
      user: null,
      theme: this.getTheme(themeName),
      themeName: themeName,
      activePage: App.defaultPage,
      debugParams: this.urlParams.get("debug"),
      showDevDialog: false,
      setLoading: this.setLoading.bind(this),
      showSwitchUserDialog: false,
      unreadNotifications: null,
      acceptTermsError: null,
      showInactiveView: false,
      demoOrgResetting: false,
      showContactUsView: false,
      showBillingPlans: false,
      drawerOpen: false,
      showDeviceBreakpoints: Utils.getDevOption("devOpts_ShowDeviceBreakpoints"),
      treeMode: Utils.checkLocalStorageOption("treeMode") || false,
      dataVersion: 0
    };

    this.appContext = this.createAppContext();

    this.props.msal.instance.addEventCallback((message) => {});

    this.loadingModel = new LoadingModel();
    this.loadingModel.debug = this.state.debugParams != null;

    this.setLoadingBarRef = (loadingBar) => {
      this.loadingBar = loadingBar;
    };

    this.setAppBarRef = (appBar) => {
      this.appBar = appBar;
    };

    this.setAppDrawerRef = (appDrawer) => {
      this.appDrawer = appDrawer;
    };

    this.setNotificationDrawerRef = (notificationDrawer) => {
      this.notificationDrawer = notificationDrawer;
    };

    this.setNotificationPopupRef = (notificationPopup) => {
      this.notificationPopup = notificationPopup;
    };

    this.dataVersion = 0;

    this.blockNavigation = false;
    this.navigateAwayCallback = null;
    this.allowNavigationCallback = null;

    this.notifications = [];

    if (Environment.isLocal) this.changeFavIcon("/favicon-localhost.ico");
    else if (Environment.isTest) this.changeFavIcon("/favicon-test.ico");
    else if (Environment.isDev) this.changeFavIcon("/favicon-dev.ico");

    LicenseInfo.setLicenseKey(
      "3715e94973e78bbbd359325da4c659bfTz04MTgxNixFPTE3MzYzOTM4NTUwMDAsUz1wcm8sTE09c3Vic2NyaXB0aW9uLEtWPTI="
    );

    this.currentBreakpoint = this.state.theme.getCurrentBreakpoint()[0];
    window.addEventListener("resize", this.onWindowResize.bind(this));

    window.signOut = this.signOut.bind(this);
    window.printTestInfo = this.printTestInfo.bind(this);

    window.addEventListener("popstate", this.onPopState.bind(this));
  }

  onWindowResize(e) {
    const { theme } = this.state;
    const breakpoint = theme.getCurrentBreakpoint()[0];

    if (breakpoint !== this.currentBreakpoint) {
      this.currentBreakpoint = breakpoint;
      this.forceUpdate();
    }
  }

  onPopState(e) {
    this.blockNavigation = false;
  }

  createAppContext() {
    return {
      isAppContext: true,
      msal: this.props.msal,
      setLoading: this.setLoading.bind(this),
      showNotifications: this.showNotifications.bind(this),
      devMode: false,
      newDesign: this.state.themeName === "v2",
      eventBus: new EventBus()
    };
  }

  static getTestDetails(organization, user) {
    let info = [];
    info.push("Version:\t\t\t" + process.env.REACT_APP_VERSION);
    info.push("Environment:\t\t" + Environment.get() + " (" + window.location.host + ")");
    info.push("URL:\t\t\t\t" + window.location.href);
    if (user) {
      info.push(
        "Impersonated user:\t" +
          (user.impersonating
            ? user.fullName +
              " (Job: " +
              user.jobTitle +
              (user.isDev ? ", Dev" : "") +
              (user.isOrganizationAdmin ? ", Org Admin" : "") +
              (user.isBillingUser ? ", Billing Admin" : "") +
              (user.isTeamLead ? ", Team Lead" : "") +
              (user.hasGroup ? ", Group manager" : "") +
              ")"
            : "<None>")
      );
      info.push(
        "Logged in user:\t\t" +
          user.loggedInUserFullName +
          " (" +
          (user.loggedInUserJobTitle ? "Job: " + user.loggedInUserJobTitle + ", " : "") +
          "Email: " +
          user.email +
          ")"
      );
    }
    if (organization)
      info.push(
        "Organization:\t\t" + organization.name + (organization.isDemo ? " (Demo org)" : "")
      );

    return info.join("\n");
  }

  printTestInfo() {
    const { organization, user } = this.state;
    return "";
  }

  getTheme(name) {
    return new Theme(
      createTheme({
        name: "v2",
        palette: {
          primary: {
            light: "#0000FF",
            main: Theme.getStyleVar("--neon-blue-nb-400"),
            dark: "#000000"
          },
          secondary: {
            light: "#ff0000",
            main: Theme.getStyleVar("--neon-blue-nb-400"),
            dark: "#ff0000"
          },
          error: {
            main: Theme.getStyleVar("--torch-red-tr-500")
          }
        },
        breakpoints: {
          values: {
            xs: 0,
            sm: 600,
            md: 900,
            lg: 1200,
            xl: 1920
          }
        }
      })
    );
  }

  componentDidMount() {
    this.showPage(App.defaultPage);

    Analytics.initialize();

    this.getLoginUser();
  }

  async getLoginUser(updateCache) {
    const { settingLandingEnabled } = this.props;

    this.setLoading("user", true);

    CurrentUser.get(this.appContext, updateCache)
      .then((user) => {
        this.appContext.devMode = user && user.isDeveloper;
        this.appContext.user = user;
        this.appContext.canChangeUser = user && user.canChangeUser;
        this.appContext.canChangeOrg = user && user.canChangeOrg;

        this.setState(
          { user, showInactiveView: user.status === CurrentUser.status.inactive },
          (state) => {
            this.getOrganisation();
            if (this.state.user.hasGroup) this.getGroups();
          }
        );

        if (
          (user.isOrganizationAdmin === true || user.isBillingUser === true) &&
          user.hasAcceptedTerms === false
        ) {
          this.props.history.push("/" + App.pages.signup.slug);
        }
      })
      .catch((e) => this.showError(new ErrorModel(ErrorModel.Severity.FAIL, e, "User API")))
      .finally(() => this.setLoading("user", false));
  }

  onInitNotifications(notifications) {
    this.notifications[notifications.mode.name] = notifications;

    notifications.unread.onChange = this.onReadNotificationsChange.bind(this);
    notifications.onNotificationReceived = this.onNotificationReceived.bind(this);

    if (this.appBar) this.appBar.setUnreadNotifications(notifications.unread.count);
    if (this.appDrawer) this.appDrawer.setUnreadNotifications(notifications.unread.count);
    if (this.notificationDrawer)
      this.notificationDrawer.setState({ notifications: this.notifications });
  }

  getOrganisation() {
    this.setLoading("org", true);

    Organization.get(this.appContext)
      .then((org) => {
        Analytics.identify(this.state.user, org);

        this.setState({
          organization: org,
          showInactiveView:
            this.state.showInactiveView ||
            (org.status === Organization.status.inactive && !org.isDemo),
          demoOrgResetting: org.demoOrgStatus === Organization.status.resetting
        });

        NotificationsData.startNotifications(this.appContext)
          .then((n) => this.onInitNotifications(n))
          .catch((e) =>
            this.showError(new ErrorModel(ErrorModel.Severity.FAIL, e, "Notifications connection"))
          );
      })
      .catch((e) => this.showError(new ErrorModel(ErrorModel.Severity.FAIL, e, "Organization API")))
      .finally(() => this.setLoading("org", false));
  }

  getGroups() {
    this.setLoading("groups", true);

    GroupsList.get(this.appContext)
      .then((groups) => {
        this.setState({ groups }, () => {
          if (this.appDrawer) this.appDrawer.updateNav();
        });
      })
      .catch((e) => this.showError(new ErrorModel(ErrorModel.Severity.FAIL, e, "Groups API")))
      .finally(() => this.setLoading("groups", false));
  }

  loadNotifications(mode, groupId) {
    //if (this.notifications[mode.name] !== undefined) return;
    this.setLoading("notifications", true);

    NotificationsData.get(this.appContext, mode, groupId)
      .then((n) => this.onInitNotifications(n))
      .catch((e) => this.showError(new ErrorModel(ErrorModel.Severity.INFO, e, "Notification API")))
      .finally(() => this.setLoading("notifications", false));
  }

  setTheme(theme) {
    this.setLoading("switchTheme", true);
    this.appContext.newDesign = theme === "v2";

    window.localStorage.setItem("theme", theme);
    this.setState({ themeName: theme, theme: this.getTheme(theme) }, () => {
      this.setLoading("switchTheme", false);
    });
  }

  onReadNotificationsChange(count) {
    if (this.appBar) this.appBar.setUnreadNotifications(count);
  }

  onLoadNotifications(mode, groupId) {
    this.loadNotifications(mode, groupId);
  }

  onNotificationReceived(notif, numUnread) {
    if (notif && notif.type === Notification.type.employeeAddedAsGroupManager) {
      //Todo: refresh group menu
      return;
    }

    if (this.notificationDrawer) this.notificationDrawer.onNotificationReceived(notif, numUnread);
    if (this.appBar) this.appBar.onNotificationReceived(notif, numUnread);
    if (this.notificationPopup) this.notificationPopup.onNotificationReceived(notif, numUnread);

    if (notif && notif.type == Notification.type.demoOrgResetting) {
      this.setState({ demoOrgResetting: true });
      return;
    } else if (notif && notif.type == Notification.type.demoOrgResetDone) {
      setTimeout((t) => window.location.reload(), 1000);
      return;
    }

    Object.values(App.pages).map((page) => {
      if (page.ref.current) page.ref.current.onNotificationReceived(notif, numUnread);
    });
  }

  showError(e) {
    if (e.isFail()) {
      this.loadingModel.resetAll();
      this.setLoading(null, false);
    }
    this.setState({ error: e });
  }

  onShowError(e) {
    this.showError(e);
  }

  isDebugMode(mode) {
    return this.state.debugParams && this.state.debugParams.includes(mode);
  }

  setLoading(event, loading) {
    this.loadingModel.setLoading(event, loading);
    if (this.loadingBar) this.loadingBar.setLoading(this.loadingModel.isLoading);
    this.appContext.eventBus.dispatch(
      this.loadingModel.isLoading ? EventBus.event.loadingStart : EventBus.event.loadingEnd,
      null
    );
  }

  setUser(data) {
    let user = new CurrentUser(data);
    user.onSignOut = this.onSignOut.bind(this);
    this.setState({ user: user });
  }

  onSignOut() {
    this.setLoading("signOut", true);

    axios
      .post(Data.apiBasePath + "/User/ClearCookies", null, {
        withCredentials: true
      })
      .finally(() => {
        this.signOut();
      });
  }

  signOut() {
    this.props.msal.instance
      .logoutRedirect()
      .then(() => {})
      .catch((e) => {
        console.error(e);
      })
      .finally(() => this.setLoading("signOut", true));
  }

  onSignIn() {}

  onShowSwitchUserDialog() {
    this.setState({ showSwitchUserDialog: true });
  }

  onSwitchUser(e, user) {
    this.setLoading("switchUser", true);
    ImpersonateUser.post(this.appContext, user ? user.employeeId : null)
      .then((n) => (window.location = "/"))
      .catch((e) => {
        this.showError(new ErrorModel(ErrorModel.Severity.INFO, e, "Switch user API"));
      })
      .finally(() => this.setLoading("switchUser", false));
  }

  closeSwitchUserDialog() {
    this.setState({ showSwitchUserDialog: false });
  }

  showPage(page) {
    this.setState({ activePage: page });
  }

  onCloseErrorSnackbar(e) {
    if (!this.state.error.isFail()) this.setState({ error: null });
  }

  onCloseNotificationSnackbar(e) {
    if (!this.state.error.isFail()) this.setState({ error: null });
  }

  onShowNotifications(e) {
    this.showNotifications(DashboardData.mode.personal, true);
  }

  showNotifications(mode, show) {
    if (!this.notificationDrawer) return;
    this.notificationDrawer.open(mode, show);
  }

  onNotificationClick(e, notif, groupId) {
    const { history } = this.props;

    if (notif.isMode(DashboardData.mode.personal)) {
      if (notif.type === Notification.type.participantAcknowledged) {
        history.push("/" + App.pages.practicals.slug + "/edit/" + notif.practicalId);
      } else if (notif.type === Notification.type.participateAcknowedgementRequest) {
        history.push(
          "/" + App.pages.practicals.slug + "/confirmation/" + notif.practicalParticipantId
        );
      } else {
        history.push("/" + App.pages.profile.slug + "/competencies#" + notif.employeeAttributeId);
      }
    } else if (notif.isMode(DashboardData.mode.team)) {
      history.push("/" + App.pages.feedback.slug + "/review/" + notif.employeeAttributeId);
    } else if (notif.isMode(DashboardData.mode.groups)) {
      history.push(
        "/" +
          App.pages.feedback.slug +
          "/" +
          (groupId ? groupId : notif.groupId) +
          "/review/" +
          notif.employeeAttributeId
      );
    }

    //This call will trigger SignalR to push an update with the new unread count

    axios
      .put(
        Data.apiBasePath + "/Notifications",
        {
          notificationId: notif.id,
          notificationState: Notification.state.read.index
        },
        {
          withCredentials: true
        }
      )
      .then((response) => {
        if (this.appBar)
          this.appBar.setUnreadNotifications(response.data.totalNumberOfUnreadNotification);
      })
      .catch((e) => {});
  }

  onSettingsChange(change) {
    this.appContext.eventBus.dispatch(EventBus.event.dataChanged);

    this.setState({ dataVersion: this.state.dataVersion + 1 });
  }

  getPageProps(page) {
    if (page === App.pages.dashboard)
      return { onNotificationClick: this.onNotificationClick.bind(this) };
    else if (page === App.pages.settings)
      return {
        onChangeCurrentUserFromSettings: this.onChangeCurrentUserFromSettings.bind(this),
        onDemoSwitch: this.onDemoSwitch.bind(this),
        onContactUs: this.onShowContactForm.bind(this),
        onChange: this.onSettingsChange.bind(this)
      };
    else if (page === App.pages.signup)
      return {
        onComplete: this.onSignUpComplete.bind(this)
      };
    return {};
  }

  onChangeCurrentUserFromSettings(employee) {
    this.getLoginUser(true);
  }

  onOpenDashboard() {
    this.props.history.push("/" + App.pages.dashboard.slug);
  }

  onOpenDevDialog() {
    const user = this.state.user;
    if ((user && user.isDeveloper) || Environment.isDev || Environment.isLocal)
      this.setState({ showDevDialog: true });
  }

  onOpenSettings() {
    const { history } = this.props;
    history.push("/" + App.pages.settings.slug);
  }

  onCloseDevDialog(e) {
    this.setState({ showDevDialog: false });
  }

  onCloseContactUsDialog(e) {
    const { history } = this.props;
    const { contactUsRedirect } = this.state;

    this.setState({ showContactUsView: false }, (s) => history.push(contactUsRedirect || "/"));
  }

  changeFavIcon(path) {
    document.querySelectorAll("link[rel*='icon']").forEach((l) => {
      l.href = path;
    });
  }

  onLogoClick(e) {
    const { settingLandingEnabled } = this.props;
    const { user, organization, theme } = this.state;

    /*if (organization && !organization.isDemo) {
            if (user.isOrganizationAdmin) this.props.history.push('/' + App.pages.settings.slug + (settingLandingEnabled ? '?showLanding=true' : ''));
            else if (user.isBillingUser) this.props.history.push(Settings.subPages.billing.paths[0]);
        }
        else */ this.props.history.push("/" + App.pages.profile.slug);
  }

  onAcceptTerms() {
    const { user } = this.state;

    this.setLoading("acceptTerms", true);

    axios
      .put(Data.apiBasePath + "/Organization/AcceptTerms", null, {
        withCredentials: true
      })
      .then((response) => {
        Analytics.event("acceptTerms");
        user.hasAcceptedTerms = true;
        this.setState({ user });
      })
      .catch((e) => {
        this.showError(new ErrorModel(ErrorModel.Severity.INFO, e, "Accept terms API"));
      })
      .finally(() => {
        this.setLoading("acceptTerms", false);
      });
  }

  onDemoSwitch(e, demo) {
    this.setLoading("switchDemo", true);

    axios
      .post(Data.apiBasePath + "/Organization/SwitchDemo?switchToDemo=" + demo, null, {
        withCredentials: true
      })
      .then((response) => {
        //window.location.reload();
        window.location.href = demo ? "/?ref=ds" : "/";
      })
      .catch((e) => {
        this.showError(new ErrorModel(ErrorModel.Severity.INFO, e, "Switch demo API"));
      })
      .finally(() => {
        this.setLoading("switchDemo", false);
      });
  }

  onUpgradePlan() {
    this.setState({ error: null, showInactiveView: false });
    this.props.history.push(Settings.subPages.billing.paths[0] + "/plan");
  }

  onShowContactForm(e, contactUsRedirect) {
    this.setState({ showContactUsView: true, contactUsRedirect });
  }

  onMenuButtonClick(e) {
    this.setState({ drawerOpen: !this.state.drawerOpen });
  }

  onDrawerClose(e) {
    this.setState({ drawerOpen: false });
  }

  onChangeViewMode(treeMode) {
    this.setState({ treeMode });
    Utils.setLocalStorageOption("treeMode", treeMode);
  }

  onSignUpComplete(e) {
    window.location.reload();
  }

  onNavigation(e, allowNavigationCallback) {
    if (this.blockNavigation && this.navigateAwayCallback) {
      e.preventDefault();
      this.pendingNavigationEvent = e;
      this.allowNavigationCallback = allowNavigationCallback;
      // console.log('Navigation blocked');
      // console.log(e);
      // console.log(allowNavigationCallback);
      this.navigateAwayCallback(e);
      return false;
    }
    if (allowNavigationCallback) allowNavigationCallback(e);
    return true;
  }

  onAllowNavigation(e) {
    if (this.pendingNavigationEvent) {
      this.blockNavigation = false;
      // console.log('onAllowNavigation');
      // console.log(this.allowNavigationCallback);
      if (this.allowNavigationCallback) this.allowNavigationCallback(e);
      else this.pendingNavigationEvent.target.click();
    }
  }

  onBlockNavigation(blockNav, blockLocation, callback) {
    // console.log('Navigation ' + (blockNav ? 'blocked' : 'unblocked') + ' at ' + blockLocation);
    this.blockNavigation = blockNav;
    this.navigateAwayCallback = callback;
  }

  render() {
    const { location } = this.props;
    const {
      organization,
      user,
      groups,
      showSwitchUserDialog,
      error,
      unreadNotifications,
      themeName,
      theme,
      showDevDialog,
      showInactiveView,
      demoOrgResetting,
      showContactUsView,
      drawerOpen,
      showDeviceBreakpoints,
      contactUsRedirect,
      treeMode,
      dataVersion
    } = this.state;

    const showBillingBanner =
      user &&
      organization &&
      user.status !== CurrentUser.status.inactive &&
      user.hasAcceptedTerms &&
      organization.isTrial &&
      (organization.trialExpired || user.isOrganizationAdmin || user.isBillingUser) &&
      !organization.isDemo &&
      organization.status !== Organization.status.inactive;

    let pageMode = "normal";
    if (showInactiveView) pageMode = "inactive";
    else if (demoOrgResetting) pageMode = "demoResetting";
    else if (error && error.isFail()) pageMode = "error";
    //else if(window.app.env.MAINTENANCE_MODE)...

    return (
      <AppContext.Provider value={this.appContext}>
        <ThemeProvider theme={theme.muiTheme}>
          <div
            className={
              "klayo-layout klaro-theme_" +
              themeName +
              (showBillingBanner ? " klayo-layout--billingbanner" : "") +
              (Environment.isDev && this.allowDevBar ? " klayo-layout--devmode" : "")
            }
          >
            <LoadingBar
              ref={this.setLoadingBarRef}
              sx={{ zIndex: 99999, width: "100vw", position: "fixed" }}
            />

            {Environment.isDev && this.allowDevBar && <div className='klayo-devbar'></div>}

            {showBillingBanner && (
              <BillingBanner
                onUpgradePlan={this.onUpgradePlan.bind(this)}
                organization={organization}
                user={user}
              />
            )}

            <AppDrawer
              ref={this.setAppDrawerRef}
              organization={organization}
              theme={theme}
              user={user}
              groups={groups}
              location={location}
              open={drawerOpen}
              devMode={this.appContext.devMode || Environment.isDev || Environment.isLocal}
              defaultNavItem={App.defaultPage}
              navItems={Object.values(App.pages).filter(
                (page) =>
                  page.showInNav !== false &&
                  user &&
                  organization &&
                  page.hasAccess(user, organization)
              )}
              getChildNavItems={(i) => {
                if (i === App.pages.groups && groups)
                  return Object.values(groups.groups).map((g) => ({
                    label: g.name,
                    path: "/groups/" + g.groupId
                  }));
                else if (i === App.pages.settings)
                  return Object.values(Settings.subPages)
                    .filter(
                      (i) =>
                        i.isShowInSideBar !== false &&
                        user &&
                        organization &&
                        i.hasAccess(user, organization)
                    )
                    .map((i) => ({ label: i.name, path: i.paths[0] }));
              }}
              allowSwitchUser={user && user.canChangeOrg}
              unreadNotifications={unreadNotifications}
              onClose={this.onDrawerClose.bind(this)}
              onShowNotifications={this.onShowNotifications.bind(this)}
              onShowSwitchUserDialog={this.onShowSwitchUserDialog.bind(this)}
              onOpenDevDialog={this.onOpenDevDialog.bind(this)}
              onOpenSettings={this.onOpenSettings.bind(this)}
              onSignOut={this.onSignOut.bind(this)}
              // allowDemoSwitch={
              //   organization &&
              //   (organization.isDemo ||
              //     (user &&
              //       user.isOrganizationAdmin &&
              //       user.hasDemoEmployee &&
              //       organization.demoOrgStatus !== Organization.status.resetting &&
              //       theme.isBreakpointUp("md")))
              // }
              onDemoSwitch={this.onDemoSwitch.bind(this)}
              onNavigation={this.onNavigation.bind(this)}
              onLogoClick={this.onLogoClick.bind(this)}
            />

            <AppBar
              ref={this.setAppBarRef}
              organization={organization}
              location={location}
              theme={theme}
              user={user}
              onShowNotifications={this.onShowNotifications.bind(this)}
              onShowSwitchUserDialog={this.onShowSwitchUserDialog.bind(this)}
              onOpenDashboard={this.onOpenDashboard.bind(this)}
              onOpenDevDialog={this.onOpenDevDialog.bind(this)}
              onOpenSettings={this.onOpenSettings.bind(this)}
              onSwitchTheme={this.setTheme.bind(this)}
              unreadNotifications={unreadNotifications}
              onSignIn={this.onSignIn.bind(this)}
              onSignOut={this.onSignOut.bind(this)}
              themeName={themeName}
              defaultNavItem={App.defaultPage}
              navItems={Object.values(App.pages).filter(
                (page) =>
                  page.showInNav !== false &&
                  user &&
                  organization &&
                  page.hasAccess(user, organization)
              )}
              showActions={true}
              onLogoClick={this.onLogoClick.bind(this)}
              onDemoSwitch={this.onDemoSwitch.bind(this)}
              onMenuButtonClick={this.onMenuButtonClick.bind(this)}
              onNavigation={this.onNavigation.bind(this)}
              sx={{ display: { xs: "flex", lg: "none" } }}
            />

            <ErrorBoundary>
              {pageMode === "error" && (
                <Fade in={true}>
                  <Box sx={{ marginLeft: { xs: "0", lg: "276px" } }}>
                    <ErrorPage theme={theme} error={error} />
                  </Box>
                </Fade>
              )}

              {pageMode === "inactive" && (organization || user) && (
                <Fade in={true}>
                  <Box sx={{ marginLeft: { xs: "0", lg: "276px" } }}>
                    <InactiveView
                      organization={organization}
                      user={user}
                      theme={theme}
                      demoOrgResetting={demoOrgResetting}
                      onBuyClick={this.onUpgradePlan.bind(this)}
                      onContactClick={this.onShowContactForm.bind(this)}
                    />
                  </Box>
                </Fade>
              )}

              {pageMode === "demoResetting" && organization && user && (
                <Fade in={true}>
                  <div>
                    <UnavailableView
                      organization={organization}
                      user={user}
                      theme={theme}
                      heading='Your demo organization is resetting'
                      message={
                        <div>
                          Please wait while your Demo organization data is being reset. This may
                          take a few minutes. Please try again soon.
                          <br />
                          <br />
                          Alternatively, you can switch to your organization.
                        </div>
                      }
                      actions={["switchOrg", "tryAgain"]}
                      demoOrgResetting={demoOrgResetting}
                      onSwitchToOrganization={this.onDemoSwitch.bind(this)}
                    />
                  </div>
                </Fade>
              )}

              {pageMode === "normal" && (
                <Switch>
                  {Object.values(App.pages)
                    .sort((a, b) => (a === App.defaultPage) - (b === App.defaultPage))
                    .map((page, index) => {
                      const paths = ["/" + page.slug];
                      if (page === App.defaultPage) paths.push("/");
                      return (
                        <Route
                          key={index}
                          exact={false}
                          path={paths}
                          render={(props) => (
                            <Fade in={true}>
                              <Box
                                sx={
                                  page === App.pages.signup
                                    ? {
                                        zIndex: "9999",
                                        position: "absolute",
                                        width: "100%",
                                        height: "100%"
                                      }
                                    : { marginLeft: { xs: "0", lg: "276px" } }
                                }
                              >
                                {React.createElement(page.component, {
                                  ref: page.ref,
                                  ...props,
                                  organization,
                                  user,
                                  groups,
                                  theme,
                                  treeMode,
                                  dataVersion,
                                  showBillingBanner,
                                  onShowError: this.onShowError.bind(this),
                                  onChangeViewMode: this.onChangeViewMode.bind(this),
                                  onNavigation: this.onNavigation.bind(this),
                                  onBlockNavigation: this.onBlockNavigation.bind(this),
                                  onAllowNavigation: this.onAllowNavigation.bind(this),
                                  ...this.getPageProps(page)
                                })}
                              </Box>
                            </Fade>
                          )}
                        />
                      );
                    })}
                </Switch>
              )}
            </ErrorBoundary>
          </div>
          {showSwitchUserDialog && (
            <SwitchUserDialog
              organization={organization}
              user={user}
              theme={theme}
              onClose={this.closeSwitchUserDialog.bind(this)}
              onSwitchUser={this.onSwitchUser.bind(this)}
            />
          )}

          {user && (
            <NotificationDrawer
              ref={this.setNotificationDrawerRef}
              organization={organization}
              user={user}
              groups={groups ? groups.groups.filter((g) => g.hasManagePermission) : null}
              onLoadNotifications={this.onLoadNotifications.bind(this)}
              onNotificationClick={this.onNotificationClick.bind(this)}
              onNavigation={this.onNavigation.bind(this)}
            />
          )}

          <NotificationPopup ref={this.setNotificationPopupRef} />

          {showDevDialog && (
            <DevDialog
              user={user}
              theme={theme}
              organization={organization}
              onShowSwitchUserDialog={this.onShowSwitchUserDialog.bind(this)}
              onSwitchUser={this.onSwitchUser.bind(this)}
              onClose={this.onCloseDevDialog.bind(this)}
            />
          )}

          {error && !error.isFail() && (
            <Snackbar
              open={true}
              type='error'
              duration={6000}
              onClose={this.onCloseErrorSnackbar.bind(this)}
              message={error.message}
            />
          )}

          {showContactUsView && (
            <ContactUsDialog
              theme={theme}
              organization={organization}
              user={user}
              redirect={contactUsRedirect}
              onClose={this.onCloseContactUsDialog.bind(this)}
            />
          )}

          {showDeviceBreakpoints && <BreakpointIndicator theme={theme} />}
        </ThemeProvider>
      </AppContext.Provider>
    );
  }
}

export default withRouter(App);
