import React, { Component } from "react";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DesktopTimePicker, MobileTimePicker } from "@mui/x-date-pickers";
import { TextField } from "./TextField";
import { ReactComponent as ClockIcon } from "../resources/images/icons-clock.svg";
import styled from "@emotion/styled";
import { KLAYO_COLORS } from "../themes";

export class TimePicker extends Component {
  // static defaultProps = {
  //     allowEmpty: true,
  //     allowFuture: true,
  //     allowPast: true,
  //     showPastWarning: false,
  //     showPastWarningMessage: 'Please select a current or future date',
  //     inputFormat: 'dd/MM/yyyy',
  //     readOnly: false
  // }

  constructor(props) {
    super(props);

    this.state = {
      value: props.value
    };
  }

  componentDidUpdate(prevProps, prevState) {}

  onChange(value) {
    const { onChange } = this.props;
    // const { error } = this.state;

    // const valid = this.isValid(value);
    const convertedValue = this.convertDateToTimeString(value);
    this.setState({ value: convertedValue });

    if (onChange) onChange(convertedValue);
  }

  convertDateToTimeString(date) {
    if (!date) return null;

    const hours = date.getHours();
    const minutes = date.getMinutes();

    const meridiem = hours >= 12 ? "PM" : "AM";

    const hours12 = hours % 12 || 12;

    return `${hours12}:${minutes} ${meridiem}`;
  }

  convertTimeStringToDate(timeStr) {
    if (!timeStr) return null;

    // time format hh:mm AM/PM

    const [time, meridiem] = timeStr.split(" ");
    let [hours, minutes] = time.split(":").map(Number);

    if (meridiem === "PM" && hours < 12) hours += 12;
    if (meridiem === "AM" && hours === 12) hours = 0;

    const date = new Date();
    date.setHours(hours, minutes, 0); // Set hours, minutes, and reset seconds

    return date;
  }

  renderTimePicker(props) {
    const { theme } = this.props;
    return theme && theme.isBreakpointDown("md") ? (
      <MobileTimePicker {...props} />
    ) : (
      <DesktopTimePickerStyled {...props} />
    );
  }

  render() {
    const {
      label,
      disabled,
      sx,
      inputFormat,
      readOnly,
      autoFocus,
      helperText,
      showPastWarning,
      showPastWarningMessage
    } = this.props;
    const { value } = this.state;

    return (
      <TimePickerStyled className='Klayo_time-picker'>
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          {label && <label className='time-picker-label'>{label}</label>}

          {this.renderTimePicker({
            value: this.convertTimeStringToDate(value),
            onChange: this.onChange.bind(this),
            disabled: disabled,
            clockIcon: <span>Digital</span>,
            components: {
              OpenPickerIcon: ClockIcon
            },
            slotProps: {
              popper: {
                className: "klayo_time-picker-popper"
              },
              actionBar: {
                open: false
              }
            },
            renderInput: (params) => <TextField {...params} />
          })}
        </LocalizationProvider>
      </TimePickerStyled>
    );
  }
}

const TimePickerStyled = styled.div`
  &.Klayo_time-picker {
    .time-picker-label {
      font-size: 14px;
      font-weight: 500;
      font-style: normal;
      line-height: 20px;
      color: ${KLAYO_COLORS.GreyGR700};
      padding-left: 14px;
      padding-bottom: 5px;
      display: block;
    }

    .MuiInputBase-input {
      padding: 9.5px 14px;
    }

    .MuiOutlinedInput-root {
      border-radius: 20px;
    }

    .Mui-focused {
      .MuiOutlinedInput-notchedOutline {
        border-color: ${KLAYO_COLORS.NeonBlueNB400};
      }
    }

    .MuiOutlinedInput-notchedOutline {
      border-color: ${KLAYO_COLORS.NeonBlueNB200};
    }
  }
`;

const DesktopTimePickerStyled = styled(DesktopTimePicker)`
  // width: 162px;
`;
