import { Grid, Link, Stack } from "@mui/material";
import { React, Component } from "react";
import { Paper } from "../../components/Paper";
import { ReactComponent as ErrorIcon } from "../../resources/images/icons-warning.svg";
import { ReactComponent as MaintenanceDrawing } from "../../resources/images/drawing-maintenance.svg";
import { ReactComponent as OfflineDrawing } from "../../resources/images/drawing-offline.svg";
import { Button } from "../../components/Button";

export class ErrorPage extends Component {
  static defaultProps = {
    retryInterval: 30000 //30 secs
  };

  constructor(props) {
    super(props);

    this.state = {
      showMore: false,
      offline: !window.navigator.onLine,
      apiDown: window.navigator.onLine && props.error && props.error.isNetworkError
    };
  }

  componentDidMount() {
    if (this.state.apiDown) {
      //Todo: replace with ping/better check/status server - clear timer
      const timerId = setTimeout((t) => {
        window.location.reload();
      }, this.props.retryInterval);
    }
  }

  onShowMore() {
    this.setState({ showMore: !this.state.showMore });
  }

  onRetryClick(e) {
    window.location.reload();
  }

  renderMaintenanceView() {
    return (
      <Paper
        className='klayo-inactiveuserview'
        padding='44px 64px 48px 64px'
        sx={{ margin: { xs: "120px 16px 0 16px", md: "120px auto 0 auto" } }}
      >
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <h1>Under maintenance</h1>
            <div className='klayo-inactiveuserview_message'>
              We apologise for any inconvenience caused. We’re almost done!
            </div>
            <Stack direction='row' className='klayo-inactiveuserview_actions' spacing={2}>
              <Button label='Retry' variant='filled' onClick={this.onRetryClick.bind(this)} />
            </Stack>
          </Grid>
          <Grid item xs={6} className='klayo-inactiveuserview_drawing'>
            <MaintenanceDrawing />
          </Grid>
        </Grid>
      </Paper>
    );
  }

  renderOfflineView() {
    return (
      <Paper className='klayo-inactiveuserview' padding='44px 64px 48px 64px'>
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <h1>No internet connection</h1>
            <div className='klayo-inactiveuserview_message'>
              Please check your internet connection and retry.
            </div>
            <Stack direction='row' className='klayo-inactiveuserview_actions' spacing={2}>
              <Button label='Retry' variant='filled' onClick={this.onRetryClick.bind(this)} />
            </Stack>
          </Grid>
          <Grid item xs={6} className='klayo-inactiveuserview_drawing'>
            <OfflineDrawing />
          </Grid>
        </Grid>
      </Paper>
    );
  }

  renderError() {
    const { error } = this.props;
    const { showMore } = this.state;

    return (
      <Paper className='klayo-errorview' padding='30px 50px 50px 50px'>
        <Stack direction='row'>
          <ErrorIcon />
          <h1>Error</h1>
        </Stack>
        <div className='klayo-errorview_message'>
          An error has occurred. Please{" "}
          <Link href='https://www.klayo.com/contact-us/' target='_blank'>
            contact support
          </Link>
          .
        </div>
        {error && (
          <div>
            <Link onClick={this.onShowMore.bind(this)} className='klayo-errorview_morelink'>
              {showMore ? "Hide details" : "Learn more"}
            </Link>
            {showMore && (
              <div className='klayo-errorview_details'>
                <div>
                  <b>Detailed message:</b> {error.message}
                </div>
                {error.httpStatus !== null && (
                  <div>
                    <b>HTTP Status:</b> {error.httpStatus}
                  </div>
                )}
                {error.source !== null && (
                  <div>
                    <b>Source:</b> {error.source}
                  </div>
                )}
              </div>
            )}
          </div>
        )}
      </Paper>
    );
  }

  render() {
    const { error } = this.props;
    const { offline, apiDown } = this.state;

    if (apiDown) return this.renderMaintenanceView();
    else if (offline) return this.renderOfflineView();
    else return this.renderError();
  }
}
