import {
  Drawer,
  Box,
  AppBar,
  IconButton,
  Toolbar,
  Typography,
  Tabs,
  Tab,
  Fade,
  Stack
} from "@mui/material";
import { React, Component } from "react";
import { NotificationsData } from "../data/notifications/NotificationsData";
import { DashboardData } from "../data/DashboardData";
import CloseIcon from "@mui/icons-material/Close";
import { AppContext } from "../common/AppContext";
import { NotificationList } from "./NotificationList";
import { Button } from "./Button";
import { SelectMenu } from "./SelectMenu";

export class NotificationDrawer extends Component {
  static contextType = AppContext;

  static defaultProps = {
    width: 400
  };

  constructor(props) {
    super(props);

    this.state = {
      open: false,
      mode: DashboardData.mode.personal,
      notifications: [],
      groups: props.groups,
      group: null
    };
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.groups !== this.props.groups) this.setState({ groups: this.props.groups });
  }

  onClose(e) {
    this.close();
  }

  onModeChange(e, mode) {
    this.setState({ mode }, () => this.loadNotifications(mode));
  }

  loadNotifications(mode, groupId) {
    this.props.onLoadNotifications(mode, groupId);
  }

  componentDidMount() {
    this.loadNotifications(this.state.mode);
  }

  onNotificationReceived(notif) {
    this.forceUpdate();
  }

  onNavigation(e, callback) {
    const { onNavigation } = this.props;
    return onNavigation && onNavigation(e, callback);
  }

  onNotificationClick(e, notif) {
    this.close();

    const { onNotificationClick } = this.props;
    const { group } = this.state;

    if (onNotificationClick)
      this.onNavigation(e, (e) => onNotificationClick(e, notif, group ? group.groupId : null));
  }

  onGroupChange(e) {
    const { groups } = this.state;
    this.setState(
      { group: groups ? groups.find((g) => g.groupId === e.target.value.id) : null },
      () =>
        this.loadNotifications(
          DashboardData.mode.groups,
          this.state.group ? this.state.group.groupId : undefined
        )
    );
  }

  open(mode, open) {
    const modeChange = this.state.mode !== mode;
    this.setState({ open: open === true || open === undefined, mode }, () => {
      if (modeChange) this.onModeChange(null, mode);
    });
  }

  close() {
    this.setState({ open: false });
  }

  render() {
    const { organization, user, width, groups } = this.props;
    const { open, mode, notifications, group } = this.state;

    const groupItems = [];
    if (groups) {
      groupItems.push({ label: "All groups" });
      groupItems.push(...Object.values(groups).map((g) => ({ id: g.groupId, label: g.name })));
    }

    return (
      <Drawer
        open={open}
        className='klayo-notificationdrawer klayo-drawer'
        anchor='right'
        onClose={this.onClose.bind(this)}
        sx={{
          "& .MuiDrawer-paper": {
            overflow: "hidden"
          }
        }}
      >
        <Stack
          direction='column'
          sx={{
            width: {
              xs: "100vw",
              sm: width
            },
            height: "100%"
          }}
        >
          <div>
            <Toolbar
              className='klayo-notificationdrawer_toolbar'
              sx={{
                padding: {
                  xs: "24px 24px!important",
                  sm: "24px 24pxpx!important",
                  md: "24px 32px!important"
                }
              }}
            >
              <Box
                className='klayo-notificationdrawer_title'
                sx={{ flexGrow: 1, fontSize: { xs: "20px", sm: "20px", md: "24px" } }}
              >
                Notifications
              </Box>
              <Button
                className='klayo-appdrawer_closebutton'
                autoFocus
                variant='simple'
                size='sm'
                icon={<CloseIcon />}
                onClick={this.onClose.bind(this)}
              />
            </Toolbar>

            {user && (user.isTeamLead || user.hasGroup) && (
              <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                <Tabs
                  className='klayo-notificationdrawer_tabs'
                  centered={true}
                  value={mode}
                  variant='fullWidth'
                  onChange={this.onModeChange.bind(this)}
                  sx={{
                    padding: {
                      xs: "0 24px!important",
                      sm: "0 24pxpx!important",
                      md: "0 32px!important"
                    },
                    width: "100%"
                  }}
                >
                  <Tab label='Me' value={DashboardData.mode.personal} />
                  {user.isTeamLead && <Tab label='My team' value={DashboardData.mode.team} />}
                  {user.hasGroup && <Tab label='My groups' value={DashboardData.mode.groups} />}
                </Tabs>
              </Box>
            )}
          </div>
          <Box sx={{ flexGrow: 1, overflow: "auto" }}>
            {mode === DashboardData.mode.personal && (
              <Fade in={true}>
                <Box sx={{ height: "100%", display: "flex", justifyContent: "center" }}>
                  <NotificationList
                    organization={organization}
                    user={user}
                    notifications={notifications[DashboardData.mode.personal.name]}
                    onNotificationClick={this.onNotificationClick.bind(this)}
                  />
                </Box>
              </Fade>
            )}

            {user && user.isTeamLead && mode === DashboardData.mode.team && (
              <Fade in={true}>
                <Box sx={{ height: "100%", display: "flex", justifyContent: "center" }}>
                  <NotificationList
                    organization={organization}
                    user={user}
                    notifications={notifications[DashboardData.mode.team.name]}
                    onNotificationClick={this.onNotificationClick.bind(this)}
                  />
                </Box>
              </Fade>
            )}

            {user && user.hasGroup && groups && mode === DashboardData.mode.groups && (
              <Fade in={true}>
                <Stack direction='column' sx={{ height: "100%" }}>
                  <div style={{ padding: "16px" }}>
                    <SelectMenu
                      value={group ? groupItems.find((g) => g.id === group.groupId) : null}
                      firstValueDefault={true}
                      dense={true}
                      items={Object.entries(groupItems)}
                      clearable={false}
                      highlightSelection={false}
                      onChange={this.onGroupChange.bind(this)}
                      sx={{
                        width: "100%"
                      }}
                    />
                  </div>
                  <Box
                    sx={{
                      flexGrow: 1,
                      overflow: "auto",
                      display: "flex",
                      justifyContent: "center"
                    }}
                  >
                    <NotificationList
                      organization={organization}
                      user={user}
                      notifications={notifications[DashboardData.mode.groups.name]}
                      group={group}
                      onNotificationClick={this.onNotificationClick.bind(this)}
                    />
                  </Box>
                </Stack>
              </Fade>
            )}
          </Box>
        </Stack>
      </Drawer>
    );
  }
}
