import styled from "@emotion/styled";
import { Stack } from "@mui/material";
import { Delayed } from "klayowebshared";
import { AppContext } from "../../common/AppContext";
import { Paper } from "../../components/Paper";
import { SelectMenu } from "../../components/SelectMenu";
import { TrainingTable } from "../../components/table/TrainingTable";
import { TextField } from "../../components/TextField";
import { ViewComponent } from "../../components/ViewComponent";
import {
  COURSE_RECURRENCE,
  TableSearchDebounceTime,
  TRAINING_ATTRIBUTE_DIALOG_TYPE,
  TRAINING_FILTER_DEFAULT_VALUE
} from "../../constants";
import { SettingsCourseList } from "../../data/settings/SettingCourseList";
import { ReactComponent as SearchIcon } from "../../resources/images/icons-search.svg";
import { KLAYO_COLORS } from "../../themes";
import { getSortByApiValue } from "../../utilities";
import { TrainingAttributesDialog } from "./TrainingAttributesDialog";

const axios = require("axios").default;
export class SettingsTrainingView extends ViewComponent {
  static contextType = AppContext;

  static defaultProps = {
    allowAssignAdminBilling: true
  };

  constructor(props) {
    super(props);

    this.state = {
      ...ViewComponent.state,
      search: null,
      integrationId: null,
      actionTarget: null,
      associatedAttributeDetail: null,
      trainingDialogType: null,
      trainingError: null,
      isTrainingAttributesDialogShow: false,
      pageSize: SettingsCourseList.defaultPageSize,
      orderByConvertList: [
        { tableFieldName: "attributes", apiFieldName: "numberOfAssociatedAttributes" },
        {
          tableFieldName: "provider",
          apiFieldName: "organizationIntegrationName",
          subApiFieldName: "organizationIntegrationAbbreviation"
        }
      ],
      sortModel: [
        {
          field: "courseName",
          sort: "asc"
        }
      ],
      paginationModel: {
        page: 0,
        pageSize: SettingsCourseList.defaultPageSize
      },
      integrationFilter: TRAINING_FILTER_DEFAULT_VALUE.ALL,
      integrations: { ...TRAINING_FILTER_DEFAULT_VALUE },
      facultyFilter: TRAINING_FILTER_DEFAULT_VALUE.ALL,
      faculties: []
    };

    this.debounceTimer = null;
  }

  async componentDidMount() {
    window.scroll({ top: 0, left: 0, behavior: "smooth" });
    const { onLoadTrainingCourse, onLoadIntegration, onLoadTrainingFaculties } = this.props;
    onLoadTrainingCourse();
    onLoadIntegration();
    var faculties = await onLoadTrainingFaculties();
    if (faculties) {
      let facultyList = [TRAINING_FILTER_DEFAULT_VALUE.ALL];
      faculties.map((item) => {
        facultyList.push({
          value: item.id,
          label: item.name
        });
      });
      this.setState({ faculties: facultyList });
    }
  }

  componentDidUpdate(prevProps) {
    if (prevProps.integration !== this.props.integration) {
      this.onProcessIntegration();
    }
  }

  onProcessIntegration() {
    const { integration } = this.props;

    if (integration) {
      const { integrations } = integration;
      let integrationList = {
        ...TRAINING_FILTER_DEFAULT_VALUE
      };

      integrations.map((item) => {
        const label = item?.abbreviation ? item?.abbreviation : item.name;

        return (integrationList = {
          ...integrationList,
          [item.id]: {
            value: item.id,
            label: label
          }
        });
      });

      this.setState({ integrations: integrationList });
    }
  }

  onSearch(e) {
    const { onLoadTrainingCourse } = this.props;
    const {
      paginationModel: { pageSize },
      integrationId,
      facultyId,
      sortModel,
      orderByConvertList
    } = this.state;
    const [{ field, sort }] = sortModel;
    const orderBy = getSortByApiValue(orderByConvertList, field, sort);
    clearTimeout(this.debounceTimer);
    this.debounceTimer = setTimeout(() => {
      onLoadTrainingCourse(e.target.value, pageSize, orderBy, integrationId, facultyId);
    }, TableSearchDebounceTime);
    const newModel = { page: 0, pageSize };
    this.setState({ search: e.target.value, paginationModel: newModel });
  }

  onFilterChange(e) {
    const { onLoadTrainingCourse } = this.props;
    const {
      paginationModel: { pageSize },
      search,
      sortModel,
      orderByConvertList,
      facultyId
    } = this.state;
    const [{ field, sort }] = sortModel;
    const orderBy = getSortByApiValue(orderByConvertList, field, sort);
    const integrationId =
      e.target.value.value !== TRAINING_FILTER_DEFAULT_VALUE.ALL.value
        ? e.target.value.value
        : null;
    const newModel = { page: 0, pageSize };
    this.setState({ integrationFilter: e.target.value, integrationId, paginationModel: newModel });
    onLoadTrainingCourse(search, pageSize, orderBy, integrationId, facultyId);
  }

  onFilterFacultyChange(e) {
    console.log(e.target.value);
    const { onLoadTrainingCourse } = this.props;
    const {
      paginationModel: { pageSize },
      search,
      sortModel,
      orderByConvertList,
      integrationId
    } = this.state;
    const [{ field, sort }] = sortModel;
    const orderBy = getSortByApiValue(orderByConvertList, field, sort);
    const facultyId =
      e.target.value.value !== TRAINING_FILTER_DEFAULT_VALUE.ALL.value
        ? e.target.value.value
        : null;
    const newModel = { page: 0, pageSize };
    this.setState({ facultyFilter: e.target.value, facultyId, paginationModel: newModel });
    onLoadTrainingCourse(search, pageSize, orderBy, integrationId, facultyId);
  }

  onCheckHasExpiredData(course) {
    const { associatedAttribute } = course;

    return associatedAttribute.every((attribute) => attribute?.hasExpiryDate);
  }

  onShowTrainingAttributesDialog(associatedRow) {
    // set detail data for dialog
    this.setState({ associatedAttributeDetail: associatedRow });

    this.setState({ isTrainingAttributesDialogShow: true });
    this.setState({ trainingDialogType: TRAINING_ATTRIBUTE_DIALOG_TYPE.DETAIL });
  }

  onCloseTrainingAttributesDialog() {
    this.setState({ isTrainingAttributesDialogShow: false });
    this.setState({ trainingDialogType: null });
  }

  onPaginationModelChange(model) {
    const { page, pageSize } = model;
    this.setState({ paginationModel: model });
    const { onLoadCoursesPageSize } = this.props;
    const { search, integrationId, sortModel, orderByConvertList } = this.state;
    const [{ field, sort }] = sortModel;
    const orderBy = getSortByApiValue(orderByConvertList, field, sort);
    onLoadCoursesPageSize(page + 1, pageSize, search, orderBy, integrationId);
  }

  onSortModelChange(sortModel) {
    const { onLoadTrainingCourse } = this.props;
    const {
      paginationModel: { pageSize },
      search,
      integrationId,
      orderByConvertList
    } = this.state;
    const [{ field, sort }] = sortModel;

    const orderBy = getSortByApiValue(orderByConvertList, field, sort);

    onLoadTrainingCourse(search, pageSize, orderBy, integrationId);
    const newModel = { page: 0, pageSize };
    this.setState({ sortModel, paginationModel: newModel });
  }

  onRecurrentChange(row, recurrence) {
    const { onSaveCourseRecurrence } = this.props;
    const { id } = row;
    const { index } = recurrence;

    if (!this.onCheckHasExpiredData(row) && row.recurrence === COURSE_RECURRENCE.NONE.value) {
      // open warning dialog

      this.onShowTrainingAttributesDialog(row);
      this.setState({ trainingDialogType: TRAINING_ATTRIBUTE_DIALOG_TYPE.WARNING });
      return;
    }

    onSaveCourseRecurrence(id, index);
  }

  render() {
    const { theme, user, courses, organization } = this.props;
    const {
      search,
      integrationFilter,
      integrations,
      sortModel,
      associatedAttributeDetail,
      isTrainingAttributesDialogShow,
      trainingDialogType,
      paginationModel,
      facultyFilter,
      faculties
    } = this.state;

    let coursesWithHierarchy = [];

    if (courses?.courses?.length) {
      coursesWithHierarchy = courses.courses.map((course) => ({
        ...course,
        hierarchy: [course.rowId]
      }));
      courses.courses.forEach((course) => {
        const competencies = course.associatedAttribute;
        competencies.forEach((competency) => {
          coursesWithHierarchy.push({
            name: competency.name,
            hierarchy: [course.rowId, competency.attributeDefinitionId],
            rowId: `${course.rowId}_${competency.attributeDefinitionId}`
          });
        });
      });
    }

    return (
      <StyledSettingTrainingView>
        <div className='klayo__setting-training-desktop'>
          <Paper
            theme={theme}
            padding={{ xs: "46px 24px", md: "56px 64px" }}
            borderFromBreakpoint='md'
          >
            <h1>Training</h1>
            <Stack
              direction='row'
              alignItems='center'
              spacing={2}
              sx={{ borderTop: "1px solid rgba(0, 0, 0, 0.12)", padding: "40px 0px 24px 0px" }}
            >
              <TextField
                value={search}
                dense={true}
                placeholder='Search course'
                disabled={user === null}
                fullWidth={true}
                autoComplete={false}
                leadingIcon={<SearchIcon />}
                onChange={this.onSearch.bind(this)}
                sx={{ maxWidth: { md: "300px" } }}
              />
              {organization?.hasMultipleIntegrations && (
                <SelectMenu
                  className='employee-filter-section-menu'
                  value={integrationFilter}
                  firstValueDefault={true}
                  dense={true}
                  disabled={false}
                  items={Object.entries(integrations)}
                  onChange={this.onFilterChange.bind(this)}
                  sx={{
                    minWidth: "300px"
                    // width: { xs: '100%', sm: '100%' },
                    // [theme.breakpoints.down(showSearchFromBreakpoint)]: {
                    //     margin: '0!important'
                    // }
                  }}
                />
              )}

              {faculties && faculties.length > 0 && (
                <SelectMenu
                  className='employee-filter-section-menu'
                  value={facultyFilter}
                  firstValueDefault={true}
                  dense={true}
                  disabled={false}
                  items={Object.entries(faculties)}
                  onChange={this.onFilterFacultyChange.bind(this)}
                  sx={{
                    minWidth: "300px"
                  }}
                />
              )}
            </Stack>
            <Delayed>
              <TrainingTable
                sortable={true}
                rowHasAction={true}
                minHeight='300px'
                theme={theme}
                filterMode='server'
                hideFirstLastBorder={true}
                dense={true}
                paginationMode='server'
                showDialog={this.onShowTrainingAttributesDialog.bind(this)}
                totalCount={this.props.courses?.totalCount}
                rowsPerPageOptions={[5, 10, 25, 50, 100]}
                onRecurrentChange={this.onRecurrentChange.bind(this)}
                onSortModelChange={this.onSortModelChange.bind(this)}
                paginationModel={paginationModel}
                onPaginationModelChange={this.onPaginationModelChange.bind(this)}
                selectable={false}
                columns={
                  organization?.hasMultipleIntegrations
                    ? TrainingTable.defaultProps.columns
                    : TrainingTable.defaultProps.columns.filter(
                        (col) => col.type.field !== TrainingTable.columns.provider.field
                      )
                }
                sortingMode='server'
                sortModel={sortModel}
                paper={false}
                rows={coursesWithHierarchy}
              />
            </Delayed>
          </Paper>

          {isTrainingAttributesDialogShow && (
            <TrainingAttributesDialog
              type={trainingDialogType}
              onClose={this.onCloseTrainingAttributesDialog.bind(this)}
              associatedAttributeDetail={associatedAttributeDetail}
              theme={theme}
              {...this.props}
            />
          )}
          {this.renderSnackbar()}
        </div>
        <div className='klayo__setting-training-mobile'>
          <p className='content'> This page is not available at this breakpoint </p>
        </div>
      </StyledSettingTrainingView>
    );
  }
}

const StyledSettingTrainingView = styled.div`
  .klayo__setting-training-mobile {
    display: none;
  }

  @media (max-width: 600px) {
    .klayo__setting-training-desktop {
      display: none;
    }

    .klayo__setting-training-mobile {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 100%;
      height: 80vh;

      .content {
        color: ${KLAYO_COLORS.RedWarning};
        font-family: Inter;
        font-size: 20px;
        font-style: normal;
        font-weight: 400;
        line-height: 28px;
        letter-spacing: -0.3px;
        text-align: center;
      }
    }
  }
`;
