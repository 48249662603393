import axios from "axios";
import { tokenRequest } from "../common/AuthConfig";
import { BrowserAuthError, InteractionRequiredAuthError } from "@azure/msal-browser";
import { Utils } from "../common/Utils";
export class Data {
  static apiBasePath = `${window.app.env.API_URL}/api`;

  constructor(options) {
    const { api, reject, callback, context, method, postData } = options;

    this.isRegaining = false;
    this.cached = false;
    this.api = api;
    //this.api = '/Error/InternalServerError';
    this.method = method || "get";
    this.requestOpts = { data: postData };
    if (api) {
      if (context.devMode) {
        const mimicApiDown = Utils.getDevOption("devOpts_MimicApiDown");
        if (mimicApiDown) {
          reject({ message: "Network Error" });
          return;
        }
      }

      this.getData(context)
        .then((data) => callback(data))
        .catch((e) => reject(e));
    }
  }

  getData(context) {
    return new Promise((resolve, reject) => {
      Data.getAccessToken(context)
        .then((accessToken) => {
          if (/*this.isRegaining || */ !accessToken) return;

          this.onGetToken(accessToken);

          Data.call(this.method, this.api, { ...this.requestOpts })
            .then((response) => {
              this.onDataReceived(response.data);
              this.dataReceived = true;
              resolve(response.data);
            })
            .catch((error) => {
              if (error.response && error.response.status === 302) window.location = "/signin-oic";
              reject(error);
            })
            .finally(() => {
              this.cached = true;
            });
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  static call(method, apiPath, opts) {
    return axios({
      method,
      url: Data.apiBasePath + apiPath,
      withCredentials: true,
      maxRedirects: 0,
      ...opts
    });
  }

  static get(context, api) {
    return new Promise((resolve, reject) => {
      Data.getAccessToken(context)
        .then((accessToken) => {
          if (/*this.isRegaining || */ !accessToken) return;
          return Data.call("get", api)
            .then((response) => resolve(response))
            .catch((error) => reject(error));
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  onDataReceived(data) {}

  updateData(context) {
    return this.getData(context);
  }

  onGetToken(accessToken) {}

  static getAccessToken(context) {
    if (context.msal.accounts[0].environment !== window.app.env.B2C_KNOWN_AUTHORITY) {
      const logoutRequest = {
        account: context.msal.accounts[0]
        //postLogoutRedirectUri: "your_app_logout_redirect_uri",
      };
      context.msal.instance.logoutRedirect(logoutRequest);
      return Promise.reject();
    }

    const accessTokenRequest = {
      ...tokenRequest,
      account: context.msal.accounts[0],
      redirectUri: "/"
    };

    return new Promise((resolve, reject) => {
      context.msal.instance
        .acquireTokenSilent(accessTokenRequest)
        .then((response) => {
          axios.defaults.headers.common["Authorization"] = `Bearer ${response.accessToken}`;
          resolve(response.accessToken);
        })
        .catch((error) => {
          // fallback to interaction when silent call fails
          // Note - this happens when token expires and cookies are disabled so no iframe request can happen automatically.
          // Needs more testing. See: https://docs.microsoft.com/en-us/azure/active-directory/develop/scenario-spa-acquire-token
          if (error instanceof InteractionRequiredAuthError || error instanceof BrowserAuthError) {
            //this.isRegaining = true;
            context.msal.instance.acquireTokenRedirect(accessTokenRequest); //Returned promise is never fired - "It currently returns a Promise in order to reflect the asynchronous nature of the code running in this function"

            resolve(null);
          } else {
            //window.location = '/signin-oic';    //TESTING
            reject();
          }
        });
    });
  }

  static getFromData(data, key) {
    return data[Object.keys(data).find((k) => k.toLowerCase() === key.toLowerCase())];
  }
}
