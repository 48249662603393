import { Badge, Button as MuiButton, IconButton } from "@mui/material";
import React, { Component } from "react";
import { NavLink } from "react-router-dom";
import { Theme } from "klayowebshared";
import { Tooltip } from "./Tooltip";

export class Button extends Component {
  static color = Theme.getStyleVar("--white-wh-900");
  static hoverColor = Theme.getStyleVar("--neon-blue-nb-050");
  static activeColor = Theme.getStyleVar("--neon-blue-nb-100");
  static filledColor = Theme.getStyleVar("--neon-blue-nb-500");
  static filledHoverColor = Theme.getStyleVar("--neon-blue-nb-700");
  static filledActiveColor = Theme.getStyleVar("--neon-blue-nb-900");
  static iconColor = Theme.getStyleVar("--neon-blue-nb-500");
  static filledIconColor = Theme.getStyleVar("--white-wh-900");
  static padding = { sm: "16px 12px", md: "10px 16px", lg: "12px 24px" };
  static iconPadding = { sm: "8px", md: "8px", lg: "12px" };
  static iconSize = { sm: "20px", md: "20px", lg: "24px" };
  static fontSize = { sm: "14px", md: "14px", lg: "16px" };
  static size = { sm: "32x", md: "40px", lg: "40px" };
  static focusBorderRadius = { sm: "20px", md: "24px", lg: "28px" };
  static focusOffset = "-4px";
  static outlinedfocusOffset = "-5px";

  static defaultProps = {
    size: "lg",
    variant: "outlined",
    labelColor: Theme.getStyleVar("--grey-gr-900"),
    filledLabelColor: Theme.getStyleVar("--white-wh-900"),
    target: null
  };

  constructor(props) {
    super(props);

    this.buttonRef = React.createRef();
  }

  componentDidUpdate(prevProps, prevState) {
    // Check to clear focus of button
    if (prevProps.blurDetect !== this.props.blurDetect) {
      // Clear the focus using the ref
      this.buttonRef.current.blur();
    }
  }

  renderButton() {
    const {
      label,
      size,
      theme,
      variant,
      path,
      href,
      onClick,
      disabled,
      autoFocus,
      icon,
      startIcon,
      endIcon,
      sx,
      className,
      badgeProps,
      color,
      hoverColor,
      activeColor,
      padding,
      labelColor,
      target,
      filledLabelColor,
      showLabelFromBreakpoint
    } = this.props;

    const btnClass =
      "klayo-button" +
      (icon ? " klayo-iconbutton" : "") +
      (className ? " " + className : "") +
      (size ? " klayo-button-" + size : "") +
      (variant && !icon ? " klayo-button-" + variant : "");

    const isOutlined = variant === "outlined";
    const isFilled = variant === "filled";

    const style = {
      padding: icon ? Button.iconPadding[size] : Button.padding[size],
      backgroundColor: color || (isFilled ? Button.filledColor : icon ? null : Button.color),
      "&:hover": {
        backgroundColor: hoverColor || (isFilled ? Button.filledHoverColor : Button.hoverColor)
      },
      "&:active": {
        backgroundColor: activeColor || (isFilled ? Button.filledActiveColor : Button.activeColor)
      },
      "&:focus::before": {
        border: "2px solid var(--neon-blue-nb-400)",
        borderRadius: Button.focusBorderRadius[size],
        top: isOutlined ? Button.outlinedfocusOffset : Button.focusOffset,
        right: isOutlined ? Button.outlinedfocusOffset : Button.focusOffset,
        bottom: isOutlined ? Button.outlinedfocusOffset : Button.focusOffset,
        left: isOutlined ? Button.outlinedfocusOffset : Button.focusOffset
      },
      ["& .MuiButton-startIcon, & .MuiButton-endIcon"]: {
        width: Button.iconSize[size]
      },
      ["& .klayo-button_label"]: {
        color: isFilled ? filledLabelColor : labelColor,
        fontSize: Button.fontSize[size],
        fontWeight: isFilled ? "400" : "600"
      },
      ['& .MuiButton-startIcon svg path[fill="#111827"], & .MuiButton-startIcon svg path[fill="#727DF9"], & .MuiButton-endIcon svg path[fill="#111827"], & .MuiButton-endIcon svg path[fill="#727DF9"], & .MuiButton-endIcon svg path[fill="#1B3DF2"]']:
        {
          fill: isFilled ? Button.filledIconColor : Button.iconColor
        },
      ...sx
    };

    if (theme && showLabelFromBreakpoint) {
      style[theme.breakpoints.down(showLabelFromBreakpoint)] = {
        padding: "0!important",
        minWidth: Button.size[size]
      };

      style["& .klayo-button_label"] = {
        [theme.breakpoints.down(showLabelFromBreakpoint)]: {
          display: "none"
        },
        [theme.breakpoints.up(showLabelFromBreakpoint)]: {
          display: "block"
        }
      };

      style["& .MuiButton-startIcon, & .MuiButton-endIcon"][
        theme.breakpoints.down(showLabelFromBreakpoint)
      ] = {
        margin: "0"
      };
    }

    return icon ? (
      <IconButton
        ref={this.buttonRef}
        className={btnClass}
        onClick={onClick}
        disabled={disabled}
        disableRipple={true}
        to={path}
        component={path ? NavLink : null}
        sx={style}
      >
        {badgeProps && badgeProps.show !== false ? <Badge {...badgeProps}>{icon}</Badge> : icon}
      </IconButton>
    ) : (
      <MuiButton
        ref={this.buttonRef}
        className={btnClass}
        href={href}
        target={target}
        to={path}
        component={path ? NavLink : href ? "a" : null}
        startIcon={startIcon}
        endIcon={endIcon}
        onClick={onClick}
        disableRipple={true}
        disabled={disabled}
        autoFocus={autoFocus}
        sx={style}
      >
        <div className='klayo-button_label'>{label}</div>
      </MuiButton>
    );
  }

  render() {
    const { tooltip } = this.props;

    return tooltip ? <Tooltip title={tooltip}>{this.renderButton()}</Tooltip> : this.renderButton();
  }
}
