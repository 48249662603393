import { DialogTitle, DialogContent, DialogActions, Stack } from "@mui/material";
import { DataGridPro } from "@mui/x-data-grid-pro";
import { AbstractDialog } from "../../components/AbstractDialog";
import { Utils } from "../../common/Utils";
import { Button } from "../../components/Button";
import { EmployeeTable } from "../../components/table/EmployeeTable";
import { Dialog } from "../../components/dialogs/Dialog";
import { unFocusTableRowOnClose } from "../../utilities";

export class AttributePeopleListDialog extends AbstractDialog {
  constructor(props) {
    super(props);

    this.state = {
      ...this.state,
      attribute: this.props.attribute,
      isGap: false
    };
  }

  componentDidMount() {
    this.setLoading("attribute", !this.props.attribute);
  }

  componentDidUpdate(prevProps) {
    if (this.props.attribute !== prevProps.attribute) this.setLoading("attribute", false);
  }

  open(attribute, activeTab) {
    this.setState({ open: true, attribute, isGap: activeTab === "gaps" });
  }

  onClose() {
    const { onClose } = this.props;

    if (onClose) onClose();
    unFocusTableRowOnClose();
  }

  render() {
    const { organization, onEmployeeSelect, theme } = this.props;
    const { open, attribute, isGap } = this.state;

    /*let columns = [
            { field: 'fullName', headerName: 'Employee', flex: true }
        ];

        if (organization && organization.showProficiency) columns.push({
            field: 'proficiency',
            headerName: 'Proficiency',
            width: 100,
            renderCell: params => {
                return Utils.formatProficiency(params.row.proficiency);
            }
        });*/

    return (
      <Dialog
        open={open}
        onClose={this.onClose.bind(this)}
        theme={theme}
        fullWidth={true}
        title={isGap ? "Gap details" : "Competency details"}
        maxWidth='sm'
        actions={[
          {
            label: "Close",
            variant: "filled",
            onClick: this.onClose.bind(this)
          }
        ]}
      >
        {super.renderLoadingBar()}
        <Stack direction='column' spacing={4}>
          <div style={{ fontSize: "16px" }}>
            <b>{attribute ? attribute.attributeName : ""}</b> competency is shared by the following
            employees:
          </div>
          <EmployeeTable
            rows={attribute ? attribute.employees : []}
            pageSize={50}
            paper={false}
            theme={theme}
            columns={[
              { type: EmployeeTable.columns.profilePicFullName },
              { type: EmployeeTable.columns.proficiency }
            ]}
            pagination={true}
            rowsPerPageOptions={[50]}
            disableMultipleSelection={true}
            disableSelectionOnClick={true}
            onEmployeeSelect={onEmployeeSelect}
            className='klaro_attrppl_table'
            sx={{ "& .MuiDataGrid-row": { cursor: "pointer" } }}
            border='none'
          />
        </Stack>
      </Dialog>
    );
  }
}
