import { PersonSelectorList } from "../../data/filters/PersonSelectorList";
import { AppContext } from "../../common/AppContext";
import { AbstractFilterSelect } from "./AbstractFilterSelect";
export class PeopleSelector extends AbstractFilterSelect {
  static contextType = AppContext;

  static defaultProps = {
    label: "People",
    listType: "People",
    ...AbstractFilterSelect.defaultProps
  };

  constructor(props) {
    super(props);

    this.dataList = PersonSelectorList;
  }

  compareItems(a, b) {
    return a.employeeId === b.employeeId;
  }

  getItemLabel(item) {
    return item.fullName;
  }

  getList(dataList) {
    return dataList.employees;
  }
}
