import { React, Component } from "react";
import {
  Menu,
  MenuItem,
  Divider,
  ListItemIcon,
  ListItemText,
  ListItemSecondaryAction,
  Typography
} from "@mui/material";
import { AppContext } from "../common/AppContext";
import { NestedMenuItem } from "mui-nested-menu";
import { ReactComponent as LogOutIcon } from "../resources/images/icons-logout.svg";
import { ReactComponent as SwitchUserIcon } from "../resources/images/icons-user-switch.svg";
import { ReactComponent as DeveloperIcon } from "../resources/images/icons-developer.svg";
import { ReactComponent as SwitchDemoIcon } from "../resources/images/icons-cursor-click.svg";
import { ReactComponent as SwitchToCompanyIcon } from "../resources/images/icons-office.svg";
import { ReactComponent as RightArrowIcon } from "../resources/images/icons-arrow-right.svg";

export class AppMenu extends Component {
  static contextType = AppContext;

  static defaultProps = {
    allowDemoSwitch: false,
    drawerMode: false,
    showIcons: false,
    anchorOrigin: {
      vertical: "top",
      horizontal: "right"
    },
    transformOrigin: {
      vertical: "bottom",
      horizontal: "right"
    }
  };

  constructor(props) {
    super(props);
  }

  getItems() {
    const {
      user,
      organization,
      drawerMode,
      devMode,
      allowSwitchUser,
      onDevOptions,
      onSignOut,
      onSwitchUser,
      onDemoSwitch,
      allowDemoSwitch,
      onClose,
      showIcons
    } = this.props;

    const hasDevItems = devMode || allowSwitchUser;
    const hasMoreItems = allowDemoSwitch;

    const items = [];

    if (hasDevItems) {
      if (allowSwitchUser) {
        items.push(
          <MenuItem
            key='switchuser'
            className={"klaro-app" + (drawerMode ? "drawer" : "menu") + "_menuitem"}
            onClick={onSwitchUser}
          >
            {showIcons && (
              <ListItemIcon>
                <SwitchUserIcon />
              </ListItemIcon>
            )}
            <ListItemText disableTypography={true}>Switch user</ListItemText>
          </MenuItem>
        );
      }

      if (devMode) {
        items.push(
          <MenuItem
            key='dev'
            className={"klaro-app" + (drawerMode ? "drawer" : "menu") + "_menuitem"}
            onClick={onDevOptions}
          >
            {showIcons && (
              <ListItemIcon>
                <DeveloperIcon />
              </ListItemIcon>
            )}
            <ListItemText disableTypography={true}>Developer</ListItemText>
          </MenuItem>
        );
      }

      items.push(<Divider key='div1' sx={{ margin: "8px 0 8px 0" }} />);
    }

    if (hasMoreItems) {
      if (allowDemoSwitch) {
        items.push(
          <MenuItem
            key='demoorg'
            className={"klaro-app" + (drawerMode ? "drawer" : "menu") + "_menuitem"}
            onClick={(e) => onDemoSwitch(e, !organization.isDemo)}
          >
            {showIcons && (
              <ListItemIcon>
                {organization && organization.isDemo ? <SwitchToCompanyIcon /> : <SwitchDemoIcon />}
              </ListItemIcon>
            )}
            <ListItemText disableTypography={true}>
              {organization && organization.isDemo
                ? "Switch to your company"
                : "Switch to Demo company"}
            </ListItemText>
          </MenuItem>
        );
      }

      items.push(<Divider key='div2' sx={{ margin: "8px 0 8px 0" }} />);
    }

    items.push(
      <MenuItem
        key='signout'
        className={"klaro-app" + (drawerMode ? "drawer" : "menu") + "_menuitem"}
        onClick={onSignOut}
      >
        {showIcons && (
          <ListItemIcon>
            <LogOutIcon />
          </ListItemIcon>
        )}
        <ListItemText disableTypography={true}>Sign out</ListItemText>
      </MenuItem>
    );

    return items;
  }

  renderItems() {
    const {
      user,
      organization,
      drawerMode,
      devMode,
      allowSwitchUser,
      onDevOptions,
      onSignOut,
      onSwitchUser,
      onDemoSwitch,
      allowDemoSwitch,
      onClose
    } = this.props;

    const hasDevItems = devMode || allowSwitchUser;
    const hasMoreItems = allowDemoSwitch;

    return (
      <>
        {hasDevItems && (
          <>
            {allowSwitchUser && (
              <MenuItem
                className={"klaro-app" + (drawerMode ? "drawer" : "menu") + "_menuitem"}
                onClick={onSwitchUser}
              >
                <ListItemIcon>
                  <SwitchUserIcon />
                </ListItemIcon>
                <ListItemText disableTypography={true}>Switch user</ListItemText>
              </MenuItem>
            )}

            {devMode && (
              <MenuItem
                className={"klaro-app" + (drawerMode ? "drawer" : "menu") + "_menuitem"}
                onClick={onDevOptions}
              >
                <ListItemIcon>
                  <DeveloperIcon />
                </ListItemIcon>
                <ListItemText disableTypography={true}>Developer</ListItemText>
              </MenuItem>
            )}

            <Divider />
          </>
        )}

        {hasMoreItems && (
          <>
            {allowDemoSwitch && (
              <MenuItem
                className={"klaro-app" + (drawerMode ? "drawer" : "menu") + "_menuitem"}
                onClick={(e) => onDemoSwitch(e, !organization.isDemo)}
              >
                <ListItemIcon>
                  {organization && organization.isDemo ? (
                    <SwitchToCompanyIcon />
                  ) : (
                    <SwitchDemoIcon />
                  )}
                </ListItemIcon>
                <ListItemText disableTypography={true}>
                  {organization && organization.isDemo
                    ? "Switch to your company"
                    : "Switch to Demo company"}
                </ListItemText>
              </MenuItem>
            )}
            <Divider sx={{ margin: "8px 0 8px 0" }} />
          </>
        )}
        <MenuItem
          className={"klaro-app" + (drawerMode ? "drawer" : "menu") + "_menuitem"}
          onClick={onSignOut}
        >
          <ListItemIcon>
            <LogOutIcon />
          </ListItemIcon>
          <ListItemText disableTypography={true}>Sign out</ListItemText>
        </MenuItem>
      </>
    );
  }

  render() {
    const {
      drawerMode,
      anchorEl,
      anchorOrigin,
      transformOrigin,
      onClose,
      open,
      hideWithoutClosing
    } = this.props;

    return drawerMode ? (
      <div className='klaro-appdrawer-menu'>{this.getItems()}</div>
    ) : (
      <Menu
        className='klaro-appbar_usermenu'
        open={open}
        anchorEl={anchorEl}
        anchorOrigin={anchorOrigin}
        transformOrigin={transformOrigin}
        onClose={onClose}
        sx={{ display: hideWithoutClosing ? "none" : "block" }}
      >
        {this.getItems()}
      </Menu>
    );
  }
}
