import {
  Autocomplete,
  TextField,
  FormControl,
  Select,
  MenuItem,
  InputAdornment,
  IconButton,
  OutlinedInput,
  Input,
  InputLabel,
  Tooltip
} from "@mui/material";
import { React, Component } from "react";
import { AbstractAttribute } from "../../data/attributes/AbstractAttribute";
import ClearIcon from "@mui/icons-material/Clear";
import { SelectMenu } from "../SelectMenu";

export class AttributeComplianceSelector extends Component {
  constructor(props) {
    super(props);

    this.showAllItem = { key: "all", label: "Show all" };

    this.state = {
      compliance: this.props.value
    };
  }

  onChange(e) {
    const { onChange } = this.props;

    this.setState({ compliance: e.target.value }, (state) => {
      onChange(e);
    });
  }

  onClear(e) {
    e.target.value = "";
  }

  render() {
    const { sx } = this.props;
    const { compliance } = this.state;

    return (
      <SelectMenu
        value={compliance}
        label='Compliance'
        placeholder='Select'
        firstValueDefault={true}
        items={Object.entries({ all: this.showAllItem, ...AbstractAttribute.compliance })}
        onChange={this.onChange.bind(this)}
        sx={sx}
      />
    );
  }
}
