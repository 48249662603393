import { React, Component } from "react";
import { CircularProgress, Box } from "@mui/material";

export class SectionLoader extends Component {
  render() {
    return (
      <Box sx={{ display: "flex", flexDirection: "column", alignItems: "center", margin: "30px" }}>
        <CircularProgress />
      </Box>
    );
  }
}
