import { Stack } from "@mui/material";
import { debounce, find, get, isEqual, trim } from "lodash";
import { Component } from "react";
import { AppContext } from "../../common/AppContext";
import { ErrorModel } from "../../common/models/ErrorModel";
import { Dialog } from "../../components/dialogs/Dialog";
import { MultiAttributeSelector } from "../../components/selectors/MultiAttributeSelector";
import { CREATE_TASK_MODE, PAGE_SIZE_FOR_ADD_EDIT } from "../../constants";
import { Data } from "../../data/Data";
import { SettingsTasksList } from "../../data/settings/SettingsTasksList";
import { CreateTaskDialog } from "./CreateTaskDialog";
const axios = require("axios").default;
export class TaskSelectorDialog extends Component {
  static contextType = AppContext;

  constructor(props) {
    super(props);

    this.state = {
      tasks: null,
      selectedTasks: [],
      originalSelectedTasks: [],
      tasksSearch: null,
      newTaskName: null,
      isShowCreateNewTaskDialog: false,
      addNewTaskError: null
    };
    this.onSearchTasks = debounce(this.onSearchTasks.bind(this), 500);
  }

  componentDidMount() {
    const { existingTasks } = this.props;
    this.setState({ selectedTasks: existingTasks, originalSelectedTasks: existingTasks });
    this.loadTasks();
  }

  loadTasks(searchText) {
    this.context.setLoading("tasks", true);
    const pagingOptions = {
      pageNumber: 1,
      pageSize: PAGE_SIZE_FOR_ADD_EDIT,
      searchText: searchText
    };
    SettingsTasksList.getWithPaging(this.context, pagingOptions)
      .then((tasks) => {
        this.setState({ tasks, tasksSearch: searchText });
      })
      .catch((error) => {})
      .finally(() => this.context.setLoading("tasks", false));
  }

  onTasksBlur() {
    const { tasksSearch } = this.state;
    if (tasksSearch) this.loadTasks();
  }

  onSearchTasks(searchText) {
    this.loadTasks(trim(searchText));
  }

  onHandleSearchTasks(searchValue) {
    this.setState({ tasks: [] });
    this.onSearchTasks(searchValue);
  }

  onSave() {
    const { onSave } = this.props;
    const { selectedTasks } = this.state;
    if (onSave) onSave(selectedTasks);
  }

  onTasksChange(e, addedTask) {
    const { selectedTasks } = this.state;
    if (!addedTask) return;
    this.setState({ selectedTasks: addedTask });
  }

  onShowCreateNewTaskDialog(e, value) {
    this.setState({ isShowCreateNewTaskDialog: true, newTaskName: value, addNewTaskError: null });
  }

  onCreateNewTask(taskName, allSelectedAttributes) {
    const { selectedTasks, originalSelectedTasks } = this.state;
    this.context.setLoading("tasks", true);
    return new Promise((resolve, reject) => {
      axios
        .post(Data.apiBasePath + "/Regulation/Task", {
          name: taskName,
          competencyIds: allSelectedAttributes.map((a) => a.attributeDefinitionId)
        })
        .then((response) => {
          const newTask = response.data.regulationTask;
          this.setState({
            isShowCreateNewTaskDialog: false,
            newTaskName: null,
            addNewTaskError: null,
            selectedTasks: [...selectedTasks, newTask]
          });
        })
        .catch((e) => {
          this.setState({ addNewTaskError: ErrorModel.parseServerError(e) });
        })
        .finally(() => {
          this.context.setLoading("tasks", false);
        });
    });
  }

  handleTaskChange() {
    const { selectedTasks, originalSelectedTasks } = this.state;
    // Compare the original selected tasks with the current selected tasks using lodash
    if (selectedTasks.length !== originalSelectedTasks.length) return false;
    return isEqual(selectedTasks, originalSelectedTasks);
  }

  render() {
    const { theme, onClose, existingTasks, placeholder, subHeader, user } = this.props;
    const { tasks, selectedTasks, isShowCreateNewTaskDialog, newTaskName, addNewTaskError } =
      this.state;

    let tasksFiltered = get(tasks, "tasks", []);

    if (existingTasks) {
      tasksFiltered = tasksFiltered.filter(
        (task) =>
          !find(existingTasks, (existingTask) => get(existingTask, "id") === get(task, "id"))
      );
    }

    if (get(selectedTasks, "length", 0) > 0) {
      tasksFiltered = tasksFiltered.filter(
        (task) =>
          !find(selectedTasks, (selectedTask) => get(selectedTask, "id") === get(task, "id"))
      );
    }

    return (
      <>
        <Dialog
          open={true}
          theme={theme}
          onClose={onClose}
          fullWidth={true}
          maxWidth='sm'
          title='Add tasks'
          actions={[
            {
              label: "Add tasks",
              primary: true,
              disabled: this.handleTaskChange(),
              onClick: this.onSave.bind(this)
            },
            {
              label: "Cancel",
              onClick: onClose
            }
          ]}
        >
          <Stack direction='column' spacing={4}>
            <p>{subHeader}</p>
            <MultiAttributeSelector
              label={null}
              items={tasksFiltered}
              values={selectedTasks}
              onNewItemClick={this.onShowCreateNewTaskDialog.bind(this)}
              getItemId={(attr) => attr.id}
              onChange={this.onTasksChange.bind(this)}
              onSearch={(searchValue) => {
                this.onHandleSearchTasks(searchValue);
              }}
              onBlur={this.onTasksBlur.bind(this)}
              placeHolder={placeholder}
            />
          </Stack>
        </Dialog>

        {isShowCreateNewTaskDialog && (
          <CreateTaskDialog
            theme={theme}
            user={user}
            onClose={() => this.setState({ isShowCreateNewTaskDialog: false })}
            error={addNewTaskError}
            mode={CREATE_TASK_MODE.CREATE}
            hasNewItem={false}
            onSave={this.onCreateNewTask.bind(this)}
            newTaskName={newTaskName}
          />
        )}
      </>
    );
  }
}
