import { Data } from "../Data";
import { OrgChartEmployee } from "./OrgChartEmployee";
import { User } from "./User";

export class OrgUserList extends Data {
  static instance = null;
  static lastDataVersion = 0;

  constructor(options) {
    super({
      api: "/Organization/Chart",
      callback: (data) => {
        options.resolve({ data: this, cached: false });
      },
      ...options
    });

    this.orgTree = [
      {
        id: "org-root",
        name: "org",
        type: "root"
      },
      {
        id: "org-root-job",
        name: "jobs",
        parentId: "org-root",
        type: "company"
      },
      {
        id: "org-root-nojob",
        name: "nojob",
        parentId: "org-root",
        type: "nojob-root"
      }
    ];
  }

  onDataReceived(data) {
    this.employees = [];

    Object.entries(data.employees).forEach(([key, d]) => {
      const employee = new OrgChartEmployee(
        d.employee ? d.employee : { employeeId: d.jobId, isVacancy: true },
        d.jobTitle,
        d.reportsTo ? d.reportsTo.employeeId : d.reportsToId
      );
      this.employees.push(employee);

      this.orgTree.push(employee);

      //if (d.jobDefinition && !this.orgTree.some(j => j.id === d.jobDefinition.jobDefinitionId)) this.addJob(d.jobDefinition);
    });

    //Missing managers / bad data - not likley to happen
    this.orgTree.forEach((e) => {
      if (e.type === "person" && e.parentId !== "org-root-job") {
        const manager = this.getEmployee(e.parentId);
        if (!manager) e.parentId = "org-root-job";
      }
    });

    /*Object.entries(data.employeesWithoutAJob).forEach(([key, d]) => {
            const employee = new OrgChartEmployee(d);
            this.employees.push(employee);
            this.orgTree.push(employee);
        });*/
  }

  addJob(job) {
    this.orgTree.push({
      id: job.jobDefinitionId,
      name: job.name,
      parentId: "org-root-job",
      type: "job"
    });
  }

  getEmployee(id) {
    if (!id) return null;
    return this.employees.find((a) => a.employeeId === id);
  }

  getOrgNode() {
    return this.orgTree[1];
  }

  static get(context, dataVersion) {
    const updateCache = OrgUserList.lastDataVersion !== dataVersion;
    OrgUserList.lastDataVersion = dataVersion;

    return new Promise((resolve, reject) => {
      if (OrgUserList.instance && updateCache !== true)
        resolve({ data: OrgUserList.instance, cached: true });
      else OrgUserList.instance = new OrgUserList({ resolve, reject, context });
    });
  }

  findMatch(attr) {
    return this.list.find((a) => a.name === attr);
  }
}
