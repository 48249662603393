import { React, Component } from "react";
import { LoginLayout } from "klayowebshared";

export class AuthLoadingView extends Component {
  render() {
    const { inProgress } = this.props;

    return <LoginLayout heading='Redirecting' text='Please wait...' loading={true}></LoginLayout>;
  }
}
