export class EventBus {
  static event = {
    profilePictureChanged: "profilePictureChanged",
    loadingStart: "loadingStart",
    loadingEnd: "loadingEnd",
    dataChanged: "dataChanged"
  };

  on(event, callback) {
    document.addEventListener(event, (e) => callback(e.detail));
  }

  dispatch(event, data) {
    document.dispatchEvent(new CustomEvent(event, { detail: data }));
  }
}
