import React, { Component } from "react";
import { Button } from "../../components/Button";
import { Alert } from "../../components/Alert";
import {
  Grid,
  Stack,
  Step,
  StepLabel,
  Stepper,
  Fade,
  Divider,
  Box,
  Tab,
  Tabs,
  MenuItem,
  Menu,
  ListItemText
} from "@mui/material";
import { TextField } from "../../components/TextField";
import { LocationSelector } from "../../components/selectors/LocationSelector";
import { SettingsEmployee } from "../../data/settings/SettingsEmployee";
import { Data } from "../../data/Data";
import { ErrorModel } from "../../common/models/ErrorModel";
import { AppContext } from "../../common/AppContext";
import { JobSelector } from "../../components/selectors/JobSelector";
import { DatePicker } from "../../components/DatePicker";
import { SelectVacancyDialog } from "./SelectVacancyDialog";
import { ReactComponent as AddIcon } from "../../resources/images/icons-add.svg";
import { ReactComponent as SearchIcon } from "../../resources/images/icons-search.svg";
import { ReactComponent as RightIcon } from "../../resources/images/icons-arrow-right.svg";
import { ReactComponent as LeftIcon } from "../../resources/images/icons-arrow-left.svg";
import { AttributeTable } from "../../components/table/AttributeTable";
import { AttributeSelectorDialog } from "./AttributeSelectorDialog";
import { AssignManagerDialog } from "./AssignManagerDialog";
import { EditAttributeDialog } from "./EditAttributeDialog";
import { TablePlaceholder } from "../../components/TablePlaceholder";
import { ReactComponent as AttributesIcon } from "../../resources/images/icons-attribute.svg";
import { Utils } from "../../common/Utils";
import { SettingsAttribute } from "../../data/settings/SettingsAttribute";
import { AbstractAttribute } from "../../data/attributes/AbstractAttribute";
import { PaperOrDialog } from "../../components/PaperOrDialog";
import { TooltipIcon } from "../../components/TooltipIcon";
import { EditTemplate, Switch } from "klayowebshared";
import styled from "@emotion/styled";
import { INTEGRATION_STATUS, PAGE_SIZE_FOR_ADD_EDIT } from "../../constants";
import { SettingsJobList } from "../../data/settings/SettingsJobList";
import { MultiStudentIdTextField } from "./MultiStudentIdTextField";
import cloneDeep from "lodash/cloneDeep";

const axios = require("axios").default;

export class AddEditEmployeeView extends Component {
  static contextType = AppContext;

  constructor(props) {
    super(props);

    this.state = {
      employee: props.employee,
      employeeLoaded: !props.editMode,
      error: props.error,
      step: 0,
      showSelectVacancyDialog: false,
      selectedPositionVacancies: null,
      selectedVacancy: null,
      createNewVacancy: false,
      search: null,
      showAttributeSelectDialog: false,
      showAssignManagerDialog: false,
      actionTarget: null,
      actionAttribute: null,
      editAttribute: null,
      dobValid: true,
      startDateValid: true,
      hasEdit: false,
      saving: false,
      jobs: null,
      sameIdEnable: false
    };

    this.existingEmployee = new SettingsEmployee(props.employee);
  }

  componentDidMount() {
    const { integration, onLoadIntegration } = this.props;

    this.loadDetails();
    this.loadJobs();

    if (!integration) onLoadIntegration();
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.props.error !== prevProps.error) this.setState({ error: this.props.error });
    if (this.state.employee?.integrationUsers !== prevState.employee?.integrationUsers)
      this.loadSameIdStatus();
    if (prevProps.jobs !== this.props.jobs) this.forceUpdate();
    if (prevProps.managers !== this.props.managers) this.forceUpdate();
  }

  loadDetails() {
    const { employee } = this.state;
    const employeeId = employee ? employee.employeeId : this.props.employeeId;

    if (!employeeId) return;

    this.context.setLoading("loading", true);

    Data.get(this.context, "/Employee/" + employeeId)
      .then((response) => {
        this.existingEmployee = new SettingsEmployee(response.data.employee);
        const employee = new SettingsEmployee(response.data.employee);

        if (response.data.employee.job && response.data.employee.job.manager) {
          employee.setManager(response.data.employee.job.manager);
          this.existingEmployee.setManager(response.data.employee.job.manager);
        }

        this.setState({ employee, employeeLoaded: true });
      })
      .catch((e) => {
        this.setState({ error: ErrorModel.parseServerError(e) });
      })
      .finally(() => {
        this.context.setLoading("loading", false);
      });
  }

  loadJobs(searchText) {
    this.context.setLoading("jobs", true);

    const pagingOptions = {
      pageNumber: 1,
      pageSize: PAGE_SIZE_FOR_ADD_EDIT,
      searchText: searchText
    };

    // get setting job data then set to jobs state
    SettingsJobList.getWithPaging(this.context, pagingOptions)
      .then((jobs) => {
        this.setState({ jobs });
      })
      .catch((error) => {})
      .finally(() => this.context.setLoading("jobs", false));
  }

  loadSameIdStatus() {
    const { employee } = this.state;

    if (employee && employee.integrationUsers && employee.integrationUsers.length > 0) {
      const studentForAllIntegration = employee.integrationUsers.some(
        (s) => s.isForAllIntegrations === true
      );

      if (studentForAllIntegration) {
        this.setState({ sameIdEnable: true });
        return;
      }
    }

    this.setState({ sameIdEnable: false });
  }

  isValid(employee, step) {
    if (
      !employee ||
      !this.isFirstNameValid(employee) ||
      !this.isLastNameValid(employee) ||
      !this.isEmailValid(employee) ||
      !this.isDobValid(employee) ||
      !this.isStartDateValid(employee) ||
      !this.isStudentIdValid(employee)
    )
      return false;
    if (step === 0) return true;
    return this.isAttributesValid(employee);
  }

  isFirstNameValid(employee) {
    return (employee && employee.firstName && employee.firstName.length > 2) === true;
  }

  isLastNameValid(employee) {
    return (employee && employee.lastName && employee.lastName.length > 2) === true;
  }

  isEmailValid(employee) {
    return (employee && employee.email && Utils.isValidEmail(employee.email)) === true;
  }

  isDobValid(employee) {
    return this.state.dobValid;
  }

  isStartDateValid(employee) {
    return this.state.startDateValid;
  }

  isAttributesValid(employee) {
    if (!employee.attributes) return true;

    /*employee.attributes.forEach(a => {
        });*/

    return true;
  }

  isStudentIdValid(employee) {
    const { sameIdEnable } = this.state;

    if (typeof employee.integrationUsers !== "undefined") {
      if (employee?.integrationUsers.length < 0) return true;

      // check require for user sameId
      if (sameIdEnable) {
        if (Array.isArray(employee.integrationUsers) && employee?.integrationUsers?.length > 0) {
          const userNameLength = employee?.integrationUsers[0]?.username?.length;

          return userNameLength > 0 && userNameLength <= 200;
        }
      }

      // if (Array.isArray(employee.integrationUsers) && employee?.integrationUsers?.length > 0) {
      //     return employee?.integrationUsers[0]?.username?.length <= 200;
      // }
    }

    return true;
  }

  hasChange() {
    const { employee } = this.state;

    if (employee && this.existingEmployee && this.existingEmployee.name !== employee.name)
      return true;

    return false;
  }

  onGetActiveIntegrationId(orgSetting) {
    if (orgSetting && orgSetting?.organizationIntegrations.length > 0) {
      const activeIntegration = orgSetting?.organizationIntegrations.filter(
        (integration) => integration.status === INTEGRATION_STATUS.ACTIVE
      );
      return activeIntegration[0].id;
    }
  }

  onSameIdEnable(e) {
    const { integration } = this.props;
    const { employee } = this.state;
    const integrationUsers = cloneDeep(employee.integrationUsers);

    if (integrationUsers[0]) {
      integrationUsers[0].isForAllIntegrations = e.target.checked;
      employee.integrationUsers = [integrationUsers[0]];
    } else {
      let integrationId = "";

      if (integration.integrations && integration.integrations.length > 0) {
        integrationId = integration.integrations[0].id;
      }

      employee.integrationUsers = [
        {
          username: "",
          organizationIntegrationId: integrationId,
          isForAllIntegrations: e.target.checked
        }
      ];
    }

    // when turn off, remove the empty student id
    if (!e.target.checked && integrationUsers[0].username.trim() === "") {
      const integrationUsersNotEmpty = integrationUsers.filter((item, index) => index !== 0);
      employee.integrationUsers = [...integrationUsersNotEmpty];
    }

    this.setState({ sameIdEnable: e.target.checked });
  }

  onFirstNameChange(e) {
    const { employee } = this.state;
    employee.updateName(e.target.value);
    this.setState({ employee });
  }

  onLastNameChange(e) {
    const { employee } = this.state;
    employee.updateName(null, e.target.value);
    this.setState({ employee });
  }

  onMiddleNameChange(e) {
    const { employee } = this.state;
    employee.middleName = e.target.value;
    this.setState({ employee });
  }

  onPreferredNameChange(e) {
    const { employee } = this.state;
    employee.preferredName = e.target.value;
    this.setState({ employee });
  }

  onDOBChange(date, valid) {
    const { employee } = this.state;
    /*if (valid)*/ employee.dateOfBirth = date;

    this.setState({ employee, dobValid: valid });
  }

  onStartDateChange(date, valid) {
    const { employee } = this.state;
    /*if (valid)*/ employee.startDate = date;

    this.setState({ employee, startDateValid: valid });
  }

  onEmailChange(e) {
    const { employee } = this.state;
    employee.email = e.target.value;
    this.setState({ employee });
  }

  onEmployeeIdChange(e) {
    const { employee } = this.state;
    employee.employeeNumber = e.target.value;
    this.setState({ employee });
  }

  onStudentIdChange(e, integrationID) {
    const { employee, sameIdEnable } = this.state;
    let integrationUsers = cloneDeep(employee.integrationUsers);
    const studentId = e.target.value;

    // same student id
    if (sameIdEnable && (studentId || studentId === "")) {
      const studentData = {
        username: studentId.trim() !== "" ? studentId.trim() : "",
        organizationIntegrationId: integrationID,
        isForAllIntegrations: true
      };

      if (integrationUsers.length > 0) {
        // edit if has user
        integrationUsers[0].username = studentData.username;
        employee.integrationUsers = [...integrationUsers];
      } else {
        // add new if integrationUsers empty
        employee.integrationUsers = [...integrationUsers, studentData];
      }

      this.setState({ employee });
      return;
    }

    // multi student id
    if (studentId && studentId.trim() !== "") {
      //get integrationUsers need to change
      const editUserId = integrationUsers.findIndex(
        (i) => i.organizationIntegrationId === integrationID
      );

      // new change data
      const studentData = {
        username: studentId.trim(),
        organizationIntegrationId: integrationID,
        isForAllIntegrations: false
      };

      // if no student change exist, add new
      if ((!editUserId && editUserId !== 0) || editUserId === -1) {
        employee.integrationUsers = [...integrationUsers, studentData];
        return;
      }

      // edit if student exist
      // userIdForIntegrationExist.username = studentData.username;
      integrationUsers[editUserId].username = studentData.username;
      employee.integrationUsers = [...integrationUsers];
    }

    // remove empty student id in multi student id mode
    if (studentId === "" || studentId.trim() === "") {
      const integrationUsersNotEmpty = integrationUsers.filter(
        (i) => i.organizationIntegrationId !== integrationID
      );

      // remove the student id with empty username;
      if (integrationUsersNotEmpty) {
        employee.integrationUsers = [...integrationUsersNotEmpty];
      }
    }

    this.setState({ employee });
  }

  onLocationsChange(e, location) {
    const { employee } = this.state;
    employee.locationId = location ? location.locationId : null;
    this.setState({ employee });
  }

  onJobInputChange(value) {
    this.loadJobs(value);
  }

  onJobChange(e, position) {
    const { jobs } = this.state;
    const { employee } = this.state;

    if (employee.job) employee.prevJob = employee.job;
    employee.job = position;
    let positions = [];
    if (position) {
      positions = jobs.positions.filter(
        (p) => p.job.jobDefinitionId == position.jobDefinitionId && p.isVacancy
      );
      employee.job.jobId = null; //remove if trouble
    } else {
      employee.manager = null;
    }

    this.setState({
      showSelectVacancyDialog: positions.length !== 0,
      employee,
      createNewVacancy: positions.length === 0,
      selectedVacancy: null,
      selectedPositionVacancies: positions
    });
  }

  onSearch(e) {
    this.setState({ search: e.target.value });
  }

  onShowAttributeSelectDialog(e) {
    this.setState({ showAttributeSelectDialog: true });
  }

  onRemoveAttribute(e) {
    const { employee, actionAttribute } = this.state;

    employee.attributes = employee.attributes.filter(
      (a) =>
        !(a.attributeId === actionAttribute.attributeId && !a.isChild) &&
        a.parentAttributeId !== actionAttribute.attributeId
    );
    this.setState({ employee, actionTarget: null, actionAttribute: null });
  }

  onAttributeAction(e, attribute) {
    this.setState({ actionTarget: e.target, actionAttribute: attribute });
  }

  onAttributeSelect(e, attribute) {
    if (!attribute.isChild && !attribute.isParent)
      this.setState({ editAttribute: new SettingsAttribute(attribute) });
  }

  onCloseActionMenu() {
    this.setState({ actionTarget: null, actionAttribute: null });
  }

  onEditAttribute() {
    const { actionAttribute } = this.state;
    this.setState({
      actionTarget: null,
      actionAttribute: null,
      editAttribute: new SettingsAttribute(actionAttribute)
    });
  }

  onSaveAttribute(e, changedAttr) {
    const { employee } = this.state;
    let attribute = employee.attributes.find(
      (a) => a.attributeDefinitionId === changedAttr.attributeDefinitionId
    );
    if (attribute) {
      employee.updateAttribute(changedAttr);
    }
    this.setState({ employee, editAttribute: null, actionTarget: null, actionAttribute: null });
  }

  onCancelAddAttribute(e) {
    this.setState({ editAttribute: null });
  }

  onAttributeRequirementChange(attribute, requirementType) {
    let { employee } = this.state;

    attribute.requirementType = requirementType;
    //employee.attributes.update(attribute);

    this.setState({ employee });
  }

  onAttributesAdded(addedAttributes) {
    const { organization } = this.props;
    let { employee } = this.state;

    let childAttrs = [];
    addedAttributes.forEach((a) => {
      a.childAttributes.forEach((c) => {
        childAttrs.push(c);
        if (
          !addedAttributes.some((a) => a.attributeDefinitionId === c.attributeDefinitionId) &&
          !employee.attributes.some((a) => a.attributeDefinitionId === c.attributeDefinitionId)
        ) {
          let stdAttr = c.toStandardAttribute();
          stdAttr.isNew = true;
          if (
            (stdAttr.hasProficiency && organization && organization.showProficiency) ||
            stdAttr.hasExpiryDate
          )
            stdAttr.validationRequestState = AbstractAttribute.status.feedback;
          else stdAttr.validationRequestState = AbstractAttribute.status.approved;
          addedAttributes.push(stdAttr);
        }
      });

      //Todo: update with default comments, helf off until 1.1
      //a.updateNeedsFeedback();
      //a.comment =
      a.isNew = true;
      if ((a.hasProficiency && organization && organization.showProficiency) || a.hasExpiryDate)
        a.validationRequestState = AbstractAttribute.status.feedback;
      else a.validationRequestState = AbstractAttribute.status.approved;
    });

    employee.attributes = [...employee.attributes, ...addedAttributes, ...childAttrs];

    //employee.addAttribute(addedAttributes);
    this.setState({ employee, showAttributeSelectDialog: false });
  }

  onCloseAttributeSelectDialog() {
    this.setState({ showAttributeSelectDialog: false });
  }

  onCancelSelectVacancy(e) {
    const { employee } = this.state;
    employee.job = employee.prevJob;
    this.setState({ showSelectVacancyDialog: false, employee, selectedPositionVacancies: [] });
  }

  onTabChange(e, newStep) {
    this.setState({ step: newStep, error: null });
  }

  onReportsToClick(e) {
    const { employee } = this.state;
    if (!employee.job) return;
    this.setState({ showAssignManagerDialog: true });
  }

  onSelectVacancy(e, selectedVacancy) {
    const { employee } = this.state;

    if (selectedVacancy.isCreatable) employee.manager = null;
    else {
      employee.job.jobId = selectedVacancy.jobId;
      if (selectedVacancy.manager) {
        employee.manager = selectedVacancy.manager;
        employee.reportsTo = selectedVacancy.manager.jobId;
      }
    }

    this.setState({
      employee,
      showSelectVacancyDialog: false
    });
  }

  onSaveAssignedManager(e, position, manager) {
    //if (!manager) return;

    const { employee } = this.state;
    employee.manager = manager;
    employee.reportsTo = manager ? manager.jobId : null;

    //Don't do this any more: If no manager, change any 'review' attrs
    /*if (!employee.hasManager()) {
            employee.attributes.forEach(a => {
                if (a.validationRequestState === AbstractAttribute.status.review) a.validationRequestState = AbstractAttribute.status.approved;
            });
        }*/

    this.setState({ employee, showAssignManagerDialog: false });
  }

  onCancelAssignedManager(e) {
    this.setState({ showAssignManagerDialog: false });
  }

  onBack() {
    this.setState({ step: 0 });
  }

  onNavigation(e, callback) {
    const { onNavigation } = this.props;
    return onNavigation && onNavigation(e, callback);
  }

  onCancel(e, source) {
    this.onNavigation(e, this.stopBlockingNavAndClose.bind(this));
  }

  stopBlockingNavAndClose(e) {
    const { onCancel, onBlockNavigation } = this.props;
    if (onBlockNavigation) onBlockNavigation(false, "Employee");
    if (onCancel) onCancel(e);
  }

  onSave() {
    const { onSave, onBlockNavigation, editMode } = this.props;
    const { employee, step } = this.state;

    if (!editMode && step === 0 && this.isValid(employee, 0))
      this.setState({ step: 1, error: null });
    if (onSave && (editMode || step === 1)) {
      if (onBlockNavigation) onBlockNavigation(false, "Employee");
      this.setState({ saving: true });
      onSave(employee, () => this.setState({ saving: false }));
    }
  }

  onEditStateChange(hasEdit) {
    this.setState({ hasEdit });
  }

  onLoadPreviousAttributeActivity(attribute, callback) {
    const { onLoadPreviousAttributeActivity } = this.props;
    const { employee } = this.state;

    if (onLoadPreviousAttributeActivity)
      onLoadPreviousAttributeActivity(attribute, (loadedAttr) => {
        employee.updateAttribute(new SettingsAttribute(loadedAttr));
        this.existingEmployee.updateAttribute(new SettingsAttribute(loadedAttr));
        callback(attribute);
      });
  }

  render() {
    const {
      organization,
      integration,
      theme,
      user,
      className,
      managers,
      editMode,
      onCancel,
      onCreate,
      onShowNewAttributeDialog,
      locations,
      attributes,
      onLoadAttributes,
      onLoadManagers,
      onDocumentClick,
      onAllowNavigation,
      onBlockNavigation,
      onNavigation
    } = this.props;
    const {
      sameIdEnable,
      error,
      employee,
      jobs,
      employeeLoaded,
      step,
      actionTarget,
      actionAttribute,
      saving,
      showSelectVacancyDialog,
      selectedPositionVacancies,
      showAssignManagerDialog,
      selectedVacancy,
      search,
      showAttributeSelectDialog,
      editAttribute,
      hasEdit
    } = this.state;

    let manager = null;
    //if (selectedVacancy && selectedVacancy.manager) manager = selectedVacancy.manager;
    if (employee && employee.manager) manager = employee.manager;

    let filterItems = [
      { id: "search", columnField: "attribute", operatorValue: "contains", value: search }
    ];

    return (
      <EditTemplate
        theme={theme}
        name='Employee'
        onAllowNavigation={onAllowNavigation}
        onBlockNavigation={onBlockNavigation}
        onEditStateChange={this.onEditStateChange.bind(this)}
        detectEdit={!saving && !editAttribute}
        compare={{
          existing: this.existingEmployee,
          editing: employee,
          members: [
            { name: "firstName" },
            { name: "middleName" },
            { name: "lastName" },
            { name: "preferredName" },
            { name: "dateOfBirth", detectEdit: "compareDay" },
            { name: "startDate", detectEdit: "compareDay" },
            { name: "email" },
            { name: "employeeNumber" },
            { name: "locationId" },
            {
              name: "integrationUsers",
              detectEdit: (existing, editing, isNew, isCleared) => {
                if (isNew) return editing.integrationUsers;

                if (existing.integrationUsers && editing.integrationUsers) {
                  let isChange = false;

                  if (existing.integrationUsers.length !== editing.integrationUsers.length)
                    return !isChange;

                  existing.integrationUsers.forEach((item, index) => {
                    if (item.username !== editing.integrationUsers[index].username) {
                      isChange = true;
                    }

                    if (
                      item.isForAllIntegrations !==
                      editing.integrationUsers[index].isForAllIntegrations
                    ) {
                      isChange = true;
                    }

                    if (
                      item.organizationIntegrationId !==
                      editing.integrationUsers[index].organizationIntegrationId
                    ) {
                      isChange = true;
                    }
                  });

                  return isChange;
                }
                return false;
              }
            },
            {
              name: "job",
              detectEdit: (existing, editing, isNew, isCleared) => {
                if (isNew) return editing.job;
                if (isCleared) return true;
                if (existing.job && editing.job)
                  return existing.job.jobDefinitionId !== editing.job.jobDefinitionId;
                return false;
              }
            },
            {
              name: "manager",
              detectEdit: (existing, editing, isNew, isCleared) => {
                if (isNew) return editing.manager;
                if (isCleared) return true;
                if (existing.manager && editing.manager)
                  return existing.manager.employeeId !== editing.manager.employeeId;
                return false;
              }
            },
            {
              name: "attributes",
              detectEdit: (existing, editing) => {
                //return !editing.isAttributesSame(existing.attributes, (i1, i2) => i1.attributeId === i2.attributeId && !i1.isChild && i1.originalValidationRequestState && i2.originalValidationRequestState && i1.originalValidationRequestState.index === i2.originalValidationRequestState.index);
                return !editing.isAttributesSame(
                  existing.attributes,
                  (i1, i2) => i1.attributeId === i2.attributeId && !i1.isChild
                );
              }
            }
          ]
        }}
      >
        <StyledAddEditEmployeeSetting className={`${className ? className : ""}`}>
          <PaperOrDialog
            theme={theme}
            dialogToBreakpoint='md'
            disabledToBreakpoint='md'
            paperPadding={{ xs: "56px 64px" }}
            title={editMode ? "Employee details" : "Create employee"}
            titleSx={{ fontSize: { xs: "20px", md: "30px" }, fontWeight: "bold" }}
            titleSize={1}
            backButton={editMode}
            onBack={this.onCancel.bind(this)}
          >
            <Stack direction='column' spacing={4}>
              {error && (
                <Alert severity='error' sx={{ width: "100%" }}>
                  {error}
                </Alert>
              )}

              {!editMode ? (
                <Stepper activeStep={step}>
                  <Step
                    onClick={this.onBack.bind(this)}
                    sx={{ cursor: step !== 0 ? "pointer" : "default" }}
                  >
                    <StepLabel>Employee information</StepLabel>
                  </Step>
                  <Step>
                    <StepLabel>Employee competencies</StepLabel>
                  </Step>
                </Stepper>
              ) : (
                <div>
                  <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                    <Tabs
                      centered={true}
                      value={step}
                      onChange={this.onTabChange.bind(this)}
                      variant='fullWidth'
                      sx={{ width: "100%" }}
                    >
                      <Tab label='Information' value={0} />
                      <Tab label='Competencies' value={1} />
                    </Tabs>
                  </Box>
                </div>
              )}

              {step === 0 && (
                <Fade className='klayo__add-edit-employee-setting-container' in={true}>
                  <div>
                    <Stack direction='column' spacing={4}>
                      <div className='klayo__add-edit-employee-personal-information'>
                        <h2>Personal information</h2>
                        <Grid container spacing={5}>
                          <Grid item xs={6}>
                            <TextField
                              label='First name (required)'
                              value={employee ? employee.firstName : null}
                              clearable={false}
                              autoComplete={false}
                              onChange={this.onFirstNameChange.bind(this)}
                              validationMethod={(e, value) => this.isFirstNameValid(employee)}
                              validationText='Please enter a valid first name (more than 2 characters)'
                              disabled={saving}
                              loading={!employeeLoaded}
                              sx={{ width: "100%" }}
                            />
                          </Grid>
                          <Grid item xs={6}>
                            <TextField
                              label='Middle name'
                              value={employee ? employee.middleName : null}
                              clearable={false}
                              autoComplete={false}
                              onChange={this.onMiddleNameChange.bind(this)}
                              disabled={saving}
                              loading={!employeeLoaded}
                              sx={{ width: "100%" }}
                            />
                          </Grid>
                          <Grid item xs={6}>
                            <TextField
                              label='Last name (required)'
                              value={employee ? employee.lastName : null}
                              clearable={false}
                              autoComplete={false}
                              onChange={this.onLastNameChange.bind(this)}
                              validationMethod={(e, value) => this.isLastNameValid(employee)}
                              validationText='Please enter a valid last name (more than 2 characters)'
                              disabled={saving}
                              loading={!employeeLoaded}
                              sx={{ width: "100%" }}
                            />
                          </Grid>
                          <Grid item xs={6}>
                            <TextField
                              label='Preferred name'
                              value={employee ? employee.preferredName : null}
                              clearable={false}
                              autoComplete={false}
                              onChange={this.onPreferredNameChange.bind(this)}
                              disabled={saving}
                              loading={!employeeLoaded}
                              sx={{ width: "100%" }}
                            />
                          </Grid>
                          <Grid item xs={6}>
                            <DatePicker
                              theme={theme}
                              label='Date of birth'
                              allowFuture={false}
                              onChange={this.onDOBChange.bind(this)}
                              value={
                                employee && employee.dateOfBirth
                                  ? new Date(employee.dateOfBirth)
                                  : null
                              }
                              allowEmpty={true}
                              disabled={saving}
                              loading={!employeeLoaded}
                              maxDate={new Date()}
                            />
                          </Grid>
                        </Grid>
                      </div>
                      <div className='klayo__add-edit-employee-divider'>
                        <Divider />
                      </div>
                      <div className='klayo__add-edit-employee-information'>
                        <h2>Employee information</h2>
                        <Grid container spacing={5}>
                          <Grid item xs={6}>
                            <TextField
                              label='Email (required)'
                              value={employee ? employee.email : null}
                              clearable={false}
                              autoComplete={false}
                              onChange={this.onEmailChange.bind(this)}
                              validationMethod={(e, value) => this.isEmailValid(employee)}
                              validationText='Please enter a valid email address'
                              disabled={saving}
                              loading={!employeeLoaded}
                              sx={{ width: "100%" }}
                            />
                          </Grid>
                          <Grid item xs={6}>
                            <LocationSelector
                              value={employee && employee.location ? employee.location : null}
                              items={locations ? locations : null}
                              callApi={false}
                              multiple={false}
                              compareItems={(a, b) => a.locationId === b.locationId}
                              getItemLabel={(location) => location.name}
                              onChange={this.onLocationsChange.bind(this)}
                              disabled={saving}
                              loading={!employeeLoaded || !locations}
                            />
                          </Grid>
                          <Grid item xs={6}>
                            <JobSelector
                              items={jobs ? jobs.jobs : null}
                              value={employee ? employee.job : null}
                              getItemLabel={(job) => job.name}
                              compareItems={(a, b) => a.jobDefinitionId === b.jobDefinitionId}
                              callApi={false}
                              multiple={false}
                              clearable={true}
                              onChange={this.onJobChange.bind(this)}
                              onInputChange={this.onJobInputChange.bind(this)}
                              disabled={saving}
                              loading={!employeeLoaded || !jobs}
                            />
                          </Grid>
                          <Grid item xs={6}>
                            <Stack direction='row' spacing={1} sx={{ width: "100%" }}>
                              <TextField
                                className='klayo-addeditemployee_reportsto'
                                label='Reports to'
                                clearable={false}
                                value={manager ? manager.fullName : "Unassigned"}
                                disabled={!employee || !employee.job || saving}
                                readOnly={true}
                                loading={!employeeLoaded || !employee}
                                onClick={this.onReportsToClick.bind(this)}
                                sx={{ width: "100%" }}
                              />
                              {/* <TooltipIcon
                                                        text='Notifications will go out to both the employee and their manager'
                                                        sx={{ marginTop: '23px!important' }} /> */}
                            </Stack>
                          </Grid>
                          <Grid item xs={6}>
                            <TextField
                              label='Employee ID'
                              clearable={false}
                              onChange={this.onEmployeeIdChange.bind(this)}
                              value={employee ? employee.employeeNumber : null}
                              disabled={saving}
                              loading={!employeeLoaded}
                              sx={{ width: "100%" }}
                            />
                          </Grid>
                          <Grid item xs={6}>
                            <DatePicker
                              theme={theme}
                              label='Start date'
                              onChange={this.onStartDateChange.bind(this)}
                              value={
                                employee && employee.startDate ? new Date(employee.startDate) : null
                              }
                              allowEmpty={true}
                              disabled={saving}
                              loading={!employeeLoaded}
                            />
                          </Grid>
                          {organization && organization.hasIntegration && (
                            <>
                              <Grid item xs={6}>
                                <Stack spacing={1} sx={{ width: "100%" }}>
                                  {sameIdEnable ? (
                                    // student id handle for sameId state with required
                                    <TextField
                                      label='Student ID'
                                      revalidate={sameIdEnable}
                                      clearable={false}
                                      onChange={this.onStudentIdChange.bind(this)}
                                      value={
                                        employee &&
                                        employee.integrationUsers &&
                                        employee.integrationUsers.length > 0
                                          ? employee.integrationUsers[0].username
                                          : null
                                      }
                                      disabled={saving}
                                      validationMethod={(e, value) =>
                                        this.isStudentIdValid(employee)
                                      }
                                      validationText='Please enter a valid student id (not empty and less than 200 characters)'
                                      loading={!employeeLoaded}
                                      sx={{ width: "100%" }}
                                    />
                                  ) : (
                                    // multi student id not required
                                    <MultiStudentIdTextField
                                      employee={employee}
                                      organization={organization}
                                      integration={integration}
                                      saving={saving}
                                      employeeLoaded={employeeLoaded}
                                      onStudentIdChange={this.onStudentIdChange.bind(this)}
                                      isStudentIdValid={this.isStudentIdValid.bind(this)}
                                    />
                                  )}
                                </Stack>
                              </Grid>
                              {organization.hasMultipleIntegrations && (
                                <Grid item xs={6}>
                                  <SameIdSwitchSectionStyled
                                    direction='row'
                                    spacing={1}
                                    justifyContent='space-between'
                                    alignItems='center'
                                    sx={{ width: "100%", maxWidth: "366px", marginTop: "24px" }}
                                  >
                                    <Switch
                                      label='Same ID for all training providers'
                                      checked={sameIdEnable}
                                      onChange={this.onSameIdEnable.bind(this)}
                                      disabled={false}
                                    />
                                    <TooltipIcon
                                      text='This employee’s unique identifier for training integrations'
                                      placement='right-end'
                                    />
                                  </SameIdSwitchSectionStyled>
                                </Grid>
                              )}
                            </>
                          )}
                        </Grid>
                      </div>
                    </Stack>
                  </div>
                </Fade>
              )}

              {step === 1 && (
                <Fade in={true}>
                  <div>
                    <Stack direction='column' spacing={2}>
                      <div>
                        <Stack
                          direction='row'
                          justifyContent='space-between'
                          alignItems='center'
                          spacing={2}
                        >
                          <TextField
                            value={search}
                            dense={true}
                            placeholder='Search competency'
                            disabled={
                              saving ||
                              (employee &&
                                employee.attributes &&
                                employee.attributes.length === 0) ||
                              !employeeLoaded
                            }
                            autoComplete={false}
                            leadingIcon={<SearchIcon />}
                            onChange={this.onSearch.bind(this)}
                            sx={{ minWidth: "300px" }}
                          />

                          <Button
                            onClick={this.onShowAttributeSelectDialog.bind(this)}
                            size='md'
                            variant='outlined'
                            startIcon={<AddIcon />}
                            label='Add competencies'
                            disabled={saving || !employeeLoaded || !locations}
                          />
                        </Stack>
                      </div>
                      <div>
                        <AttributeTable
                          rows={employee ? employee.attributes : null}
                          pagination={false}
                          showFooter={false}
                          theme={theme}
                          filterItems={filterItems}
                          hideFirstLastBorder={true}
                          dense={true}
                          tree={true}
                          onAttributeAction={this.onAttributeAction.bind(this)}
                          onAttributeSelect={this.onAttributeSelect.bind(this)}
                          onRequirementChange={this.onAttributeRequirementChange.bind(this)}
                          paper={false}
                          disabled={saving}
                          rowHasAction={(attr) => attr.isChild !== true}
                          childRowClass={(attr) => {
                            return (
                              "klayo-attributetable_nonactionablechild" +
                              (employee &&
                              employee.attributes &&
                              !employee.attributes.some(
                                (a) =>
                                  a.attributeDefinitionId === attr.attributeDefinitionId &&
                                  !a.isChild
                              )
                                ? " klayo-attributetable_ghosted"
                                : "")
                            );
                          }}
                          columns={[
                            { type: AttributeTable.columns.attribute, label: "Competency" },
                            //{ type: AttributeTable.columns.comment },
                            //{ type: AttributeTable.columns.currentProficiency },
                            //{ type: AttributeTable.columns.documents, label: 'Docs' },
                            //{ type: AttributeTable.columns.expiryTest },
                            //{ type: AttributeTable.columns.validationRequestStateTest },
                            { type: AttributeTable.columns.reasonLong, label: "Status" },
                            //{ type: AttributeTable.columns.needsFeedback },
                            { type: AttributeTable.columns.attachmentIcon },
                            { type: AttributeTable.columns.actions }
                          ]}
                          noRowsComponent={
                            <TablePlaceholder
                              text='Use Add competencies button to select competencies for this employee'
                              icon={<AttributesIcon />}
                            />
                          }
                        />

                        {actionAttribute && (
                          <Menu
                            open={actionTarget !== null}
                            anchorEl={actionTarget}
                            anchorOrigin={{
                              vertical: "bottom",
                              horizontal: "right"
                            }}
                            transformOrigin={{
                              vertical: "top",
                              horizontal: "right"
                            }}
                            onClose={this.onCloseActionMenu.bind(this)}
                          >
                            {!actionAttribute.isParent && (
                              <MenuItem onClick={this.onEditAttribute.bind(this)}>
                                <ListItemText>Edit competency</ListItemText>
                              </MenuItem>
                            )}
                            <MenuItem onClick={this.onRemoveAttribute.bind(this)}>
                              <ListItemText>Remove competency</ListItemText>
                            </MenuItem>
                          </Menu>
                        )}
                      </div>
                    </Stack>
                  </div>
                </Fade>
              )}

              <StyleEmployeeSettingBottomBtn
                className='klayo__add-edit-employee-bottom-btn-container'
                direction='row'
                justifyContent='space-between'
                alignItems='center'
                spacing={2}
                editMode={editMode ? true : false}
              >
                <Button onClick={this.onCancel.bind(this)} disabled={saving} label='Cancel' />
                <Stack direction='row' spacing={2}>
                  {step > 0 && !editMode && (
                    <Button
                      variant='outlined'
                      onClick={this.onBack.bind(this)}
                      startIcon={<LeftIcon />}
                      label='Back'
                    />
                  )}
                  <Button
                    variant='filled'
                    disabled={!this.isValid(employee, step) || !hasEdit || saving}
                    onClick={this.onSave.bind(this)}
                    endIcon={!editMode && step === 0 ? <RightIcon /> : null}
                    label={editMode ? "Save" : step === 0 ? "Continue" : "Create"}
                  />
                </Stack>
              </StyleEmployeeSettingBottomBtn>
            </Stack>

            {showSelectVacancyDialog && !employee.job.jobId && (
              <SelectVacancyDialog
                position={employee ? employee.job : null}
                vacancy={selectedVacancy}
                theme={theme}
                vacancies={selectedPositionVacancies}
                onSave={this.onSelectVacancy.bind(this)}
                onCancel={this.onCancelSelectVacancy.bind(this)}
              />
            )}

            {showAttributeSelectDialog && (
              <AttributeSelectorDialog
                attributes={attributes}
                theme={theme}
                excludedAttributes={employee ? employee.attributes.filter((a) => !a.isChild) : null}
                jobDefinitionId={employee && employee.job ? employee.job.jobDefinitionId : null}
                showSuggestions={true}
                onLoadAttributes={onLoadAttributes}
                onShowNewAttributeDialog={onShowNewAttributeDialog}
                onClose={this.onCloseAttributeSelectDialog.bind(this)}
                onSave={this.onAttributesAdded.bind(this)}
              />
            )}

            {showAssignManagerDialog && employee && (
              <AssignManagerDialog
                excludeEmployee={employee}
                theme={theme}
                managers={managers}
                position={employee.job}
                manager={manager}
                allowNone={true}
                onLoadManagers={onLoadManagers}
                onSave={this.onSaveAssignedManager.bind(this)}
                onCancel={this.onCancelAssignedManager.bind(this)}
              />
            )}

            {editAttribute && (
              <EditAttributeDialog
                user={user}
                employee={user}
                theme={theme}
                attribute={editAttribute}
                onSave={this.onSaveAttribute.bind(this)}
                allowNeedsFeedback={employee /* && employee.hasManager()*/}
                orgHasProficiency={organization && organization.showProficiency}
                onLoadPreviousAttributeActivity={this.onLoadPreviousAttributeActivity.bind(this)}
                onDocumentClick={onDocumentClick}
                onAllowNavigation={onAllowNavigation}
                onBlockNavigation={onBlockNavigation}
                onNavigation={onNavigation}
                onCancel={this.onCancelAddAttribute.bind(this)}
              />
            )}
          </PaperOrDialog>
        </StyledAddEditEmployeeSetting>
      </EditTemplate>
    );
  }
}

const StyledAddEditEmployeeSetting = styled.div`
  &.klayo__add-edit-employee-setting {
    .klayo__add-edit-employee-setting-container {
      margin-top: 40px;

      h2 {
        margin-bottom: 40px;
      }

      .klayo__add-edit-employee-personal-information {
        .MuiGrid-item {
          padding-top: 44px;
        }
      }

      .klayo__add-edit-employee-divider {
        margin-top: 40px;
      }

      .klayo__add-edit-employee-information {
        margin-top: 40px;

        .MuiGrid-item {
          padding-top: 44px;
        }
      }
    }

    .klayo__add-edit-employee-bottom-btn-container {
      margin-top: 40px;
    }
  }
`;

const StyleEmployeeSettingBottomBtn = styled(Stack)`
  &.klayo__add-edit-employee-bottom-btn-container {
    justify-content: ${(props) => (props.editMode ? "start" : "space-between")};
  }
`;

const SameIdSwitchSectionStyled = styled(Stack)`
  .MuiFormControlLabel-root {
    margin-right: 0px;
  }

  .MuiBox-root {
  }
`;
