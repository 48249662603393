import { get, trim } from "lodash";
import React from "react";
import { Route, Switch } from "react-router-dom";
import App from "../../App";
import { AppContext } from "../../common/AppContext";
import { Utils } from "../../common/Utils";
import { ErrorModel } from "../../common/models/ErrorModel";
import { Alert } from "../../components/Alert";
import { View } from "../../components/View";
import { Data } from "../../data/Data";
import { Organization } from "../../data/Organization";
import { BillingInvoices } from "../../data/billing/BillingInvoices";
import { BillingPlans } from "../../data/billing/BillingPlans";
import { BillingSettings } from "../../data/settings/BillingSettings";
import { OrganizationSettings } from "../../data/settings/OrganizationSettings";
import { SettingsCourseList } from "../../data/settings/SettingCourseList";
import { SettingsAttribute } from "../../data/settings/SettingsAttribute";
import { SettingsAttributeCategory } from "../../data/settings/SettingsAttributeCategory";
import { SettingsAttributeCategoryList } from "../../data/settings/SettingsAttributeCategoryList";
import { SettingsAttributeList } from "../../data/settings/SettingsAttributeList";
import { SettingsRegulationDomainList } from "../../data/settings/SettingsRegulationDomainList";
import { SettingsEmployeeList } from "../../data/settings/SettingsEmployeeList";
import { SettingsGroupList } from "../../data/settings/SettingsGroupList";
import { SettingsIntegrationList } from "../../data/settings/SettingsIntegrationList";
import { SettingsJobList } from "../../data/settings/SettingsJobList";
import { SettingsManagerList } from "../../data/settings/SettingsManagerList";
import { SettingsTasksList } from "../../data/settings/SettingsTasksList";
import { ReactComponent as AttributesIcon } from "../../resources/images/icons-attribute.svg";
import { ReactComponent as JobsIcon } from "../../resources/images/icons-briefcase.svg";
import { ReactComponent as GroupsIcon } from "../../resources/images/icons-departments.svg";
import { ReactComponent as BillingIcon } from "../../resources/images/icons-invoice.svg";
import { ReactComponent as CompanyIcon } from "../../resources/images/icons-office.svg";
import { ReactComponent as EmployeesIcon } from "../../resources/images/icons-users-1.svg";
import { AbstractView } from "../AbstractPage";
import { BillingDetailsDialog } from "../billing/BillingDetailsDialog";
import { BillingPlansDialog } from "../billing/BillingPlansDialog";
import { CreditCardDetailsDialog } from "../billing/CreditCardDetailsDialog";
import { SettingsRegulationsView } from "../regulations/SettingsRegulationsView";
import { UpdateRegulationView } from "../regulations/UpdateRegulationView";
import { SettingsTasksView } from "../tasks/SettingsTasksView";
import { AddEditAttributeView } from "./AddEditAttributeView";
import { AddEditCategoryView } from "./AddEditCategoryView";
import { SettingsTrainingView } from "./SettingTrainingView";
import { SettingsAttributesView } from "./SettingsAttributesView";
import { SettingsBillingView } from "./SettingsBillingView";
import { SettingsCompanyView } from "./SettingsCompanyView";
import { SettingsEmployeesView } from "./SettingsEmployeesView";
import { SettingsGroupsView } from "./SettingsGroupsView";
import { SettingsJobsView } from "./SettingsJobsView";
import { SettingsLandingView } from "./SettingsLandingView";
import { SettingsRegulationRegionList } from "../../data/settings/SettingRegulationRegionList";
import { SettingsRegulationCategoryList } from "../../data/settings/SettingRegulationCategoryList";
import { SettingsRegulationList } from "../../data/settings/SettingRegulationList";
import { GroupMemberSelectorList } from "../../data/filters/GroupMemberSelectorList";
import { SettingCourseFacultyList } from "../../data/settings/SettingCourseFacultyList";
const axios = require("axios").default;

export class Settings extends AbstractView {
  static contextType = AppContext;

  static defaultProps = {
    landingEnabled: false
  };

  static subPages = {
    jobs: {
      name: "Jobs",
      icon: <JobsIcon />,
      paths: [
        "/settings/jobs",
        "/settings/jobs/new",
        "/settings/jobs/edit/:id",
        "/settings/jobs/edit/:id/competencies",
        "/settings/jobs/assign-manager/:id",
        "/settings/jobs/assign-employee/:id"
      ],
      component: SettingsJobsView,
      hasAccess: (user, organization) =>
        user && user.isOrganizationAdmin && organization.status !== Organization.status.inactive,
      isDefault: (user) => true,
      isShowInSideBar: true
    },
    employees: {
      name: "Employees",
      icon: <EmployeesIcon />,
      paths: ["/settings/employees", "/settings/employees/new", "/settings/employees/edit/:id"],
      component: SettingsEmployeesView,
      hasAccess: (user, organization) =>
        user && user.isOrganizationAdmin && organization.status !== Organization.status.inactive,
      isShowInSideBar: true
    },
    groups: {
      name: "Groups",
      icon: <GroupsIcon />,
      paths: ["/settings/groups", "/settings/groups/new", "/settings/groups/edit/:id"],
      component: SettingsGroupsView,
      hasAccess: (user, organization) =>
        user && user.isOrganizationAdmin && organization.status !== Organization.status.inactive,
      isShowInSideBar: true
    },
    competencies: {
      name: "Competencies",
      icon: <AttributesIcon />,
      paths: [
        "/settings/competencies",
        "/settings/competencies/new",
        "/settings/competencies/new/parent",
        "/settings/competencies/edit/:id",
        "/settings/competencies/categories",
        "/settings/competencies/categories/new",
        "/settings/competencies/categories/edit/:id"
      ],
      component: SettingsAttributesView,
      hasAccess: (user, organization) =>
        user && user.isOrganizationAdmin && organization.status !== Organization.status.inactive,
      isShowInSideBar: true
    },
    regulations: {
      name: "Regulations",
      icon: <AttributesIcon />,
      paths: ["/settings/regulations"],
      component: SettingsRegulationsView,
      hasAccess: (user, organization) =>
        user && user.isOrganizationAdmin && organization.status !== Organization.status.inactive,
      isShowInSideBar: true
    },
    tasks: {
      name: "Tasks",
      icon: <AttributesIcon />,
      paths: ["/settings/tasks"],
      component: SettingsTasksView,
      hasAccess: (user, organization) =>
        user && user.isOrganizationAdmin && organization.status !== Organization.status.inactive,
      isShowInSideBar: true
    },
    training: {
      name: "Training",
      icon: <AttributesIcon />,
      paths: ["/settings/training"],
      component: SettingsTrainingView,
      hasAccess: (user, organization) =>
        user &&
        user.isOrganizationAdmin &&
        organization.status !== Organization.status.inactive &&
        organization.hasIntegration,
      isShowInSideBar: true
    },
    company: {
      name: "Company",
      icon: <CompanyIcon />,
      paths: [
        "/settings/company",
        "/settings/company/edit",
        "/settings/company/address/new",
        "/settings/company/address/edit",
        "/settings/company/locations/new",
        "/settings/company/locations/edit/:id"
      ],
      component: SettingsCompanyView,
      hasAccess: (user, organization) =>
        user && user.isOrganizationAdmin && organization.status !== Organization.status.inactive,
      isShowInSideBar: true
    },
    billing: {
      name: "Billing",
      icon: <BillingIcon />,
      paths: [
        "/settings/billing",
        "/settings/billing/history",
        "/settings/billing/payment",
        "/settings/billing/plan",
        "/settings/billing/details",
        "/settings/billing/payment/update-card"
      ],
      component: SettingsBillingView,
      hasAccess: (user, organization) =>
        user && user.isBillingUser && organization && !organization.isDemo,
      isDefault: (user) => user && !user.isOrganizationAdmin && user.isBillingUser,
      isShowInSideBar: true
    },
    newRegulation: {
      name: "New Regulation",
      paths: ["/settings/regulations/new"],
      component: UpdateRegulationView,
      hasAccess: (user, organization) =>
        user && user.isOrganizationAdmin && organization.status !== Organization.status.inactive,
      isShowInSideBar: false
    },
    editRegulation: {
      name: "Edit Regulation",
      paths: ["/settings/regulations/edit/:id"],
      component: UpdateRegulationView,
      hasAccess: (user, organization) =>
        user && user.isOrganizationAdmin && organization.status !== Organization.status.inactive,
      isShowInSideBar: false
    }
  };

  constructor(props) {
    super(props, App.pages.settings);

    this.state = {
      orgSettings: null,
      categories: null,
      attributes: null,
      jobs: null,
      courses: null,
      integration: null,
      employees: null,
      managers: null,
      groups: null,
      billingDetails: null,
      billingPlans: null,
      billingInvoices: null,
      showAddEditAttributeDialog: false,
      addAttributeCallback: null,
      showAddEditCategoryDialog: false,
      addCategoryCallback: null,
      categoryError: null,
      attributeError: null,
      taskError: null,
      courseError: null,
      showLanding:
        (!Utils.checkLocalStorageOption("hideSettingsLanding") ||
          this.hasQuery("showLanding=true")) &&
        props.user &&
        props.user.isOrganizationAdmin &&
        props.organization &&
        !props.organization.isDemo,
      newCategory: null,
      newAttribute: null,
      showBillingPlans: false,
      showBillingDetails: false,
      showCreditCardDetails: false,
      adminError: null,
      errorSnackbar: null,
      tasks: [],
      listRegulationRegion: [],
      listRegulationDomain: [],
      listAllRegulationDomain: [],
      listRegulationCategory: [],
      listRegulation: [],
      groupMembers: [],
      isHaveOneItemInLastPageInTasks: false
    };
  }

  onViewChange(location) {
    if (this.pathEquals("/settings")) this.resetErrors();
    if (this.hasQuery("showLanding=true")) this.setState({ showLanding: true });
  }

  resetErrors() {
    this.setState({ categoryError: null, attributeError: null });
  }

  onShowNewAttributeDialog(e, value, addAttributeCallback) {
    const newAttribute = new SettingsAttribute();
    newAttribute.name = value;
    this.setState({
      showAddEditAttributeDialog: true,
      attributeError: null,
      addAttributeCallback,
      newAttribute
    });
  }

  onCancelAddEditAttribute(e) {
    this.setState({ showAddEditAttributeDialog: false, attributeError: null });
  }

  onShowNewCategoryDialog(e, value, addCategoryCallback) {
    const newCategory = new SettingsAttributeCategory();
    newCategory.name = value;
    this.setState({
      showAddEditCategoryDialog: true,
      categoryError: null,
      addCategoryCallback,
      newCategory
    });
  }

  onCancelAddEditCategory(e) {
    this.setState({ showAddEditCategoryDialog: false, categoryError: null });
  }

  onDeleteVacancy(e, job) {
    const { jobs } = this.state;

    this.context.setLoading("addjob", true);

    return new Promise((resolve, reject) => {
      axios
        .post(Data.apiBasePath + "/Job", job.toApiDto(), {
          withCredentials: true
        })
        .then((response) => {
          jobs.addVacancy(job.jobDefinitionId, response.data);
          resolve();
        })
        .catch((e) => {
          //this.setState({ jobError: ErrorModel.parseServerError(e) });
          reject(e);
        })
        .finally(() => {
          this.context.setLoading("addjob", false);
        });
    });
  }

  onLoadAttributes(updateCache, searchText, pageSize, orderBy) {
    // option for some api use list all with this api
    const pagingOptions = {
      pageNumber: 1,
      searchText,
      orderBy,
      pageSize: pageSize || SettingsAttributeList.defaultPageSize
    };

    this.context.setLoading("attributes", true);

    // get setting attributes data then set to attributes state
    SettingsAttributeList.getWithPaging(this.context, pagingOptions)
      .then((attributes) => {
        this.setState({ attributes });
      })
      .catch((error) => {})
      .finally(() => this.context.setLoading("attributes", false));
  }

  onLoadAttributesPaging(pageNumber, pageSize, searchText, orderBy) {
    this.context.setLoading("attributes", true);

    SettingsAttributeList.getWithPaging(this.context, {
      pageNumber: pageNumber,
      searchText,
      orderBy,
      pageSize: pageSize || SettingsAttributeList.defaultPageSize
    })
      .then((attributes) => {
        this.setState({ attributes });
      })
      .catch((error) => {})
      .finally(() => this.context.setLoading("attributes", false));
  }

  onLoadAttributesPageSize(pageNumber, pageSize, searchText, orderBy) {
    this.context.setLoading("attributes", true);

    SettingsAttributeList.getWithPaging(this.context, {
      pageNumber: pageNumber,
      searchText,
      orderBy,
      pageSize: pageSize || SettingsAttributeList.defaultPageSize
    })
      .then((attributes) => {
        this.setState({ attributes });
      })
      .catch((error) => {})
      .finally(() => this.context.setLoading("attributes", false));
  }

  onLoadCategoriesPaging(pageNumber, pageSize, searchText, orderBy) {
    this.context.setLoading("categories", true);

    SettingsAttributeCategoryList.getWithPaging(this.context, {
      pageNumber: pageNumber,
      searchText: searchText,
      orderBy,
      pageSize: pageSize || SettingsAttributeCategoryList.defaultPageSize
    })
      .then((categories) => {
        this.setState({ categories });
      })
      .catch((error) => {})
      .finally(() => this.context.setLoading("categories", false));
  }

  onLoadCategoriesPageSize(pageNumber, pageSize, searchText, orderBy) {
    this.context.setLoading("categories", true);

    SettingsAttributeCategoryList.getWithPaging(this.context, {
      pageNumber: pageNumber,
      searchText: searchText,
      orderBy,
      pageSize: pageSize || SettingsAttributeCategoryList.defaultPageSize
    })
      .then((categories) => {
        this.setState({ categories });
      })
      .catch((error) => {})
      .finally(() => this.context.setLoading("categories", false));
  }

  onLoadCategories(updateCache, searchText, pageSize, orderBy) {
    this.context.setLoading("categories", true);

    const pagingOptions = {
      pageNumber: 1,
      searchText,
      orderBy,
      pageSize: pageSize || SettingsAttributeCategoryList.defaultPageSize
    };

    SettingsAttributeCategoryList.getWithPaging(this.context, pagingOptions)
      .then((categories) => {
        this.setState({ categories });
      })
      .catch((error) => {})
      .finally(() => this.context.setLoading("categories", false));
  }

  onLoadIntegration(updateCache, searchText, pageSize, orderBy) {
    this.context.setLoading("integration", true);

    const pagingOptions = {
      pageNumber: 1,
      searchText,
      orderBy,
      pageSize: pageSize || SettingsIntegrationList.defaultPageSize
    };

    SettingsIntegrationList.getWithPaging(this.context, pagingOptions)
      .then((integration) => {
        this.setState({ integration });
      })
      .catch((error) => {})
      .finally(() => this.context.setLoading("integration", false));
  }

  async onLoadTrainingFaculties() {
    this.context.setLoading("faculties", true);
    try {
      const listFaculties = await SettingCourseFacultyList.onGetData(this.context, {
        apiUrl: "/Integration/Faculties"
      });
      this.setState({ faculties: listFaculties.faculties });
      this.context.setLoading("faculties", false);
      return listFaculties.faculties;
    } catch (error) {
      this.context.setLoading("faculties", false);
    }
  }

  onLoadTrainingCourse(searchText, pageSize, orderBy, integrationId, facultyId) {
    this.context.setLoading("courses", true);

    const pagingOptions = {
      pageNumber: 1,
      orderBy,
      searchText: searchText,
      integrationId,
      facultyId,
      pageSize: pageSize || SettingsCourseList.defaultPageSize
    };

    // get setting courses data then set to courses state
    SettingsCourseList.getWithPaging(this.context, pagingOptions)
      .then((courses) => {
        this.setState({ courses });
      })
      .catch((error) => {})
      .finally(() => this.context.setLoading("courses", false));
  }

  onLoadCoursesPaging(pageNumber, pageSize, searchText, orderBy, integrationId) {
    this.context.setLoading("courses", true);

    SettingsCourseList.getWithPaging(this.context, {
      pageNumber: pageNumber,
      orderBy,
      searchText: searchText,
      integrationId,
      pageSize: pageSize || SettingsCourseList.defaultPageSize
    })
      .then((courses) => {
        this.setState({ courses });
      })
      .catch((error) => {})
      .finally(() => this.context.setLoading("courses", false));
  }

  onLoadCoursesPageSize(pageNumber, pageSize, searchText, orderBy, integrationId) {
    this.context.setLoading("courses", true);

    SettingsCourseList.getWithPaging(this.context, {
      pageNumber: pageNumber,
      orderBy,
      searchText: searchText,
      integrationId,
      pageSize: pageSize || SettingsCourseList.defaultPageSize
    })
      .then((courses) => {
        this.setState({ courses });
      })
      .catch((error) => {})
      .finally(() => this.context.setLoading("courses", false));
  }

  onSaveCourseRecurrence(id, recurrence) {
    this.context.setLoading("courses", true);

    const updateData = {
      id,
      recurrence
    };

    return new Promise((resolve, reject) => {
      axios
        .put(Data.apiBasePath + "/Integration/Course/UpdateCourse", updateData, {
          withCredentials: true
        })
        .then((response) => {
          const { id, recurrence } = get(response, "data.integrationCourse", {});
          const { courses } = this.state;
          const updateCourses = courses?.courses.map((course) => {
            if (course.id === id) course.recurrence = recurrence;
            return course;
          });

          this.setState({ courses: { ...this.state.courses, courses: updateCourses } });
          // this.onLoadTrainingCourse(true);
          resolve();
        })
        .catch((e) => {
          this.setState({ courseError: ErrorModel.parseServerError(e) });
          reject(e);
        })
        .finally(() => {
          this.context.setLoading("courses", false);
        });
    });
  }

  async onLoadTasks(searchText, pageNumber, pageSize, orderBy) {
    this.context.setLoading("tasks", true);

    const pagingOptions = {
      pageNumber,
      orderBy,
      searchText: searchText,
      pageSize: pageSize || SettingsTasksList.defaultPageSize
    };

    // get setting tasks data then set to tasks state
    SettingsTasksList.getWithPaging(this.context, pagingOptions)
      .then((tasks) => {
        if (tasks && tasks.tasks && tasks.tasks.length === 1 && !tasks.hasNext) {
          this.setState({ isHaveOneItemInLastPageInTasks: true });
        } else {
          this.setState({ isHaveOneItemInLastPageInTasks: false });
        }

        this.setState({ tasks });
      })
      .catch((error) => {})
      .finally(() => this.context.setLoading("tasks", false));
  }

  onCreateTask(taskName, allSelectedAttributes) {
    this.context.setLoading("tasks", true);
    return new Promise((resolve, reject) => {
      axios
        .post(Data.apiBasePath + "/Regulation/Task", {
          name: taskName,
          competencyIds: allSelectedAttributes.map((a) => a.attributeDefinitionId)
        })
        .then((response) => {
          resolve(response);
        })
        .catch((e) => {
          reject(e);
        })
        .finally(() => {
          this.context.setLoading("tasks", false);
        });
    });
  }

  onEditTask(regulationTaskId, taskName, allSelectedAttributes) {
    this.context.setLoading("tasks", true);
    return new Promise((resolve, reject) => {
      axios
        .put(Data.apiBasePath + "/Regulation/Task", {
          regulationTaskId,
          name: taskName,
          competencyIds: allSelectedAttributes.map((a) => a.attributeDefinitionId)
        })
        .then((response) => {
          resolve();
        })
        .catch((e) => {
          reject(e);
        })
        .finally(() => {
          this.context.setLoading("tasks", false);
        });
    });
  }

  async onGetListRegulationRegion() {
    this.context.setLoading("regulationRegion", true);
    try {
      const listRegulationRegion = await SettingsRegulationRegionList.onGetData(this.context, {
        apiUrl: "/Regulation/Region/List"
      });
      this.setState({ listRegulationRegion: listRegulationRegion.regions });
      this.context.setLoading("regulationRegion", false);
      return listRegulationRegion.regions;
    } catch (error) {
      this.context.setLoading("regulationRegion", false);
    }
  }

  async onGetListRegulationDomain(regionId, search) {
    let url = "";
    if (search === undefined || search === null || search === "") {
      url = `/Regulation/Region/${regionId}/Domain/List`;
    } else {
      url = `/Regulation/Region/${regionId}/Domain/List?searchTerm=${search}`;
    }
    this.context.setLoading("regularDomains", true);
    try {
      const listRegulationDomain = await SettingsRegulationDomainList.onGetData(this.context, {
        apiUrl: url
      });
      this.setState({ listRegulationDomain: listRegulationDomain.domains });
      if (search === undefined || search === null || search === "") {
        this.setState({ listAllRegulationDomain: listRegulationDomain.domains });
      }
      this.context.setLoading("regularDomains", false);
      return listRegulationDomain.domains;
    } catch (error) {
      this.context.setLoading("regularDomains", false);
    }
  }

  async onGetListRegulationCategory(domainId, search) {
    let url = "";
    if (search === undefined || search === null || search === "") {
      url = `/Regulation/Domain/${domainId}/Category/List`;
    } else {
      url = `/Regulation/Domain/${domainId}/Category/List?searchTerm=${search}`;
    }
    this.context.setLoading("regularCategories", true);

    try {
      const listRegulationCategory = await SettingsRegulationCategoryList.onGetData(this.context, {
        apiUrl: url
      });
      this.setState({ listRegulationCategory: listRegulationCategory.categories });
      this.context.setLoading("regularCategories", false);
      return listRegulationCategory.categories;
    } catch (error) {
      this.context.setLoading("regularCategories", false);
    }
  }

  async onGetListRegulationByCategoryId(categoryId, search) {
    let url = "";
    if (search === undefined || search === null || search === "") {
      url = `/Regulation/Category/${categoryId}/Regulations`;
    } else {
      url = `/Regulation/Category/${categoryId}/Regulations?searchTerm=${search}`;
    }
    this.context.setLoading("regularRegulations", true);

    try {
      const listRegulation = await SettingsRegulationList.onGetData(this.context, { apiUrl: url });
      this.setState({ listRegulation: listRegulation.regulations });
      this.context.setLoading("regularRegulations", false);
      return listRegulation.regulations;
    } catch (error) {
      this.context.setLoading("regularRegulations", false);
    }
  }

  onCreateRegulationDomain(domain, regionId) {
    this.context.setLoading("regularDomains", true);
    return new Promise((resolve, reject) => {
      axios
        .post(Data.apiBasePath + `/Regulation/Domain`, {
          name: trim(domain),
          regionId: regionId
        })
        .then((response) => {
          resolve(response.data);
        })
        .catch((e) => {
          reject(e);
        })
        .finally(() => {
          this.context.setLoading("regularDomains", false);
        });
    });
  }

  onUpdateRegulationDomain(domain, domainId) {
    this.context.setLoading("regularDomains", true);
    return new Promise((resolve, reject) => {
      axios
        .put(Data.apiBasePath + `/Regulation/Domain`, {
          name: trim(domain),
          regulationDomainId: domainId
        })
        .then((response) => {
          resolve(response.data);
        })
        .catch((e) => {
          reject(e);
        })
        .finally(() => {
          this.context.setLoading("regularDomains", false);
        });
    });
  }

  onCreateRegulationCategory(domainId, category) {
    this.context.setLoading("regularCategories", true);
    return new Promise((resolve, reject) => {
      axios
        .post(Data.apiBasePath + `/Regulation/Category`, {
          name: trim(category),
          regulationDomainId: domainId
        })
        .then((response) => {
          resolve(response.data);
        })
        .catch((e) => {
          reject(e);
        })
        .finally(() => {
          this.context.setLoading("regularCategories", false);
        });
    });
  }

  onUpdateRegulationCategory(category, categoryId, regulationDomainId) {
    this.context.setLoading("regularCategories", true);
    return new Promise((resolve, reject) => {
      axios
        .put(Data.apiBasePath + `/Regulation/Category`, {
          name: trim(category),
          regulationCategoryId: categoryId,
          regulationDomainId: regulationDomainId
        })
        .then((response) => {
          resolve(response.data);
        })
        .catch((e) => {
          reject(e);
        })
        .finally(() => {
          this.context.setLoading("regularCategories", false);
        });
    });
  }

  async onSaveRegulation(params) {
    this.context.setLoading("regularRegulations", true);
    return new Promise((resolve, reject) => {
      axios
        .post(Data.apiBasePath + `/Regulation/Regulation`, params)
        .then((response) => {
          resolve(response.data);
        })
        .catch((e) => {
          reject(e);
        })
        .finally(() => {
          this.context.setLoading("regularRegulations", false);
        });
    });
  }

  async onUpdateRegulation(params, regulationId) {
    this.context.setLoading("regularRegulations", true);
    return new Promise((resolve, reject) => {
      axios
        .put(Data.apiBasePath + `/Regulation/Regulation`, {
          ...params,
          regulationId
        })
        .then((response) => {
          resolve(response.data);
        })
        .catch((e) => {
          reject(e);
        })
        .finally(() => {
          this.context.setLoading("regularRegulations", false);
        });
    });
  }

  onDeleteTask(regulationTaskId) {
    this.context.setLoading("tasks", true);
    return new Promise((resolve, reject) => {
      axios
        .delete(Data.apiBasePath + "/Regulation/Task/" + regulationTaskId)
        .then((response) => {
          resolve();
        })
        .catch((e) => {
          reject(e);
        })
        .finally(() => {
          this.context.setLoading("tasks", false);
        });
    });
  }

  async onDeleteDomain(domainId) {
    this.context.setLoading("regularDomains", true);
    return new Promise((resolve, reject) => {
      axios
        .delete(Data.apiBasePath + `/Regulation/Domain`, {
          data: {
            regulationDomainId: domainId
          }
        })
        .then((response) => {
          resolve();
        })
        .catch((e) => {
          reject(e);
        })
        .finally(() => {
          this.context.setLoading("regularDomains", false);
        });
    });
  }

  async onDeleteCategory(categoryId) {
    this.context.setLoading("regularCategories", true);
    return new Promise((resolve, reject) => {
      axios
        .delete(Data.apiBasePath + `/Regulation/Category`, {
          data: {
            categoryId: categoryId
          }
        })
        .then((response) => {
          resolve();
        })
        .catch((e) => {
          reject(e);
        })
        .finally(() => {
          this.context.setLoading("regularCategories", false);
        });
    });
  }

  async onDeleteRegulation(regulationId) {
    this.context.setLoading("regularRegulations", true);
    return new Promise((resolve, reject) => {
      axios
        .delete(Data.apiBasePath + `/Regulation/Regulation`, {
          data: {
            regulationId: regulationId
          }
        })
        .then((response) => {
          resolve();
        })
        .catch((e) => {
          reject(e);
        })
        .finally(() => {
          this.context.setLoading("regularRegulations", false);
        });
    });
  }

  onLoadJobs(updateCache, searchText, pageSize) {
    this.context.setLoading("jobs", true);

    const pagingOptions = {
      pageNumber: 1,
      pageSize: pageSize || SettingsJobList.defaultPageSize,
      searchText: searchText
    };

    // get setting job data then set to jobs state
    SettingsJobList.getWithPaging(this.context, pagingOptions)
      .then((jobs) => {
        this.setState({ jobs });
      })
      .catch((error) => {})
      .finally(() => this.context.setLoading("jobs", false));
  }

  onLoadJobsPaging(pageNumber, pageSize, searchText) {
    this.context.setLoading("jobs", true);

    SettingsJobList.getWithPaging(this.context, {
      pageNumber: pageNumber,
      pageSize: pageSize || SettingsJobList.defaultPageSize,
      searchText: searchText
    })
      .then((jobs) => {
        this.setState({ jobs });
      })
      .catch((error) => {})
      .finally(() => this.context.setLoading("jobs", false));
  }

  onLoadJobsPageSize(pageNumber, pageSize, searchText) {
    this.context.setLoading("jobs", true);

    SettingsJobList.getWithPaging(this.context, {
      pageNumber: pageNumber,
      searchText: searchText,
      pageSize: pageSize || SettingsJobList.defaultPageSize
    })
      .then((jobs) => {
        this.setState({ jobs });
      })
      .catch((error) => {})
      .finally(() => this.context.setLoading("jobs", false));
  }

  onLoadGroupMembers(updateCache, searchText, pageSize) {
    this.context.setLoading("groupMembers", true);
    GroupMemberSelectorList.getWithPaging(this.context, {
      pageNumber: 1,
      pageSize: pageSize || GroupMemberSelectorList.defaultPageSize,
      searchText: searchText
    })
      .then((groups) => {
        this.setState({ groupMembers: groups.employees });
      })
      .catch((error) => {})
      .finally(() => this.context.setLoading("groupMembers", false));
  }

  onLoadEmployees(updateCache, searchText, pageSize, status, orderBy) {
    this.context.setLoading("employees", true);

    // get setting employees data then set to employees state
    SettingsEmployeeList.getWithPaging(this.context, {
      pageNumber: 1,
      searchText,
      orderBy,
      status,
      pageSize: pageSize || SettingsEmployeeList.defaultPageSize
    })
      .then((employees) => {
        this.setState({ employees });
      })
      .catch((error) => {})
      .finally(() => this.context.setLoading("employees", false));
  }

  onLoadEmployeesPaging(pageNumber, pageSize, searchText, status, orderBy) {
    this.context.setLoading("employees", true);

    SettingsEmployeeList.getWithPaging(this.context, {
      pageNumber: pageNumber,
      searchText,
      status,
      orderBy,
      pageSize: pageSize || SettingsEmployeeList.defaultPageSize
    })
      .then((employees) => {
        this.setState({ employees });
      })
      .catch((error) => {})
      .finally(() => this.context.setLoading("employees", false));
  }

  onLoadEmployeesPageSize(pageNumber, pageSize, searchText, status, orderBy) {
    this.context.setLoading("employees", true);

    // get setting employees data then set to employees state
    SettingsEmployeeList.getWithPaging(this.context, {
      pageNumber: pageNumber,
      searchText,
      status,
      orderBy,
      pageSize: pageSize || SettingsEmployeeList.defaultPageSize
    })
      .then((employees) => {
        this.setState({ employees });
      })
      .catch((error) => {})
      .finally(() => this.context.setLoading("employees", false));
  }

  onLoadManagers(updateCache) {
    this.context.setLoading("managers", true);

    SettingsManagerList.get(this.context, updateCache)
      .then((managers) => {
        this.setState({ managers });
      })
      .catch((error) => {})
      .finally(() => this.context.setLoading("managers", false));
  }

  onLoadGroups(updateCache) {
    this.context.setLoading("groups", true);

    SettingsGroupList.get(this.context, updateCache)
      .then((groups) => {
        this.setState({ groups });
      })
      .catch((error) => {})
      .finally(() => this.context.setLoading("groups", false));
  }

  handleNewAttribute(attr, returnToList) {
    const { addAttributeCallback } = this.state;

    this.onCancelAddEditAttribute();
    if (addAttributeCallback) addAttributeCallback(attr);
  }

  onSaveAttribute(attribute, returnToList, attributeSearch, categorySearch) {
    const { attributes } = this.state;

    this.context.setLoading("savingAttribute", true);

    return new Promise((resolve, reject) => {
      if (attribute.attributeId) {
        axios
          .put(Data.apiBasePath + "/AttributeDefinition", attribute.toApiDto(), {
            withCredentials: true
          })
          .then((response) => {
            const updatedAttr = attributes.update(response.data);
            this.handleNewAttribute(updatedAttr, returnToList);
            this.onLoadCategories(true, categorySearch);
            this.onLoadAttributes(true, attributeSearch);
            resolve();
          })
          .catch((e) => {
            this.setState({ attributeError: ErrorModel.parseServerError(e) });
            reject(e);
          })
          .finally(() => {
            this.context.setLoading("savingAttribute", false);
          });
      } else {
        axios
          .post(Data.apiBasePath + "/AttributeDefinition", attribute.toApiDto(), {
            withCredentials: true
          })
          .then((response) => {
            console.log(response.data);
            console.log(attributes);

            const newAttr = attributes.add(response.data);
            this.handleNewAttribute(newAttr, returnToList);
            this.onLoadCategories(true, categorySearch);
            this.onLoadAttributes(true, attributeSearch);
            resolve();
          })
          .catch((e) => {
            this.setState({ attributeError: ErrorModel.parseServerError(e) });
            reject(e);
          })
          .finally(() => {
            this.context.setLoading("savingAttribute", false);
          });
      }
    });
  }

  handleNewCategory(cat) {
    const { addCategoryCallback } = this.state;

    this.onCancelAddEditCategory();
    if (addCategoryCallback) addCategoryCallback(cat);
  }

  onSaveCategory(category, categorySearch) {
    const { categories, addCategoryCallback } = this.state;

    this.context.setLoading("savingCategory", true);

    return new Promise((resolve, reject) => {
      if (category.categoryId) {
        axios
          .put(
            Data.apiBasePath + "/AttributeDefinition/AttributeCategory",
            {
              attributeCategoryId: category.categoryId,
              name: category.name
            },
            {
              withCredentials: true
            }
          )
          .then((response) => {
            const updatedCat = categories.updateCategory(response.data);
            this.handleNewCategory(updatedCat);
            this.onLoadCategories(true, categorySearch);
            resolve();
          })
          .catch((e) => {
            this.setState({ categoryError: ErrorModel.parseServerError(e) });
            reject(e);
          })
          .finally(() => {
            this.context.setLoading("savingCategory", false);
          });
      } else {
        axios
          .post(
            Data.apiBasePath + "/AttributeDefinition/AttributeCategory",
            {
              name: category.name
            },
            {
              withCredentials: true
            }
          )
          .then((response) => {
            const newCat = categories.addCategory(response.data);
            this.handleNewCategory(newCat);
            this.onLoadCategories(true, categorySearch);
            resolve();
          })
          .catch((e) => {
            this.setState({ categoryError: ErrorModel.parseServerError(e) });
            reject(e);
          })
          .finally(() => {
            this.context.setLoading("savingCategory", false);
          });
      }
    });
  }

  onHasUser(user) {
    if (user && user.isOrganizationAdmin) {
      if (this.hasQuery("showLanding=true")) this.setState({ showLanding: true });

      this.context.setLoading("settings", true);

      OrganizationSettings.get(this.context)
        .then((orgSettings) => {
          this.setState({ orgSettings });
        })
        .catch((e) => {
          this.setState({ adminError: ErrorModel.parseServerError(e) });
          //Todo: handle error
          //alert('error 4553565: ' + error);
        })
        .finally(() => this.context.setLoading("settings", false));
    }
  }

  onCompanyNameChange(name) {
    const { organization } = this.props;
    organization.name = name;
    this.updateTitle();
  }

  onLoadBillingDetails(updateCache) {
    const { user } = this.props;

    if (user.isBillingUser) {
      this.context.setLoading("billing", true);

      BillingSettings.get(this.context, updateCache)
        .then((billingDetails) => {
          if (billingDetails.dataReceived) this.setState({ billingDetails });
        })
        .catch((error) => {})
        .finally(() => this.context.setLoading("billing", false));
    }
  }

  onLoadBillingInvoices(updateCache) {
    const { user } = this.props;

    if (user.isBillingUser) {
      this.context.setLoading("billingInvoices", true);

      BillingInvoices.get(this.context, updateCache)
        .then((billingInvoices) => {
          if (billingInvoices.dataReceived) this.setState({ billingInvoices });
        })
        .catch((error) => {})
        .finally(() => this.context.setLoading("billingInvoices", false));
    }
  }

  onLoadBillingPlans(updateCache) {
    const { user } = this.props;

    if (user.isBillingUser) {
      this.context.setLoading("billingPlans", true);

      BillingPlans.get(this.context, updateCache)
        .then((billingPlans) => {
          this.setState({ billingPlans });
        })
        .catch((error) => {})
        .finally(() => this.context.setLoading("billingPlans", false));
    }
  }

  reload() {
    this.onLoadBillingDetails(true);
    this.onLoadBillingInvoices(true);
  }

  onShowBillingPlans() {
    this.setState({ showBillingPlans: true });
  }

  onCloseBillingPlansDialog() {
    const { history } = this.props;
    this.setState({ showBillingPlans: false }, (s) => this.reload());
    history.push("/settings/billing");
  }

  onShowBillingDetails() {
    this.setState({ showBillingDetails: true });
  }

  onCloseBillingDetailsDialog() {
    const { history } = this.props;
    this.setState({ showBillingDetails: false }, (s) => this.reload());
    history.push("/settings/billing");
  }

  onShowCreditCardDetails() {
    this.setState({ showCreditCardDetails: true });
  }

  onCloseCreditCardDetailsDialog() {
    const { history } = this.props;
    this.setState({ showCreditCardDetails: false }, (s) => this.reload());
    history.push("/settings/billing/payment");
  }

  onInvoiceDownload(e, invoice) {
    this.context.setLoading("downloadingInvoice", true);

    Data.call("get", "/Organization/Invoices/" + invoice.invoiceId, { responseType: "blob" })
      .then((response) => {
        const blob = new Blob([response.data], { type: response.headers["content-type"] });

        const url = window.URL.createObjectURL(blob);
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", invoice.invoiceId);
        document.body.appendChild(link);
        link.click();
      })
      .catch((e) => {
        this.setState({ errorSnackbar: "Failed to download invoice" });
      })
      .finally(() => this.context.setLoading("downloadingInvoice", false));
  }

  getPageProps(page) {
    const { billingDetails, billingInvoices } = this.state;

    if (page === Settings.subPages.company)
      return { onCompanyNameChange: this.onCompanyNameChange.bind(this) };
    else if (page === Settings.subPages.competencies)
      return {
        onSaveAttribute: this.onSaveAttribute.bind(this),
        onSaveCategory: this.onSaveCategory.bind(this)
      };
    else if (page === Settings.subPages.billing)
      return {
        onLoadBillingDetails: this.onLoadBillingDetails.bind(this),
        onLoadBillingInvoices: this.onLoadBillingInvoices.bind(this),
        billingDetails,
        billingInvoices,
        onShowBillingPlans: this.onShowBillingPlans.bind(this),
        onShowBillingDetails: this.onShowBillingDetails.bind(this),
        onShowCreditCardDetails: this.onShowCreditCardDetails.bind(this),
        onInvoiceDownload: this.onInvoiceDownload.bind(this)
      };
    return {};
  }

  onHideLandingView(e) {
    Utils.setLocalStorageOption("hideSettingsLanding", "true");
    this.setState({ showLanding: false });
  }

  onDataChanged(change) {
    const { onChange } = this.props;
    if (onChange) onChange(change);
  }

  render() {
    if (!this.verifyAccess()) return this.renderAccessDenied();

    const {
      organization,
      user,
      theme,
      location,
      onChangeCurrentUserFromSettings,
      onDemoSwitch,
      onContactUs,
      landingEnabled,
      onNavigation,
      onBlockNavigation,
      onAllowNavigation
    } = this.props;
    let {
      orgSettings,
      courses,
      tasks,
      listRegulationDomain,
      listAllRegulationDomain,
      listRegulation,
      listRegulationCategory,
      listRegulationRegion,
      integration,
      categories,
      attributes,
      jobs,
      employees,
      managers,
      groups,
      billingPlans,
      adminError,
      groupMembers,
      isHaveOneItemInLastPageInTasks
    } = this.state;
    const {
      showAddEditCategoryDialog,
      showAddEditAttributeDialog,
      categoryError,
      attributeError,
      showLanding,
      newCategory,
      newAttribute,
      showBillingPlans,
      showBillingDetails,
      showCreditCardDetails,
      billingDetails,
      errorSnackbar
    } = this.state;
    const root = this.props.location.pathname === "/settings";

    return landingEnabled && showLanding && root ? (
      <SettingsLandingView
        {...this.props}
        allowDemoSwitch={
          organization &&
          (organization.isDemo ||
            (user &&
              user.isOrganizationAdmin &&
              user.hasDemoEmployee &&
              organization.demoOrgStatus !== Organization.status.resetting &&
              theme.isBreakpointUp("md")))
        }
        onHide={this.onHideLandingView.bind(this)}
        onDemoSwitch={onDemoSwitch}
        showNameInGreeting={user && user.isValidFirstName}
      />
    ) : (
      <View
        className='klayo-settings'
        theme={theme}
        fullWidth={false}
        scrollToTopOnMount={true}
        sx={{
          padding: { xs: "56px 0px", sm: "56px 0px" },
          minHeight: "100vh",
          background: { xs: "var(--white-wh-900)", md: "#f8f8fe" }
        }}
      >
        {adminError && <Alert severity='error'>{adminError}</Alert>}

        {!adminError && (
          <Switch>
            {Object.values(Settings.subPages)
              .filter((i) => user && organization && i.hasAccess(user, organization))
              .map((page, index) => (
                <Route
                  key={index}
                  exact={true}
                  path={[
                    ...page.paths,
                    user && page.isDefault && page.isDefault(user) ? "/settings" : ""
                  ]}
                  render={(props) =>
                    React.createElement(page.component, {
                      ...props,
                      organization,
                      theme,
                      location,
                      orgSettings,
                      categories,
                      attributes,
                      jobs,
                      courses,
                      tasks,
                      listRegulation,
                      listRegulationCategory,
                      listRegulationRegion,
                      listRegulationDomain,
                      listAllRegulationDomain,
                      integration,
                      employees,
                      user,
                      managers,
                      groups,
                      groupMembers,
                      allowAssignAdminBilling: true,
                      onLoadAttributes: this.onLoadAttributes.bind(this),
                      onLoadAttributesPaging: this.onLoadAttributesPaging.bind(this),
                      onLoadAttributesPageSize: this.onLoadAttributesPageSize.bind(this),
                      onLoadCategoriesPaging: this.onLoadCategoriesPaging.bind(this),
                      onLoadCategoriesPageSize: this.onLoadCategoriesPageSize.bind(this),
                      onLoadCategories: this.onLoadCategories.bind(this),
                      onLoadJobs: this.onLoadJobs.bind(this),
                      onLoadJobsPaging: this.onLoadJobsPaging.bind(this),
                      onLoadJobsPageSize: this.onLoadJobsPageSize.bind(this),
                      onLoadIntegration: this.onLoadIntegration.bind(this),
                      onLoadTrainingCourse: this.onLoadTrainingCourse.bind(this),
                      onLoadTrainingFaculties: this.onLoadTrainingFaculties.bind(this),
                      onLoadCoursesPaging: this.onLoadCoursesPaging.bind(this),
                      onLoadCoursesPageSize: this.onLoadCoursesPageSize.bind(this),
                      onLoadTasks: this.onLoadTasks.bind(this),
                      onCreateTask: this.onCreateTask.bind(this),
                      onEditTask: this.onEditTask.bind(this),
                      onGetListRegulationRegion: this.onGetListRegulationRegion.bind(this),
                      onGetListRegulationDomain: this.onGetListRegulationDomain.bind(this),
                      onGetListRegulationCategory: this.onGetListRegulationCategory.bind(this),
                      onGetListRegulationByCategoryId:
                        this.onGetListRegulationByCategoryId.bind(this),
                      onCreateRegulationDomain: this.onCreateRegulationDomain.bind(this),
                      onUpdateRegulationDomain: this.onUpdateRegulationDomain.bind(this),
                      onCreateRegulationCategory: this.onCreateRegulationCategory.bind(this),
                      onUpdateRegulationCategory: this.onUpdateRegulationCategory.bind(this),
                      onSaveRegulation: this.onSaveRegulation.bind(this),
                      onUpdateRegulation: this.onUpdateRegulation.bind(this),
                      onLoadEmployees: this.onLoadEmployees.bind(this),
                      onLoadEmployeesPaging: this.onLoadEmployeesPaging.bind(this),
                      onLoadEmployeesPageSize: this.onLoadEmployeesPageSize.bind(this),
                      onLoadGroupMembers: this.onLoadGroupMembers.bind(this),
                      onSaveCourseRecurrence: this.onSaveCourseRecurrence.bind(this),
                      onLoadManagers: this.onLoadManagers.bind(this),
                      onLoadGroups: this.onLoadGroups.bind(this),
                      onDataChanged: this.onDataChanged.bind(this),
                      onShowNewCategoryDialog: this.onShowNewCategoryDialog.bind(this),
                      onShowNewAttributeDialog: this.onShowNewAttributeDialog.bind(this),
                      onDeleteDomain: this.onDeleteDomain.bind(this),
                      onDeleteCategory: this.onDeleteCategory.bind(this),
                      onDeleteRegulation: this.onDeleteRegulation.bind(this),
                      onBlockNavigation,
                      onAllowNavigation,
                      onNavigation,
                      onContactUs,
                      onChangeCurrentUserFromSettings,
                      errorSnackbar,
                      isHaveOneItemInLastPageInTasks,
                      ...this.getPageProps(page)
                    })
                  }
                />
              ))}
          </Switch>
        )}

        {showAddEditAttributeDialog && (
          <AddEditAttributeView
            theme={theme}
            orgSettings={orgSettings}
            dialogMode={true}
            error={attributeError}
            location={location}
            categories={categories}
            attributes={attributes}
            attribute={newAttribute}
            onLoadCategories={this.onLoadCategories.bind(this)}
            onLoadAttributes={this.onLoadAttributes.bind(this)}
            onLoadJob={this.onLoadJobs.bind(this)}
            onShowNewCategoryDialog={this.onShowNewCategoryDialog.bind(this)}
            onBlockNavigation={onBlockNavigation}
            onAllowNavigation={onAllowNavigation}
            onNavigation={onNavigation}
            onSave={this.onSaveAttribute.bind(this)}
            onCancel={this.onCancelAddEditAttribute.bind(this)}
          />
        )}

        {showAddEditCategoryDialog && (
          <AddEditCategoryView
            theme={theme}
            dialogMode={true}
            error={categoryError}
            category={newCategory}
            onSave={this.onSaveCategory.bind(this)}
            onCancel={this.onCancelAddEditCategory.bind(this)}
          />
        )}

        {showBillingPlans && (
          <BillingPlansDialog
            user={user}
            organization={organization}
            theme={theme}
            billingPlans={billingPlans}
            existingBillingDetails={billingDetails}
            onLoadBillingDetails={this.onLoadBillingDetails.bind(this)}
            onLoadBillingPlans={this.onLoadBillingPlans.bind(this)}
            onContactUs={onContactUs}
            onClose={this.onCloseBillingPlansDialog.bind(this)}
          />
        )}

        {showBillingDetails && (
          <BillingDetailsDialog
            user={user}
            organization={organization}
            theme={theme}
            existingBillingDetails={billingDetails}
            onLoadBillingDetails={this.onLoadBillingDetails.bind(this)}
            onLoadBillingPlans={this.onLoadBillingPlans.bind(this)}
            onContactUs={onContactUs}
            onClose={this.onCloseBillingDetailsDialog.bind(this)}
          />
        )}

        {showCreditCardDetails && (
          <CreditCardDetailsDialog
            user={user}
            organization={organization}
            theme={theme}
            onClose={this.onCloseCreditCardDetailsDialog.bind(this)}
          />
        )}
      </View>
    );
  }
}
