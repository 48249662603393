import { Data } from "../Data";
import { User } from "../users/User";

export class PersonSelectorList extends Data {
  static instance = null;

  constructor(options) {
    super({
      api:
        (options.groupId
          ? "/MyGroup/" + options.groupId + "/SearchPeopleByName"
          : "/Teams/SearchPeopleByName") +
        "?searchText=" +
        options.search,
      callback: (data) => {
        options.resolve(this, false);
      },
      ...options
    });
  }

  onDataReceived(data) {
    this.employees = [];
    data.employees.forEach((employee, index) =>
      this.employees.push(new User({ data: { id: index, ...employee } }))
    );
  }

  static get(context, search, groupId) {
    return new Promise((resolve, reject) => {
      if (PersonSelectorList.instance && search === "") resolve(PersonSelectorList.instance, true);
      else
        PersonSelectorList.instance = new PersonSelectorList({
          resolve,
          reject,
          search,
          context,
          groupId
        });
    });
  }

  findMatch(attr) {
    return this.attributes.find((a) => a.name === attr);
  }
}
