import { Data } from "../Data";

export class ImpersonateUser extends Data {
  constructor(options) {
    super({
      api: "/User/Impersonate" + (options.employeeId ? "?employeeId=" + options.employeeId : ""),
      callback: (data) => {
        options.resolve(this, false);
      },
      ...options
    });
  }

  static post(context, employeeId) {
    return new Promise(
      (resolve, reject) =>
        new ImpersonateUser({ resolve, reject, context, employeeId, method: "post" })
    );
  }
}
