import { Box, Fade, Grid, Stack, Step, StepLabel, Stepper, Tab, Tabs } from "@mui/material";
import { Delayed, EditTemplate } from "klayowebshared";
import { get, trim } from "lodash";
import { AppContext } from "../../common/AppContext";
import { ErrorModel } from "../../common/models/ErrorModel";
import { Alert } from "../../components/Alert";
import { Button } from "../../components/Button";
import { MenuSelect } from "../../components/MenuSelect";
import { PaperOrDialog } from "../../components/PaperOrDialog";
import { TablePlaceholder } from "../../components/TablePlaceholder";
import { TextField } from "../../components/TextField";
import { TasksTable } from "../../components/table/TasksTable";
import { Data } from "../../data/Data";
import { ReactComponent as AddIcon } from "../../resources/images/icons-add.svg";
import { ReactComponent as LeftIcon } from "../../resources/images/icons-arrow-left.svg";
import { ReactComponent as RightIcon } from "../../resources/images/icons-arrow-right.svg";
import { ReactComponent as CheckListIcon } from "../../resources/images/icons-checklist.svg";
import { ReactComponent as SearchIcon } from "../../resources/images/icons-search.svg";
import { TaskSelectorDialog } from "./TaskSelectorDialog";

import { ViewComponent } from "../../components/ViewComponent";
import { trimToLowercase } from "../../utilities";

const axios = require("axios").default;
export class AddEditRegulationView extends ViewComponent {
  static contextType = AppContext;
  constructor(props) {
    super(props);

    this.state = {
      step: 0,
      saving: false,
      hasEdit: false,
      regulation: {
        name: null,
        region: null,
        domain: null,
        category: null,
        listSelectedTasks: []
      },
      error: null,
      search: null,
      listRegulationRegion: [],
      listDomain: [],
      listCategory: [],
      isShowTasksSelectDialog: false,
      taskSortModel: [
        {
          field: "name",
          sort: "asc"
        }
      ],
      existingRegulation: null
    };
  }

  async componentDidMount() {
    const { editMode, regulationId, regulationCategoryId, navigateStep } = this.props;
    const { regulation } = this.state;

    if (navigateStep && navigateStep === "2") {
      this.setState({ step: 1 });
    }

    const listRegulationRegion = await this.getListRegulationRegion();
    regulation.region = listRegulationRegion[0].id;
    this.setState({ regulation: regulation });
    await this.getListRegulationDomain(listRegulationRegion[0].id);

    if (editMode) {
      try {
        const response = await this.getRegulationDetails(regulationId);
        const { regulation } = response;
        await this.getListRegulationCategory(regulation.regulationDomain.id);
        this.setState({
          regulation: {
            name: regulation.name,
            region: regulation.regulationRegion.id,
            domain: regulation.regulationDomain.id,
            category: regulation.regulationCategory.id,
            listSelectedTasks: regulation.regulationTasks
          },
          existingRegulation: {
            name: regulation.name,
            region: regulation.regulationRegion.id,
            domain: regulation.regulationDomain.id,
            category: regulation.regulationCategory.id,
            listSelectedTasks: regulation.regulationTasks
          }
        });
      } catch (error) {
        // Handle error
      }
    }

    if (regulationCategoryId) {
      try {
        const response = await this.getRegulationCategoryDetails(regulationCategoryId);
        const { regulationCategory } = response;
        await this.getListRegulationCategory(regulationCategory.regulationDomain.id);
        this.setState({
          regulation: {
            name: null,
            region: regulationCategory.regulationRegion.id,
            domain: regulationCategory.regulationDomain.id,
            category: regulationCategory.id,
            listSelectedTasks: []
          },
          existingRegulation: {
            name: null,
            region: regulationCategory.regulationRegion.id,
            domain: regulationCategory.regulationDomain.id,
            category: regulationCategory.id,
            listSelectedTasks: []
          }
        });
      } catch (error) {
        // Handle error
      }
    }
  }

  async getRegulationDetails(regulationId) {
    return new Promise((resolve, reject) => {
      this.context.setLoading("loading", true);
      axios
        .get(Data.apiBasePath + `/Regulation/${regulationId}`, { withCredentials: true })
        .then((response) => {
          resolve(response.data);
        })
        .catch((e) => {
          reject({ error: ErrorModel.parseServerError(e) });
        })
        .finally(() => {
          this.context.setLoading("loading", false);
        });
    });
  }

  async getRegulationCategoryDetails(regulationCategoryId) {
    return new Promise((resolve, reject) => {
      this.context.setLoading("loading", true);
      axios
        .get(Data.apiBasePath + `/Regulation/Category/${regulationCategoryId}`, {
          withCredentials: true
        })
        .then((response) => {
          resolve(response.data);
        })
        .catch((e) => {
          reject({ error: ErrorModel.parseServerError(e) });
        })
        .finally(() => {
          this.context.setLoading("loading", false);
        });
    });
  }

  onEditStateChange(hasEdit) {
    this.setState({ hasEdit });
  }

  onCancel(e, source) {
    if (source === "back") this.onNavigation(e, this.stopBlockingNavAndClose.bind(this));
    else this.stopBlockingNavAndClose(e);
  }

  stopBlockingNavAndClose(e) {
    const { onCancel, onBlockNavigation } = this.props;
    if (onBlockNavigation) onBlockNavigation(false, "Job");
    if (onCancel) onCancel(e);
  }

  onTabChange(e, newStep) {
    this.setState({ step: newStep });
  }

  onNameChange(e) {
    const { regulation } = this.state;
    regulation.name = e.target.value;
    this.setState({ regulation });
  }

  onBack() {
    this.setState({ step: 0 });
  }

  onNavigation(e, callback) {
    const { onNavigation } = this.props;
    return onNavigation && onNavigation(e, callback);
  }

  async onSave() {
    const { editMode, onBlockNavigation, onSave, existingRegulation } = this.props;
    const { regulation, step } = this.state;
    if (!editMode && step === 0 && this.isValid(regulation, existingRegulation, 0))
      this.setState({ step: 1 });

    if (editMode || step === 1) {
      if (onBlockNavigation) onBlockNavigation(false, "Regulation");
      this.setState({ saving: true });
      const params = {
        name: trim(regulation.name),
        regulationCategoryId: regulation.category,
        regulationTaskIds: regulation.listSelectedTasks.map((t) => t.id)
      };
      onSave(params);
    }
  }

  isValid(regulation, existingRegulation, step) {
    if (
      !regulation ||
      !this.isNameValid(regulation) ||
      !regulation.region ||
      !regulation.domain ||
      !regulation.category
    )
      return false;

    if (existingRegulation) {
      if (
        existingRegulation.name === regulation.name &&
        existingRegulation.region === regulation.region &&
        existingRegulation.domain === regulation.domain &&
        existingRegulation.category === regulation.category &&
        existingRegulation.listSelectedTasks === regulation.listSelectedTasks
      )
        return false;
    }

    if (step === 0) return true;
    if (step === 1 && regulation.listSelectedTasks && regulation.listSelectedTasks.length > 0)
      return true;
    return true;
  }

  isNameValid(regulation) {
    return regulation && regulation.name != null && regulation.name.length > 2;
  }

  handleValidationText(name) {
    if (!name || name.length < 3) return "Please enter a valid name (more than 2 characters)";
    if (!name || name.length > 1000) return "Please enter a valid name (less than 1000 characters)";
  }

  onFormatListRegulationDomain(listRegulationDomain) {
    if (listRegulationDomain) {
      const newFormattedTypes = listRegulationDomain.reduce((acc, curr) => {
        acc[curr.id] = {
          id: curr.id,
          name: curr.name,
          value: curr.id,
          label: curr.name
        };
        return acc;
      }, {});
      return Object.values(newFormattedTypes);
    }
    return null;
  }

  onFormatListRegulationRegion(listRegulationRegion) {
    if (listRegulationRegion) {
      const newFormattedTypes = listRegulationRegion.reduce((acc, curr) => {
        acc[curr.id] = {
          id: curr.id,
          name: curr.name,
          value: curr.id,
          label: curr.name
        };
        return acc;
      }, {});
      return Object.values(newFormattedTypes);
    }
    return null;
  }

  async onSelectRegionItem(event) {
    const { regulation } = this.state;
    regulation.region = event.target.value;
    regulation.domain = null;
    regulation.category = null;
    this.setState({ regulation: regulation });
    this.setState({ listDomain: [], listCategory: [] });

    if (!event.target.value) return;
    await this.getListRegulationDomain(event.target.value);
  }

  async onSelectDomainItem(event) {
    const { regulation } = this.state;
    regulation.domain = event.target.value;
    regulation.category = null;
    this.setState({ regulation: regulation });
    this.setState({ listCategory: [] });
    if (!event.target.value) return;
    await this.getListRegulationCategory(event.target.value);
  }

  onSelectCategoryItem(event) {
    const { regulation } = this.state;
    regulation.category = event.target.value;
    this.setState({ regulation: regulation });
  }

  async getListRegulationDomain(regionId) {
    const { onGetListRegulationDomain } = this.props;
    const listRegulationDomain = await onGetListRegulationDomain(regionId);
    const filterListDomainHaveCategory = listRegulationDomain.filter(
      (item) => item.numberOfCategories !== 0
    );
    this.setState({ listDomain: filterListDomainHaveCategory });
  }

  async getListRegulationRegion() {
    const { onGetListRegulationRegion } = this.props;
    const listRegulationRegion = await onGetListRegulationRegion();
    this.setState({ listRegulationRegion });
    return listRegulationRegion;
  }

  async getListRegulationCategory(domainId) {
    const { onGetListRegulationCategory } = this.props;
    const listRegulationCategory = await onGetListRegulationCategory(domainId);
    this.setState({ listCategory: listRegulationCategory });
  }

  onCloseTasksSelectDialog() {
    this.setState({ isShowTasksSelectDialog: false });
  }

  onShowTasksSelectDialog() {
    this.setState({ isShowTasksSelectDialog: true });
  }

  onSearch = (e) => {
    const searchValue = e.target.value;
    this.setState({ search: searchValue });
  };

  filterTasksInRegulationByName(tasks, search) {
    return tasks.filter((task) => task.name.toLowerCase().includes(search.toLowerCase()));
  }

  onTasksAdded(tasks) {
    const { regulation } = this.state;
    regulation.listSelectedTasks = [...tasks];
    this.setState(regulation);
    this.onCloseTasksSelectDialog();
  }

  onPositionAction(action, selectedRegulation) {
    const { regulation } = this.state;
    const filterRegulationList = regulation.listSelectedTasks.filter(
      (r) => r.id !== selectedRegulation.id
    );
    this.setState({ regulation: { ...regulation, listSelectedTasks: filterRegulationList } });
  }

  render() {
    const {
      theme,
      onAllowNavigation,
      onBlockNavigation,
      editMode,
      saveRegulationError,
      user,
      loading
    } = this.props;

    const {
      step,
      saving,
      hasEdit,
      regulation,
      error,
      search,
      listCategory,
      listDomain,
      isShowTasksSelectDialog,
      existingRegulation,
      listRegulationRegion
    } = this.state;

    let filterItems = [
      { id: "search", columnField: "name", operatorValue: "contains", value: search }
    ];

    const filterTasks = get(regulation, "listSelectedTasks", []).filter((task) => {
      if (search) {
        const keyword = trimToLowercase(search || "");
        const taskName = trimToLowercase(task?.name || "");
        return taskName?.includes(keyword);
      } else {
        return true;
      }
    });

    return (
      <EditTemplate
        theme={theme}
        name='regulation'
        onAllowNavigation={onAllowNavigation}
        onBlockNavigation={onBlockNavigation}
        onEditStateChange={this.onEditStateChange.bind(this)}
        detectEdit={!saving}
        compare={{
          existing: existingRegulation,
          editing: regulation,
          members: [
            { name: "name" },
            { name: "region" },
            { name: "domain" },
            { name: "category" },
            { name: "listSelectedTasks" }
          ]
        }}
      >
        <PaperOrDialog
          theme={theme}
          dialogToBreakpoint='md'
          disabledToBreakpoint='md'
          paperPadding={{ xs: "46px 24px", md: "60px" }}
          title={editMode ? "Regulation details" : "Create regulation"}
          titleSx={{ fontSize: { xs: "20px", md: "30px" }, fontWeight: "bold" }}
          titleSize={1}
          backButton={editMode}
          onBack={this.onCancel.bind(this)}
        >
          <Stack direction='column' spacing={4}>
            {saveRegulationError && (
              <Alert severity='error' sx={{ width: "100%" }}>
                {saveRegulationError}
              </Alert>
            )}

            {!editMode ? (
              <Stepper activeStep={step}>
                <Step
                  onClick={this.onBack.bind(this)}
                  sx={{ cursor: step !== 0 ? "pointer" : "default" }}
                >
                  <StepLabel>Regulation information</StepLabel>
                </Step>
                <Step>
                  <StepLabel>Tasks</StepLabel>
                </Step>
              </Stepper>
            ) : (
              <div>
                <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                  <Tabs
                    centered={true}
                    value={step}
                    onChange={this.onTabChange.bind(this)}
                    variant='fullWidth'
                    sx={{ width: "100%" }}
                  >
                    <Tab label='Regulation information' value={0} />
                    <Tab label='Tasks' value={1} />
                  </Tabs>
                </Box>
              </div>
            )}

            {step === 0 && (
              <Fade className='klayo__add-edit-employee-setting-container' in={true}>
                <Grid container spacing={4}>
                  <Grid item xs={12}>
                    <TextField
                      label='Regulation name(required)'
                      placeholder=''
                      autoComplete={false}
                      autoFocus={true}
                      validationMethod={(e, value) => this.isNameValid(regulation)}
                      validationText={this.handleValidationText(regulation?.name)}
                      clearable={false}
                      sx={{ width: "100%" }}
                      value={regulation ? regulation.name : null}
                      onChange={this.onNameChange.bind(this)}
                      disabled={editMode && loading}
                    />
                  </Grid>

                  <Grid item xs={12}>
                    <MenuSelect
                      className='klayo_practical-type-select-menu'
                      label='Region (required)'
                      value={regulation?.region || null}
                      firstValueDefault={false}
                      noSelectionError={true}
                      dense={true}
                      disabled={true}
                      items={this.onFormatListRegulationRegion(listRegulationRegion)}
                      sx={{ width: "100%" }}
                      defaultValue={this.onFormatListRegulationRegion(listRegulationRegion)[0]}
                      placeholder='Select region'
                      clearable={true}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <MenuSelect
                      className='klayo_practical-type-select-menu'
                      label='Domain (required)'
                      value={regulation?.domain || null}
                      firstValueDefault={false}
                      noSelectionError={true}
                      dense={true}
                      items={this.onFormatListRegulationDomain(listDomain)}
                      onChange={this.onSelectDomainItem.bind(this)}
                      sx={{ width: "100%" }}
                      disabled={listDomain.length === 0}
                      placeholder='Select domain'
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <MenuSelect
                      className='klayo_practical-type-select-menu'
                      label='Category (required)'
                      value={regulation?.category || null}
                      firstValueDefault={false}
                      noSelectionError={true}
                      dense={true}
                      items={this.onFormatListRegulationDomain(listCategory)}
                      onChange={this.onSelectCategoryItem.bind(this)}
                      sx={{ width: "100%" }}
                      disabled={listCategory.length === 0}
                      placeholder='Select category'
                    />
                  </Grid>
                </Grid>
              </Fade>
            )}

            {step === 1 && (
              <Fade in={true}>
                <div>
                  <Stack direction='column' spacing={2}>
                    <div>
                      <Stack
                        direction='row'
                        justifyContent='space-between'
                        alignItems='center'
                        spacing={2}
                      >
                        <TextField
                          value={search}
                          dense={true}
                          placeholder='Search task'
                          autoComplete={false}
                          leadingIcon={<SearchIcon />}
                          disabled={
                            get(regulation, "listSelectedTasks", []).length > 0
                              ? regulation.listSelectedTasks.length === 0
                              : true
                          }
                          onChange={this.onSearch.bind(this)}
                          sx={{ minWidth: "300px" }}
                        />

                        <Button
                          onClick={this.onShowTasksSelectDialog.bind(this)}
                          size='md'
                          variant='outlined'
                          startIcon={<AddIcon />}
                          label='Add tasks'
                        />
                      </Stack>

                      <Delayed>
                        <TasksTable
                          rows={filterTasks}
                          pagination={false}
                          theme={theme}
                          showFooter={false}
                          rowHasAction={true}
                          filterItems={filterItems}
                          hideFirstLastBorder={true}
                          noRowsComponent={
                            <TablePlaceholder
                              text='Use Add tasks button to link tasks for this regulation.'
                              icon={<CheckListIcon />}
                            />
                          }
                          dense={true}
                          paper={false}
                          columns={[
                            { type: TasksTable.columns.name },
                            { type: TasksTable.columns.actions }
                          ]}
                          onPositionAction={this.onPositionAction.bind(this)}
                          isUsingDeleteIcon={true}
                          onRowClick={() => {}}
                        />
                      </Delayed>
                    </div>
                  </Stack>
                </div>
              </Fade>
            )}

            <Stack direction='row' justifyContent='space-between' alignItems='center' spacing={2}>
              <Button onClick={this.onCancel.bind(this)} label='Cancel' />

              <Stack direction='row' spacing={2}>
                {step > 0 && !editMode && (
                  <Button
                    variant='outlined'
                    onClick={this.onBack.bind(this)}
                    startIcon={<LeftIcon />}
                    label='Back'
                  />
                )}
                <Button
                  onClick={this.onSave.bind(this)}
                  variant='filled'
                  disabled={
                    !this.isValid(regulation, existingRegulation, step) || (editMode && !hasEdit)
                  }
                  endIcon={!editMode && step === 0 ? <RightIcon /> : null}
                  label={editMode ? "Save" : step === 0 ? "Continue" : "Create"}
                />
              </Stack>
            </Stack>
          </Stack>

          {isShowTasksSelectDialog && (
            <TaskSelectorDialog
              theme={theme}
              existingTasks={get(regulation, "listSelectedTasks", [])}
              onClose={this.onCloseTasksSelectDialog.bind(this)}
              onSave={this.onTasksAdded.bind(this)}
              subHeader='Select one or more tasks to be added to the regulation'
            />
          )}
        </PaperOrDialog>
      </EditTemplate>
    );
  }
}
