import { Loader } from "@googlemaps/js-api-loader";
import axios from "axios";
import { cloneDeep } from "lodash";
import { Component } from "react";
import { AppContext } from "../../common/AppContext";
import { Dialog } from "../../components/dialogs/Dialog";
import { AddressSelector } from "../../components/selectors/AddressSelector";
import { Data } from "../../data/Data";

export class AddEditAddressDialog extends Component {
  static contextType = AppContext;
  static loader;
  static googleApiKey = process.env.REACT_APP_GOOGLE_API_KEY;
  static googleGeolocationApiPath = `https://www.googleapis.com/geolocation/v1/geolocate?key=${this.googleApiKey}`;
  static googleGeocodingApiPath = `https://maps.googleapis.com/maps/api/geocode/json?key=${this.googleApiKey}`;
  static autoCompleteService;
  static placesService;

  constructor(props) {
    super(props);
    this.state = {
      addresses: null,
      searchAddress: "",
      address: props.address,
      saving: false,
      countryCode: null,
      successMessage: null
    };

    this.googleGeolocationApiPath = AddEditAddressDialog.googleGeolocationApiPath;
    this.googleGeocodingApiPath = AddEditAddressDialog.googleGeocodingApiPath;
    this.currentAddress = cloneDeep(props.address);

    this.loader = new Loader({
      apiKey: AddEditAddressDialog.googleApiKey,
      version: "weekly",
      libraries: ["places"]
    });
    this.loader.importLibrary("places").then((google) => {
      this.autoCompleteService = new google.AutocompleteService();
      this.placesService = new google.PlacesService(document.createElement("div"));
      this.PlacesServiceStatus = google.PlacesServiceStatus;
    });
  }

  componentDidMount() {
    this.onGetUserLocation();
  }

  onGetUserLocation() {
    axios
      .post(this.googleGeolocationApiPath, {})
      .then((res) => {
        this.onGetCountryCode(res.data?.location);
      })
      .catch((e) => {})
      .finally(() => {
        // this.context.setLoading('savePractical', false);
        // this.setState({saving: false});
      });
  }
  onGetCountryCode(data) {
    const path = `${this.googleGeocodingApiPath}&latlng=${data?.lat},${data?.lng}&result_type=country`;
    fetch(path, {})
      .then((res) => res.json())
      .then((res) => {
        const code = res?.results[0]?.address_components[0]?.short_name;
        this.setState({ countryCode: code });
      });
  }

  onSearchMapLocation(address) {
    this.setState({ searchAddress: address });

    if (!address) return;

    this.context.setLoading("address", true);

    const request = {
      input: address,
      types: ["geocode"],
      componentRestrictions: { country: this.state.countryCode || "au" },
      language: "en-AU"
    };

    this.autoCompleteService
      .getPlacePredictions(request, (results, status) => {
        const formattedAddresses =
          results &&
          results.map((address) => {
            return {
              placeId: address.place_id,
              street: address.description,
              latitude: 0,
              longitude: 0,
              city: "",
              state: "",
              zipCode: "",
              country: ""
            };
          });

        this.setState({ addresses: formattedAddresses });
      })
      .catch((error) => {})
      .finally(() => {
        this.context.setLoading("address", false);
      });
  }

  onGetAddressDetails(placeId) {
    const { isEdit } = this.props;
    if (placeId === null) {
      return;
    }
    const addressData = {
      latitude: 0,
      longitude: 0,
      street: "",
      city: "",
      state: "",
      zipCode: "",
      country: ""
    };

    this.context.setLoading("addressDetail", true);

    const request = {
      placeId: placeId,
      fields: ["geometry", "address_components", "formatted_address"],
      componentRestrictions: { country: this.state.countryCode || "au" },
      language: "en-AU"
    };

    this.placesService.getDetails(request, (place, status) => {
      if (status !== this.PlacesServiceStatus.OK) {
        console.error("Google map api error:", status);
      } else {
        const addressComponents = place.address_components;

        addressComponents.forEach((address) => {
          if (address.types.includes("postal_code")) {
            addressData.zipCode = address.long_name;
          }

          if (address.types.includes("country")) {
            addressData.country = address.short_name;
          }

          if (address.types.includes("administrative_area_level_1")) {
            addressData.state = address.long_name;
          }

          if (address.types.includes("administrative_area_level_2")) {
            addressData.city = address.long_name;
          }
          // check case if city is CBD
          if (
            address.types.includes("locality") ||
            (address.types.includes("political") && address.types.length === 1)
          ) {
            addressData.city = address.long_name;
          }
        });

        if (place.formatted_address) {
          addressData.street = place.formatted_address;
        }

        addressData.latitude = place.geometry.location.lat();
        addressData.longitude = place.geometry.location.lng();

        // const changeData = { ...addressData, latitude: latitude, longitude: longitude };

        if (isEdit) {
          this.onEditAddress(addressData);
        } else {
          this.onSaveAddress(addressData);
        }
      }

      this.context.setLoading("addressDetail", false);
    });
  }

  onSaveAddress(addressData) {
    const { history, orgSettings } = this.props;
    if (addressData === null) {
      return;
    }
    this.context.setLoading("savePractical", true);
    this.setState({ saving: true });

    axios
      .post(Data.apiBasePath + "/Organization/Admin/Address", addressData, {
        withCredentials: true
      })
      .then(() => {
        orgSettings.addAddress(addressData);
        history.push("/settings/company", { successMessage: "Add address successfully" });
      })
      .catch((e) => {})
      .finally(() => {
        this.context.setLoading("savePractical", false);
        this.setState({ saving: false });
      });
  }

  onEditAddress(addressData) {
    const { history, orgSettings } = this.props;

    if (addressData === null) {
      return;
    }

    this.context.setLoading("editPractical", true);
    this.setState({ saving: true });

    axios
      .put(Data.apiBasePath + "/Organization/Admin/Address", addressData, {
        withCredentials: true
      })
      .then(() => {
        orgSettings.addAddress(addressData);
        history.push("/settings/company", { successMessage: "Edit address successfully" });
      })
      .catch((e) => {})
      .finally(() => {
        this.context.setLoading("editPractical", false);
        this.setState({ saving: false });
      });
  }

  onAddressChange(e, address) {
    this.setState({ address: address });
  }

  isValid(address) {
    if (!this.isAddressValid(address) || !this.hasAddressChange(this.currentAddress, address))
      return false;
    return true;
  }

  isAddressValid(address) {
    if (!address) {
      return false;
    }

    return (address && address?.street?.length > 0) === true;
  }

  hasAddressChange(currentAddress, newAddress) {
    if (
      currentAddress?.street !== newAddress?.street ||
      currentAddress?.city !== newAddress?.city ||
      currentAddress?.state !== newAddress?.state ||
      currentAddress?.zipCode !== newAddress?.zipCode ||
      currentAddress?.country !== newAddress?.country
    ) {
      return true;
    }

    return false;
  }

  onSave() {
    const { address } = this.state;

    if (address) {
      const { placeId } = address;
      this.onGetAddressDetails(placeId);
    }
  }

  render() {
    const { isEdit, theme, onClose } = this.props;
    const { addresses, address, saving } = this.state;

    return (
      <Dialog
        open={true}
        theme={theme}
        title={isEdit ? "Edit address" : "New address"}
        onClose={onClose}
        fullWidth={true}
        maxWidth='sm'
        actions={[
          {
            label: isEdit ? "Save" : "Add address",
            primary: true,
            disabled: !this.isValid(address) || saving,
            onClick: this.onSave.bind(this)
          },
          {
            label: "Cancel",
            onClick: onClose
          }
        ]}
      >
        <AddressSelector
          label={"Address"}
          placeHolder='Search and add'
          items={addresses ? addresses : null}
          values={address ? address : null}
          onNewItemClick={false}
          getItemLabel={(add) => add.street}
          onChange={this.onAddressChange.bind(this)}
          onSearch={this.onSearchMapLocation.bind(this)}
        />
      </Dialog>
    );
  }
}
