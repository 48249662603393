import { useEffect, useState } from "react";
import { ErrorModel } from "../../common/models/ErrorModel";
import { AddEditRegulationView } from "../settings/AddEditRegulationView";
import { useLocation, useHistory } from "react-router-dom";

export const UpdateRegulationView = (props) => {
  const path = location.pathname;
  const editMode = path.startsWith("/settings/regulations/edit/");
  let editRegulationId = null;
  if (editMode) editRegulationId = path.replace("/settings/regulations/edit/", "");

  const { search } = useLocation();
  const historyRouter = useHistory();
  const queryParams = new URLSearchParams(search);
  const regulationCategoryId = queryParams.get("regulationCategoryId");
  const navigateMode = queryParams.get("navigateMode");
  const navigateStep = queryParams.get("navigateStep");
  const [loading, setLoading] = useState(null);
  const {
    theme,
    onBlockNavigation,
    onAllowNavigation,
    onDataChanged,
    onNavigation,
    onGetListRegulationRegion,
    onGetListRegulationDomain,
    onGetListRegulationCategory,
    selectedRegulation,
    history,
    onSaveRegulation,
    onUpdateRegulation
  } = props;

  const [saveRegulationError, setSaveRegulationError] = useState(null);
  const [listRegulationRegion, setListRegulationRegion] = useState([]);

  useEffect(() => {
    setLoading(true);
    onGetListRegulationRegion()
      .then((response) => {
        setListRegulationRegion(response);
      })
      .finally(() => {
        setLoading(false);
      });
  }, []);

  const onSaveRegulationItem = async (regulation) => {
    try {
      if (editMode === true) {
        await onUpdateRegulation(regulation, editRegulationId);
      } else {
        await onSaveRegulation(regulation);
      }
      history.push("/settings/regulations");
    } catch (error) {
      await setSaveRegulationError(ErrorModel.parseServerError(error));
    }
  };

  return (
    <div>
      <AddEditRegulationView
        editMode={editMode}
        theme={theme}
        saveRegulationError={saveRegulationError}
        regulationId={editRegulationId}
        regulationCategoryId={regulationCategoryId}
        onBlockNavigation={onBlockNavigation}
        onAllowNavigation={onAllowNavigation}
        onDataChanged={onDataChanged}
        onNavigation={onNavigation}
        onCancel={(e) => historyRouter.goBack()}
        onSave={onSaveRegulationItem}
        onGetListRegulationDomain={onGetListRegulationDomain}
        onGetListRegulationCategory={onGetListRegulationCategory}
        selectedRegulation={selectedRegulation}
        onGetListRegulationRegion={onGetListRegulationRegion}
        loading={loading}
        navigateMode={navigateMode}
        navigateStep={navigateStep}
      />
    </div>
  );
};
