import { Component } from "react";
import { Box, Tabs, Tab, Stack, Menu, MenuItem, ListItemText } from "@mui/material";
import { NavLink } from "react-router-dom";
import { Button } from "./Button";
import { ReactComponent as ChevronDownIcon } from "../resources/images/icons-chevron-down.svg";
import { ReactComponent as ChevronUpIcon } from "../resources/images/icons-chevron-up.svg";

export class PillTabs extends Component {
  static defaultProps = {
    padding: 23,
    title: null
  };

  constructor(props) {
    super(props);

    this.state = {
      showMenu: false,
      dropButton: null
    };
  }

  onDropDownClick(e) {
    const { theme } = this.props;
    if (!theme.isBreakpointDown("md")) return;
    this.setState({
      showMenu: true,
      dropButton: e.target.classList.contains("klayo-pilltabs_tabs_dropbutton")
        ? e.target.parentElement.parentElement
        : e.target
    });
  }

  onMenuClose(e) {
    this.setState({ showMenu: false });
  }

  renderItems(items, tabMode) {
    const { user, organization, path, basePath, params } = this.props;

    return items.map(([key, page]) => {
      let value = basePath + (page.paths[0] !== "/" ? page.paths[0] : "");
      let to =
        page.paths[0] === "/"
          ? basePath + (params ? "?" + params : "")
          : basePath + page.paths[0] + (params ? "?" + params : "");
      return tabMode ? (
        <Tab
          key={key}
          value={value}
          exact={true}
          isActive={(match, location) => {
            return user && page.isDefault && page.isDefault(user)
              ? location.pathname === basePath ||
                  (page.allowRoot && location.pathname === page.paths[0])
              : location.pathname.startsWith(basePath + page.paths[0]);
          }}
          to={to}
          label={page.name}
          activeClassName='active'
          disableRipple={true}
          disabled={!user || !organization || !page.hasAccess(user, organization)}
          component={NavLink}
          sx={{
            display: {
              xs: value === path ? "block" : "none",
              md: "block"
            },
            padding: {
              xs: "4px 12px",

              md: "12px 24px"
            },
            height: {
              xs: "32px",

              md: "32px"
            },
            minHeight: {
              xs: "32px",

              md: "48px"
            }
          }}
        />
      ) : (
        <MenuItem
          key={key}
          to={to}
          disabled={!user || !organization || !page.hasAccess(user, organization)}
          component={NavLink}
          onClick={this.onMenuClose.bind(this)}
        >
          <ListItemText disableTypography={true}>{page.name}</ListItemText>
        </MenuItem>
      );
    });
  }

  render() {
    const { user, organization, theme, sx, className, path, pages } = this.props;
    const { dropButton, showMenu } = this.state;

    return (
      <Box
        className='klayo-pilltabs'
        sx={{
          height: {
            xs: "48px",
            md: "64px"
          },
          cursor: {
            xs: "pointer",
            sm: "pointer"
          },
          ...sx
        }}
      >
        <Stack
          direction='row'
          justifyContent='space-between'
          alignItems='center'
          onClick={this.onDropDownClick.bind(this)}
        >
          <Tabs
            className={"klayo-pilltabs_tabs " + className}
            value={path}
            TabIndicatorProps={{
              sx: {
                display: { xs: "none", sm: "none", md: "block" },
                height: { xs: "0", sm: "0", md: "46px" }
              }
            }}
          >
            {this.renderItems(Object.entries(pages), true)}
          </Tabs>
          <Button
            className='klayo-pilltabs_tabs_dropbutton'
            icon={<ChevronDownIcon />}
            onClick={this.onDropDownClick.bind(this)}
            sx={{
              display: { xs: "block", sm: "block", md: "none" },
              padding: "6px 14px 0 0",
              margin: "0 4px 0 0"
            }}
          />
        </Stack>

        <Menu
          className='klayo-pilltabs_menu'
          open={showMenu}
          anchorEl={dropButton}
          onClose={this.onMenuClose.bind(this)}
          PaperProps={{
            sx: {
              width: { xs: "calc(100vw - 48px)", sm: "calc(100vw - 82px)" },
              maxWidth: "100%",
              left: { xs: "32px", sm: "48px" },
              right: { xs: "32px", sm: "48px" }
            }
          }}
          anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
          transformOrigin={{ vertical: "top", horizontal: "left" }}
        >
          {this.renderItems(Object.entries(pages), false)}
        </Menu>
      </Box>
    );
  }
}
