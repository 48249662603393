import App from "../../App";
import { Team } from "./Team";

export class Group extends Team {
  static defaultProps = {
    ...Team.defaultProps,
    groupMode: true,
    basePathDepth: 2
  };

  constructor(props, context) {
    super(props, context, App.pages.groups);

    const { groups, location } = this.props;
    const groupId = Group.getGroupIdFromPath(location);

    this.state = {
      ...this.state,
      groupId,
      groups,
      group: groups ? groups.get(groupId) : null
    };
  }

  componentDidMount() {
    super.componentDidMount();
    this.loadDetails();
  }

  componentDidUpdate(prevProps, prevState) {
    super.componentDidUpdate(prevProps, prevState);
    const { groups } = this.props;
    const { groupId, group } = this.state;
    if (prevProps.groups !== groups || prevState.group !== group)
      this.setState({ groups, group: groups ? groups.get(groupId) : null }, () =>
        this.updateTitle()
      );
  }

  updateTitle() {
    const { organization } = this.props;
    const { group } = this.state;
    document.title =
      (group ? group.name + " - " : "") +
      this.page.name +
      (organization ? " - " + organization.name : "") +
      " - Klayo";
  }

  onViewChange(location) {
    const { groupId, groups } = this.state;

    const newGroupId = Group.getGroupIdFromPath(location);
    if (newGroupId !== groupId) {
      const group = groups ? groups.get(newGroupId) : null;
      this.setState({ group, groupId: newGroupId, basePath: this.getBasePath(location) }, () =>
        this.loadDetails()
      );
    }
  }

  getBasePath(location) {
    return "/groups/" + Group.getGroupIdFromPath(location ?? this.props.location);
  }

  static getGroupIdFromPath(location) {
    const pathSplit = location.pathname.split("/");
    if (pathSplit.length >= 3) return pathSplit[2];
    return null;
  }
}
