import React, { Component } from "react";
import {
  Editor,
  EditorState,
  RichUtils,
  ContentState,
  convertToRaw,
  convertFromHTML
} from "draft-js";
import "draft-js/dist/Draft.css";
import { Button } from "./Button";
import draftToHtml from "draftjs-to-html";
import { ReactComponent as BoldIcon } from "../resources/images/icons-style-bold.svg";
import { ReactComponent as ItalicIcon } from "../resources/images/icons-style-italic.svg";
import { ReactComponent as UnderlineIcon } from "../resources/images/icons-style-underline.svg";
import { ReactComponent as ListBulletIcon } from "../resources/images/icons-style-list-bullet.svg";
import { ReactComponent as ListNumberIcon } from "../resources/images/icons-style-list-number.svg";
import { Divider, InputLabel } from "@mui/material";

export class RichTextEditor extends Component {
  constructor(props) {
    super(props);

    this.state = {
      editorState: props.editorState ? props.editorState : RichTextEditor.createEditorState(),
      focused: false
    };

    this.editorRef = React.createRef();
  }

  static createEditorState(html) {
    if (html) {
      const blocksFromHTML = convertFromHTML(html);
      return EditorState.createWithContent(
        ContentState.createFromBlockArray(blocksFromHTML.contentBlocks, blocksFromHTML.entityMap)
      );
    } else return EditorState.createEmpty();
  }

  componentDidUpdate(prevProps) {
    if (this.props.editorState !== prevProps.editorState) {
      this.setState({ editorState: this.props.editorState }); //, () => this.editorRef.current.focus());
    }
  }

  onChange(editorState) {
    const { onChange, onChangeEditorState } = this.props;
    if (onChangeEditorState) onChangeEditorState(editorState);
    if (onChange) {
      const raw = convertToRaw(editorState.getCurrentContent());
      onChange(draftToHtml(raw));
    }
  }

  returnFocus() {
    setTimeout((t) => this.editorRef.current.focus(), 100);
  }

  onBoldClick() {
    this.onChange(RichUtils.toggleInlineStyle(this.state.editorState, "BOLD"));
    this.returnFocus();
  }

  onItalicClick() {
    this.onChange(RichUtils.toggleInlineStyle(this.state.editorState, "ITALIC"));
    this.returnFocus();
  }

  onUnderlineClick() {
    this.onChange(RichUtils.toggleInlineStyle(this.state.editorState, "UNDERLINE"));
    this.returnFocus();
  }

  onBulletListClick() {
    this.onChange(RichUtils.toggleBlockType(this.state.editorState, "unordered-list-item"));
    this.returnFocus();
  }

  onNumberListClick() {
    this.onChange(RichUtils.toggleBlockType(this.state.editorState, "ordered-list-item"));
    this.returnFocus();
  }

  onFocus(e) {
    this.setState({ focused: true });
  }

  onBlur(e) {
    this.setState({ focused: false });
  }

  onKeyCommand(command, editorState) {
    const newState = RichUtils.handleKeyCommand(editorState, command);
    if (newState) {
      this.onChange(newState);
      return true;
    }
    return false;
  }

  render() {
    const { label, disabled } = this.props;
    const { focused, editorState } = this.state;

    return (
      <div className={"klayo-rte" + (focused ? " klayo-rte--focused" : "")}>
        {label && (
          <InputLabel disabled={disabled} sx={{ marginBottom: "6px" }}>
            {label}
          </InputLabel>
        )}

        <div className='klayo-rte_box'>
          <div className='klayo-rte_toolbar'>
            <Button
              tooltip='Bold'
              size='sm'
              icon={<BoldIcon />}
              onMouseDown={(e) => e.preventDefault()}
              onClick={this.onBoldClick.bind(this)}
            />
            <Button
              tooltip='Italic'
              size='sm'
              icon={<ItalicIcon />}
              onClick={this.onItalicClick.bind(this)}
            />
            <Button
              tooltip='Underline'
              size='sm'
              icon={<UnderlineIcon />}
              onClick={this.onUnderlineClick.bind(this)}
            />
            <Divider orientation='vertical' flexItem />
            <Button
              tooltip='Bullet list'
              size='sm'
              icon={<ListBulletIcon />}
              onClick={this.onBulletListClick.bind(this)}
            />
            <Button
              tooltip='Number list'
              size='sm'
              icon={<ListNumberIcon />}
              onClick={this.onNumberListClick.bind(this)}
            />
          </div>
          <div className='klayo-rte_content'>
            <Editor
              ref={this.editorRef}
              editorState={editorState}
              onFocus={this.onFocus.bind(this)}
              onBlur={this.onBlur.bind(this)}
              onChange={this.onChange.bind(this)}
              handleKeyCommand={this.onKeyCommand.bind(this)}
              spellCheck={true}
              stripPastedStyles={true}
            />
          </div>
        </div>
      </div>
    );
  }
}
