import { Data } from "../Data";

export class CountrySelectorList extends Data {
  static instance = null;

  constructor(options) {
    super({
      api: "/Organization/Admin/Billing/SupportedCountries",
      callback: (data) => {
        options.resolve(this, false);
      },
      ...options
    });
  }

  onDataReceived(data) {
    this.countries = data.countries;
  }

  static get(context, search) {
    return new Promise((resolve, reject) => {
      if (CountrySelectorList.instance && (search === "" || search === null))
        resolve(CountrySelectorList.instance, true);
      else
        CountrySelectorList.instance = new CountrySelectorList({
          resolve,
          reject,
          search,
          context
        });
    });
  }

  findMatch(attr) {
    return this.attributes.find((a) => a.name === attr);
  }
}
