import { unstable_composeClasses as composeClasses, styled } from "@mui/material";
import Box from "@mui/material/Box";
import CircularProgress from "@mui/material/CircularProgress";
import IconButton from "@mui/material/IconButton";
import { getDataGridUtilityClass, useGridApiContext, useGridRootProps } from "@mui/x-data-grid-pro";
import { useState } from "react";

export const GroupingCellWithLazyLoading = (props) => {
  const { id, rowNode, row, formattedValue } = props;
  const useUtilityClasses = (ownerState) => {
    const { classes } = ownerState;
    const slots = {
      root: ["treeDataGroupingCell"],
      toggle: ["treeDataGroupingCellToggle"]
    };
    return composeClasses(slots, getDataGridUtilityClass, classes);
  };

  const [selectedRowId, setSelectedRowId] = useState(null);

  const rootProps = useGridRootProps();
  const apiRef = useGridApiContext();
  const classes = useUtilityClasses({ classes: rootProps.classes });
  const isLoading = rowNode.childrenExpanded ? !row.childrenFetched : false;
  const Icon = rowNode.childrenExpanded
    ? rootProps.slots.treeDataCollapseIcon
    : rootProps.slots.treeDataExpandIcon;

  const handleClick = (event) => {
    event.stopPropagation();
    if (rowNode.childrenExpanded) {
      setSelectedRowId(null);
    } else {
      setSelectedRowId(id);
    }
    apiRef.current.setRowChildrenExpansion(id, !rowNode.childrenExpanded);
  };

  return (
    <Box className={classes.root} sx={{ ml: rowNode.depth * 2 }}>
      <div className={classes.toggle}>
        {row.descendantCount > 0 &&
          (isLoading ? (
            <LoadingContainer>
              <CircularProgress size='1rem' color='inherit' />
            </LoadingContainer>
          ) : (
            <IconButton
              size='small'
              onClick={(event) => handleClick(event)}
              tabIndex={-1}
              zIndex={9999}
              aria-label={
                rowNode.childrenExpanded
                  ? apiRef.current.getLocaleText("treeDataCollapse")
                  : apiRef.current.getLocaleText("treeDataExpand")
              }
            >
              <Icon fontSize='inherit' />
            </IconButton>
          ))}
      </div>
      <span
        style={{
          fontWeight: selectedRowId === id ? "bold" : "normal"
        }}
        id={id}
      >
        {formattedValue === undefined ? rowNode.groupingKey : formattedValue}
      </span>
    </Box>
  );
};

const LoadingContainer = styled("div")({
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  height: "100%"
});
