import { Component } from "react";
import { Stack } from "@mui/material";
import { MultiAttributeSelector } from "../../components/selectors/MultiAttributeSelector";
import { AppContext } from "../../common/AppContext";
import { Data } from "../../data/Data";
import { ErrorModel } from "../../common/models/ErrorModel";
import { SettingsAttribute } from "../../data/settings/SettingsAttribute";
import { ChipContainer } from "../../components/ChipContainer";
import { Dialog } from "../../components/dialogs/Dialog";
import { PAGE_SIZE_FOR_ADD_EDIT } from "../../constants";
import { SettingsAttributeList } from "../../data/settings/SettingsAttributeList";
import { debounce, get, find } from "lodash";

export class AttributeSelectorDialog extends Component {
  static contextType = AppContext;

  constructor(props) {
    super(props);

    this.state = {
      attributes: null,
      selectedAttributes: [],
      selectedSuggestedAttributes: [],
      allSelectedAttributes: [],
      suggestedAttributes: [],
      allowChildren: true,
      attributesSearch: null
    };

    this.onSearchAttributes = debounce(this.onSearchAttributes.bind(this), 500);
  }

  componentDidMount() {
    const { onLoadAttributes, showSuggestions, jobDefinitionId } = this.props;
    // if (onLoadAttributes) onLoadAttributes(true);
    this.loadAttributes();

    if (showSuggestions && jobDefinitionId) this.loadSuggestedAttributes(jobDefinitionId);
  }

  loadSuggestedAttributes(jobDefinitionId) {
    this.context.setLoading("loading", true);

    Data.get(this.context, "/JobDefinition/" + jobDefinitionId, {
      withCredentials: true
    })
      .then((response) => {
        const suggestedAttributes = [];
        response.data.jobDefinition.jobAttributes.forEach((a) => {
          const attr = new SettingsAttribute(a);
          suggestedAttributes.push(attr);
        });
        this.setState({ suggestedAttributes });
      })
      .catch((e) => {
        this.setState({ error: ErrorModel.parseServerError(e) });
      })
      .finally(() => {
        this.context.setLoading("loading", false);
      });
  }

  loadAttributes(searchText) {
    this.context.setLoading("attributes", true);

    const pagingOptions = {
      pageNumber: 1,
      pageSize: PAGE_SIZE_FOR_ADD_EDIT,
      searchText: searchText
    };

    // get setting attributes data then set to attributes state
    SettingsAttributeList.getWithPaging(this.context, pagingOptions)
      .then((attributes) => {
        this.setState({ attributes, attributesSearch: searchText });
      })
      .catch((error) => {})
      .finally(() => this.context.setLoading("attributes", false));
  }

  onAttributesBlur() {
    const { attributesSearch } = this.state;

    if (attributesSearch) this.loadAttributes();
  }

  onSearchAttributes(searchText) {
    this.loadAttributes(searchText);
  }

  onHandleSearchAttributes(searchText) {
    this.setState({ attributes: [] });
    this.onSearchAttributes(searchText);
  }

  onSave() {
    const { onSave } = this.props;
    const { allSelectedAttributes } = this.state;
    if (onSave) onSave(allSelectedAttributes);
  }

  onAttributesChange(e, selectedAttributes) {
    const { selectedSuggestedAttributes } = this.state;
    this.setAttributes([...selectedSuggestedAttributes, ...selectedAttributes]);
    this.setState({ selectedAttributes });
  }

  onSuggestedAttributesChange(e, selectedSuggestedAttributes) {
    const { selectedAttributes } = this.state;
    this.setAttributes([...selectedAttributes, ...selectedSuggestedAttributes]);
    this.setState({ selectedSuggestedAttributes });
  }

  setAttributes(allSelectedAttributes) {
    allSelectedAttributes.forEach((a) => (a.type = null));
    this.setState({ allSelectedAttributes });
  }

  render() {
    const {
      organization,
      user,
      theme,
      onClose,
      error,
      onShowNewAttributeDialog,
      showSuggestions,
      excludedAttributes,
      allowChildren
    } = this.props;
    const {
      attributes,
      allSelectedAttributes,
      selectedAttributes,
      suggestedAttributes,
      selectedSuggestedAttributes
    } = this.state;

    let suggestedAttributesFiltered = null;
    if (showSuggestions)
      suggestedAttributesFiltered = suggestedAttributes.filter(
        (a) =>
          !excludedAttributes.find((a2) => a2.attributeDefinitionId === a.attributeDefinitionId)
      );

    let attributesFiltered = get(attributes, "attributes", []);

    if (attributes) {
      if (excludedAttributes) {
        attributesFiltered = attributesFiltered.filter(
          (a) =>
            !find(
              excludedAttributes,
              (a2) => get(a2, "attributeDefinitionId") === get(a, "attributeDefinitionId")
            )
        );
      }

      if (suggestedAttributesFiltered) {
        attributesFiltered = attributesFiltered.filter(
          (a) =>
            !find(
              suggestedAttributesFiltered,
              (a2) => get(a2, "attributeDefinitionId") === get(a, "attributeDefinitionId")
            )
        );
      }
    }

    if (!allowChildren) {
      attributesFiltered = attributesFiltered.filter((a) => !get(a, "isChild"));
    }

    return (
      <Dialog
        open={true}
        theme={theme}
        onClose={onClose}
        fullWidth={true}
        maxWidth='sm'
        title='Add competencies'
        actions={[
          {
            label: "Add competencies",
            primary: true,
            disabled: allSelectedAttributes.length === 0,
            onClick: this.onSave.bind(this)
          },
          {
            label: "Cancel",
            onClick: onClose
          }
        ]}
      >
        <Stack direction='column' spacing={4}>
          {showSuggestions &&
          suggestedAttributesFiltered &&
          suggestedAttributesFiltered.length > 0 ? (
            <Stack direction='column' spacing={4}>
              <div>Suggested competencies based on the selected job</div>
              <ChipContainer
                items={suggestedAttributesFiltered}
                selectedItems={selectedSuggestedAttributes}
                onChange={this.onSuggestedAttributesChange.bind(this)}
              />
            </Stack>
          ) : (
            <div>
              Select one or more competencies to be added to the{" "}
              {showSuggestions ? "employee" : "job"}
            </div>
          )}

          <MultiAttributeSelector
            label={null}
            items={attributesFiltered}
            values={selectedAttributes}
            onNewItemClick={onShowNewAttributeDialog}
            getItemId={(attr) => attr.attributeId}
            onChange={this.onAttributesChange.bind(this)}
            onSearch={(searchText) => {
              this.onHandleSearchAttributes(searchText);
            }}
            onBlur={this.onAttributesBlur.bind(this)}
          />
        </Stack>
      </Dialog>
    );
  }
}
