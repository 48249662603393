import { SettingsEmployee } from "./SettingsEmployee";
import { Data } from "../Data";

export class SettingsEmployeeList extends Data {
  static instance = null;
  static defaultPageSize = 50;

  constructor(options) {
    const { searchText, pageNumber, pageSize, orderBy, status, ...otherOptions } = options;

    const apiOptions = {
      api: `/Employee/List`,
      callback: (data) => {
        options.resolve(this, false);
      },
      ...otherOptions
    };

    // add params for api if provided
    const queryParams = [];
    if (searchText) queryParams.push(`searchText=${searchText}`);
    if (pageNumber) queryParams.push(`pageNumber=${pageNumber}`);
    if (pageSize) queryParams.push(`pageSize=${pageSize || SettingsEmployeeList.defaultPageSize}`);
    if (orderBy) queryParams.push(`orderBy=${orderBy}`);
    if (status || status === 0) queryParams.push(`status=${status}`);

    if (queryParams.length > 0) {
      apiOptions.api += `?${queryParams.join("&")}`;
    }

    super(apiOptions);

    this.employees = [];
    this.currentPage = 1;
    this.totalPages = null;
    this.totalCount = null;
    this.hasNext = false;
    this.hasPrevious = false;
  }

  onDataReceived(data) {
    // reformat data from api then assign to state
    const { currentPage, totalPages, totalCount, hasNext, hasPrevious } = data.employees;

    this.currentPage = currentPage;
    this.totalPages = totalPages;
    this.totalCount = totalCount;
    this.hasNext = hasNext;
    this.hasPrevious = hasPrevious;

    data.employees.data.forEach((emp, index) => this.employees.push(new SettingsEmployee(emp)));

    this.numOrganizationAdmins = this.employees.filter((e) => e.isOrganizationAdmin).length;
    this.numActiveOrganizationAdmins = this.employees.filter(
      (e) => e.isOrganizationAdmin && e.isActive()
    ).length;
    this.numBillingUsers = this.employees.filter((e) => e.isBilling).length;
    this.numActiveBillingUsers = this.employees.filter((e) => e.isBilling && e.isActive()).length;
  }

  get(id) {
    return this.employees.find((l) => l.id === id || l.employeeId === id);
  }

  add(employee) {
    this.employees = [...this.employees];
    this.employees.push(employee);
  }

  update(employee) {
    const employees = [...this.employees];
    const index = employees.indexOf(this.get(employee.employeeId));
    if (index !== -1) employees.splice(index, 1, employee);
    this.employees = [...employees];
  }

  canDeactivate(employee) {
    if (
      employee.isOrganizationAdmin &&
      this.numActiveOrganizationAdmins <= 1 &&
      (employee.isActive() || employee.isInvited())
    )
      return false;
    if (
      employee.isBilling &&
      this.numActiveBillingUsers <= 1 &&
      (employee.isActive() || employee.isInvited())
    )
      return false;
    return true;
  }

  canUnassignAdmin(employee) {
    if (!employee.isOrganizationAdmin) return false;
    if (this.numActiveOrganizationAdmins <= 1 && (employee.isActive() || employee.isInvited()))
      return false;
    return true;
  }

  canUnassignBilling(employee) {
    if (!employee.isBilling) return false;
    if (this.numActiveBillingUsers <= 1 && (employee.isActive() || employee.isInvited()))
      return false;
    return true;
  }

  static get(context, updateCache) {
    return new Promise((resolve, reject) => {
      if (SettingsEmployeeList.instance && updateCache !== true)
        resolve(SettingsEmployeeList.instance, true);
      else SettingsEmployeeList.instance = new SettingsEmployeeList({ resolve, reject, context });
    });
  }

  static getWithPaging(context, options) {
    const { searchText, pageNumber, pageSize, orderBy, status } = options;

    return new Promise((resolve, reject) => {
      SettingsEmployeeList.instance = new SettingsEmployeeList({
        searchText,
        pageNumber,
        pageSize,
        orderBy,
        status,
        resolve,
        reject,
        context
      });
    });
  }
}
