import { React, Component } from "react";
import { Stack } from "@mui/material";

export class DetailsGrid extends Component {
  static defaultProps = {
    direction: "column",
    spacing: 2
  };

  render() {
    const { children, direction, spacing, fontSize, sx } = this.props;

    return (
      <Stack direction={direction} spacing={spacing} sx={sx}>
        {children}
      </Stack>
    );
  }
}
