import { Divider, Stack, Skeleton, MenuItem, Menu, ListItemText } from "@mui/material";
import React, { Component } from "react";
import { Paper } from "../../components/Paper";
import { AddEditLocationDialog } from "./AddEditLocationDialog";
import { CompanyNameDialog } from "./CompanyNameDialog";
import { ReactComponent as EditIcon } from "../../resources/images/icons-edit.svg";
import { Button } from "../../components/Button";
import { Data } from "../../data/Data";
import { ErrorModel } from "../../common/models/ErrorModel";
import { AppContext } from "../../common/AppContext";
import { LocationTable } from "../../components/table/LocationTable";
import { TooltipIcon } from "../../components/TooltipIcon";
import { Switch, ConfirmationDialog } from "klayowebshared";
import { Organization } from "../../data/Organization";
import { ReactComponent as AddIcon } from "../../resources/images/icons-add.svg";
import { CompanyIntegrationCard } from "../../components/CompanyIntegrationCard";
import { AddressTable } from "../../components/table/AddressTable";
import { AddEditAddressDialog } from "./AddEditAddressDialog";
import { Snackbar } from "../../components/Snackbar";

const axios = require("axios").default;

export class SettingsCompanyView extends Component {
  static contextType = AppContext;
  static menuType = {
    address: 0,
    location: 1
  };

  constructor(props) {
    super(props);

    this.state = {
      addLocationError: null,
      deleteLocationError: null,
      deleteAddressError: null,
      savingLocation: false,
      actionTarget: null,
      actionLocation: null,
      menuType: null,
      deletingLocation: null,
      deletingAddress: null,
      hasResetDemoData: false,
      successMessage: null
    };
  }

  componentDidUpdate(prevProps, prevState) {
    window.scroll({ top: 0, left: 0, behavior: "smooth" });
    const prevMessage = prevProps?.location?.state?.successMessage;
    const currentMessage = this.props?.location?.state?.successMessage;
    if (prevMessage !== currentMessage && !!currentMessage) {
      this.setState({ successMessage: currentMessage });
    }
  }

  onCloseCompanyNameDialog() {
    this.props.history.push("/settings/company");
  }

  onCloseAddEditLocationDialog() {
    this.props.history.push("/settings/company");
    this.setState({ addLocationError: null });
  }

  onCloseAddEditAddressDialog() {
    this.props.history.push("/settings/company");
    this.setState({ addAddressError: null });
  }

  onCompanyNameChange(name) {
    const { organization, onCompanyNameChange } = this.props;
    organization.name = name;
    this.props.history.push("/settings/company");
    if (onCompanyNameChange) onCompanyNameChange(name);
  }

  onResetDemoData() {
    const { user } = this.state;

    this.context.setLoading("resetDemoData", true);

    axios
      .put(Data.apiBasePath + "/Organization/ResetDemo", null, {
        withCredentials: true
      })
      .then((response) => {
        this.setState({ hasResetDemoData: true });
        window.location.reload();
      })
      .catch((e) => {
        this.showError(new ErrorModel(ErrorModel.Severity.INFO, e, "Reset demo data API"));
      })
      .finally(() => {
        this.context.setLoading("resetDemoData", false);
      });
  }

  onLocationAction(e, location) {
    this.setState({
      actionTarget: e.target,
      actionLocation: location,
      menuType: SettingsCompanyView.menuType.location
    });
  }

  onAddressAction(e, location) {
    this.setState({
      actionTarget: e.target,
      actionLocation: location,
      menuType: SettingsCompanyView.menuType.address
    });
  }

  onSaveLocation(locationId, name) {
    const { orgSettings } = this.props;

    this.context.setLoading("saving", true);
    this.setState({ savingLocation: true });

    if (locationId) {
      axios
        .put(
          Data.apiBasePath + "/Organization/Admin/Location",
          {
            locationId,
            name
          },
          {
            withCredentials: true
          }
        )
        .then((response) => {
          orgSettings.updateLocation(response.data.location);
          this.onCloseAddEditLocationDialog();
        })
        .catch((e) => {
          this.setState({ addLocationError: ErrorModel.parseServerError(e) });
        })
        .finally(() => {
          this.context.setLoading("saving", false);
          this.setState({ savingLocation: false });
        });
    } else {
      axios
        .post(
          Data.apiBasePath + "/Organization/Admin/Location",
          {
            name: name
          },
          {
            withCredentials: true
          }
        )
        .then((response) => {
          orgSettings.addLocation(response.data.location);
          this.onCloseAddEditLocationDialog();
        })
        .catch((e) => {
          this.setState({ addLocationError: ErrorModel.parseServerError(e) });
        })
        .finally(() => {
          this.context.setLoading("saving", false);
          this.setState({ savingLocation: false });
        });
    }
  }

  onEditAddress() {
    this.onCloseMenu();

    this.props.history.push("/settings/company/address/edit/");
  }

  deleteAddress() {
    const { orgSettings } = this.props;
    this.context.setLoading("deleting", true);

    axios
      .delete(Data.apiBasePath + "/Organization/Admin/Address", {
        data: {},
        withCredentials: true
      })
      .then((response) => {
        orgSettings.deleteAddress();
        this.setState({
          deletingAddress: null,
          deleteAddressError: null,
          successMessage: "Delete address successfully"
        });
        this.forceUpdate();
      })
      .catch((e) => {
        this.setState({
          deletingAddress: true,
          deleteAddressError: ErrorModel.parseServerError(e)
        });
      })
      .finally(() => {
        this.context.setLoading("deleting", false);
      });
  }

  onDeleteAddress() {
    this.setState({ deletingAddress: true });
    this.onCloseMenu();
  }

  onDeleteAddressConfirmed() {
    this.deleteAddress();
    this.setState({ deletingAddress: null });
  }

  onCancelDeleteAddress() {
    this.setState({ deletingAddress: null, deleteAddressError: null });
  }

  onEditLocation() {
    const { actionLocation } = this.state;
    this.onCloseMenu();

    this.props.history.push("/settings/company/locations/edit/" + actionLocation.locationId);
  }

  onDeleteLocation() {
    const { actionLocation } = this.state;

    if (actionLocation.isInUsed) this.setState({ deletingLocation: actionLocation });
    else this.deleteLocation(actionLocation);

    this.onCloseMenu();
  }

  onDeleteLocationConfirmed() {
    const { deletingLocation } = this.state;
    this.deleteLocation(deletingLocation);
    // this.setState({ deletingLocation: null });
  }

  onCancelDeleteLocation() {
    this.setState({ deletingLocation: null, deleteLocationError: null });
  }

  deleteLocation(location) {
    const { orgSettings } = this.props;
    this.context.setLoading("deleting", true);

    axios
      .delete(Data.apiBasePath + "/Organization/Admin/Location", {
        data: { locationId: location.locationId },
        withCredentials: true
      })
      .then((response) => {
        orgSettings.deleteLocation(location);
        this.setState({ deletingLocation: null, deleteLocationError: null });
        this.forceUpdate();
      })
      .catch((e) => {
        this.setState({
          deletingLocation: location,
          deleteLocationError: ErrorModel.parseServerError(e)
        });
      })
      .finally(() => {
        this.context.setLoading("deleting", false);
      });
  }

  onCloseMenu() {
    this.setState({ actionTarget: null, actionLocation: null, menuType: null });
  }

  onSnackBarClose() {
    this.setState({ successMessage: null });
  }

  render() {
    const { organization, orgSettings, theme, user, location } = this.props;
    let {
      menuType,
      savingLocation,
      addLocationError,
      actionTarget,
      deletingAddress,
      deletingLocation,
      hasResetDemoData,
      deleteLocationError,
      deleteAddressError,
      successMessage
    } = this.state;
    const path = location.pathname;
    const showCompanyNameDialog = path.startsWith("/settings/company/edit");
    const editingLocation = path.startsWith("/settings/company/locations/edit/");
    const showAddEditLocationDialog =
      path.startsWith("/settings/company/locations/new") || editingLocation;
    const editingAddress = path.startsWith("/settings/company/address/edit/");
    const showAddEditAddressDialog =
      path.startsWith("/settings/company/address/new") || editingAddress;

    let editLocation = null;
    if (orgSettings && editingLocation) {
      const editLocationId = path.replace("/settings/company/locations/edit/", "");
      editLocation = orgSettings.getLocation(editLocationId);
    }

    return (
      <div>
        <Paper
          theme={theme}
          padding={{ xs: "40px 24px", md: "56px 64px" }}
          borderFromBreakpoint='md'
        >
          <Stack direction='row' justifyContent='space-between' alignItems='center' spacing={2}>
            <h1 style={{ margin: 0 }}>{orgSettings ? orgSettings.name : <Skeleton />}</h1>
            <Button
              path={"/settings/company/edit"}
              disabled={false}
              icon={<EditIcon width='20px' height='20px' />}
            />
          </Stack>

          <Divider sx={{ margin: "40px 0" }} />
          <Stack direction='row' justifyContent='space-between' alignItems='center' spacing={2}>
            <div>
              <h2>Address</h2>
              <div className='klayo-instruction'>Manage your company address</div>
            </div>
            {orgSettings && !orgSettings.address && (
              <Button
                path={"/settings/company/address/new"}
                size='md'
                theme={theme}
                startIcon={<AddIcon className='klayo-add-icon' />}
                showLabelFromBreakpoint='md'
                label='Add address'
              />
            )}
          </Stack>
          {orgSettings && orgSettings.address && (
            <AddressTable
              sx={{ marginTop: "32px" }}
              theme={theme}
              showHeader={false}
              pagination={false}
              showFooter={false}
              minHeight={
                orgSettings && orgSettings.address && orgSettings.address.length !== 0
                  ? ""
                  : "300px"
              }
              hideFirstLastBorder={true}
              dense={true}
              onAddressAction={this.onAddressAction.bind(this)}
              paper={false}
              rows={orgSettings && orgSettings.address ? [orgSettings.address] : null}
            />
          )}

          <Divider sx={{ margin: "40px 0" }} />
          <Stack direction='row' justifyContent='space-between' alignItems='center' spacing={2}>
            <div>
              <h2>Locations</h2>
              <div className='klayo-instruction'>Manage your office locations</div>
            </div>
            <Button
              path={"/settings/company/locations/new"}
              size='md'
              theme={theme}
              startIcon={<AddIcon className='klayo-add-icon' />}
              showLabelFromBreakpoint='md'
              label='Add location'
            />
          </Stack>
          {orgSettings && orgSettings.locations && orgSettings.locations.length !== 0 && (
            <LocationTable
              sx={{ marginTop: "32px" }}
              theme={theme}
              showHeader={false}
              pagination={false}
              showFooter={false}
              minHeight={
                orgSettings && orgSettings.locations && orgSettings.locations.length !== 0
                  ? ""
                  : "300px"
              }
              hideFirstLastBorder={true}
              dense={true}
              onLocationAction={this.onLocationAction.bind(this)}
              paper={false}
              rows={orgSettings ? orgSettings.locations : null}
            />
          )}

          {actionTarget && (
            <Menu
              open={true}
              anchorEl={actionTarget}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "right"
              }}
              transformOrigin={{
                vertical: "top",
                horizontal: "right"
              }}
              onClose={this.onCloseMenu.bind(this)}
            >
              {menuType === SettingsCompanyView.menuType.location ? (
                <>
                  <MenuItem onClick={this.onEditLocation.bind(this)}>
                    <ListItemText>Edit location</ListItemText>
                  </MenuItem>
                  <MenuItem onClick={this.onDeleteLocation.bind(this)}>
                    <ListItemText>Delete location</ListItemText>
                  </MenuItem>
                </>
              ) : menuType === SettingsCompanyView.menuType.address ? (
                <>
                  <MenuItem onClick={this.onEditAddress.bind(this)}>
                    <ListItemText>Edit address</ListItemText>
                  </MenuItem>
                  <MenuItem onClick={this.onDeleteAddress.bind(this)}>
                    <ListItemText>Delete address</ListItemText>
                  </MenuItem>
                </>
              ) : (
                <></>
              )}
            </Menu>
          )}

          <Divider sx={{ margin: "40px 0" }} />
          <div style={{ marginBottom: "40px" }}>
            <h2>Proficiency</h2>
            <div className='klayo-instruction'>Manage your competency proficiency settings</div>
          </div>
          <Stack direction='row' justifyContent='space-between' alignItems='center' spacing={2}>
            <Switch
              label={
                "Proficiency " +
                (orgSettings && orgSettings.showProficiency ? "enabled" : "disabled")
              }
              checked={orgSettings && orgSettings.showProficiency}
              disabled={true}
            />
            <TooltipIcon
              text={
                "Contact us to discuss turning proficiency " +
                (orgSettings && orgSettings.showProficiency ? "off" : "on")
              }
            />
          </Stack>

          {orgSettings &&
            orgSettings.organizationIntegrations &&
            orgSettings.organizationIntegrations.length !== 0 && (
              <div>
                <Divider sx={{ margin: "40px 0" }} />
                <div>
                  <h2>Integrations</h2>
                  <div className='klayo-instruction'>Check the status of your integrations</div>
                </div>
                <Stack direction='column' justifyContent='space-between' alignItems='center'>
                  {orgSettings.organizationIntegrations.map((integrationItem) => (
                    <CompanyIntegrationCard
                      key={integrationItem.id}
                      organization={organization ? organization : null}
                      organizationIntegration={integrationItem}
                    />
                  ))}
                </Stack>
              </div>
            )}

          {organization && organization.isDemo && (
            <div>
              <Divider sx={{ margin: "40px 0" }} />
              <div>
                <h2>Reset demo data</h2>
                <div className='klayo-instruction'>
                  Reset all demo company data to its original state
                </div>
              </div>
              <Button
                label='Reset data'
                variant='filled'
                disabled={
                  hasResetDemoData || organization.demoOrgStatus === Organization.status.resetting
                }
                onClick={this.onResetDemoData.bind(this)}
              />
            </div>
          )}
        </Paper>

        {showCompanyNameDialog && (
          <CompanyNameDialog
            organization={organization}
            theme={theme}
            onSave={this.onCompanyNameChange.bind(this)}
            onClose={this.onCloseCompanyNameDialog.bind(this)}
          />
        )}

        {showAddEditLocationDialog && (
          <AddEditLocationDialog
            theme={theme}
            saving={savingLocation}
            location={editLocation}
            onSave={this.onSaveLocation.bind(this)}
            error={addLocationError}
            onClose={this.onCloseAddEditLocationDialog.bind(this)}
          />
        )}

        {showAddEditAddressDialog && (
          <AddEditAddressDialog
            theme={theme}
            saving={savingLocation}
            isEdit={editingAddress}
            orgSettings={orgSettings}
            error={addLocationError}
            onClose={this.onCloseAddEditAddressDialog.bind(this)}
            address={orgSettings?.address ? orgSettings?.address : null}
            {...this.props}
          />
        )}

        {deletingLocation && (
          <ConfirmationDialog
            theme={theme}
            title='Delete?'
            question='This location is currently used by employees. Are you sure you want to delete this location?'
            cancelButton='No'
            acceptButton='Yes'
            acceptDanger={true}
            error={deleteLocationError}
            onCancel={this.onCancelDeleteLocation.bind(this)}
            onAccept={this.onDeleteLocationConfirmed.bind(this)}
          />
        )}

        {deletingAddress && (
          <ConfirmationDialog
            theme={theme}
            title='Delete address'
            question='Are you sure you want to delete the company address? Some features may not work without a physical address.'
            cancelButton='Cancel'
            acceptButton='Delete'
            acceptDanger={true}
            error={deleteAddressError}
            onCancel={this.onCancelDeleteAddress.bind(this)}
            onAccept={this.onDeleteAddressConfirmed.bind(this)}
          />
        )}
        {successMessage && (
          <Snackbar
            open={true}
            duration={3000}
            type='success'
            onClose={this.onSnackBarClose.bind(this)}
            message={successMessage}
          />
        )}
      </div>
    );
  }
}
