import { React, Component } from "react";
import { MultiSelector } from "./MultiSelector";

export class MultiPracticalAttributeSelector extends Component {
  static defaultProps = {
    label: "Competencies"
  };

  constructor(props) {
    super(props);

    this.state = {};
  }

  onChange(e, practicalAttributes) {
    const { onChange } = this.props;

    let practicalAttributesData = practicalAttributes;

    if (practicalAttributesData && practicalAttributesData.length > 0) {
      //get parent attribute
      const parentPracticalAttributes = practicalAttributesData.filter((p) => p.isParent);

      // if has parent attribute
      if (parentPracticalAttributes.length > 0) {
        // get & merge all child attribute from parent attribute
        const childPracticalAttributes = [].concat(
          ...parentPracticalAttributes.map((p) => p.childAttributes)
        );

        childPracticalAttributes.forEach((c) => {
          const childNotExist = practicalAttributesData.every(
            (p) => p.attributeId !== c.attributeId
          );

          // if child not include, add child
          if (childNotExist) {
            practicalAttributesData = [...practicalAttributesData, c];
          }
        });

        practicalAttributesData = practicalAttributesData.filter((p) => !p.isParent);
      }
    }

    onChange(e, practicalAttributesData);
  }

  render() {
    return <MultiSelector {...this.props} hasNewItem={false} onChange={this.onChange.bind(this)} />;
  }
}
