import { Data } from "../Data";

export class SubmitBilling extends Data {
  constructor(options) {
    super({
      api:
        options.method === "post"
          ? "/Organization/Admin/Billing"
          : "/Organization/Admin/Billing/Plan",
      postData: options.details,
      callback: (data) => {
        options.resolve(this, false);
      },
      ...options
    });
  }

  static post(context, details) {
    return new Promise(
      (resolve, reject) => new SubmitBilling({ resolve, reject, context, details, method: "post" })
    );
  }

  static put(context, details) {
    return new Promise(
      (resolve, reject) => new SubmitBilling({ resolve, reject, context, details, method: "put" })
    );
  }
}
