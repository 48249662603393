import { AbstractAttribute } from "../../data/attributes/AbstractAttribute";
import { clone } from "lodash";
import { Utils } from "../Utils";

export class TeamFilterModel {
  constructor() {
    this.people = [];
    this.peopleIds = [];
    this.jobs = [];
    this.locations = [];
    this.compliance = null;
    this.attributes = [];
    this.attributeIds = [];
    this.attributeType = null;
    this.isPendingObjects = false;
    this.expireAfter = null;
    this.expireBefore = null;
  }

  clone() {
    return clone(this);
  }

  countFilters() {
    let count =
      this.people.length + this.jobs.length + this.locations.length + this.attributes.length;
    if (this.compliance) count++;
    if (this.attributeType) count++;
    if (this.expireAfter || this.expireBefore) count++;
    return count;
  }

  isFiltered() {
    if (this.people.length !== 0) return true;
    if (this.jobs.length !== 0) return true;
    if (this.attributes.length !== 0) return true;
    if (this.compliance) return true;
    if (this.attributeType) return true;
    if (this.locations.length !== 0) return true;
    if (this.expireAfter) return true;
    if (this.expireBefore) return true;
    return false;
  }

  toApiUrlParams() {
    const params = new URLSearchParams();

    this.people.forEach((e) => params.append("EmployeeIds", e.employeeId));
    this.peopleIds.forEach((id) => params.append("EmployeeIds", id));
    this.jobs.forEach((j) => params.append("Jobs", j));
    this.locations.forEach((l) => params.append("Locations", l));
    this.attributes.forEach((a) =>
      params.append("AttributeDefinitionIds", a.attributeDefinitionId)
    );
    this.attributeIds.forEach((id) => params.append("AttributeDefinitionIds", id));
    if (this.compliance)
      params.append("IsCompliant", this.compliance === AbstractAttribute.compliance.compliant);
    if (this.attributeType) params.append("AttributeType", this.attributeType.index);
    if (this.expireAfter) params.append("ExpiryDateFrom", Utils.toApiDate(this.expireAfter));
    if (this.expireBefore) params.append("ExpiryDateTo", Utils.toApiDate(this.expireBefore));

    return params.toString();
  }

  toUrlParams() {
    const params = new URLSearchParams();

    this.people.forEach((e) => TeamFilterModel.appendIfUnique(params, "employee", e.employeeId));
    this.peopleIds.forEach((id) => TeamFilterModel.appendIfUnique(params, "employee", id));
    this.jobs.forEach((j) => TeamFilterModel.appendIfUnique(params, "job", j));
    this.locations.forEach((l) => TeamFilterModel.appendIfUnique(params, "location", l));
    this.attributes.forEach((a) =>
      TeamFilterModel.appendIfUnique(params, "attribute", a.attributeDefinitionId)
    );
    this.attributeIds.forEach((id) => TeamFilterModel.appendIfUnique(params, "attribute", id));
    if (this.compliance)
      TeamFilterModel.appendIfUnique(
        params,
        "compliant",
        this.compliance === AbstractAttribute.compliance.compliant
      );
    if (this.attributeType)
      TeamFilterModel.appendIfUnique(params, "type", this.attributeType.index);
    if (this.expireAfter)
      TeamFilterModel.appendIfUnique(params, "expireAfter", Utils.toApiDate(this.expireAfter));
    if (this.expireBefore)
      TeamFilterModel.appendIfUnique(params, "expireBefore", Utils.toApiDate(this.expireBefore));

    return params.toString();
  }

  static appendIfUnique(params, key, value) {
    const existing = params.get(key);
    if (existing === value) return;
    params.append(key, value);
  }

  static pushIfUnique(list, value) {
    if (!list.includes(value)) list.push(value);
  }

  static fromUrlParams(urlParams) {
    const model = new TeamFilterModel();
    const params = new URLSearchParams(urlParams);

    params.forEach((value, key) => {
      if (key === "employee") {
        TeamFilterModel.pushIfUnique(model.peopleIds, value);
        model.isPendingObjects = true;
      } else if (key === "job") {
        TeamFilterModel.pushIfUnique(model.jobs, value);
      } else if (key === "location") {
        TeamFilterModel.pushIfUnique(model.locations, value);
      } else if (key === "attribute") {
        TeamFilterModel.pushIfUnique(model.attributeIds, value);
        model.isPendingObjects = true;
      } else if (key === "compliant")
        model.compliance =
          value === "true"
            ? AbstractAttribute.compliance.compliant
            : AbstractAttribute.compliance.nonCompliant;
      else if (key === "type") model.type = value;
      else if (key === "expireAfter") model.expireAfter = Utils.parseApiDate(value);
      else if (key === "expireBefore") model.expireBefore = Utils.parseApiDate(value);
    });
    return model;
  }
}
