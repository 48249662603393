import { SettingsCourse } from "./SettingCourse";
import { Data } from "../Data";

export class SettingsCourseList extends Data {
  static instance = null;
  static defaultPageSize = 50;

  constructor(options) {
    const { integrationId, facultyId, searchText, pageNumber, pageSize, orderBy, ...otherOptions } =
      options;
    const apiOptions = {
      api: `/Integration/Courses`,
      callback: (data) => {
        options.resolve(this, false);
      },
      ...otherOptions
    };

    // add params for api if provided
    const queryParams = [];
    if (integrationId) queryParams.push(`integrationId=${integrationId}`);
    if (facultyId) queryParams.push(`facultyId=${facultyId}`);
    if (searchText) queryParams.push(`searchText=${searchText}`);
    if (pageNumber) queryParams.push(`pageNumber=${pageNumber}`);
    if (pageSize) queryParams.push(`pageSize=${pageSize || SettingsCourseList.defaultPageSize}`);
    if (orderBy) queryParams.push(`orderBy=${orderBy}`);

    if (queryParams.length > 0) {
      apiOptions.api += `?${queryParams.join("&")}`;
    }

    super(apiOptions);

    this.courses = [];
    this.currentPage = 1;
    this.totalPages = null;
    this.totalCount = null;
    this.hasNext = false;
    this.hasPrevious = false;
  }

  onDataReceived(data) {
    // reformat data from api then assign to state
    const { currentPage, totalPages, totalCount, hasNext, hasPrevious } = data.courses;
    this.currentPage = currentPage;
    this.totalPages = totalPages;
    this.totalCount = totalCount;
    this.hasNext = hasNext;
    this.hasPrevious = hasPrevious;

    data.courses.data.forEach((c, index) => {
      const course = new SettingsCourse(c);
      this.courses.push(course);
    });
  }

  static get(context, updateCache) {
    return new Promise((resolve, reject) => {
      if (SettingsCourseList.instance && updateCache !== true)
        resolve(SettingsCourseList.instance, true);
      else SettingsCourseList.instance = new SettingsCourseList({ resolve, reject, context });
    });
  }

  static getWithPaging(context, options) {
    const { searchText, pageNumber, pageSize, orderBy, integrationId, facultyId } = options;

    return new Promise((resolve, reject) => {
      SettingsCourseList.instance = new SettingsCourseList({
        searchText,
        pageNumber,
        pageSize,
        orderBy,
        integrationId,
        facultyId,
        resolve,
        reject,
        context
      });
    });
  }
}
