import { Alert as MuiAlert, Zoom } from "@mui/material";
import React, { Component } from "react";
import { ReactComponent as InfoIcon } from "../resources/images/icons-info-circle.svg";

export class Alert extends Component {
  static defaultProps = {
    scrollTo: false
  };

  constructor(props) {
    super(props);

    this.ref = React.createRef();
  }

  componentDidMount() {
    const { scrollTo } = this.props;
    if (scrollTo) this.ref.current.scrollIntoView({ behavior: "smooth" });
  }

  componentDidUpdate(prevProps, prevState) {
    const { scrollTo } = this.props;
    if (scrollTo) this.ref.current.scrollIntoView({ behavior: "smooth" });
  }

  render() {
    const { severity } = this.props;

    return (
      <div ref={this.ref} className='klayo-alert_container'>
        <Zoom in={true}>
          <MuiAlert
            {...this.props}
            icon={severity === "info" ? <InfoIcon /> : null}
            className='klayo-alert'
          />
        </Zoom>
      </div>
    );
  }
}
