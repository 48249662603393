import { TextField as MuiTextField, InputAdornment, IconButton, Skeleton } from "@mui/material";
import React, { Component } from "react";
import { Theme } from "klayowebshared";
import { ReactComponent as RemoveIcon } from "../resources/images/icons-remove.svg";

export class TextField extends Component {
  constructor(props) {
    super(props);

    this.ref = React.createRef();

    this.state = {
      valid: null,
      focus: false,
      autofilled: false
    };
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.props.forceBlur !== prevProps.forceBlur && this.props.forceBlur) this.forceBlur();

    /*const inputRef = this.getInputRef();
        console.log('getInputRef');
        console.log(inputRef);
        if (inputRef) {
            inputRef.addEventListener('animationstart', e => {
                if (e.animationName === 'onAutoFillStart') this.onAutoFillStart();
                else if (e.animationName === 'onAutoFillCancel') this.onAutoFillCancel();
            });
        }*/
  }

  onAutoFillStart() {
    this.setState({ autofilled: true });
  }

  onAutoFillCancel() {
    this.setState({ autofilled: false });
  }

  forceBlur() {
    if (this.inputRef) this.inputRef.blur();
  }

  getInputRef() {
    const { inputProps, inputRef } = this.props;
    return inputProps && inputProps.ref && inputProps.ref.current
      ? inputProps.ref.current.node
      : inputRef
        ? inputRef
        : this.ref.current;
  }

  clearValue() {
    const { defaultValue, onClear } = this.props;

    const inputRef = this.getInputRef();
    if (!inputRef) return;

    if (!this.props.select) {
      inputRef.value = "";

      let e = new Event("input", {
        bubbles: true,
        cancelable: true,
        target: inputRef
      });

      inputRef.dispatchEvent(e);
    } else this.props.onChange({ target: { value: defaultValue } });

    inputRef.focus();

    if (onClear) onClear();
  }

  onInput(e) {
    const { onChange, validationMethod } = this.props;
    const { valid } = this.state;

    const inputRef = this.getInputRef();

    if (inputRef && inputRef.setAttribute) inputRef.setAttribute("size", e.target.value.length);

    if (onChange) onChange(e);

    if (validationMethod) {
      const newValid = validationMethod(e, e.target.value);
      if (newValid !== valid) this.setState({ valid: newValid });
    }
  }

  onChange(e) {
    const { onChange } = this.props;
    if (onChange) onChange(e);
  }

  onFocus(e) {
    this.setState({ focus: true });
    const { onFocus } = this.props;
    if (onFocus) onFocus(e);
  }

  onBlur(e) {
    const { onBlur, validationMethod } = this.props;
    const { valid } = this.state;

    if (validationMethod) {
      const newValid = validationMethod(e, e.target.value);
      if (newValid !== valid) this.setState({ valid: newValid, focus: false });
    } else this.setState({ focus: false });

    if (onBlur) onBlur(e);
  }

  onReValidate() {
    const { validationMethod } = this.props;
    const { valid } = this.state;

    if (validationMethod) {
      const newValid = validationMethod();
      if (newValid !== valid) this.setState({ valid: newValid, focus: false });
    }
  }

  setInputRef(inputRef) {
    if (inputRef) this.inputRef = inputRef;
  }

  render() {
    const {
      id,
      ref,
      revalidate,
      inputRef,
      children,
      label,
      value,
      readOnly,
      defaultValue,
      onClick,
      disabled,
      autoComplete,
      autoFocus,
      rows,
      loading,
      className,
      sx,
      placeholder,
      focusPlaceholder,
      dense,
      leadingIcon,
      multiline,
      helperText,
      error,
      endAdornment,
      InputProps,
      InputLabelProps,
      fullWidth,
      inputProps,
      select,
      SelectProps,
      minRows,
      maxRows,
      validationText,
      inputSx
    } = this.props;
    const clearable =
      this.props.clearable !== false &&
      (select ? value !== defaultValue : value && value.length !== 0);
    const { valid, focus, autofilled } = this.state;

    if (revalidate) this.onReValidate();

    return loading ? (
      <div className='klayo-textfield--loading'>
        <Skeleton width={70} height={20} />
        <Skeleton width={sx.width || null} height={42} />
      </div>
    ) : (
      <MuiTextField
        id={id}
        ref={ref}
        inputRef={this.setInputRef.bind(this)}
        className={
          "klayo-textfield " +
          (dense ? "klayo-textfield-dense " : "") +
          (label ? "klayo-textfield-withlabel " : "") +
          (className ? className : "") +
          (autofilled ? "klayo-textfield--autofilled" : "")
        }
        label={label}
        variant='outlined'
        select={select}
        SelectProps={SelectProps}
        placeholder={focusPlaceholder && focus ? focusPlaceholder : placeholder}
        multiline={multiline}
        rows={rows}
        minRows={minRows}
        maxRows={maxRows}
        onInput={this.onInput.bind(this)}
        onChange={this.onChange.bind(this)}
        onClick={onClick}
        onFocus={this.onFocus.bind(this)}
        onBlur={this.onBlur.bind(this)}
        disabled={disabled}
        helperText={valid === false ? validationText : helperText}
        fullWidth={fullWidth}
        error={error || valid === false}
        InputLabelProps={{ shrink: true, variant: "filled", ...InputLabelProps }}
        autoComplete={autoComplete === false ? "off" : "on"}
        autoFocus={autoFocus}
        value={value ? value : ""}
        inputProps={{ ref: inputRef ? inputRef : this.ref, ...inputProps }}
        InputProps={{
          notched: false,
          readOnly: readOnly,
          startAdornment: leadingIcon ? (
            <InputAdornment position='start'>{leadingIcon}</InputAdornment>
          ) : null,
          endAdornment: endAdornment ? (
            endAdornment
          ) : (
            <InputAdornment position='end'>
              <IconButton
                className='klayo-textfield_clearbutton'
                sx={{ display: clearable ? "flex" : "none" }}
                onClick={this.clearValue.bind(this)}
              >
                <RemoveIcon />
              </IconButton>
            </InputAdornment>
          ),
          ...InputProps
        }}
        sx={{
          ...sx,
          "& .MuiOutlinedInput-root": {
            "& fieldset": {
              borderColor: Theme.getStyleVar("--neon-blue-nb-200")
            },
            "&:hover fieldset": {
              borderColor: Theme.getStyleVar("--neon-blue-nb-300")
            },
            "&.Mui-focused fieldset": {
              borderWidth: "2px",
              borderColor: Theme.getStyleVar("--neon-blue-nb-500")
            }
          }
        }}
      >
        {children}
      </MuiTextField>
    );
  }
}
