import { GapAttribute } from "./GapAttribute";
import { AbstractAttributeList } from "./AbstractAttributeList";
import { EmployeeAttribute } from "./EmployeeAttribute";

export class GapAttributeList extends AbstractAttributeList {
  constructor(options) {
    super(options);

    options.data.forEach((attr, index) => this.attributes.push(new GapAttribute(index, attr)));
  }

  initAttributesLists(jobAttributes, employeeAttributes) {
    jobAttributes.attributes.forEach((jobAttr) => {
      const gap = this.attributes.find(
        (g) => g.attributeDefinitionId == jobAttr.attributeDefinitionId
      );
      if (gap) {
        gap.isParent = jobAttr.isParent;
        gap.hasExpiryDate = jobAttr.hasExpiryDate;
        gap.hasProficiency = jobAttr.hasProficiency;

        if (jobAttr.isParent) {
          gap.childAttributes = [...jobAttr.childAttributes];
          gap.childAttributes.forEach((childAttr, index) => {
            childAttr.rowId = gap.attributeDefinitionId + childAttr.attributeDefinitionId;
            childAttr.isChild = true;
            childAttr.childIndex = index;
            childAttr.isLastChild = index === gap.childAttributes.length - 1;
            childAttr.attributeHierarchy = [
              gap.attributeDefinitionId,
              childAttr.attributeDefinitionId
            ];
            childAttr.parentAttributeId = gap.attributeId;
          });
        }
      }
    });

    employeeAttributes.attributes.forEach((emplAttr) => {
      const gap = this.attributes.find(
        (g) => g.attributeDefinitionId == emplAttr.attributeDefinitionId
      );

      if (gap) {
        gap.proficiency = emplAttr.proficiency;
        gap.expiryDate = emplAttr.expiryDate;
        gap.currentExpiryDate = emplAttr.currentExpiryDate;
        gap.requestedExpiryDate = emplAttr.requestedExpiryDate;
        gap.currentProficiency = emplAttr.currentProficiency;
        gap.requestedProficiency = emplAttr.requestedProficiency;
      }
    });

    super.initAttributesLists();
  }

  static getFilterItems(includeProficiency) {
    const filters = {
      all: {
        ...AbstractAttributeList.allFilter
      }
    };
    if (includeProficiency) filters["lowProficiency"] = GapAttribute.reason.lowProficiency;
    filters["missing"] = GapAttribute.reason.missing;
    filters["needsFeedback"] = EmployeeAttribute.status.feedback;
    filters["underReview"] = EmployeeAttribute.status.review;
    return filters;
  }
}
