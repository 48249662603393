import { React, Component } from "react";
import { Box, Grid, Stack } from "@mui/material";
import { SectionLoader } from "../../components/SectionLoader";
import { AttributeProgressOverview } from "../../components/AttributeProgressOverview";
import { AttributeTable } from "../../components/table/AttributeTable";
import { Paper } from "../../components/Paper";
import { Utils } from "../../common/Utils";
import { ListTreeToggleButton } from "../../components/ListTreeToggleButton";
import { JobDescriptionDialog } from "./JobDescriptionDialog";
import { JobDescriptionContainer } from "klayowebshared";
import { ReactComponent as UserIcon } from "../../resources/images/icons-user.svg";
import { Placeholder } from "../../components/Placeholder";

export class ProfileJobSection extends Component {
  static descriptionLimit = 500;

  constructor(props) {
    super(props);

    this.state = {
      showFullDescription: false
    };
  }

  onOpenJobDescriptionDialog(e) {
    e.preventDefault();
    this.setState({ showFullDescription: true });
  }

  onCloseJobDescriptionDialog(e) {
    e.preventDefault();
    this.setState({ showFullDescription: false });
  }

  onHighlightRowComplete() {
    Utils.removeLocationHash();
  }

  render() {
    const {
      organization,
      user,
      employee,
      theme,
      profile,
      readOnly,
      location,
      treeMode,
      onChangeViewMode
    } = this.props;
    const { showFullDescription } = this.state;

    const highlightAttr = location.hash ? location.hash.substring(1) : null;

    let mandatoryMet,
      mandatoryTotal,
      requiredMet,
      requiredTotal,
      desirableMet,
      desirableTotal = 0;
    if (profile && profile.attributeSummary) {
      ({ mandatoryMet, mandatoryTotal, requiredMet, requiredTotal, desirableMet, desirableTotal } =
        profile.attributeSummary);
    }

    return (
      <Box>
        {employee && employee.hasJob && (
          <>
            <Grid container spacing={4}>
              <Grid item xs={12} md={6} lg={6}>
                <Paper
                  className='klaro_section klaro_profile-jobdesc'
                  title={readOnly ? "Job description" : "My job description"}
                  sx={{ height: "100%", boxSizing: "border-box" }}
                >
                  {!employee || !profile ? (
                    <SectionLoader />
                  ) : (
                    <JobDescriptionContainer
                      text={profile.jobDescription ? profile.jobDescription : ""}
                      maxHeight={180}
                      onShowMore={this.onOpenJobDescriptionDialog.bind(this)}
                    />
                  )}
                </Paper>
              </Grid>
              <Grid item xs={12} md={6} lg={6}>
                <Paper
                  className='klaro_section klaro_profile-attroverview'
                  title={readOnly ? "Job competencies overview" : "My job competencies overview"}
                  sx={{ height: "100%", boxSizing: "border-box" }}
                >
                  {!employee || !profile ? (
                    <SectionLoader />
                  ) : (
                    <AttributeProgressOverview
                      mandatoryMet={mandatoryMet}
                      mandatoryTotal={mandatoryTotal}
                      requiredMet={requiredMet}
                      requiredTotal={requiredTotal}
                      desirableMet={desirableMet}
                      desirableTotal={desirableTotal}
                      spacing='20px'
                    />
                  )}
                </Paper>
              </Grid>
              <Grid item xs={12} md={12} lg={12}>
                <Stack
                  direction='row'
                  spacing={2}
                  justifyContent='space-between'
                  alignItems='center'
                  sx={{ marginBottom: "24px" }}
                >
                  <div></div>
                  <ListTreeToggleButton value={treeMode} onChange={onChangeViewMode} />
                </Stack>
                <AttributeTable
                  title={readOnly ? "Job competencies" : "My job competencies"}
                  theme={theme}
                  minHeight='300px'
                  dense={true}
                  rows={
                    profile && profile.jobAttributes
                      ? treeMode
                        ? profile.jobAttributes.treeAttributes
                        : profile.jobAttributes.flatAttributes
                      : null
                  }
                  tree={treeMode}
                  showHeaderFromBreakpoint='md'
                  selectable={false}
                  highlightRow={highlightAttr}
                  onHighlightRowComplete={this.onHighlightRowComplete.bind(this)}
                  columns={[
                    { type: AttributeTable.columns.summary },
                    { type: AttributeTable.columns.attribute },
                    { type: AttributeTable.columns.category },
                    { type: AttributeTable.columns.attributeType },
                    { type: AttributeTable.columns.requiredProficiency },
                    {
                      type: AttributeTable.columns.currentProficiency,
                      label: readOnly ? "Proficiency" : "My Proficiency"
                    },
                    { type: AttributeTable.columns.gapIcon }
                  ]}
                  readOnly={readOnly}
                  showProficiency={organization && organization.showProficiency}
                  noResultsMessage={{
                    primary: "No search results",
                    secondary: "We couldn’t find any results for your search"
                  }}
                  sx={{ padding: "23px" }}
                  tableSx={{ margin: "0 -7px" }}
                />
              </Grid>
            </Grid>
          </>
        )}

        {employee && !employee.hasJob && (
          <Placeholder
            text={{
              primary: "Nothing to show yet",
              secondary: (
                <div>
                  This section requires having a job assigned to{" "}
                  {user && user.isMe(employee) ? "you" : "the employee"}.
                  {user && user.isOrganizationAdmin && (
                    <div>Go to Settings &gt; Employees and add a job to your employee details.</div>
                  )}
                </div>
              )
            }}
            icon={<UserIcon />}
            sx={{ height: "100vh", maxHeight: "40vh" }}
          />
        )}

        {showFullDescription && (
          <JobDescriptionDialog
            theme={theme}
            description={profile.jobDescription}
            onClose={this.onCloseJobDescriptionDialog.bind(this)}
          />
        )}
      </Box>
    );
  }
}
