export class UserAttributeSummary {
  constructor(data) {
    this.mandatoryTotal = data.totalNumberOfMandatoryAttributes;
    this.mandatoryMet = data.numberOfMetMandatoryAttributes;
    this.requiredTotal = data.totalNumberOfRequiredAttributes;
    this.requiredMet = data.numberOfMetRequiredAttributes;
    this.desirableTotal = data.totalNumberOfDesirableAttributes;
    this.desirableMet = data.numberOfMetDesirableAttributes;
  }
}
