import { Box, gridClasses } from "@mui/material";
import { DataGridPro } from "@mui/x-data-grid-pro";
import { Utils } from "../../common/Utils";
import { ReactComponent as SearchIcon } from "../../resources/images/icons-search.svg";
import { GroupingCellWithLazyLoading } from "../GroupingCellWithLazyLoading";
import { TablePlaceholder } from "../TablePlaceholder";

import { forwardRef } from "react";

export const RegulationsTable = forwardRef((props, ref) => {
  const {
    initialRows,
    initialColumns,
    theme,
    handleOnRowItemClick,
    showHeader,
    hideFirstLastBorder,
    sx,
    tableStyle
  } = props;

  const tableClass =
    "klayo-table" +
    (showHeader !== false ? " klayo-table--withheader" : "") +
    (hideFirstLastBorder ? " klayo-table--hidefirstlastborder" : "");

  return (
    <Box className={tableClass} sx={sx}>
      <DataGridPro
        sx={{
          "& .MuiDataGrid-virtualScroller": {
            overflow: "hidden"
          },
          "& .MuiDataGrid-row": { cursor: "pointer" }
        }}
        className={
          "klayo-table_grid" +
          (theme ? " klayo-table--breakpoint-" + theme.getCurrentBreakpoint()[0] : "") +
          tableStyle
        }
        treeData
        apiRef={ref}
        rows={initialRows}
        columns={initialColumns([
          {
            type: "name",
            field: "name"
          },
          {
            type: "task",
            field: "task"
          },
          {
            field: "actions",
            type: "actions"
          }
        ])}
        getTreeDataPath={Utils.getTreeDataPath}
        groupingColDef={{
          headerName: "",
          cellClassName: "klayo-table_expandbuttoncell",
          renderCell: (params) => <GroupingCellWithLazyLoading {...params} />,
          flex: 1
        }}
        disableChildrenFiltering
        getRowId={Utils.getRowId}
        onRowClick={handleOnRowItemClick}
        columnHeaderHeight={0}
        hideFooterRowCount={true}
        hideFooter={true}
        slots={{
          noRowsOverlay: () => <TablePlaceholder text={""} />,
          noResultsOverlay: () => <TablePlaceholder text={""} icon={<SearchIcon />} />
        }}
      />
    </Box>
  );
});

RegulationsTable.displayName = "RegulationsTable";
