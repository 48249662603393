import React from "react";
import App from "../../App";
import { AbstractView } from "../AbstractPage";
import { AppContext } from "../../common/AppContext";
import { View } from "../../components/View";
import { Grid, Stack } from "@mui/material";
import { Paper } from "../../components/Paper";
import { Button } from "../../components/Button";
import { ReactComponent as SwitchDemoIcon } from "../../resources/images/icons-cursor-click.svg";
import {
  ReactComponent as SwitchToCompanyIcon,
  ReactComponent as CompanyIcon
} from "../../resources/images/icons-office.svg";
import { ReactComponent as VideoIcon } from "../../resources/images/icons-youtube.svg";
import { ReactComponent as SettingsImage } from "../../resources/images/illustration-settings.svg";
import { ReactComponent as AttributesIcon } from "../../resources/images/icons-attribute.svg";
import { ReactComponent as JobsIcon } from "../../resources/images/icons-briefcase.svg";
import { ReactComponent as EmployeesIcon } from "../../resources/images/icons-users-1.svg";
import { ReactComponent as ChecklistIcon } from "../../resources/images/icons-checklist.svg";
import { TourVideoDialog } from "./TourVideoDialog";
import { Heading } from "../../components/Heading";
import { SettingsCard } from "../../components/SettingsCard";

export class SettingsLandingView extends AbstractView {
  static contextType = AppContext;

  static defaultProps = {
    showNameInGreeting: false,
    guideUrl: "https://klayo.com/wp-content/uploads/Quick-start-user-guide.pdf"
  };

  constructor(props) {
    super(props, App.pages.settings);

    this.state = {
      showTourVideo: false
    };
  }

  onWatchVideoClick() {
    this.setState({ showTourVideo: true });
  }

  onCloseVideo() {
    this.setState({ showTourVideo: false });
  }

  render() {
    if (!this.verifyAccess()) return this.renderAccessDenied();

    const {
      user,
      theme,
      organization,
      onHide,
      showNameInGreeting,
      onDemoSwitch,
      allowDemoSwitch,
      guideUrl
    } = this.props;
    const { showTourVideo } = this.state;

    return (
      <View
        className='klayo-settingslanding'
        theme={theme}
        fullWidth={true}
        scrollToTopOnMount={true}
      >
        <Grid container direction='row' spacing={{ xs: 2, md: 6 }} alignItems='stretch'>
          <Grid item xs={12}>
            <Paper padding={{ xs: "32px 24px", md: "56px 40px" }}>
              <Grid container spacing={2}>
                <Grid item xs={12} md={6}>
                  <Heading
                    size={1}
                    sx={{ marginBottom: "24px", fontSize: { xs: "24px", md: "36px" } }}
                    text={
                      (showNameInGreeting ? "Hi, " + user.firstName + "," : "Hi there,") +
                      " let's get you started"
                    }
                  />
                  <p>
                    Complete the tasks below to fully set up your company and employees. All the
                    data from your 30 day trial will be saved should you decide to buy Klayo.
                  </p>
                  <h3>Need some help?</h3>
                  <p>
                    Watch an overview of the main features or switch to our demo company and test
                    all Klayo's features.
                  </p>
                  <Stack
                    direction={{ xs: "column", sm: "row" }}
                    spacing={2}
                    sx={{ marginTop: "40px" }}
                  >
                    <div>
                      <Button
                        startIcon={<VideoIcon />}
                        label='Product tour video'
                        onClick={this.onWatchVideoClick.bind(this)}
                        size='md'
                      />
                    </div>

                    <div>
                      <Button
                        startIcon={<ChecklistIcon />}
                        target='_blank'
                        label='Quick start guide'
                        href={guideUrl}
                        size='md'
                      />
                    </div>

                    {allowDemoSwitch && (
                      <div>
                        <Button
                          startIcon={
                            organization && organization.isDemo ? (
                              <SwitchToCompanyIcon />
                            ) : (
                              <SwitchDemoIcon />
                            )
                          }
                          label={
                            organization && organization.isDemo
                              ? "Switch to your company"
                              : "Test with demo company"
                          }
                          size='md'
                          onClick={(e) => onDemoSwitch(e, !organization.isDemo)}
                        />
                      </div>
                    )}
                  </Stack>
                </Grid>
                <Grid
                  item
                  xs={0}
                  md={6}
                  sx={{ textAlign: "center", display: { xs: "none", md: "block" } }}
                >
                  <SettingsImage />
                </Grid>
              </Grid>
            </Paper>
          </Grid>

          <Grid item xs={12} md={6}>
            <SettingsCard
              heading='Company'
              text='Add locations and manage your attribute proficiency settings'
              icon={<CompanyIcon />}
              actionPath='/settings/company'
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <SettingsCard
              heading='Attributes'
              text='Create attributes for employees and jobs'
              icon={<AttributesIcon />}
              actionPath='/settings/competencies'
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <SettingsCard
              heading='Jobs'
              text='Create jobs, add responsibilities and attributes'
              icon={<JobsIcon />}
              actionPath='/settings/jobs'
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <SettingsCard
              heading='Employees'
              text='Create employees, assign them jobs and attributes'
              icon={<EmployeesIcon />}
              actionPath='/settings/employees'
            />
          </Grid>

          <Grid item xs={12} sx={{ textAlign: "center", padding: "42px!important" }}>
            <Button
              className='klayo-settingslanding_hidebutton'
              path='/settings/jobs'
              size='sm'
              onClick={onHide}
              variant='simple'
              label='Hide this screen'
            />
          </Grid>
        </Grid>

        {showTourVideo && <TourVideoDialog theme={theme} onClose={this.onCloseVideo.bind(this)} />}
      </View>
    );
  }
}
