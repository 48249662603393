import React, { Component } from "react";
import styled from "@emotion/styled";
import { ReactComponent as WarningIcon } from "../resources/images/icon-warning-no-color.svg";
import { ReactComponent as CheckCircleIcon } from "../resources/images/icons-check-circle.svg";
import { ReactComponent as PauseIcon } from "../resources/images/icons-pause-circle.svg";
import { INTEGRATION_STATUS } from "../constants";
import { KLAYO_COLORS } from "../themes";
import { Tooltip } from "@mui/material";
import { Utils } from "../common/Utils";

export class CompanyIntegrationStatus extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    const { status, lastSyncDate, isLiveSync } = this.props;
    const workingStatusToolTipMessage = isLiveSync
      ? "Live"
      : lastSyncDate
        ? `Last Sync (UTC): ${Utils.formatDate(lastSyncDate, "long")} `
        : "Not synced yet";

    return (
      <StyledCompanyIntegrationStatus className='klayo_company-integration-status'>
        {status === INTEGRATION_STATUS.ACTIVE ? (
          <>
            <Tooltip
              classes={{ tooltip: "klayo__integration-status-tooltip" }}
              title={<div>{workingStatusToolTipMessage}</div>}
              placement='left'
              arrow
            >
              <CheckCircleIcon />
            </Tooltip>
            <p className='status'>Working</p>
          </>
        ) : status === INTEGRATION_STATUS.PAUSE ? (
          <>
            <Tooltip
              classes={{ tooltip: "klayo__integration-status-tooltip" }}
              title={<div>Integration has been paused by the Klayo team</div>}
              placement='left'
              arrow
            >
              <PauseIcon className='pause-icon' />
            </Tooltip>
            <p className='status'>Paused</p>
          </>
        ) : status === INTEGRATION_STATUS.ISSUE ? (
          <>
            <Tooltip
              classes={{ tooltip: "klayo__integration-status-tooltip" }}
              title={<div>An error was encountered on the last synchronization</div>}
              placement='left'
              arrow
            >
              <WarningIcon className='warning-icon' />
            </Tooltip>
            <p className='status'>Not Working</p>
          </>
        ) : (
          <></>
        )}
      </StyledCompanyIntegrationStatus>
    );
  }
}

const StyledCompanyIntegrationStatus = styled.div`
  &.klayo_company-integration-status {
    display: flex;
    align-items: center;
    gap: 12px;

    .status {
      margin: 0px;
      color: ${KLAYO_COLORS.GreyGR900};
      font-family: Inter;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 24px;
      letter-spacing: -0.1px;
    }

    .warning-icon {
      color: ${KLAYO_COLORS.TouchRedTR600};
    }

    .pause-icon {
      color: ${KLAYO_COLORS.GreyGR400};
    }

    @media (max-width: 600px) {
      .status {
        display: none;
      }
    }
  }
`;
