import { AbstractAttribute } from "./AbstractAttribute";

export class JobAttribute extends AbstractAttribute {
  constructor(index, data) {
    super(index, data);

    this.attributeName = data.attributeDefinitionName;
    this.category = data.attributeDefinitionCategory;
    this.required = data.required;
    this.mandatory = data.mandatory;
    this.currentProficiency = data.proficiencyValue;
    this.requiredProficiency = data.proficiencyMinimum;
    this.gapReason = Object.values(AbstractAttribute.gapReason)[
      data.employeeAttributeToJobAttributeMetType
    ];

    if (this.isParent) this.type = null;
    else {
      if (this.mandatory) this.type = AbstractAttribute.type.mandatory.label;
      else if (this.required) this.type = "Required";
      else this.type = "Desirable";
    }
  }
}
