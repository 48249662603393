import React, { Component } from "react";
import styled from "@emotion/styled";
import { KLAYO_COLORS } from "../../themes";
import { ListItem, Tooltip } from "@mui/material";
import parse from "autosuggest-highlight/parse";
import match from "autosuggest-highlight/match";
import IntegrationAbbreviation from "../../views/settings/IntegrationAbbreviation";
import { ReactComponent as MarkerIcon } from "../../resources/images/icons-marking.svg";

export class SingleSelectorContent extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isOptionTooltipVisible: false
    };

    this.hoveredOptionRef = React.createRef();
  }

  handleOptionMouseEnter = (optionElement) => {
    const { selectorContainerSize } = this.props;

    // Get the size of the option content
    const optionSize = optionElement?.current?.getBoundingClientRect();

    // Check if the option is too large
    const isOptionTooLarge = optionSize.width > selectorContainerSize.width;

    if (isOptionTooLarge) {
      // Set the state to show the tooltip
      this.setState({ isOptionTooltipVisible: true });
    }
  };

  handleOptionMouseLeave = () => {
    // Hide the tooltip when the mouse leaves the option
    this.setState({ isOptionTooltipVisible: false });
  };

  render() {
    const { organization, option, inputValue, props, getItemId } = this.props;
    const { getItemLabel } = this.props;
    const { isOptionTooltipVisible } = this.state;

    const name = getItemLabel(option);
    const matches = match(name, inputValue, { insideWords: true });
    const parts = parse(name, matches);

    const renderTitle = (tooltip = false) =>
      parts.length === 1 ? (
        <>
          {name}{" "}
          {organization && organization.hasMultipleIntegrations && (
            <IntegrationAbbreviation
              data={option ? option : null}
              type={IntegrationAbbreviation.dataType.integrationUser.value}
            />
          )}
        </>
      ) : (
        <span>
          {parts.map((part, index) => (
            <>
              <span
                key={index}
                className={
                  (tooltip ? "" : "klayo-selector_itempart") +
                  (part.highlight === true ? " klayo-selector_highlight" : "")
                }
              >
                {part.text}
                {/* render with abbreviation if text is last character */}
              </span>
              <span>
                {index === parts.length - 1 &&
                  organization &&
                  organization.hasMultipleIntegrations && (
                    <IntegrationAbbreviation
                      data={option ? option : null}
                      type={IntegrationAbbreviation.dataType.integrationUser.value}
                    />
                  )}
              </span>
            </>
          ))}
        </span>
      );

    return (
      <Tooltip
        PopperProps={{
          style: { maxWidth: "none" }
        }}
        title={renderTitle(true)}
        placement='top'
        open={isOptionTooltipVisible}
        arrow
      >
        <ListItemStyled
          {...props}
          key={String(getItemId(option))}
          onMouseEnter={(e) => this.handleOptionMouseEnter(this.hoveredOptionRef)}
          onMouseLeave={this.handleOptionMouseLeave.bind(this)}
        >
          <p className='klayo_selector-option-container' ref={this.hoveredOptionRef}>
            <MarkerIcon className='klayo_mark-icon' /> {renderTitle()}
          </p>
        </ListItemStyled>
      </Tooltip>
    );
  }
}

const ListItemStyled = styled(ListItem)`
  .klayo_mark-icon {
    width: 25px;
    height: 25px;
    color: ${KLAYO_COLORS.NeonBlueNB500};
    margin-right: 12px;
  }

  .klayo_selector-option-container {
    display: flex;
    align-items: center;
    justify-content: flex-start;
  }

  :hover {
    .klayo_integration-abbreviation {
      color: ${KLAYO_COLORS.WhiteWH900};
    }

    .klayo_mark-icon {
      color: ${KLAYO_COLORS.WhiteWH900};
    }
  }
`;
