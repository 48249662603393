import { Box, Stack, Typography } from "@mui/material";
import { React, Component } from "react";
import { ReactComponent as AttributesIcon } from "../resources/images/icons-attribute.svg";

export class Placeholder extends Component {
  static defaultProps = {
    text: "No rows",
    icon: <AttributesIcon />,
    primaryTextSize: "20px",
    secondaryTextSize: "16px",
    spacing: 3
  };

  render() {
    const { icon, text, sx, className, primaryTextSize, secondaryTextSize, spacing } = this.props;

    return (
      <Stack
        className={"klayo-placeholder" + (className ? " " + className : "")}
        spacing={spacing}
        sx={sx}
      >
        <div className='klayo-placeholder_icon'>{icon}</div>
        <div className='klayo-placeholder_text'>
          {text && text.primary ? (
            <div>
              <Box className='klayo-placeholder_text_primary' sx={{ fontSize: primaryTextSize }}>
                {text.primary}
              </Box>
              <Box
                className='klayo-placeholder_text_secondary'
                sx={{ fontSize: secondaryTextSize }}
              >
                {text.secondary}
              </Box>
            </div>
          ) : (
            text
          )}
        </div>
      </Stack>
    );
  }
}
