import { Stack } from "@mui/material";
import { Alert } from "klayowebshared";
import { Component } from "react";
import { AppContext } from "../../common/AppContext";
import { TextField } from "../../components/TextField";
import { Dialog } from "../../components/dialogs/Dialog";
import { CREATE_TASK_MODE } from "../../constants";
export class CreateRegulationDomainDialog extends Component {
  static contextType = AppContext;

  constructor(props) {
    super(props);

    this.state = {
      domain: null
    };
  }

  componentDidMount() {
    const { mode } = this.props;
    if (mode === CREATE_TASK_MODE.EDIT) {
      const { selectedDomain } = this.props;
      this.setState({ domain: selectedDomain.name });
    }
  }

  onSave() {
    const { onSave } = this.props;
    const { domain } = this.state;
    if (onSave) onSave(domain);
  }

  onUpdateName(e) {
    this.setState({ domain: e.target.value });
  }

  render() {
    const { user, theme, onClose, error, mode, selectedDomain } = this.props;
    const { domain } = this.state;

    return (
      <Dialog
        open={true}
        theme={theme}
        onClose={onClose}
        fullWidth={true}
        maxWidth='sm'
        title={mode === CREATE_TASK_MODE.EDIT ? "Edit domain" : "Create domain"}
        actions={[
          {
            label: `${mode === CREATE_TASK_MODE.EDIT ? "Save" : "Create"}`,
            primary: true,
            disabled:
              !domain ||
              domain.length === 0 ||
              (mode === CREATE_TASK_MODE.EDIT && domain === selectedDomain.name),
            onClick: this.onSave.bind(this)
          },
          {
            label: "Cancel",
            onClick: onClose
          }
        ]}
      >
        <Stack direction='column' spacing={4}>
          {error && (
            <Alert severity='error' sx={{ width: "100%" }}>
              {error}
            </Alert>
          )}

          <TextField
            value={domain}
            label='Domain name (required)'
            dense={true}
            disabled={user === null}
            autoComplete={false}
            autoFocus={true}
            sx={{ width: "100%" }}
            onChange={this.onUpdateName.bind(this)}
          />
        </Stack>
      </Dialog>
    );
  }
}
