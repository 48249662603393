export class SettingRegulation {
  constructor(data) {
    if (data) {
      this.id = data.id;
      this.name = data.name;
      this.createdDate = data.createdDate;
      this.lastUpdatedDate = data.lastUpdatedDate;
      this.regulationCategory = data.regulationCategory;
      this.regulationDomain = data.regulationDomain;
      this.regulationRegion = data.regulationRegion;
      this.regulationTasks = data.regulationTasks;
    }
  }
}
