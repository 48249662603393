import { Paper } from "../../components/Paper";
import { Stack, Box, Tabs, Tab, Link } from "@mui/material";
import { ViewComponent } from "../../components/ViewComponent";
import { AppContext } from "../../common/AppContext";
import { Button } from "../../components/Button";
import { BillingHistoryTable } from "../../components/table/BillingHistoryTable";
import { TablePlaceholder } from "../../components/TablePlaceholder";
import { ReactComponent as BillingIcon } from "../../resources/images/icons-invoice.svg";
import { ReactComponent as CreditCardIcon } from "../../resources/images/icons-credit-card.svg";
import { Utils } from "../../common/Utils";
import { DetailsGrid } from "../../components/DetailsGrid";
import { DetailsGridRow } from "../../components/DetailsGridRow";
import { PaymentMethodCard } from "../../components/PaymentMethodCard";
import { BillingPlan } from "../../data/billing/BillingPlan";

export class SettingsBillingView extends ViewComponent {
  static contextType = AppContext;

  componentDidMount() {
    const {
      onLoadBillingDetails,
      onShowBillingPlans,
      onShowBillingDetails,
      onLoadBillingInvoices,
      onShowCreditCardDetails
    } = this.props;
    onLoadBillingDetails();

    if (this.pathStartsWith("/settings/billing/plan")) onShowBillingPlans();
    else if (this.pathStartsWith("/settings/billing/history") && onLoadBillingInvoices)
      onLoadBillingInvoices();
    else if (this.pathStartsWith("/settings/billing/details")) onShowBillingDetails();
    else if (this.pathStartsWith("/settings/billing/payment/update-card"))
      onShowCreditCardDetails();
  }

  componentDidUpdate(prevProps, prevState) {
    super.componentDidUpdate(prevProps, prevState);
    if (this.props.billingDetails !== prevProps.billingDetails) this.forceUpdate();
  }

  onViewChange(location) {
    const {
      onShowBillingPlans,
      onShowBillingDetails,
      onLoadBillingInvoices,
      onShowCreditCardDetails
    } = this.props;

    if (this.pathStartsWith("/settings/billing/plan") && onShowBillingPlans) onShowBillingPlans();
    else if (this.pathStartsWith("/settings/billing/history") && onLoadBillingInvoices)
      onLoadBillingInvoices();
    else if (this.pathStartsWith("/settings/billing/details")) onShowBillingDetails();
    else if (this.pathStartsWith("/settings/billing/payment/update-card"))
      onShowCreditCardDetails();
  }

  onTabChange(e, step) {
    const { history } = this.props;
    if (step == 0) history.push("/settings/billing");
    else if (step == 1) history.push("/settings/billing/history");
    else if (step == 2) history.push("/settings/billing/payment");
  }

  onInvoiceDownload(e, invoice) {
    const { onInvoiceDownload } = this.props;
    if (onInvoiceDownload) onInvoiceDownload(e, invoice);
  }

  onChangeCardClick(e) {
    const { history } = this.props;
    history.push("/settings/billing/payment/update-card");
  }

  onContactUsLinkClick(e) {
    //e.preventDefault();
    //const { onContactUs } = this.props;
    //if (onContactUs) onContactUs(e);
  }

  render() {
    const { theme, billingDetails, billingInvoices } = this.props;
    const contactDetails = billingDetails ? billingDetails.contactDetails : null;
    const creditCardDetails = billingDetails ? billingDetails.creditCardDetails : null;

    const isInvoice =
      billingDetails && billingDetails.paymentMethod === BillingPlan.paymentMethod.invoice;

    let step = 0;
    if (this.pathStartsWith("/settings/billing/history")) step = 1;
    else if (this.pathStartsWith("/settings/billing/payment")) step = 2;

    return (
      <div>
        <Paper theme={theme} padding={{ xs: "46px 24px", md: "60px" }} borderFromBreakpoint='md'>
          <h1>Billing</h1>
          <Stack direction='column' spacing={4}>
            <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
              <Tabs
                centered={true}
                value={step}
                onChange={this.onTabChange.bind(this)}
                variant='fullWidth'
                sx={{ width: "100%" }}
              >
                <Tab label='Overview' value={0} />
                <Tab label='Billing History' value={1} />
                <Tab label='Payment method' value={2} />
              </Tabs>
            </Box>

            {step === 0 && (
              <div>
                <Stack direction='column' spacing={4}>
                  <div>
                    <h2>Your current plan</h2>
                    <Stack
                      direction={{ xs: "column", md: "row" }}
                      justifyContent='space-between'
                      spacing={2}
                    >
                      <div className='klayo-text-md'>
                        You are currently on the{" "}
                        <b>
                          {billingDetails
                            ? billingDetails.planGroupName || billingDetails.planName
                            : ""}
                        </b>{" "}
                        plan.
                      </div>
                      <div>
                        <Button
                          path={"/settings/billing/plan"}
                          size='md'
                          disabled={billingDetails && billingDetails.isCustomPlan}
                          label='Change plan'
                        />
                      </div>
                    </Stack>
                  </div>

                  {billingDetails && (
                    <div style={{ borderTop: "1px solid #0000001f", padding: "24px 0" }}>
                      <h2>Plan details</h2>
                      <DetailsGrid spacing={2}>
                        <DetailsGridRow label='Monthly cost'>
                          {!isNaN(billingDetails.planPricePerCycleInCents)
                            ? "$" +
                              Utils.formatCurrency(billingDetails.planPricePerCycleInCents / 100)
                            : "-"}
                        </DetailsGridRow>
                        <DetailsGridRow label='Users'>
                          {Utils.formatNumber(billingDetails.planEmployeeCap)} (
                          {billingDetails.currentNumberOfEmployeesInOrg} used)
                        </DetailsGridRow>
                        <DetailsGridRow label='Next bill'>
                          {billingDetails.currentCycleEndDate
                            ? billingDetails.currentCycleEndDate.toDateString()
                            : "-"}
                        </DetailsGridRow>
                        <DetailsGridRow label='Payment method'>
                          {billingDetails.paymentMethod
                            ? billingDetails.paymentMethod.label
                            : "None"}
                        </DetailsGridRow>
                      </DetailsGrid>
                    </div>
                  )}

                  {contactDetails && (
                    <div style={{ borderTop: "1px solid #0000001f", padding: "24px 0" }}>
                      <h2>Billing details</h2>
                      <Stack
                        direction={{ xs: "column", md: "row" }}
                        justifyContent='space-between'
                        spacing={2}
                      >
                        <DetailsGrid spacing={2}>
                          <DetailsGridRow
                            label='Billing contact'
                            lineHeight='24px'
                            direction={{ xs: "column", md: "row" }}
                          >
                            <div>
                              {contactDetails.contactFirstName} {contactDetails.contactLastName}
                            </div>
                            <div>{contactDetails.contactEmail}</div>
                            <div>{contactDetails.contactPhone}</div>
                          </DetailsGridRow>
                          <DetailsGridRow
                            label='Billing details'
                            lineHeight='24px'
                            direction={{ xs: "column", md: "row" }}
                          >
                            <div>{contactDetails.companyName}</div>
                            <div>{contactDetails.street1}</div>
                            <div>
                              {contactDetails.city}, {contactDetails.state},{" "}
                              {contactDetails.postalCode}
                            </div>
                            <div>{contactDetails.country}</div>
                          </DetailsGridRow>
                        </DetailsGrid>
                        <div>
                          <Button
                            path={"/settings/billing/details"}
                            size='md'
                            disabled={billingDetails && billingDetails.isCustomPlan}
                            label='Change details'
                          />
                        </div>
                      </Stack>
                    </div>
                  )}
                </Stack>
              </div>
            )}

            {step === 1 && (
              <div>
                <BillingHistoryTable
                  paper={false}
                  theme={theme}
                  minHeight='300px'
                  showHeader={true}
                  showFooter={false}
                  selectable={false}
                  rows={billingInvoices ? billingInvoices.invoices : null}
                  onClickDownloadLink={this.onInvoiceDownload.bind(this)}
                  noRowsComponent={
                    <TablePlaceholder
                      text='Your billing history will be shown here when available'
                      icon={<BillingIcon />}
                    />
                  }
                />
              </div>
            )}

            {step === 2 && (
              <div>
                {billingDetails &&
                (isInvoice ||
                  (billingDetails.paymentMethod === BillingPlan.paymentMethod.creditCard &&
                    creditCardDetails)) ? (
                  <Stack direction='column' spacing={4} sx={{ width: "100%" }}>
                    <div>
                      <PaymentMethodCard
                        billingDetails={billingDetails}
                        creditCardDetails={creditCardDetails}
                        onChangeCardClick={this.onChangeCardClick.bind(this)}
                      />
                    </div>
                    <div style={{ fontSize: "16px" }}>
                      Would you like to change your payment method to{" "}
                      {isInvoice ? "credit card" : "invoice"}?{" "}
                      <Link
                        href='https://www.klayo.com/contact-us/'
                        target='_blank'
                        onClick={this.onContactUsLinkClick.bind(this)}
                        style={{
                          textDecoration: "none",
                          fontWeight: "600",
                          color: "var(--neon-blue-nb-500)"
                        }}
                      >
                        Contact us
                      </Link>
                    </div>
                  </Stack>
                ) : (
                  <TablePlaceholder
                    text='No billing method has been added yet'
                    icon={<CreditCardIcon />}
                    sx={{ position: "static", height: "90px" }}
                  />
                )}
              </div>
            )}
          </Stack>
        </Paper>

        {this.renderSnackbar()}
      </div>
    );
  }
}
