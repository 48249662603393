import { Component } from "react";
import { Paper } from "../../components/Paper";
import { Link } from "react-router-dom";
import { Dialog, Box, AppBar, Toolbar, DialogContent, Stack, Divider, Zoom } from "@mui/material";
import { ViewComponent } from "../../components/ViewComponent";
import { AppContext } from "../../common/AppContext";
import { Button } from "../../components/Button";
import { Alert } from "../../components/Alert";
import { ReactComponent as CloseIcon } from "../../resources/images/icons-close.svg";
import { ReactComponent as Logo } from "../../resources/images/logo.svg";
import { BillingGroupCard } from "../../components/BillingGroupCard";
import { BillingPlan } from "../../data/billing/BillingPlan";
import { PillButtons } from "../../components/PillButtons";
import { Utils } from "../../common/Utils";
import { ErrorModel } from "../../common/models/ErrorModel";
import { UpdateCreditCard } from "../../data/settings/UpdateCreditCard";
import { BillingSelectionCard } from "../../components/BillingSelectionCard";
import { BillingContact } from "./BillingContact";
import { BillingDetails } from "./BillingDetails";
import { ReactComponent as ClapIcon } from "../../resources/images/icons-clap.svg";
import { ConfirmationBox } from "../../components/ConfirmationBox";
import { EwayField } from "../../components/billing/EwayField";
import { SubmitBillingDetails } from "../../data/settings/SubmitBillingDetails";
import { CreditCardPayment } from "./CreditCardPayment";
import { InvoicePayment } from "./InvoicePayment";

export class CreditCardDetailsDialog extends ViewComponent {
  static contextType = AppContext;

  constructor(props) {
    super(props);

    this.state = {
      error: null,
      submitting: false,
      submitted: false,
      creditCardDetails: null,
      creditCardAttemptId: 0,
      ewaySecureFieldCode: null
    };
  }

  onSubmit(e) {
    const { onClose } = this.props;
    const { ewaySecureFieldCode } = this.state;

    this.context.setLoading("submitting", true);
    this.setState({ error: null, submitting: true });

    UpdateCreditCard.post(this.context, {
      securedFieldsCode: ewaySecureFieldCode
    })
      .then((n) => {
        this.setState({ error: null, submitted: true });
        onClose();
      })
      .catch((e) => {
        const error = ErrorModel.parseServerError(e);
        this.setState({ error, creditCardAttemptId: Utils.generateUniqueId() });
      })
      .finally(() => {
        this.context.setLoading("submitting", false);
        this.setState({ submitting: false });
      });
  }

  setError(error) {
    this.setState({ error });
    if (error) window.scrollTo(0, 0);
  }

  onCreditCardChange(creditCardDetails, ewaySecureFieldCode) {
    this.setState({ creditCardDetails, ewaySecureFieldCode });
  }

  render() {
    const { organization, user, onClose } = this.props;
    const { error, submitting, billingContact, billingDetails, creditCardAttemptId } = this.state;

    return (
      <Dialog className='klayo-billingplandialog' onClose={onClose} open={true} fullScreen={true}>
        <AppBar className='klaro_employeeprofile-appbar' elevation={0}>
          <Toolbar>
            <Logo className='klaro-appbar_logo' alt='Klayo Logo' />
            <Box sx={{ flexGrow: 1 }}></Box>
            <Box sx={{ display: { xs: "none", md: "flex" } }}>
              <Button
                autoFocus
                label='Close'
                variant='outlined'
                size='md'
                startIcon={<CloseIcon />}
                onClick={onClose}
              />
            </Box>
          </Toolbar>
        </AppBar>
        <DialogContent sx={{ marginTop: "64px", backgroundColor: "#F8F8FE" }}>
          <div className='klayo-billingplandialog_content'>
            <Stack direction='column' spacing={4}>
              {error && (
                <Alert severity='error' scrollTo={true}>
                  {error}
                </Alert>
              )}

              <CreditCardPayment
                onChange={this.onCreditCardChange.bind(this)}
                visible={true}
                attemptId={creditCardAttemptId}
              />

              <div style={{ textAlign: "center", padding: "30px" }}>
                <Button
                  label='Save details'
                  variant='filled'
                  onClick={this.onSubmit.bind(this)}
                  disabled={submitting}
                />
              </div>
            </Stack>
          </div>
        </DialogContent>
      </Dialog>
    );
  }
}
