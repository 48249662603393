import { LocationSelectorList } from "../../data/filters/LocationSelectorList";
import { AppContext } from "../../common/AppContext";
import { AbstractFilterSelect } from "./AbstractFilterSelect";
export class LocationSelector extends AbstractFilterSelect {
  static contextType = AppContext;

  static defaultProps = {
    label: "Location",
    matchFrom: "start",
    listType: "Locations",
    ...AbstractFilterSelect.defaultProps
  };

  constructor(props) {
    super(props);

    this.dataList = LocationSelectorList;
  }

  getItemId(item) {
    return item.locationId;
  }

  getItemLabel(item) {
    const { getItemLabel } = this.props;

    if (getItemLabel) return getItemLabel(item);
    return item;
  }

  getList(dataList) {
    return dataList.locations;
  }
}
