import { Stack } from "@mui/material";
import { Alert } from "klayowebshared";
import { Component } from "react";
import { AppContext } from "../../common/AppContext";
import { Dialog } from "../../components/dialogs/Dialog";
export class ConfirmDeleteTaskDialog extends Component {
  static contextType = AppContext;

  constructor(props) {
    super(props);
  }

  render() {
    const { theme, onClose, error, title, content, onConfirm, onNotConfirm } = this.props;

    return (
      <Dialog
        open={true}
        theme={theme}
        onClose={onClose}
        fullWidth={true}
        maxWidth='sm'
        title={title}
        actions={[
          {
            label: "Cancel",
            onClick: onClose
          },
          {
            label: "No",
            acceptWarning: true,
            onClick: onNotConfirm
          },
          {
            label: "Yes",
            primary: true,
            onClick: onConfirm
          }
        ]}
      >
        <Stack direction='column' spacing={4}>
          {error && (
            <Alert severity='error' sx={{ width: "100%" }}>
              {error}
            </Alert>
          )}
          {content}
        </Stack>
      </Dialog>
    );
  }
}
