import { Data } from "../Data";
import { SettingsRegulationRegion } from "./SettingRegulationRegion";

export class SettingsRegulationRegionList extends Data {
  static instance = null;

  constructor(options) {
    const { apiUrl, ...otherOptions } = options;

    const apiOptions = {
      api: apiUrl,
      callback: (data) => {
        options.resolve(this, false);
      },
      ...otherOptions
    };

    super(apiOptions);
    this.regions = [];
  }

  onDataReceived(data) {
    this.regions = [];
    data.regions.forEach((g, index) => this.regions.push(new SettingsRegulationRegion(g)));
  }

  static onGetData(context, options) {
    const { apiUrl } = options;
    return new Promise((resolve, reject) => {
      SettingsRegulationRegionList.instance = new SettingsRegulationRegionList({
        apiUrl,
        resolve,
        reject,
        context
      });
    });
  }

  static get(context, updateCache) {
    return new Promise((resolve, reject) => {
      if (SettingsRegulationRegionList.instance && updateCache !== true)
        resolve(SettingsRegulationRegionList.instance, true);
      else
        SettingsRegulationRegionList.instance = new SettingsRegulationRegionList({
          resolve,
          reject,
          context
        });
    });
  }
}
