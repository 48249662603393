import { AbstractAttribute } from "./AbstractAttribute";

export class AttributeDefinition extends AbstractAttribute {
  constructor(index, data) {
    super(index, data);

    this.category = data.category;
    this.description = data.description;
    this.attribute = data.name;
    this.organizationId = data.organizationId;
    this.trainingCourses = data.trainingCourses;
  }
}
