export class BillingCreditCardDetails {
  constructor(data) {
    this.cardHolderName = data.cardHolderName;
    this.lastFourDigits = data.lastFourDigits;
    this.isDefault = data.isDefault;
    this.createdDate = data.createdDate;
    this.expiryMonth = data.expiryMonth;
    this.expiryYear = data.expiryYear + 2000;
  }
}
