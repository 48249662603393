import { Data } from "../Data";
import { User } from "../users/User";

export class ManagerSelectorList extends Data {
  static instance = null;

  constructor(options) {
    super({
      api: "/Employee/SearchManager?searchText=" + options.search,
      callback: (data) => {
        options.resolve(this, false);
      },
      ...options
    });
  }

  onDataReceived(data) {
    this.employees = [];
    data.employees.forEach((e, index) => {
      const manager = new User({ data: { id: index, ...e } });
      manager.jobId = e.jobId;
      manager.fullName = e.employeeFullName;
      if (manager.fullName === null) manager.fullName = "Vacancy";
      manager.jobTitle = e.jobDefinitionName;
      manager.reportsToEmployeeFullname = e.reportsToEmployeeFullname;
      manager.directReports = e.directReports;
      this.employees.push(manager);
    });
  }

  static get(context, search) {
    return new Promise((resolve, reject) => {
      if (ManagerSelectorList.instance && search === "")
        resolve(ManagerSelectorList.instance, true);
      else
        ManagerSelectorList.instance = new ManagerSelectorList({
          resolve,
          reject,
          search,
          context
        });
    });
  }

  findMatch(attr) {
    return this.attributes.find((a) => a.name === attr);
  }
}
