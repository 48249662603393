import { React, Component } from "react";
import { ErrorPage } from "./ErrorPage";

export class ErrorBoundary extends Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(error) {
    return { hasError: true, error };
  }
  componentDidCatch(error, errorInfo) {}

  render() {
    const { hasError, error } = this.state;

    if (hasError) return <ErrorPage error={error} />;
    else return this.props.children;
  }
}
