import { format } from "date-fns";
import Cookies from "js-cookie";
import { get } from "lodash";
import uniqueId from "lodash.uniqueid";
import { REGULATION_ATTRIBUTE_TYPE } from "../constants";

export class Utils {
  static formatDate(s, type) {
    const date = Date.parse(s);
    if (isNaN(date)) return "";

    if (Utils.isToday(date)) return "Today";

    const opts =
      type === "long"
        ? {
            year: "numeric",
            month: "long",
            day: "numeric",
            hour: "numeric",
            minute: "numeric",
            hour12: true
          }
        : {
            year: "numeric",
            month: "short",
            day: "numeric"
          };

    return new Intl.DateTimeFormat("en-GB", opts).format(date);
  }

  static isToday(date) {
    if (typeof date.getDate !== "function") return false;
    const today = new Date();
    return (
      date.getDate() === today.getDate() &&
      date.getMonth() === today.getMonth() &&
      date.getFullYear() === today.getFullYear()
    );
  }

  static toApiDate(date) {
    if (!date) return null;
    const offset = date.getTimezoneOffset();
    const d = new Date(date.getTime() - offset * 60 * 1000);
    return d.toISOString().split("T")[0];
  }

  static formatReadableDate(date) {
    if (!date) return null;
    let yyyy = date.getFullYear().toString();
    let mm = (date.getMonth() + 1).toString();
    let dd = date.getDate().toString();

    return dd + "/" + mm + "/" + yyyy;
  }

  static parseApiDate(date) {
    return date ? new Date(Date.parse(date)) : date;
  }

  static formatProficiency(prof, valid) {
    return prof && prof > 0 && (valid === undefined || valid) ? Utils.formatNumber(prof, 1) : "n/a";
  }

  static formatProficiencyFraction(currentProf, requiredProf, valid) {
    return currentProf &&
      currentProf > 0 &&
      requiredProf &&
      requiredProf > 0 &&
      (valid === undefined || valid)
      ? Utils.formatProficiency(currentProf, valid) +
          " / " +
          Utils.formatProficiency(requiredProf, valid)
      : "n/a";
  }

  static formatPercent(n) {
    if (n === null || isNaN(n)) return "n/a";
    return Math.ceil(n) + "%";
  }

  static roundNumber(num, dec = 2) {
    return +(Math.round(num + "e+" + dec) + "e-" + dec);
  }

  static zeroPadNumber(num, zeros) {
    return String(num).padStart(zeros, "0");
  }

  static formatNumber(num) {
    return num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  }

  static formatCurrency(num, dec = 0) {
    return parseInt(num, 10).toLocaleString("en-US", { minimumFractionDigits: dec });
  }

  static setCookie(name, value, days = 7, path = "/") {
    const expires =
      days === -1
        ? "Thu, 01 Jan 1970 00:00:00 GMT"
        : new Date(Date.now() + days * 864e5).toUTCString();
    //document.cookie = name + '=' + encodeURIComponent(value) + '; expires=' + expires + '; path=' + path + ';samesite=none';
    //document.cookie = name + '=' + encodeURIComponent(value) + '; expires=' + expires + '; path=' + path + ';samesite=none; domain=localhost:9631';
    //document.cookie = name + '=' + encodeURIComponent(value) + '; expires=' + expires + '; path=' + path + ';samesite=none; domain=localhost';

    //Cookies.set(name, value, { expires: days, path, sameSite: 'none' })

    //alert(document.cookie);
  }

  static getCookie(name) {
    return document.cookie.split("; ").reduce((r, v) => {
      const parts = v.split("=");
      return parts[0] === name ? decodeURIComponent(parts[1]) : r;
    }, "");
  }

  static truncate(input, limit) {
    if (!input) return input;
    return input.length > limit ? input.substring(0, limit) + "..." : input;
  }

  static deleteCookie(name, path) {
    Cookies.remove(name, { path, sameSite: "none" });
  }

  static getDevOption(key) {
    return window.localStorage.getItem(key) === "true";
  }

  static setDevOption(key, value) {
    window.localStorage.setItem(key, value);
  }

  static getLocalStorageOption(key) {
    return window.localStorage.getItem(key);
  }

  static checkLocalStorageOption(key) {
    return window.localStorage.getItem(key) === "true";
  }

  static setLocalStorageOption(key, value) {
    window.localStorage.setItem(key, value);
  }

  static removeLocationHash() {
    window.history.pushState("", document.title, window.location.pathname, window.location.search);
  }

  static setLocation(history, path, useNative) {
    if (useNative === true) window.history.pushState(null, null, path);
    else history.push(path);
  }

  static isValidEmail(email) {
    return email
      ? email.match(
          // eslint-disable-next-line no-useless-escape
          /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        ) !== null
      : false;
  }

  static isTouchDevice() {
    return (
      "ontouchstart" in window || navigator.maxTouchPoints > 0 || navigator.msMaxTouchPoints > 0
    );
  }

  static generateUniqueId(prefix) {
    return uniqueId(prefix);
  }

  static convertPracticalTime(time, at) {
    if (!time) return "";
    const splits = String(time).trim().split(" ");
    const suffix = splits[1].toLowerCase();
    const timeSegments = splits[0].split(":");
    const hour = timeSegments[0];
    const minute = this.zeroPadNumber(timeSegments[1], 2);
    return `${at ? "at " : ""}${hour}:${minute} ${suffix}`;
  }

  static toDtoDate(date) {
    if (!date) return null;
    return format(new Date(date), "yyyy-MM-dd'T'00:00:00");
  }

  static getTreeDataPath = (row) => row.hierarchy;

  static getRowId = (row) => {
    if (typeof row?.id === "string" && row?.id.startsWith("placeholder-children-")) {
      return row.id;
    }
    return row.id;
  };

  static updateRows(apiRef, rows) {
    if (!apiRef.current) {
      return;
    }
    const rowsToAdd = [...rows];
    rows.forEach((row) => {
      if (row.descendantCount && row.descendantCount > 0) {
        rowsToAdd.push({
          id: `placeholder-children-${this.getRowId(row)}`,
          hierarchy: [...row.hierarchy, ""]
        });
      }
    });
    apiRef.current.updateRows(rowsToAdd);
  }

  static updateNewCategoryRowAndParent(apiRef, row, parentRow) {
    if (!apiRef.current) {
      return;
    }

    const rowsToAdd = [row];

    if (parentRow.descendantCount && parentRow.descendantCount > 0 && !parentRow.childrenFetched) {
      rowsToAdd.push({
        id: `placeholder-children-${this.getRowId(parentRow)}`,
        hierarchy: [...parentRow.hierarchy, ""]
      });
    }
    apiRef.current.updateRows(rowsToAdd);
  }

  static updateDomainRow(apiRef, row, callback) {
    if (!apiRef.current) {
      return;
    }

    const rowIds = apiRef.current.getAllRowIds();
    const allRows = rowIds.map((rowId) => apiRef.current.getRow(rowId));
    const allRowNodes = rowIds.map((rowId) => apiRef.current.getRowNode(rowId));
    const selectedRowNode = allRowNodes.find((node) => node.id === row.id);

    if (selectedRowNode.type === "leaf") {
      const updatedRows = allRows.map((r) => {
        if (r.id === row.id) {
          return row;
        }
        return r;
      });
      apiRef.current.updateRows(updatedRows);
    }
    if (selectedRowNode.type === "group") {
      callback();
    }
  }

  static deleteRow(apiRef, rowId, type) {
    if (!apiRef.current) {
      return;
    }

    const rowIds = apiRef.current.getAllRowIds();
    const allRows = rowIds.map((rowId) => {
      const row = apiRef.current.getRow(rowId);
      return row;
    });

    const allRowNodes = rowIds.map((rowId) => {
      const row = apiRef.current.getRowNode(rowId);
      return row;
    });

    if (type === REGULATION_ATTRIBUTE_TYPE.DOMAIN) {
      const rowsToAdd = allRows.map((row) => {
        if (row.id === rowId) {
          return { ...row, _action: "delete" };
        }
        if (row.id === `placeholder-children-${rowId}`) {
          return { ...row, _action: "delete" };
        }
        return row;
      });
      apiRef.current.updateRows(rowsToAdd);
    } else if (type === REGULATION_ATTRIBUTE_TYPE.CATEGORY) {
      const rowsToAdd = allRows.map((row) => {
        if (row.id === rowId) {
          return { ...row, _action: "delete" };
        }
        if (
          row.id === `placeholder-children-${rowId}` ||
          get(row, "regulationCategory.id", null) === rowId
        ) {
          return { ...row, _action: "delete" };
        }
        return row;
      });

      const parentRowNode = allRowNodes.find((row) => row.id === rowId);
      const parentRow = apiRef.current.getRow(parentRowNode.parent);

      // Decrease parent descendant count with allRowNodes
      const parentRowDescendantCount = parentRow.descendantCount - 1;
      const parentRowToUpdate = { ...parentRow, descendantCount: parentRowDescendantCount };
      const parentRowToUpdateIndex = allRows.findIndex((row) => row.id === parentRow.id);
      rowsToAdd[parentRowToUpdateIndex] = parentRowToUpdate;

      apiRef.current.updateRows(rowsToAdd);
    } else {
      const rowsToAdd = allRows.map((row) => {
        if (row.id === rowId) {
          return { ...row, _action: "delete" };
        }
        return row;
      });

      const parentRowNode = allRowNodes.find((row) => row.id === rowId);
      const parentRow = apiRef.current.getRow(parentRowNode.parent);

      // Decrease parent descendant count with allRowNodes
      const parentRowDescendantCount = parentRow.descendantCount - 1;
      const parentRowToUpdate = { ...parentRow, descendantCount: parentRowDescendantCount };
      const parentRowToUpdateIndex = allRows.findIndex((row) => row.id === parentRow.id);
      rowsToAdd[parentRowToUpdateIndex] = parentRowToUpdate;
      apiRef.current.updateRows(rowsToAdd);
    }
  }

  static getAllRowIds(apiRef) {
    if (!apiRef.current) {
      return;
    }

    return apiRef.current.getAllRowIds();
  }
}
