import { Data } from "../Data";
import { SettingCourseFaculty } from "./SettingCourseFaculty";

export class SettingCourseFacultyList extends Data {
  static instance = null;

  constructor(options) {
    const { apiUrl, ...otherOptions } = options;
    const apiOptions = {
      api: apiUrl,
      callback: (data) => {
        options.resolve(this, false);
      },
      ...otherOptions
    };

    super(apiOptions);

    this.faculties = [];
  }

  onDataReceived(data) {
    data.faculties.forEach((f, index) => {
      const faculty = new SettingCourseFaculty(f);
      this.faculties.push(faculty);
    });
  }

  static onGetData(context, options) {
    const { apiUrl } = options;
    return new Promise((resolve, reject) => {
      SettingCourseFacultyList.instance = new SettingCourseFacultyList({
        apiUrl,
        resolve,
        reject,
        context
      });
    });
  }

  static get(context, updateCache) {
    return new Promise((resolve, reject) => {
      if (SettingCourseFaculty.instance && updateCache !== true)
        resolve(SettingCourseFaculty.instance, true);
      else SettingCourseFaculty.instance = new SettingCourseFaculty({ resolve, reject, context });
    });
  }
}
