import { Data } from "../Data";

export class BillingPlan {
  static cycle = {
    none: {
      index: 0,
      label: "None"
    },
    monthly: {
      index: 1,
      label: "Monthly",
      paymentLabel: "Monthly",
      perLabel: "Month"
    },
    quarterly: {
      index: 2,
      label: "Quarterly",
      paymentLabel: "Quarterly",
      perLabel: "Quarter"
    },
    annually: {
      index: 3,
      label: "Annually",
      paymentLabel: "Annual",
      perLabel: "Year"
    }
  };

  static paymentMethod = {
    none: {
      index: 0,
      label: "None"
    },
    creditCard: {
      index: 1,
      label: "Credit Card"
    },
    invoice: {
      index: 2,
      label: "Invoice"
    }
  };

  static group = {
    starter: new BillingPlan({
      name: "Starter",
      pricePerCycleInCents: 50000,
      cycle: 2,
      employeeCap: 50,
      isGroup: true
    }),
    large: new BillingPlan({
      name: "Large",
      pricePerCycleInCents: 80000,
      cycle: 2,
      employeeCap: 100,
      isGroup: true
    }),
    organization: new BillingPlan({
      name: "Organization",
      pricePerCycleInCents: 120000,
      cycle: 2,
      employeeCap: 200,
      isGroup: true
    }),
    enterprise: new BillingPlan({
      name: "Enterprise",
      pricePerCycleInCents: 0,
      cycle: 2,
      employeeCap: 200,
      isGroup: true,
      isCustom: true
    })
  };

  constructor(data) {
    this.billingPlanId = data.billingPlanId;
    this.planGroupId = data.billingPlanGroupId;
    this.name = data.name;
    this.cycle = data.cycle ? Object.values(BillingPlan.cycle)[data.cycle] : BillingPlan.cycle.none;
    this.paymentMethod = data.paymentMethod
      ? Object.values(BillingPlan.paymentMethod)[data.paymentMethod]
      : BillingPlan.paymentMethod.none;
    this.isCustom = data.isCustom || this.name === "Custom";
    this.employeeCap = data.employeeCap;
    this.pricePerCycleInCents = data.pricePerCycleInCents;
    this.pricePerCycle = this.pricePerCycleInCents / 100;
    this.pricePerUser = this.pricePerCycle / this.employeeCap;
    this.isGroup = data.isGroup;
    this.isAllowed = data.isAllowed;
    this.employeeCapDescription = data.employeeCapDescription;
    this.pricePerMonthDescription = data.pricePerMonthDescription;
    this.pricePerEmployeeDescription = data.pricePerEmployeeDescription;

    if (!this.isGroup) {
      if (this.employeeCap === 50) this.group = BillingPlan.group.starter;
      else if (this.employeeCap === 100) this.group = BillingPlan.group.large;
      else if (this.employeeCap === 150) this.group = BillingPlan.group.organization;
      else if (this.isCustom) this.group = BillingPlan.group.enterprise;
    }

    this.plans = [];
    if (data.billingPlans) {
      data.billingPlans.forEach((p) => {
        const plan = new BillingPlan(p);
        this.plans.push(plan);
      });

      this.isAllowed = this.plans.some((p) => p.isAllowed);
    }
  }

  getPlan(paymentMethod, billingCycle) {
    return this.plans.find(
      (p) =>
        (!paymentMethod || (paymentMethod && p.paymentMethod === paymentMethod)) &&
        (!billingCycle || (billingCycle && p.cycle === billingCycle))
    );
  }
}
