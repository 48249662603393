import { Data } from "../Data";
import { DocumentList } from "../documents/DocumentList";
import { User } from "../users/User";

export class AttributeRequestActivity extends Data {
  static instance = null;

  constructor(options) {
    super({
      api: "/EmployeeAttribute/GetActivityHistory/" + options.attributeId,
      callback: (data) => {
        options.resolve(this);
      },
      ...options
    });
  }

  hasDocuments() {
    return this.documents && this.documents.list.length > 0;
  }

  onDataReceived(data) {
    this.previousActivity = [];

    this.documents = new DocumentList(data.documents);

    if (data.activities && data.activities.length > 0) {
      let activities = data.activities;

      activities.sort((a, b) => new Date(b.createDate) - new Date(a.createDate));

      activities.forEach((a, i) => {
        a.user = new User({
          data: {
            firstName: a.employeeFirstName,
            fullName: a.employeeFullName,
            hasProfilePicture: a.employeeHasProfilePicture,
            employeeId: a.employeeId,
            lastName: a.employeeLastName
          }
        });

        if (a.documentIds)
          a.documentIds.forEach((docId) => {
            const doc = this.documents.list.find((d) => d.documentId === docId);
            if (doc) doc.createDate = a.createDate;
          });

        if (i === 0) this.latestActivity = a;
        else this.previousActivity.push(a);
      });

      this.documents.list.sort((a, b) => new Date(b.createDate) - new Date(a.createDate));
    }
  }
}
