import { Data } from "../Data";
import { Group } from "./Group";

export class GroupsList extends Data {
  static instance = null;

  constructor(options) {
    super({
      api: "/MyGroup",
      callback: (data) => {
        options.resolve(this, false);
      },
      ...options
    });
  }

  get(id) {
    return this.groups.find((g) => g.groupId === id);
  }

  onDataReceived(data) {
    this.groups = [];

    data.groups.forEach((g) => {
      this.groups.push(new Group(g));
    });
  }

  static get(context) {
    return new Promise((resolve, reject) => {
      if (GroupsList.instance) resolve(GroupsList.instance, true);
      else GroupsList.instance = new GroupsList({ resolve, reject, context });
    });
  }
}
