import styled from "@emotion/styled";
import { Divider, Grid, List, ListItem, ListItemButton, ListSubheader } from "@mui/material";
import { Component } from "react";
import { ReactComponent as NewWindowIcon } from "../resources/images/icons-new-window.svg";
import { KLAYO_COLORS } from "../themes";
import IntegrationAbbreviation from "../views/settings/IntegrationAbbreviation";
import { Button } from "./Button";
export class TrainingCourseList extends Component {
  constructor(props) {
    super(props);
  }

  onOpenTrainingCourse(trainingUrl) {
    window.open(trainingUrl, "_blank");
  }

  render() {
    const { courses, theme, style, organization } = this.props;
    //TODO: Style to match design

    return (
      <Grid className='klayo-feed' item xs={12} md={12} lg={12} style={style ? style : {}}>
        {courses && courses.length !== 0 && (
          <List
            subheader={<StyledListSubheader component='div'>Related training</StyledListSubheader>}
          >
            {courses.map((c, i) => {
              const trainingUrl = c.enrollmentUrl ? c.enrollmentUrl : c.courseUrl;

              return (
                <StyledRelatedTrainingItem
                  key={Math.random().toString(36).substring(7)}
                  className='klayo__related-training-item'
                  lastItem={i + 1 === courses.length ? true : false}
                >
                  <ListItemButton>
                    <p className='klayo__related-training-item-title'>
                      {c.courseName}{" "}
                      {organization && organization.hasMultipleIntegrations && (
                        <IntegrationAbbreviation
                          data={c ? c : null}
                          type={IntegrationAbbreviation.dataType.integrationUser.value}
                        />
                      )}
                    </p>
                    {trainingUrl && (
                      <Button
                        className='new-window-btn'
                        onClick={() => this.onOpenTrainingCourse(trainingUrl)}
                        disabled={c.courseUrl ? false : true}
                        icon={<NewWindowIcon className='new-window-icon' />}
                      />
                    )}
                  </ListItemButton>
                  <Divider />
                </StyledRelatedTrainingItem>
              );
            })}
          </List>
        )}
      </Grid>
    );
  }
}

const StyledListSubheader = styled(ListSubheader)`
  color: ${KLAYO_COLORS.GreyGR700};
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
  padding-left: 14px;
  padding-right: 14px;
  padding-bottom: 4px;
`;

const StyledRelatedTrainingItem = styled(ListItem)`
  &.klayo__related-training-item {
    padding: 0px;

    .klayo__related-training-item-title {
      color: ${KLAYO_COLORS.GreyGR900};
      font-family: Inter;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 24px;
      letter-spacing: -0.1px;
      margin: 0px;
    }

    .MuiIconButton-root {
      padding: 4px;
    }

    .MuiIconButton-root {
      &.Mui-disabled {
        .new-window-icon {
          color: ${KLAYO_COLORS.GreyGR400};
        }
      }
    }

    .MuiListItemButton-root {
      display: flex;
      justify-content: space-between;
      padding: 16px;
      border-bottom: ${(props) =>
        !props.lastItem ? `1px solid ${KLAYO_COLORS.GreyGR200}` : "none"};

      :hover {
        cursor: default;
      }
    }

    .new-window-btn {
    }

    .new-window-icon {
      color: ${KLAYO_COLORS.NeonBlueNB500};
    }
  }
`;
