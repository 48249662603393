import { Grid, Stack } from "@mui/material";
import { Component } from "react";
import { Alert } from "../../components/Alert";
import { Dialog } from "../../components/dialogs/Dialog";
import { EmployeeSelector } from "../../components/selectors/EmployeeSelector";

export class AssignManagerDialog extends Component {
  constructor(props) {
    super(props);

    this.state = {
      manager: props.manager ? this.matchManagerInfo(props.manager) : null,
      noneSelected: false,
      allowNone: false
    };

    this.matchManagerInfo(props.manager);
  }

  componentDidMount() {
    const { onLoadManagers } = this.props;
    if (onLoadManagers) onLoadManagers(true);
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.managers !== this.props.managers) {
      this.matchManagerInfo(this.state.manager);
      this.forceUpdate();
    }
    if (prevProps.manager !== this.props.manager) this.setManager(this.props.manager);
  }

  onEmployeeChange(e, manager) {
    if (manager && manager.isNoneItem) this.setState({ manager: null, noneSelected: true });
    else this.setManager(manager);
  }

  setManager(manager) {
    this.setState({ manager: this.matchManagerInfo(manager), noneSelected: false });
  }

  matchManagerInfo(manager) {
    const { managers } = this.props;
    const managerInfo =
      managers && manager ? managers.employees.find((m) => m.jobId === manager.jobId) : null;
    if (managerInfo) {
      manager.reportsToEmployeeFullname = managerInfo.reportsToEmployeeFullname;
      manager.directReports = managerInfo.directReports;
    }
    return manager;
  }

  onSave(e) {
    const { onSave, position } = this.props;
    const { manager } = this.state;
    if (onSave) onSave(e, position, manager);
  }

  render() {
    const { organization, user, theme, excludeEmployee, managers, onCancel, error, allowNone } =
      this.props;
    const { manager, noneSelected } = this.state;

    return (
      <Dialog
        open={true}
        theme={theme}
        onClose={onCancel}
        fullWidth={true}
        title='Select reporting manager'
        maxWidth='sm'
        actions={[
          {
            label: "Select",
            primary: true,
            disabled: manager === null && !noneSelected,
            onClick: this.onSave.bind(this)
          },
          {
            label: "Cancel",
            onClick: onCancel
          }
        ]}
      >
        <Stack spacing={4}>
          {error && (
            <Alert severity='error' sx={{ width: "100%" }}>
              {error}
            </Alert>
          )}
          <EmployeeSelector
            value={manager}
            theme={theme}
            managersOnly={true}
            hasNoneItem={allowNone}
            multiple={false}
            label={null}
            filterItems={(managers) =>
              excludeEmployee
                ? managers.filter((m) => m.employeeId !== excludeEmployee.employeeId)
                : managers
            }
            initialItems={managers ? managers.employees : null}
            placeholder='Search manager'
            onChange={this.onEmployeeChange.bind(this)}
          />
          {manager && (
            <div>
              <Grid container spacing={3}>
                <Grid item xs={4}>
                  Name
                </Grid>
                <Grid item xs={8}>
                  {manager.fullName}
                </Grid>
                <Grid item xs={4}>
                  Job
                </Grid>
                <Grid item xs={8}>
                  {manager.jobTitle}
                </Grid>
                <Grid item xs={4}>
                  Reports to
                </Grid>
                <Grid item xs={8}>
                  {manager.reportsToEmployeeFullname}
                </Grid>
                <Grid item xs={4}>
                  Direct reports
                </Grid>
                <Grid item xs={8}>
                  <ul className='klay-managerdialog_directreportslist'>
                    {manager.directReports &&
                      manager.directReports.map((e) => (
                        <li key={Math.random().toString(36).substring(7)}>{e}</li>
                      ))}
                  </ul>
                </Grid>
              </Grid>
            </div>
          )}
        </Stack>
      </Dialog>
    );
  }
}
