import { Utils } from "../../common/Utils";
import { Tooltip as CustomTooltip } from "../Tooltip";
import { Tooltip } from "@mui/material";
import { Table } from "./Table";
import styled from "@emotion/styled";
import { KLAYO_COLORS } from "../../themes";
import { ReactComponent as AssessmentIcon } from "../../resources/images/icons-assessment.svg";

export class ParticipantPracticalTable extends Table {
  static columns = {
    dateTime: {
      field: "practicalDate",
      label: "Date"
    },
    practical: {
      field: "name",
      label: "Practical"
    },
    competencies: {
      field: "numberOfAttributes",
      label: "Competencies"
    },
    participants: {
      field: "numberOfParticipants",
      label: "Participants"
    },
    status: {
      field: "status",
      label: "Status"
    }
  };

  static defaultProps = {
    ...Table.defaultProps,
    columns: [
      { type: ParticipantPracticalTable.columns.dateTime, visibleFromBreakpoint: "sm" },
      { type: ParticipantPracticalTable.columns.practical },
      { type: ParticipantPracticalTable.columns.competencies, visibleFromBreakpoint: "sm" },
      { type: ParticipantPracticalTable.columns.participants, visibleFromBreakpoint: "sm" },
      { type: ParticipantPracticalTable.columns.status, visibleFromBreakpoint: "sm" }
    ],
    noRowsMessage: "You haven’t attended any practicals yet",
    noResultsMessage: "No practicals matched your search"
  };

  constructor(props) {
    super(props);

    this.state = {
      ...this.state,
      sortModel: this.props.sortModel
        ? this.props.sortModel
        : [
            {
              field: "practicalDate",
              sort: "desc"
            }
          ]
    };
  }

  onRowClick(params, e) {
    const { onPracticalSelect } = this.props;
    if (params.row && onPracticalSelect) onPracticalSelect(e, params.row);
  }

  renderDateField(data) {
    const { practicalDate, practicalTime } = data;
    const date = Utils.formatDate(practicalDate);
    const time = Utils.convertPracticalTime(practicalTime, true);
    const string = `${date} ${time}`.trim();
    return (
      <CustomTooltip title={string}>
        <div>{string}</div>
      </CustomTooltip>
    );
  }

  initColumns() {
    const { columns, sortable } = this.props;

    this.columns = [];

    columns.forEach((c) => {
      if (this.hasColumn(c)) {
        if (c.type === ParticipantPracticalTable.columns.dateTime)
          this.columns.push({
            field: "practicalDate",
            headerName: c.label || c.type.label,
            sortable: sortable,
            visibleFromBreakpoint: c.visibleFromBreakpoint || c.type.visibleFromBreakpoint,
            filterable: false,
            disableReorder: true,
            width: 180,
            valueGetter: (params) => params.row.practicalDate,
            renderCell: (params) => this.renderDateField(params.row)
          });

        if (c.type === ParticipantPracticalTable.columns.practical)
          this.columns.push({
            field: "name",
            headerName: c.label || c.type.label,
            sortable: sortable,
            visibleFromBreakpoint: c.visibleFromBreakpoint || c.type.visibleFromBreakpoint,
            filterable: false,
            disableReorder: true,
            minWidth: 160,
            flex: 1,
            valueGetter: (params) => params.row.name,
            renderCell: (params) => (
              <PracticalNameSection>
                {params.row.name}
                {params.row.isAssessment && (
                  <Tooltip title='Assessment' placement='top' arrow>
                    <AssessmentIcon className='klayo_assessment-icon' />
                  </Tooltip>
                )}
              </PracticalNameSection>
            )
          });

        if (c.type === ParticipantPracticalTable.columns.competencies)
          this.columns.push({
            field: "numberOfAttributes",
            headerName: c.label || c.type.label,
            sortable: sortable,
            visibleFromBreakpoint: c.visibleFromBreakpoint || c.type.visibleFromBreakpoint,
            filterable: false,
            disableReorder: true,
            width: 120,
            headerAlign: "left",
            align: "left",
            valueGetter: (params) => params.row.numberOfAttributes
          });

        if (c.type === ParticipantPracticalTable.columns.participants)
          this.columns.push({
            field: "numberOfParticipants",
            headerName: c.label || c.type.label,
            sortable: sortable,
            visibleFromBreakpoint: c.visibleFromBreakpoint || c.type.visibleFromBreakpoint,
            filterable: false,
            disableReorder: true,
            width: 120,
            headerAlign: "left",
            align: "left",
            valueGetter: (params) => params.row.numberOfParticipants
          });

        if (c.type === ParticipantPracticalTable.columns.status)
          this.columns.push({
            field: "status",
            headerName: c.label || c.type.label,
            sortable: sortable,
            visibleFromBreakpoint: c.visibleFromBreakpoint || c.type.visibleFromBreakpoint,
            filterable: false,
            disableReorder: true,
            width: 170,
            headerAlign: "left",
            align: "left",
            valueGetter: (params) => params.row.status
          });
      }
    });
  }
}

const PracticalNameSection = styled.div`
  display: flex;
  gap: 8px;
  align-items: center;

  .klayo_assessment-icon {
    color: ${KLAYO_COLORS.GreyGR400};

    :hover {
      color: ${KLAYO_COLORS.NeonBlueNB500};
    }
  }
`;
