import styled from "@emotion/styled";
import { Component } from "react";
import { Dialog } from "../../components/dialogs/Dialog";
import { SingleColumnTable } from "../../components/table/SingleColumnTable";
import { TRAINING_ATTRIBUTE_DIALOG_TYPE } from "../../constants";
import { KLAYO_COLORS } from "../../themes";

export class TrainingAttributesDialog extends Component {
  constructor(props) {
    super(props);
  }

  onAttributeSelect(e, attribute) {
    const { history } = this.props;
    history.push("/settings/competencies/edit/" + attribute.attributeDefinitionId);
  }

  render() {
    const { theme, onClose, associatedAttributeDetail, type } = this.props;

    const dialogText =
      type === TRAINING_ATTRIBUTE_DIALOG_TYPE.DETAIL
        ? "course is linked to the following competencies:"
        : type === TRAINING_ATTRIBUTE_DIALOG_TYPE.WARNING
          ? "course is mapped to competencies that do not have expiry date turned on. To allow recurrence for this course please update the following competencies"
          : "";
    const dialogTitle =
      type === TRAINING_ATTRIBUTE_DIALOG_TYPE.DETAIL
        ? "Linked competencies"
        : type === TRAINING_ATTRIBUTE_DIALOG_TYPE.WARNING
          ? "Edit course recurrence"
          : "";
    const attributeWithoutExpiredDate = associatedAttributeDetail?.associatedAttribute.filter(
      (attribute) => attribute.hasExpiryDate === false
    );
    const attributeData =
      associatedAttributeDetail && type === TRAINING_ATTRIBUTE_DIALOG_TYPE.DETAIL
        ? associatedAttributeDetail.associatedAttribute
        : associatedAttributeDetail && type === TRAINING_ATTRIBUTE_DIALOG_TYPE.WARNING
          ? attributeWithoutExpiredDate
          : null;

    return (
      <StyledTrainingAttributesDialog
        className='klayo__training-attribute-dialog'
        open={true}
        theme={theme}
        onClose={onClose}
        fullWidth
        maxWidth='sm'
        actions={[
          {
            label: "Close",
            variant: "filled",
            onClick: onClose
          }
        ]}
      >
        <h1>{dialogTitle}</h1>
        <h2>
          <span className='main-title'>{associatedAttributeDetail.name} </span>
          {dialogText}
        </h2>

        <SingleColumnTable
          showFooterRowCount={false}
          pagination={false}
          rowHasAction={true}
          minHeight='56px'
          theme={theme}
          showHeaderFromBreakpoint='md'
          hideFirstLastBorder={true}
          onAttributeSelect={this.onAttributeSelect.bind(this)}
          dense={true}
          paginationMode='client'
          columns={[{ type: SingleColumnTable.columns.attributes }]}
          paper={false}
          rows={attributeData ? attributeData : null}
        />
      </StyledTrainingAttributesDialog>
    );
  }
}

const StyledTrainingAttributesDialog = styled(Dialog)`
  &.klayo__training-attribute-dialog {
    h1 {
      color: ${KLAYO_COLORS.GreyGR900};
      font-family: Inter;
      font-size: 24px;
      font-style: normal;
      font-weight: 600;
      line-height: 32px; /* 133.333% */
      letter-spacing: -0.5px;
      margin-top: 0px;
      margin-bottom: 32px;
    }

    h2 {
      color: ${KLAYO_COLORS.GreyGR900};
      font-family: Inter;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 20px;
      margin-top: 0px;
      margin-bottom: 24px;
    }

    .main-title {
      font-weight: 700;
    }

    .MuiDialogContent-root {
      padding: 40px 40px 0px 40px;
    }

    .MuiPaper-root {
      max-width: 640px;
    }
  }
`;
