import { Grid } from "@mui/material";
import { AttributeSelector } from "../../components/selectors/AttributeSelector";
import { ProficiencySelector } from "../../components/selectors/ProficiencySelector";
import { AppContext } from "../../common/AppContext";
import { RequestActivityFeed } from "../../components/RequestActivityFeed";
import { AbstractDialog } from "../../components/AbstractDialog";
import { FeedbackAttribute } from "../../data/attributes/FeedbackAttribute";
import { Data } from "../../data/Data";
import { ErrorModel } from "../../common/models/ErrorModel";
import { ConfirmationDialog } from "klayowebshared";
import { TextField } from "../../components/TextField";
import { Alert } from "../../components/Alert";
import { DatePicker } from "../../components/DatePicker";
import { Snackbar } from "../../components/Snackbar";
import { Dialog } from "../../components/dialogs/Dialog";
import { unFocusTableRowOnClose } from "../../utilities";

const axios = require("axios").default;

export class ReviewFeedbackDialog extends AbstractDialog {
  static contextType = AppContext;

  static mode = {
    add: {
      title: "Add new competency request",
      hash: "add"
    },
    proficiencyChange: {
      title: "Request changes",
      hash: "edit"
    },
    feedback: {
      hash: "edit"
    }
  };

  constructor(props) {
    super(props);

    this.state = {
      ...this.state,
      error: null,
      attribute: null,
      downloadingDoc: null,
      originalProficiency: 0,
      mode: ReviewFeedbackDialog.mode.add,
      showConfirmClose: false,
      comments: "",
      commentHasChange: false,
      newProficiency: 0,
      requestedProficiency: 0,
      lastMode: null,
      newExpiry: null
    };
  }

  loadAttribute() {
    const { requestId, attributes } = this.props;

    if (!attributes) return;
    this.setLoading("loadAttribute", true);

    attributes
      .fetchAttributeByEmployeeAttributeId(this.context, requestId)
      .then((attribute) => {
        this.setState({
          mode:
            attribute.type === FeedbackAttribute.type.newAttribute
              ? ReviewFeedbackDialog.mode.add
              : ReviewFeedbackDialog.mode.proficiencyChange
        });

        attribute
          .loadRequestActivity(this.context)
          .then((activity) => {
            this.setState({
              attribute: attribute,
              originalProficiency: attribute.currentProficiencyValue,
              requestedProficiency: attribute.proficiencyValue,
              newProficiency: attribute.proficiencyValue,
              newExpiry: attribute.expiryDate
            });
          })
          .catch((e) => {
            alert(e.message);
          })
          .finally(() => this.setLoading("loadAttribute", false));
      })
      .catch((e) => {
        //this.props.onClose();
      });
  }

  componentDidMount() {
    this.loadAttribute();
  }

  componentDidUpdate(prevProps) {
    if (this.props.attributes !== prevProps.attributes) this.loadAttribute();
  }

  onDocumentClick(e, doc) {
    this.setLoading("downloadingDoc", true);
    this.setState({ downloadingDoc: doc });
    //window.open(Data.apiBasePath + '/EmployeeAttribute/download/' + doc.documentId, '_blank');

    Data.call("get", "/EmployeeAttribute/download/" + doc.documentId, { responseType: "blob" })
      .then((response) => {
        const blob = new Blob([response.data], { type: response.headers["content-type"] });

        const url = window.URL.createObjectURL(blob);
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", doc.documentName);
        document.body.appendChild(link);
        link.click();
      })
      .catch((e) => {
        this.setState({ error: ErrorModel.parseServerError(e) });
      })
      .finally(() => this.setLoading("downloadingDoc", false));
  }

  onDocumentDownloadSnackbarClose() {
    this.setState({ downloadingDoc: null });
  }

  onProficiencyChange(e, proficiency) {
    this.setState({ newProficiency: proficiency });
  }

  onExpiryDateChange(date, valid) {
    this.setState({ newExpiry: date });
  }

  onAccept(e) {
    this.setLoading("accept", true);

    axios
      .post(
        Data.apiBasePath + "/EmployeeValidationRequest/Accept",
        {
          employeeAttributeValidationRequestId:
            this.state.attribute.employeeAttributeValidationRequestId
        },
        {
          withCredentials: true
        }
      )
      .then((response) => {
        this.props.onClose(true);
      })
      .catch((e) => {
        this.setState({ error: ErrorModel.parseServerError(e) });
      })
      .finally(() => this.setLoading("accept", false));
  }

  onRequestChange(e) {
    this.setState({ lastMode: this.state.mode, mode: ReviewFeedbackDialog.mode.feedback });
  }

  hasChanges() {
    const { organization } = this.props;
    const { attribute, mode, requestedProficiency, newProficiency, comments, commentHasChange } =
      this.state;

    const commentsChange = commentHasChange;
    const hasProficiency =
      organization && organization.showProficiency && attribute && attribute.hasProficiency;
    const profChange = requestedProficiency !== newProficiency;

    return (hasProficiency && profChange) || commentsChange;
  }

  onSendFeedback(e) {
    const { attribute, newProficiency, newExpiry, comments } = this.state;

    this.setLoading("accept", true);

    axios
      .post(
        Data.apiBasePath + "/EmployeeValidationRequest/RequestChange",
        {
          employeeAttributeValidationRequestId: attribute.employeeAttributeValidationRequestId,
          proficiencyValue: newProficiency,
          expiryDate: newExpiry,
          commentText: comments
        },
        {
          withCredentials: true
        }
      )
      .then((response) => {
        this.props.onClose(true);
      })
      .catch((e) => {
        this.setState({ error: ErrorModel.parseServerError(e) });
      })
      .finally(() => this.setLoading("accept", false));
  }

  onCommentsChange(e) {
    this.setState({ comments: e.target.value, commentHasChange: true });
  }

  onClose() {
    const { readOnly } = this.props;

    unFocusTableRowOnClose();
    if (this.hasChanges() && !readOnly) this.setState({ showConfirmClose: true });
    else this.props.onClose(false);
  }

  onCancelClose() {
    this.setState({ showConfirmClose: false });
  }

  onAcceptClose() {
    this.setState({ showConfirmClose: false });
    this.props.onClose(false);
  }

  render() {
    const { organization, user, employee, theme, readOnly } = this.props;
    const {
      error,
      attribute,
      downloadingDoc,
      originalProficiency,
      newProficiency,
      mode,
      lastMode,
      showConfirmClose,
      isLoading,
      comments,
      newExpiry
    } = this.state;

    const allowSubmit = !isLoading;

    const actions = [];

    if (!readOnly) {
      if (mode !== ReviewFeedbackDialog.mode.feedback)
        actions.push({
          label: "Accept",
          variant: "filled",
          onClick: this.onAccept.bind(this),
          disabled: isLoading
        });

      if (mode !== ReviewFeedbackDialog.mode.feedback)
        actions.push({
          label: "Request changes",
          variant: "filled",
          onClick: this.onRequestChange.bind(this),
          disabled: isLoading
        });

      if (mode === ReviewFeedbackDialog.mode.feedback)
        actions.push({
          label: "Send feedback",
          variant: "filled",
          onClick: this.onSendFeedback.bind(this),
          disabled: !allowSubmit
        });
    }

    actions.push({
      label: readOnly ? "Close" : "Cancel",
      autoFocus: false,
      variant: readOnly ? "filled" : "outlined",
      onClick: this.onClose.bind(this),
      disabled: isLoading
    });

    return (
      <Dialog
        open={true}
        theme={theme}
        onClose={this.onClose.bind(this)}
        fullWidth={true}
        title={mode === ReviewFeedbackDialog.mode.feedback ? lastMode.title : mode.title}
        maxWidth='sm'
        actions={actions}
      >
        {this.renderLoadingBar()}

        <Grid container spacing={4}>
          {error && (
            <Grid item xs={12} md={12} lg={12}>
              <Alert severity='error'>{error}</Alert>
            </Grid>
          )}
          <Grid item xs={12} md={12} lg={12}>
            <AttributeSelector attribute={attribute} allowSelection={false} />
          </Grid>

          {organization &&
            organization.showProficiency &&
            attribute &&
            attribute.hasProficiency && (
              <Grid item xs={12} md={12} lg={12}>
                <ProficiencySelector
                  label='Proficiency (required)'
                  disabled={isLoading || mode !== ReviewFeedbackDialog.mode.feedback}
                  proficiency={newProficiency}
                  originalProficiency={
                    lastMode !== ReviewFeedbackDialog.mode.add ? originalProficiency : 0
                  }
                  forceShowOriginal={mode !== ReviewFeedbackDialog.mode.add}
                  onChange={this.onProficiencyChange.bind(this)}
                />
              </Grid>
            )}

          {attribute && attribute.hasExpiryDate && (
            <Grid item xs={12} md={12} lg={12}>
              <DatePicker
                theme={theme}
                disabled={true} //isLoading || mode !== ReviewFeedbackDialog.mode.feedback}
                label='Expiry date'
                onChange={this.onExpiryDateChange.bind(this)}
                value={newExpiry ? new Date(newExpiry) : null}
                allowPast={false}
                allowEmpty={true}
              />
            </Grid>
          )}

          {mode === ReviewFeedbackDialog.mode.feedback && (
            <Grid item xs={12} md={12} lg={12}>
              <TextField
                id='klaro_attrchange_comments'
                label='Comments (optional)'
                multiline
                disabled={user === null}
                rows={4}
                placeholder='Add any comments or notes'
                sx={{ width: "100%" }}
                value={comments}
                InputLabelProps={{ shrink: true }}
                onChange={this.onCommentsChange.bind(this)}
              />
            </Grid>
          )}

          {attribute && attribute.requestActivity && (
            <RequestActivityFeed
              sx={{ marginTop: "-20px" }}
              user={user}
              employee={employee}
              activity={attribute.requestActivity}
              onDocumentClick={this.onDocumentClick.bind(this)}
            />
          )}
        </Grid>

        {showConfirmClose && (
          <ConfirmationDialog
            title='Cancel?'
            theme={theme}
            question='Are you sure you want to cancel this feedback?'
            cancelButton='No'
            acceptButton='Yes'
            onCancel={this.onCancelClose.bind(this)}
            onAccept={this.onAcceptClose.bind(this)}
          />
        )}

        {downloadingDoc && (
          <Snackbar
            open={true}
            duration={6000}
            type='info'
            onClose={this.onDocumentDownloadSnackbarClose.bind(this)}
            message={"Downloaded document " + downloadingDoc.documentName}
          />
        )}
      </Dialog>
    );
  }
}
