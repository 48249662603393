import { Data } from "../Data";
import { User } from "./User";
import { TeamAttributeList } from "../attributes/TeamAttributeList";
import { TeamGapList } from "../attributes/TeamGapList";

export class TeamData extends Data {
  static instance = {};

  constructor(options) {
    super({
      api:
        (options.groupId ? "/MyGroup/" + options.groupId : "/Teams") +
        "?" +
        (options.filterModel === undefined ? "" : options.filterModel.toApiUrlParams()),
      callback: (data) => {
        options.resolve(this, false);
      },
      ...options
    });

    this.employees = [];
    this.classificationsSummary = {};
    this.jobs = [];
    this.locations = [];
    this.isInstructor = false;
  }

  onDataReceived(data) {
    this.employees = [];
    this.summary = data.teamSummary;
    this.classificationsSummary = {};
    this.jobs = [];
    this.locations = [];

    data.allEmployees.forEach((e, index) => {
      this.employees.push(new User({ data: { id: e.employeeId, ...e } }));

      if (this.jobs.indexOf(e.jobTitle) === -1) this.jobs.push(e.jobTitle);
      if (this.locations.indexOf(e.location) === -1) this.locations.push(e.location);

      const classification = this.classificationsSummary[e.jobTitle];
      if (classification) classification.employees++;
      else
        this.classificationsSummary[e.jobTitle] = {
          id: Object.keys(this.classificationsSummary).length,
          classification: e.jobTitle,
          employees: 1
        };
    });

    this.attributes = new TeamAttributeList({ data: data.teamAttributeSummary });
    this.gaps = new TeamGapList({ data: data.teamGapAttributeSummary });
    this.isInstructor = data.isInstructor;
  }

  getEmployee(id) {
    if (!this.employees) return null;
    return this.employees.find((a) => a.employeeId === id);
  }

  sortEmployees(sortedIds) {
    this.employees.sort(
      (a, b) => sortedIds.indexOf(a.employeeId) - sortedIds.indexOf(b.employeeId)
    );
  }

  static getInstanceKey(groupId) {
    return groupId ? groupId : "team"; //todo filter caching
  }

  static get(context, filterModel, groupId) {
    return new Promise((resolve, reject) => {
      const instKey = TeamData.getInstanceKey(groupId);

      if (!filterModel || !filterModel.isFiltered()) {
        if (TeamData.instance[instKey]) resolve(TeamData.instance[instKey], true);
        else TeamData.instance[instKey] = new TeamData({ resolve, reject, context, groupId });
      } else {
        new TeamData({ resolve, reject, filterModel, context, groupId });
      }
    });
  }
}
