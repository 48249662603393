import { AbstractAttributeList } from "./AbstractAttributeList";
import { AttributeDefinition } from "./AttributeDefinition";

export class AttributeDefinitionList extends AbstractAttributeList {
  static instance = null;

  constructor(options) {
    super({
      api: "/AttributeDefinition?includeParentAttributes=true",
      callback: (data) => {
        options.resolve(this, false);
      },
      ...options
    });
  }

  onDataReceived(data) {
    data.attributeDefinitions.forEach((attr, index) => {
      if (!this.findMatch(attr.name)) this.attributes.push(new AttributeDefinition(index, attr));
    });
  }

  static get(context) {
    return new Promise((resolve, reject) => {
      if (AttributeDefinitionList.instance) resolve(AttributeDefinitionList.instance, true);
      else
        AttributeDefinitionList.instance = new AttributeDefinitionList({
          resolve,
          reject,
          context
        });
    });
  }

  findMatch(attr) {
    return this.attributes.find((a) => a.attribute === attr);
  }
}
