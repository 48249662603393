import { Component } from "react";
import { AbstractAttributeList } from "../../data/attributes/AbstractAttributeList";

export class AbstractProfileSection extends Component {
  constructor(props) {
    super(props);

    this.state = {
      filter: AbstractAttributeList.allFilter,
      search: null,
      attribute: null
    };

    this.actionTarget = null;
    this.actionAttribute = null;

    this.setMenuRef = (menu) => {
      this.menu = menu;
    };
  }

  onFilterChange(filter) {
    this.setState({ filter: filter });
  }

  onSearch(search) {
    this.setState({ search: search });
  }

  onAttributeAction(e, attr) {
    if (this.menu) this.menu.open(e.target);
    this.actionTarget = e.target;
    this.actionAttribute = attr;
  }

  closeActionMenus() {
    this.actionTarget = null;
    this.actionAttribute = null;
    if (this.menu) this.menu.close();
  }
}
