import { Table } from "./Table";
import { ReactComponent as MoreVertIcon } from "../../resources/images/icons-more-vertical.svg";
import { DataGridPro, GridActionsCellItem } from "@mui/x-data-grid-pro";

export class AddressTable extends Table {
  static columns = {
    name: {
      field: "street",
      label: "Name"
    },
    actions: {
      field: "actions",
      label: "Actions"
    }
  };

  static defaultProps = {
    ...Table.defaultProps,
    columns: [{ type: AddressTable.columns.name }, { type: AddressTable.columns.actions }],
    noRowsMessage: null,
    rowHasAction: null
  };

  constructor(props) {
    super(props);
  }

  getRowId(e) {
    return this.tableId + "_" + e.locationId;
  }

  initColumns() {
    const { columns, sortable, onAddressAction } = this.props;

    this.columns = [];

    columns.forEach((c) => {
      if (this.hasColumn(c)) {
        if (c.type === AddressTable.columns.name)
          this.columns.push({
            field: "street",
            sortable: sortable,
            headerName: c.label,
            flex: true,
            filterable: false,
            disableReorder: true
          });
      }

      if (c.type === AddressTable.columns.actions)
        this.columns.push({
          field: "actions",
          type: "actions",
          filterable: false,
          disableReorder: true,
          align: "right",
          getActions: (params) => [
            <GridActionsCellItem
              icon={<MoreVertIcon />}
              label='Actions'
              onClick={(e) => this.props.onAddressAction(e, params.row)}
              key={Math.random().toString(36).substring(7)}
            />
          ]
        });
    });
  }
}
