import { Snackbar as MuiSnackbar, Stack } from "@mui/material";
import { React, Component } from "react";
import { ReactComponent as SuccessIcon } from "../resources/images/icons-check-circle.svg";
import { ReactComponent as ErrorIcon } from "../resources/images/icons-warning-circle.svg";
import { ReactComponent as InfoIcon } from "../resources/images/icons-info-circle.svg";

export class Snackbar extends Component {
  static defaultProps = {
    type: "success",
    duration: 6000
  };

  onClose(e, reason) {
    const { onClose } = this.props;
    if (onClose && reason === "timeout") onClose(e);
  }

  render() {
    const { type, open, message, duration, onClose } = this.props;

    return (
      <MuiSnackbar
        className={"klayo-snackbar klayo-snackbar_" + type}
        open={open}
        autoHideDuration={duration}
        onClose={this.onClose.bind(this)}
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
      >
        <Stack spacing={4}>
          <div className='klayo-snackbar_icon'>
            {type === "success" && <SuccessIcon />}
            {type === "error" && <ErrorIcon />}
            {type === "info" && <InfoIcon />}
          </div>
          <div className='klayo-snackbar_text'>{message}</div>
        </Stack>
      </MuiSnackbar>
    );
  }
}
