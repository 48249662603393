export const KLAYO_COLORS = {
  transparent: "transparent",
  PrussianBluePB900: "#002a5b",
  PrussianBluePB800: "#073b72",
  PrussianBluePB700: "#10447f",
  PrussianBluePB600: "#1c4e8a",
  PrussianBluePB500: "#245693",
  PrussianBluePB400: "#4b6ea1",
  PrussianBluePB300: "#6c87b0",
  PrussianBluePB200: "#95a9c7",
  PrussianBluePB100: "#bfcade",
  PrussianBluePB050: "#e6eaf0",
  NeonBlueNB900: "#0000b6",
  NeonBlueNB800: "#0017cf",
  NeonBlueNB700: "#0027da",
  NeonBlueNB600: "#1634e6",
  NeonBlueNB500: "#1b3df2",
  NeonBlueNB400: "#4d5ef7",
  NeonBlueNB300: "#727df9",
  NeonBlueNB200: "#9ea2fa",
  NeonBlueNB100: "#c6c7fc",
  NeonBlueNB050: "#e9e9fe",
  BrightTurquoiseBT900: "#006d6f",
  BrightTurquoiseBT800: "#00919f",
  BrightTurquoiseBT700: "#00a6b8",
  BrightTurquoiseBT600: "#00bbd4",
  BrightTurquoiseBT500: "#00cce8",
  BrightTurquoiseBT400: "#00d5eb",
  BrightTurquoiseBT300: "#00dff0",
  BrightTurquoiseBT200: "#19ebf5",
  BrightTurquoiseBT100: "#98f2f8",
  BrightTurquoiseBT050: "#d7fafc",
  GoldGD900: "#f67300",
  GoldGD800: "#f89f00",
  GoldGD700: "#f9b800",
  GoldGD600: "#fad200",
  GoldGD500: "#f8e100",
  GoldGD400: "#fbe63f",
  GoldGD300: "#fceb66",
  GoldGD200: "#fdf193",
  GoldGD100: "#fef7be",
  GoldGD050: "#fffce5",
  TouchRedTR900: "#c40003",
  TouchRedTR800: "#d20014",
  TouchRedTR700: "#de001d",
  TouchRedTR600: "#f00022",
  TouchRedTR500: "#ff151f",
  TouchRedTR400: "#fa3941",
  TouchRedTR300: "#f06568",
  TouchRedTR200: "#f79293",
  TouchRedTR100: "#ffc9cf",
  TouchRedTR050: "#ffe9ed",
  DodgerBlueDB900: "#0d47a1",
  DodgerBlueDB800: "#1565c0",
  DodgerBlueDB700: "#1976d2",
  DodgerBlueDB600: "#1e88e5",
  DodgerBlueDB500: "#2196f3",
  DodgerBlueDB400: "#42a5f5",
  DodgerBlueDB300: "#64b5f6",
  DodgerBlueDB200: "#90caf9",
  DodgerBlueDB100: "#bbdefb",
  DodgerBlueDB050: "#e3f2fd",
  LimeGreenLG900: "#007209",
  LimeGreenLG800: "#009420",
  LimeGreenLG700: "#1ea92b",
  LimeGreenLG600: "#3dbd35",
  LimeGreenLG500: "#4ece3d",
  LimeGreenLG400: "#6ed65c",
  LimeGreenLG300: "#8cde7b",
  LimeGreenLG200: "#afe8a2",
  LimeGreenLG100: "#cff1c7",
  LimeGreenLG050: "#ecfae9",
  WhiteWH900: "#fff",
  GreyGR900: "#111827",
  GreyGR800: "#1f2937",
  GreyGR700: "#374151",
  GreyGR600: "#4b5563",
  GreyGR500: "#6b7280",
  GreyGR400: "#9ca3af",
  GreyGR300: "#d1d5db",
  GreyGR200: "#e5e7eb",
  GreyGR100: "#f3f4f6",
  GreyGR050: "#f9fafb",
  RedWarning: "#FF0000"
};
