import styled from "@emotion/styled";
import { ListItemText, Menu, MenuItem, Stack } from "@mui/material";
import { GridActionsCellItem, useGridApiRef } from "@mui/x-data-grid-pro";
import { ConfirmationDialog } from "klayowebshared";
import { debounce } from "lodash";
import { useCallback, useEffect, useRef, useState } from "react";
import { Utils } from "../../common/Utils";
import { ErrorModel } from "../../common/models/ErrorModel";
import { Button } from "../../components/Button";
import { Paper } from "../../components/Paper";
import { SectionLoader } from "../../components/SectionLoader";
import { Snackbar } from "../../components/Snackbar";
import { TablePlaceholder } from "../../components/TablePlaceholder";
import { TextField } from "../../components/TextField";
import { RegulationsTable } from "../../components/table/RegulationsTable";
import { CREATE_TASK_MODE, REGULATION_ATTRIBUTE_TYPE } from "../../constants";
import { ReactComponent as AddIcon } from "../../resources/images/icons-add.svg";
import { ReactComponent as AttributesIcon } from "../../resources/images/icons-attribute.svg";
import { ReactComponent as ChevronDownWhiteIcon } from "../../resources/images/icons-chevron-down-white.svg";
import { ReactComponent as MoreVertIcon } from "../../resources/images/icons-more-vertical.svg";
import { ReactComponent as SearchIcon } from "../../resources/images/icons-search.svg";
import { CreateRegulationCategoryDialog } from "../../views/settings/CreateRegulationCategoryDialog";
import { CreateRegulationDomainDialog } from "../../views/settings/CreateRegulationDomainDialog";
import { KLAYO_COLORS } from "../../themes";

export function SettingsRegulationsView(props) {
  const {
    history,
    theme,
    user,
    onGetListRegulationDomain,
    onGetListRegulationCategory,
    onGetListRegulationByCategoryId,
    listRegulationDomain,
    listAllRegulationDomain,
    onGetListRegulationRegion
  } = props;

  const [selectedRowElement, setSelectedRowElement] = useState(null);
  const [search, setSearch] = useState("");
  const [domainAndCategoryActionMenuElement, setDomainAndCategoryActionMenuElement] =
    useState(null);
  const [isShowRegulationDomainDialog, setIsShowRegulationDomainDialog] = useState(false);
  const [createRegulationDomainMode, setCreateRegulationDomainMode] = useState(null);
  const [selectedDomain, setSelectedDomain] = useState(null);
  const [isShowRegulationCategoryDialog, setIsShowRegulationCategoryDialog] = useState(false);
  const [createRegulationCategoryMode, setCreateRegulationCategoryMode] = useState(null);
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [regulationDomainError, setRegulationDomainError] = useState(null);
  const [regulationCategoryError, setRegulationCategoryError] = useState(null);
  const [selectedRegion, setSelectedRegion] = useState(null);
  const [selectedRow, setSelectedRow] = useState(null);
  const [isDeleteRow, setIsDeleteRow] = useState(false);
  const [deletingRowError, setDeletingRowError] = useState(null);
  const [loading, setLoading] = useState(false);
  const [messageSnackbar, setMessageSnackbar] = useState(null);
  const [isExistRegulationDomain, setIsExistRegulationDomain] = useState(false);
  const [initialRows, setInitialRows] = useState([]);

  const apiRef = useGridApiRef();
  const searchRef = useRef("");

  useEffect(() => {
    window.scroll({ top: 0, left: 0, behavior: "smooth" });
    getData(null, true);
    const handleRowExpansionChange = async (node) => {
      const row = apiRef.current.getRow(node.id);
      if (!node.childrenExpanded || !row || row.childrenFetched) {
        return;
      }
      if (row.type === REGULATION_ATTRIBUTE_TYPE.DOMAIN) {
        const regulationCategories = await onGetListRegulationCategory(row.id, searchRef.current);
        const childrenRows = regulationCategories.map((category) => ({
          ...category,
          hierarchy: [...row.hierarchy, category.name],
          type: REGULATION_ATTRIBUTE_TYPE.CATEGORY,
          descendantCount: category.numberOfRegulations || 0,
          domainRowId: row.id
        }));

        Utils.updateRows(apiRef, [
          ...childrenRows,
          { ...row, childrenFetched: true },
          { id: `placeholder-children-${node.id}`, _action: "delete" }
        ]);
      } else if (row.type === REGULATION_ATTRIBUTE_TYPE.CATEGORY) {
        const listRegulation = await onGetListRegulationByCategoryId(row.id, searchRef.current);
        if (listRegulation.length === 0) {
          Utils.updateRows(apiRef, [
            {
              ...row,
              childrenFetched: true,
              descendantCount: 0
            },
            { id: `placeholder-children-${node.id}`, _action: "delete" }
          ]);
          return;
        }
        const childrenRows = listRegulation.map((regulation) => ({
          ...regulation,
          hierarchy: [...row.hierarchy, regulation.name],
          type: REGULATION_ATTRIBUTE_TYPE.REGULATION,
          numberOfRegulationTasks: regulation.regulationTasks.length || 0
        }));
        Utils.updateRows(apiRef, [
          ...childrenRows,
          { ...row, childrenFetched: true },
          { id: `placeholder-children-${node.id}`, _action: "delete" }
        ]);
      }
    };
    return apiRef.current.subscribeEvent("rowExpansionChange", handleRowExpansionChange);
  }, [apiRef]);

  const getData = async (searchValue = "", isShowLoading = false) => {
    try {
      isShowLoading && setLoading(true);
      setInitialRows([]);
      const listRegulationRegion = await onGetListRegulationRegion();
      setSelectedRegion(listRegulationRegion[0]);
      const listRegulationDomain = await onGetListRegulationDomain(
        listRegulationRegion[0].id,
        searchValue
      );
      setIsExistRegulationDomain(listRegulationDomain.length > 0);
      const updateData = listRegulationDomain.map((domain) => ({
        ...domain,
        hierarchy: [domain.name],
        type: REGULATION_ATTRIBUTE_TYPE.DOMAIN,
        descendantCount: domain.numberOfCategories || 0
      }));
      setLoading(false);
      Utils.updateRows(apiRef, updateData);
    } catch (error) {
      setLoading(false);
    }
  };

  const functionDebounce = useCallback(
    debounce(async (value) => {
      getData(value);
    }, 500),
    []
  );

  const isNotGeneratedChildrenRow = (arrayChildrenRow) => {
    return arrayChildrenRow.some((id) => id.includes("placeholder-children"));
  };

  const handleCLickTag = (event, params) => {
    event.stopPropagation();
    if (params.row.numberOfRegulationTasks === 0) {
      return;
    }
    history.push(`/settings/regulations/edit/${params.row.id}?navigateStep=2`);
  };

  const initialColumns = (columns) => {
    let initialColumnList = [];
    if (columns && columns.length > 0) {
      columns.forEach((column) => {
        if (column.type === "name") {
          return;
        } else if (column.type === "actions") {
          const nameColumn = {
            ...column,
            filterable: false,
            disableReorder: true,
            align: "right",
            valueGetter: (params) => (params.row.deleted ? null : params),
            getActions: (params) =>
              params.row.deleted
                ? []
                : [
                    <GridActionsCellItem
                      icon={<MoreVertIcon />}
                      label='Actions'
                      onClick={(event) => {
                        event.stopPropagation();
                        setSelectedRow(params.row);
                        setSelectedRowElement(event.currentTarget);
                      }}
                      key={Math.random().toString(36).substring(7)}
                    />
                  ]
          };
          initialColumnList.push(nameColumn);
        } else if (column.type === "task") {
          initialColumnList.push({
            ...column,
            filterable: false,
            disableReorder: true,
            sortable: false,
            align: "right",
            valueGetter: (params) => params.row.numberOfRegulationTasks,
            cellClassName: "Klayo_associated-attribute-cell",
            renderCell: (params) => (
              <KlayoTag
                isTag={
                  params.row.numberOfRegulationTasks !== 0 &&
                  params.row.type === REGULATION_ATTRIBUTE_TYPE.REGULATION
                }
                onClick={(event) => handleCLickTag(event, params)}
              >
                {params.row.numberOfRegulationTasks}
              </KlayoTag>
            )
          });
        }
      });
    }
    return initialColumnList;
  };

  const renderRowActionMenu = () => {
    if (!selectedRow) return null;
    return (
      <div>
        <MenuItem
          onClick={() => {
            handleOnRowClick(selectedRow);
            setSelectedRowElement(null);
          }}
        >
          <ListItemText>Edit</ListItemText>
        </MenuItem>

        {selectedRow.type === REGULATION_ATTRIBUTE_TYPE.DOMAIN && (
          <MenuItem
            onClick={() => {
              onOpenCreateCategoryDialogFromRelatedDomain(selectedRow);
              setSelectedRowElement(null);
            }}
          >
            <ListItemText>Add Category</ListItemText>
          </MenuItem>
        )}

        {selectedRow.type === REGULATION_ATTRIBUTE_TYPE.CATEGORY && (
          <MenuItem
            onClick={() => {
              onOpenCreateRegulationFromRelatedCategory(selectedRow);
              setSelectedRowElement(null);
            }}
          >
            <ListItemText>Add Regulation</ListItemText>
          </MenuItem>
        )}

        <MenuItem onClick={onDeleteRow}>
          <ListItemText>Delete</ListItemText>
        </MenuItem>
      </div>
    );
  };

  const onDeleteRow = () => {
    setSelectedRowElement(null);
    setDeletingRowError(null);
    setIsDeleteRow(true);
  };

  const resetAllMessageError = () => {
    setRegulationDomainError(null);
    setRegulationCategoryError(null);
    setDeletingRowError(null);
  };

  const handleOnRowClick = (dataRow) => {
    switch (dataRow.type) {
      case REGULATION_ATTRIBUTE_TYPE.DOMAIN:
        onOpenUpdateDomainDialog(dataRow);
        break;

      case REGULATION_ATTRIBUTE_TYPE.CATEGORY:
        onOpenUpdateCategoryDialog(dataRow);
        break;

      case REGULATION_ATTRIBUTE_TYPE.REGULATION:
        onOpenUpdateRegulation(dataRow);
        break;

      default:
        break;
    }
  };

  const onOpenUpdateRegulation = (regulation) => {
    history.push(`/settings/regulations/edit/${regulation.id}`);
  };

  const onOpenCreateRegulationFromRelatedCategory = (category) => {
    history.push(`/settings/regulations/new?regulationCategoryId=${category.id}`);
  };

  const renderDomainAndCategoryActionMenu = () => {
    return (
      <div>
        <MenuItem onClick={onOpenCreateDomainDialog}>
          <ListItemText>New domain</ListItemText>
        </MenuItem>
        <MenuItem onClick={onOpenCreateCategoryDialog}>
          <ListItemText>New category</ListItemText>
        </MenuItem>
      </div>
    );
  };

  const onOpenCreateDomainDialog = () => {
    setIsShowRegulationDomainDialog(true);
    setCreateRegulationDomainMode(CREATE_TASK_MODE.CREATE);
    setDomainAndCategoryActionMenuElement(null);
    resetAllMessageError();
  };

  const onOpenUpdateDomainDialog = (domain) => {
    setIsShowRegulationDomainDialog(true);
    setCreateRegulationDomainMode(CREATE_TASK_MODE.EDIT);
    setSelectedDomain(domain);
    setDomainAndCategoryActionMenuElement(null);
    resetAllMessageError();
  };

  const onOpenCreateCategoryDialogFromRelatedDomain = (domain) => {
    setIsShowRegulationCategoryDialog(true);
    setCreateRegulationCategoryMode(CREATE_TASK_MODE.CREATE);
    setSelectedDomain(domain);
    setDomainAndCategoryActionMenuElement(null);
    resetAllMessageError();
  };

  const onOpenCreateCategoryDialog = () => {
    setIsShowRegulationCategoryDialog(true);
    setCreateRegulationCategoryMode(CREATE_TASK_MODE.CREATE);
    setDomainAndCategoryActionMenuElement(null);
    resetAllMessageError();
  };

  const onOpenUpdateCategoryDialog = (category) => {
    setIsShowRegulationCategoryDialog(true);
    setCreateRegulationCategoryMode(CREATE_TASK_MODE.EDIT);
    setSelectedCategory(category);
    setDomainAndCategoryActionMenuElement(null);
    resetAllMessageError();
  };

  const onCloseCreateDomainDialog = () => {
    setIsShowRegulationDomainDialog(false);
    setSelectedDomain(null);
  };

  const onCloseCreateCategoryDialog = () => {
    setIsShowRegulationCategoryDialog(false);
    setSelectedCategory(null);
  };

  const onSaveRegulationDomain = async (domain) => {
    const { onCreateRegulationDomain, onUpdateRegulationDomain } = props;
    try {
      if (createRegulationDomainMode === CREATE_TASK_MODE.CREATE) {
        const { regulationDomain } = await onCreateRegulationDomain(domain, selectedRegion.id);
        setIsExistRegulationDomain(true);
        // update new row to table
        const newRow = {
          ...regulationDomain,
          hierarchy: [regulationDomain.name],
          type: REGULATION_ATTRIBUTE_TYPE.DOMAIN,
          descendantCount: regulationDomain.numberOfCategories || 0
        };
        Utils.updateRows(apiRef, [newRow]);
      } else {
        const { regulationDomain } = await onUpdateRegulationDomain(domain, selectedDomain.id);
        const updatedRow = {
          ...regulationDomain,
          hierarchy: [regulationDomain.name],
          type: REGULATION_ATTRIBUTE_TYPE.DOMAIN,
          descendantCount: regulationDomain.numberOfCategories || 0
        };
        Utils.updateDomainRow(apiRef, updatedRow, getData);
      }
      onCloseCreateDomainDialog();
      setMessageSnackbar("Domain saved successfully");
    } catch (error) {
      setRegulationDomainError(ErrorModel.parseServerError(error));
    }
  };

  const onSaveRegulationCategory = async (domain, category) => {
    const { onCreateRegulationCategory, onUpdateRegulationCategory } = props;
    try {
      if (createRegulationCategoryMode === CREATE_TASK_MODE.CREATE) {
        const { regulationCategory } = await onCreateRegulationCategory(domain, category);
        const parentRow = apiRef.current.getRow(regulationCategory.regulationDomain.id);
        // update parentRow with new descendantCount
        parentRow.descendantCount = parentRow.descendantCount + 1;

        const newRow = {
          ...regulationCategory,
          hierarchy: [...parentRow.hierarchy, regulationCategory.name],
          type: REGULATION_ATTRIBUTE_TYPE.CATEGORY,
          descendantCount: regulationCategory.numberOfRegulations || 0,
          domainRowId: regulationCategory.regulationDomain.id
        };
        Utils.updateNewCategoryRowAndParent(apiRef, newRow, parentRow);
      } else {
        await onUpdateRegulationCategory(category, selectedCategory.id, domain);
        await getData();
      }
      onCloseCreateCategoryDialog();
      setMessageSnackbar("Category saved successfully");
    } catch (error) {
      setRegulationCategoryError(ErrorModel.parseServerError(error));
    }
  };

  const onCancelDeleteRegulation = () => {
    setIsDeleteRow(false);
  };

  const onDeleteRegulationConfirmed = async () => {
    const { onDeleteDomain, onDeleteCategory, onDeleteRegulation } = props;

    Utils.deleteRow(apiRef, selectedRow.id, selectedRow.type);
    if (Utils.getAllRowIds(apiRef).length === 0) {
      setIsExistRegulationDomain(false);
    }

    try {
      if (selectedRow.type === REGULATION_ATTRIBUTE_TYPE.DOMAIN) {
        await onDeleteDomain(selectedRow.id);
      } else if (selectedRow.type === REGULATION_ATTRIBUTE_TYPE.CATEGORY) {
        await onDeleteCategory(selectedRow.id);
      } else {
        await onDeleteRegulation(selectedRow.id);
      }
      setIsDeleteRow(false);
      setDeletingRowError(null);
      setSelectedRowElement(null);
      setMessageSnackbar("Row deleted successfully");
    } catch (e) {
      setDeletingRowError(ErrorModel.parseServerError(e));
    }
  };

  const onSearchValueChange = (event) => {
    event.preventDefault();
    setSearch(event.target.value);
    searchRef.current = event.target.value;
    functionDebounce(event.target.value);
  };

  const handleOnRowItemClick = (params, event) => {
    event.stopPropagation();
    handleOnRowClick(params.row);
    setSelectedRowElement(null);
  };

  const handleDeleteRowText = (selectedRow) => {
    const row = apiRef.current.getRow(selectedRow.id);
    if (row && row.type === REGULATION_ATTRIBUTE_TYPE.DOMAIN) {
      if (row.descendantCount > 0) {
        return `Please delete all categories first.`;
      } else {
        return `Are you sure you want to delete this domain? This operation can’t be undone.`;
      }
    } else if (row && row.type === REGULATION_ATTRIBUTE_TYPE.CATEGORY) {
      return `Are you sure you want to delete this category? This action will also delete all regulations within it.`;
    } else {
      return `Are you sure you want to delete this regulation? This operation can’t be undone.`;
    }
  };

  return (
    <div>
      <Paper
        theme={theme}
        padding={{ xs: "46px 24px", md: "60px!important" }}
        borderFromBreakpoint='md'
      >
        <h1>Regulations</h1>
        <div>
          <Stack
            direction='row'
            justifyContent='space-between'
            alignItems='center'
            spacing={2}
            sx={{ borderTop: "1px solid rgba(0, 0, 0, 0.12)", padding: "30px 0" }}
          >
            <TextField
              value={search}
              dense={true}
              placeholder='Search'
              disabled={user === null}
              fullWidth={true}
              autoComplete={false}
              leadingIcon={<SearchIcon />}
              onChange={onSearchValueChange}
              sx={{ maxWidth: { md: "300px" } }}
            />

            <div className='klaro_regulations-button-container'>
              <Button
                path={"/settings/regulations/new"}
                size='md'
                theme={theme}
                variant='filled'
                showLabelFromBreakpoint='md'
                startIcon={<AddIcon />}
                label='New regulation'
                className='klaro_regulations-button-add-regulation'
              />
              <div className='klaro_regulations-vertical-line'></div>
              <div className='klaro_regulations-domain-category'>
                <ChevronDownWhiteIcon
                  onClick={(event) => {
                    setDomainAndCategoryActionMenuElement(event.currentTarget);
                  }}
                />
              </div>
            </div>
          </Stack>

          {/* No row component */}

          {loading && <SectionLoader />}

          {!loading && !isExistRegulationDomain && search.length === 0 && (
            <TablePlaceholder
              text='Add your own + New regulation, or contact us to discuss importing your region’s regulations'
              icon={<AttributesIcon />}
            />
          )}

          {!loading && !isExistRegulationDomain && search.length !== 0 && (
            <TablePlaceholder text='No rows, yet' icon={<AttributesIcon />} />
          )}

          {/* Table */}

          <RegulationsTable
            ref={apiRef}
            initialRows={initialRows}
            initialColumns={initialColumns}
            theme={theme}
            handleOnRowItemClick={handleOnRowItemClick}
            showHeader={false}
            hideFirstLastBorder={false}
            tableStyle={
              loading ? "klaro_regulations-display-none" : "klaro_regulations-display-block"
            }
          />
        </div>

        {isShowRegulationDomainDialog && (
          <CreateRegulationDomainDialog
            user={user}
            employee={user}
            theme={theme}
            onClose={onCloseCreateDomainDialog}
            onSave={onSaveRegulationDomain}
            error={regulationDomainError}
            mode={createRegulationDomainMode}
            selectedDomain={selectedDomain}
          />
        )}

        {isShowRegulationCategoryDialog && (
          <CreateRegulationCategoryDialog
            user={user}
            employee={user}
            theme={theme}
            onClose={onCloseCreateCategoryDialog}
            onSave={onSaveRegulationCategory}
            error={regulationCategoryError}
            mode={createRegulationCategoryMode}
            listRegulationDomain={listAllRegulationDomain}
            selectedCategory={selectedCategory}
            selectedDomain={selectedDomain}
          />
        )}

        {isDeleteRow && (
          <ConfirmationDialog
            theme={theme}
            title={`Delete ${
              selectedRow.type === REGULATION_ATTRIBUTE_TYPE.DOMAIN
                ? "domain"
                : selectedRow.type === REGULATION_ATTRIBUTE_TYPE.CATEGORY
                  ? "category"
                  : "regulation"
            }`}
            question={<div>{handleDeleteRowText(selectedRow)}</div>}
            acceptButton='Delete'
            acceptDanger={true}
            error={deletingRowError}
            onCancel={onCancelDeleteRegulation}
            onAccept={onDeleteRegulationConfirmed}
            isNotShowAcceptButton={
              selectedRow.type === REGULATION_ATTRIBUTE_TYPE.DOMAIN &&
              selectedRow.descendantCount > 0
            }
          />
        )}

        <StyledMenu
          open={domainAndCategoryActionMenuElement !== null}
          anchorEl={domainAndCategoryActionMenuElement}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "right"
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "right"
          }}
          onClose={() => {
            setDomainAndCategoryActionMenuElement(null);
          }}
        >
          {renderDomainAndCategoryActionMenu()}
        </StyledMenu>

        <StyledMenu
          open={selectedRowElement !== null}
          anchorEl={selectedRowElement}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "right"
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "right"
          }}
          onClose={() => setSelectedRowElement(null)}
        >
          {renderRowActionMenu()}
        </StyledMenu>

        {messageSnackbar && (
          <Snackbar
            open={true}
            duration={5000}
            onClose={() => setMessageSnackbar(null)}
            type='success'
            message={messageSnackbar}
          />
        )}
      </Paper>
    </div>
  );
}

const StyledMenu = styled(Menu)`
  .MuiMenu-paper {
    margin-top: 10px !important;
  }
`;

const KlayoTag = styled.div`
  padding: 2px 12px;
  background-color: ${(props) =>
    props.isTag ? KLAYO_COLORS.NeonBlueNB050 : KLAYO_COLORS.transparent};
  border-radius: 100px;

  color: ${(props) => (props.isTag ? KLAYO_COLORS.NeonBlueNB500 : KLAYO_COLORS.GreyGR900)};
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;

  :hover {
    filter: ${(props) => (props.isTag ? "brightness(95%)" : "")};
    cursor: ${(props) => (props.isTag ? "pointer" : "")};
  }

  :focus {
    background-color: ${(props) => (props.isTag ? "#c6c7fc" : "")};
  }
`;
