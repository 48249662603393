import { Utils } from "klayowebshared";
import { User } from "./User";

export class OrgChartEmployee extends User {
  constructor(employee, jobTitle, reportsTo) {
    super({ data: employee });

    this.id = employee.employeeId;
    this.isMyTeam = employee.isMyTeam;
    this.isVacancy = employee.isVacancy;
    this.type = "person";
    this.jobTitle = jobTitle;
    this.parentId = reportsTo ? reportsTo : "org-root-job";
    this.highlight = false;
    this.hasJob = true;
  }
}
