export class SettingsAttributeCategory {
  constructor(data) {
    if (data) {
      this.categoryId = data.categoryId || data.attributeCategoryId;
      this.name = data.name;
      this.numberOfAttributeDefinitions = data.numberOfAttributeDefinitions;
      this.isInUsed = data.numberOfAttributeDefinitions !== 0;
    }
  }
}
