import React from "react";
import { AbstractView } from "../AbstractPage";
import { AppContext } from "../../common/AppContext";
import { App } from "../../App";
import { View } from "../../components/View";
import { CircularProgress, Stack, Box } from "@mui/material";
import { ViewHeading } from "../../components/typography/ViewHeading";
import { OrgUserList } from "../../data/users/OrgUserList";
import { OrgChart } from "klayowebshared";
import { EmployeeProfileDialog } from "../profile/EmployeeProfileDialog";
import { TextField } from "../../components/TextField";
import { AppDrawer } from "../../components/AppDrawer";

export class CompanyView extends AbstractView {
  static contextType = AppContext;

  constructor(props) {
    super(props, App.pages.company);

    this.state = {
      data: null,
      height: this.getChartHeight(),
      centerOn: props.user ? props.user.employeeId : null,
      search: null
    };

    this.chartRef = React.createRef();
    window.addEventListener("resize", this.onWindowResize.bind(this));
  }

  componentDidMount() {
    super.componentDidMount();
    this.loadData();
  }

  componentDidUpdate(prevProps) {
    if (this.props.organization !== prevProps.organization) this.forceUpdate();
    if (this.props.showBillingBanner !== prevProps.showBillingBanner) this.forceUpdate();
  }

  onWindowResize(e) {
    this.setState({ height: this.getChartHeight() });
  }

  getChartHeight() {
    const { theme, showBillingBanner } = this.props;
    const breakpoint = theme.getCurrentBreakpoint()[0];
    return window.innerHeight - View.topPadding[breakpoint] - (showBillingBanner ? 44 : 0);
  }

  onHasUser(user) {
    this.centerOnCurrentUser();

    this.setState({ centerOn: user.employeeId });
  }

  centerOnCurrentUser() {
    //const { user } = this.props;
    //if(user && this.chartRef) this.chartRef.current.centerOn(user.employeeId);
  }

  loadData() {
    const { organization, dataVersion } = this.props;

    this.context.setLoading("employees", true);

    OrgUserList.get(this.context, dataVersion)
      .then((r) => {
        this.setState({ data: r.data }, () => {
          this.centerOnCurrentUser();
        });
      })
      .catch((error) => {})
      .finally(() => this.context.setLoading("employees", false));
  }

  getEmployeeIdFromPath() {
    const pathSplit = this.props.location.pathname.split("/");
    return pathSplit.length >= 3 ? pathSplit[2] : null;
  }

  onViewProfile(e, employee) {
    this.prevPath = this.props.location.pathname;
    this.setState({ centerOn: null });
    this.setLocation("/company/" + employee.employeeId);
  }

  onCloseEmployeeProfileDialog(e) {
    this.setLocation(this.prevPath ? this.prevPath : "/company");
  }

  onShowEmployeeAttributeDetails(e, attr) {
    const employeeId = this.getEmployeeIdFromPath();
    this.setLocation("/company/" + employeeId + "/competencies/" + attr.employeeAttributeId);
  }

  onCloseEmployeeAttributeDetails(e, attr) {
    const employeeId = this.getEmployeeIdFromPath();
    this.setLocation("/company/" + employeeId + "/competencies");
  }

  setLocation(path, useNative) {
    if (useNative === true) window.history.pushState(null, null, path);
    else this.props.history.push(path);
  }

  getActiveTab() {
    const pathSplit = this.props.location.pathname.split("/");
    return pathSplit.length > 2
      ? pathSplit[2] === "gaps" || pathSplit[2] === "competencies"
        ? pathSplit[2]
        : ""
      : "";
  }

  onSearch(e) {
    this.setState({ search: e.target.value });
  }

  render() {
    if (!this.verifyAccess()) return this.renderAccessDenied();

    const { user, organization, theme, location, treeMode, onChangeViewMode, showBillingBanner } =
      this.props;
    const { data, height, centerOn, search } = this.state;

    let selectedEmployee = null;
    let selectedEmployeeAttributeId = null;

    let activeTab = this.getActiveTab();

    const pathSplit = location.pathname.split("/");
    if (data && activeTab === "") {
      selectedEmployee = data.getEmployee(pathSplit[2]);
      selectedEmployeeAttributeId = pathSplit[4] || null;
    }

    return (
      <View
        theme={theme}
        nopadding={true}
        fullWidth={true}
        scrollToTopOnMount={true}
        fullHeight={true}
        showBillingBanner={showBillingBanner}
      >
        <Box
          sx={{
            position: "absolute",
            right: "0",
            left: { lg: AppDrawer.defaultProps.drawerWidth, xs: "0" },
            background: "linear-gradient(0deg, #f8f8fe00,#f8f8feff, #f8f8feff)",
            overflow: "hidden"
          }}
        >
          <Stack
            direction='row'
            justifyContent='space-between'
            sx={{
              margin: "0 auto",
              width: "100%",
              maxWidth: { md: "960px", lg: "1040px", xl: "1440px" },
              padding: { xs: "0 16px", sm: "0 32px", md: "0 32px", lg: "0 48px", xl: "0 48px" }
            }}
          >
            <ViewHeading text='My company' />
            <TextField
              label='Search'
              value={search}
              onChange={this.onSearch.bind(this)}
              autoComplete={false}
              sx={{ width: "250px", display: "none" }}
            />
          </Stack>
        </Box>
        {organization && (
          <OrgChart
            ref={this.chartRef}
            onViewProfile={this.onViewProfile.bind(this)}
            organization={organization}
            data={data}
            height={height}
            currentUser={user ? user.employeeId : null}
            search={search}
            searchFilter={(n, search) =>
              n.type === "person" &&
              ((n.fullName && n.fullName.toUpperCase().includes(search.toUpperCase())) ||
                (n.jobTitle && n.jobTitle.toUpperCase().includes(search.toUpperCase())))
            }
            centerOn={centerOn}
          />
        )}

        {(!data || !organization) && (
          <div style={{ width: "100%", textAlign: "center", paddingTop: "100px" }}>
            <CircularProgress />
          </div>
        )}

        {selectedEmployee && (
          <EmployeeProfileDialog
            location={location}
            user={user}
            organization={organization}
            theme={theme}
            treeMode={treeMode}
            onChangeViewMode={onChangeViewMode}
            employee={selectedEmployee}
            attribute={selectedEmployeeAttributeId}
            onClose={this.onCloseEmployeeProfileDialog.bind(this)}
            onShowAttributeDetails={this.onShowEmployeeAttributeDetails.bind(this)}
            onCloseAttributeDialog={this.onCloseEmployeeAttributeDetails.bind(this)}
            basePath={"/company/" + selectedEmployee.employeeId}
          />
        )}
      </View>
    );
  }
}
