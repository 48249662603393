import { React, Component } from "react";
import { Chip, Divider } from "@mui/material";
import { Button } from "./Button";
import { ReactComponent as ChevronDownIcon } from "../resources/images/icons-chevron-down.svg";
import { ReactComponent as ChevronUpIcon } from "../resources/images/icons-chevron-up.svg";
import { ReactComponent as AddIcon } from "../resources/images/icons-add.svg";
import { ReactComponent as TickIcon } from "../resources/images/icons-check.svg";
import { Link } from "react-router-dom";

export class ChipContainer extends Component {
  static defaultProps = {
    limit: 5
  };

  constructor(props) {
    super(props);

    this.state = {
      showAll: props.showAll ? props.showAll : false,
      selectedItems: props.selectedItems ? props.selectedItems : []
    };
  }

  onShowMoreLess(e) {
    const { showAll } = this.state;

    this.setState({ showAll: !showAll });
  }

  onSelectAll(e) {
    e.preventDefault();

    const { items, onChange } = this.props;
    const { selectedItems } = this.state;

    this.setState({ selectedItems: this.isAllSelected() ? [] : items }, () => {
      if (onChange) onChange(e, this.state.selectedItems);
    });
  }

  isAllSelected() {
    const { items } = this.props;
    const { selectedItems } = this.state;
    return items.length === selectedItems.length;
  }

  onChipSelect(e, item) {
    const { onChange } = this.props;

    let { selectedItems } = this.state;
    if (selectedItems.includes(item)) selectedItems = selectedItems.filter((i) => i !== item);
    else selectedItems.push(item);

    this.setState({ selectedItems });

    if (onChange) onChange(e, selectedItems);
  }

  render() {
    const { items, limit } = this.props;
    const { showAll, selectedItems } = this.state;

    return (
      <div className='klaro-chipcontainer'>
        {items.slice(0, showAll ? items.length : limit).map((i) => (
          <Chip
            className={"klaro-chip" + (selectedItems.includes(i) ? " klaro-chip--selected" : "")}
            label={i.name}
            disableRipple={true}
            deleteIcon={selectedItems.includes(i) ? <TickIcon /> : <AddIcon />}
            onClick={(e) => this.onChipSelect(e, i)}
            onDelete={(e) => this.onChipSelect(e, i)}
            key={Math.random().toString(36).substring(7)}
          />
        ))}
        {showAll && (
          <Link className='klaro-chipcontainer_selectalllink' onClick={this.onSelectAll.bind(this)}>
            {this.isAllSelected() ? "Deselect all" : "Select all"}
          </Link>
        )}
        {items.length > limit ? (
          <div className='klaro-chipcontainer_showmore'>
            <Button
              autoFocus
              label={showAll ? "Show less" : "Show all"}
              variant='simple'
              size='sm'
              endIcon={showAll ? <ChevronUpIcon /> : <ChevronDownIcon />}
              onClick={this.onShowMoreLess.bind(this)}
            />
          </div>
        ) : (
          <Divider sx={{ marginTop: "20px" }} />
        )}
      </div>
    );
  }
}
