import { Component } from "react";
import { ToggleButtonGroup, ToggleButton as MuiToggleButton } from "@mui/material";
import { Tooltip } from "./Tooltip";

export class ToggleButton extends Component {
  static defaultProps = {
    padding: 23,
    title: null
  };

  render() {
    const { options, onChange, value, disabled } = this.props;

    return (
      <ToggleButtonGroup
        className={"klayo-togglebutton" + (disabled ? " klayo-togglebutton--disabled" : "")}
        exclusive
        onChange={onChange}
        value={value}
        disabled={disabled}
      >
        {options.map((option) => {
          return (
            <MuiToggleButton
              key={option.value}
              value={option.value}
              disabled={option.disabled}
              disableFocusRipple={true}
              disableRipple={true}
              aria-label='right aligned'
            >
              <Tooltip title={option.tooltip}>{option.icon}</Tooltip>
            </MuiToggleButton>
          );
        })}
      </ToggleButtonGroup>
    );
  }
}
