import { Stack, Grid } from "@mui/material";
import { Alert } from "klayowebshared";
import { Component } from "react";
import { AppContext } from "../../common/AppContext";
import { TextField } from "../../components/TextField";
import { Dialog } from "../../components/dialogs/Dialog";
import { CREATE_TASK_MODE } from "../../constants";
import { MenuSelect } from "../../components/MenuSelect";

export class CreateRegulationCategoryDialog extends Component {
  static contextType = AppContext;

  constructor(props) {
    super(props);

    this.state = {
      domain: null,
      category: null
    };
  }

  componentDidMount() {
    const { mode, selectedDomain } = this.props;

    if (mode === CREATE_TASK_MODE.CREATE && selectedDomain) {
      this.setState({ domain: selectedDomain.id });
    }

    if (mode === CREATE_TASK_MODE.EDIT) {
      const { selectedCategory } = this.props;
      this.setState({ domain: selectedCategory.domainRowId, category: selectedCategory.name });
    }
  }

  onFormatListRegulationDomain(listRegulationDomain) {
    if (listRegulationDomain) {
      const newFormattedTypes = listRegulationDomain.reduce((acc, curr) => {
        acc[curr.id] = {
          id: curr.id,
          name: curr.name,
          value: curr.id,
          label: curr.name
        };
        return acc;
      }, {});
      return Object.values(newFormattedTypes);
    }
    return null;
  }

  onSave() {
    const { onSave } = this.props;
    const { domain, category } = this.state;
    if (onSave) onSave(domain, category);
  }

  onUpdateName(e) {
    this.setState({ category: e.target.value });
  }

  onSelectItem(event) {
    this.setState({ domain: event.target.value });
  }

  render() {
    const {
      user,
      theme,
      onClose,
      error,
      mode,
      listRegulationDomain,
      selectedCategory,
      selectedDomain
    } = this.props;
    const { domain, category } = this.state;

    const findSelectedDomain =
      mode === CREATE_TASK_MODE.EDIT &&
      listRegulationDomain.find((item) => item.id === selectedCategory.domainRowId);
    return (
      <Dialog
        open={true}
        theme={theme}
        onClose={onClose}
        fullWidth={true}
        maxWidth='sm'
        title={mode === CREATE_TASK_MODE.EDIT ? "Edit category" : "Create category"}
        actions={[
          {
            label: `${mode === CREATE_TASK_MODE.EDIT ? "Save" : "Create"}`,
            primary: true,
            disabled:
              !domain ||
              domain.length === 0 ||
              !category ||
              category.length === 0 ||
              (mode === CREATE_TASK_MODE.EDIT &&
                category === selectedCategory.name &&
                domain === selectedCategory.domainRowId),
            onClick: this.onSave.bind(this)
          },
          {
            label: "Cancel",
            onClick: onClose
          }
        ]}
      >
        <Stack direction='column' spacing={4}>
          {error && (
            <Alert severity='error' sx={{ width: "100%" }}>
              {error}
            </Alert>
          )}

          {selectedDomain !== null || mode === CREATE_TASK_MODE.EDIT ? (
            <TextField
              value={selectedDomain ? selectedDomain.name : findSelectedDomain.name}
              label='Domain (Required)'
              dense={true}
              disabled={true}
              autoComplete={false}
              autoFocus={true}
              sx={{ width: "100%" }}
              clearable={false}
            />
          ) : (
            <MenuSelect
              className='klayo_practical-type-select-menu'
              label='Domain (required)'
              value={domain}
              firstValueDefault={false}
              noSelectionError={true}
              dense={true}
              disabled={selectedDomain !== null}
              items={this.onFormatListRegulationDomain(listRegulationDomain)}
              onChange={this.onSelectItem.bind(this)}
              sx={{ width: "100%" }}
              placeholder='Select domain'
            />
          )}

          <TextField
            value={category}
            label='Category name (required)'
            dense={true}
            disabled={user === null}
            autoComplete={false}
            autoFocus={true}
            sx={{ width: "100%" }}
            onChange={this.onUpdateName.bind(this)}
          />
        </Stack>
      </Dialog>
    );
  }
}
