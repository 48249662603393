import { Typography, Tooltip as MuiTooltip, Box } from "@mui/material";
import { React, Component } from "react";
import { Utils } from "../common/Utils";

export class Tooltip extends Component {
  render() {
    const {
      title,
      placement,
      children,
      open,
      componentsProps,
      onClose,
      disableFocusListener,
      disableHoverListener,
      disableTouchListener,
      maxWidth,
      sx
    } = this.props;

    return !title ? (
      <div>{children}</div>
    ) : (
      <MuiTooltip
        title={<Box>{title}</Box>}
        open={open}
        placement={placement}
        arrow={true}
        onClose={onClose}
        disableFocusListener={disableFocusListener}
        disableHoverListener={disableHoverListener}
        disableTouchListener={disableTouchListener}
        sx={{ ...sx, maxWidth: maxWidth }}
        componentsProps={{ tooltip: { sx: { maxWidth: maxWidth, ...sx } }, ...componentsProps }}
      >
        {children}
      </MuiTooltip>
    );
  }
}
