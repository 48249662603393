import React, { Component } from "react";
import { Grid, Stack } from "@mui/material";
import { FileUpload } from "./FileUpload";
import uniqueId from "lodash.uniqueid";

export class MultiFileUpload extends Component {
  static defaultProps = {
    multiple: true
  };

  constructor(props) {
    super(props);

    this.state = {
      files: props.files || {}
    };

    this.addBlank();
    this.currentFileUploadRef = React.createRef();
  }

  onFileChange(e, key, file, multiple) {
    const { files } = this.state;

    files[key] = file;
    if (!multiple) {
      this.addBlank();
      this.setState({ files }, (state) => this.fireOnChange(e, files));
    }
  }

  onMultipleFilesSelected(e, newFiles) {
    const { files } = this.state;

    newFiles.map((file) => this.addFile(file));
    this.addBlank();
    this.setState({ files }, (state) => this.fireOnChange(e, files));
  }

  fireOnChange(e, files) {
    const { onChange } = this.props;

    const newFiles = [];
    Object.values(files)
      .filter((f) => f !== null)
      .forEach((f, index) => (newFiles["file_" + index] = f));
    if (onChange) onChange(e, newFiles);
  }

  onRemoveFile(e, key, file) {
    let { files } = this.state;

    const newFiles = [];
    const otherFiles = Object.entries(files).filter(([k, f]) => k !== key);
    otherFiles.forEach(([k, f], index) => (newFiles["file_" + index] = f));

    this.setState({ files: newFiles }, (state) => this.fireOnChange(e, newFiles));
  }

  addBlank() {
    let { files } = this.state;
    const fileEntries = Object.entries(files);
    const hasEmpty = fileEntries.some((i) => !i[1]);
    if (hasEmpty) return;
    this.addFile(null);
    //files['file_' + fileEntries.length] = null;
  }

  addFile(file) {
    let { files } = this.state;
    const fileEntries = Object.entries(files);
    files["file_" + fileEntries.length] = file;
  }

  selectFile(e) {
    this.currentFileUploadRef.current.selectFile(e);
  }

  dropFile(e) {
    this.currentFileUploadRef.current.dropFile(e);
  }

  render() {
    const {
      id,
      accept,
      disabled,
      label,
      placeholder,
      InputLabelProps,
      allowedMimeTypes,
      maxFileSize,
      errorMimeType,
      errorFileSize,
      helperText,
      onError,
      multiple,
      sx
    } = this.props;
    const { files } = this.state;

    const fileEntries = Object.entries(files);

    return (
      <Stack spacing={2} sx={sx}>
        {fileEntries.map(([key, file], i) => (
          <Grid key={key} item xs={12} md={12} lg={12}>
            <FileUpload
              id={uniqueId(id + "_")}
              ref={i === fileEntries.length - 1 ? this.currentFileUploadRef : null}
              className={i === fileEntries.length - 1 ? "klayo-multifileupload_current" : null}
              label={label}
              disabled={disabled}
              placeholder={placeholder}
              accept={accept}
              onFileChange={(e, file, multiple) => this.onFileChange(e, key, file, multiple)}
              onMultipleFilesSelected={this.onMultipleFilesSelected.bind(this)}
              onError={onError}
              hasRemove={Object.values(this.state.files).length > 1}
              file={file}
              allowedMimeTypes={allowedMimeTypes}
              maxFileSize={maxFileSize}
              multiple={multiple}
              errorMimeType={errorMimeType}
              helperText={helperText}
              errorFileSize={errorFileSize}
              onRemoveFile={(e, file) => this.onRemoveFile(e, key, file)}
              sx={{ width: "100%" }}
            />
          </Grid>
        ))}
      </Stack>
    );
  }
}
