import { React, Component } from "react";
import {
  Tabs,
  Tab,
  Fade,
  MenuItem,
  Divider,
  Typography,
  ListItemIcon,
  ListItemText,
  List,
  Collapse,
  ListItem
} from "@mui/material";
import { NavLink } from "react-router-dom";
import { AppContext } from "../common/AppContext";
import { ReactComponent as ChevronDownIcon } from "../resources/images/icons-chevron-down.svg";
import { ReactComponent as ChevronUpIcon } from "../resources/images/icons-chevron-up.svg";
import { ReactComponent as ArrowLeftIcon } from "../resources/images/icons-arrow-left.svg";
import { ReactComponent as RightArrowIcon } from "../resources/images/icons-arrow-right.svg";
import { Utils, Tooltip } from "klayowebshared";

export class AppNav extends Component {
  static contextType = AppContext;

  static defaultProps = {
    drawerMode: false,
    showIcons: false,
    showNavChildren: true,
    autoCollapse: true,
    maxLabelLength: 22
  };

  constructor(props) {
    super(props);

    this.state = {
      //collapsed: props.itemState ?? [],
      //childItems: [],
      currentPage: props.currentPage
    };
  }

  componentDidUpdate(prevProps, prevState) {
    const { autoCollapse, currentPage, items } = this.props;

    if (autoCollapse && prevProps.currentPage !== currentPage && currentPage) {
      if (items)
        items.forEach((page, index) => {
          this.collapseItem(page, page === currentPage, false);
        });

      this.forceUpdate();
    }
  }

  updateNav() {
    const { itemState, items, currentPage, getChildNavItems } = this.props;
    const { collapsed, childItems } = itemState;

    let update = false;
    if (items)
      items.forEach((page, index) => {
        if (page.hasChildren && childItems[page.slug] === undefined) {
          childItems[page.slug] = getChildNavItems(page);
          if (childItems[page.slug]) {
            update = true;
            if (page === currentPage)
              collapsed[page.slug] = childItems[page.slug] && childItems[page.slug].length > 0;
          }
        }
      });

    if (update) this.forceUpdate();
  }

  onItemClick(e) {
    const { drawerMode, onDrawerClose, onNavigation } = this.props;
    if (onNavigation) onNavigation(e);
    if (drawerMode) onDrawerClose(e);
  }

  isCollapsed(page) {
    const { itemState } = this.props;
    const { collapsed } = itemState;
    return collapsed[page.slug] ?? false;
  }

  onCollapseItem(e, page) {
    e.preventDefault();
    this.collapseItem(page);
  }

  collapseItem(page, collapse, update = true) {
    const { itemState, getChildNavItems, currentPage } = this.props;
    const { collapsed, childItems } = itemState;

    collapsed[page.slug] =
      collapse !== undefined
        ? collapse
        : collapsed[page.slug] === undefined
          ? true
          : !collapsed[page.slug];

    if (page.hasChildren && childItems[page.slug] === undefined) {
      childItems[page.slug] = getChildNavItems(page);
      if (collapse)
        collapsed[page.slug] = childItems[page.slug] && childItems[page.slug].length > 0;
    }
    if (update) this.forceUpdate();
  }

  renderChildItems(page) {
    const { itemState, maxLabelLength } = this.props;
    const { childItems } = itemState;

    if (!childItems[page.slug]) return null;

    return (
      <List className='klayo-appdrawer_childlist'>
        {childItems[page.slug].map((item, i) => (
          <Tooltip
            key={`tt-${i}`}
            title={item.label.length > maxLabelLength ? item.label : null}
            placement='right'
          >
            <ListItem
              key={i}
              className='klaro-appdrawer_menuitem klaro-appdrawer_childmenuitem'
              to={item.path}
              component={NavLink}
              onClick={this.onItemClick.bind(this)}
            >
              <ListItemText disableTypography={true}>
                {Utils.truncate(item.label, maxLabelLength)}
              </ListItemText>
            </ListItem>
          </Tooltip>
        ))}
      </List>
    );
  }

  renderItems(items) {
    const { user, organization, drawerMode, showIcons, showNavChildren } = this.props;

    return items.map((page, index) => {
      const slug = page.getSlug ? page.getSlug(user, organization) : page.slug;
      return drawerMode ? (
        <div key={index}>
          {page.seperate === true && <Divider />}

          <ListItem
            key={index}
            className={
              "klaro-appdrawer_menuitem" +
              (page.enabled === false ? " klaro-appdrawer_menuitem--hidden" : "") +
              (page.hasChildren === true ? " klaro-appdrawer_parentmenuitem" : "")
            }
            to={"/" + slug}
            component={NavLink}
            onClick={
              page.hasChildren !== true
                ? this.onItemClick.bind(this)
                : (e) => this.onCollapseItem(e, page)
            }
          >
            {showIcons && <ListItemIcon>{page.icon}</ListItemIcon>}
            <ListItemText disableTypography={true}>{page.name}</ListItemText>
            {page.hasChildren === true && (
              <div className='klaro-appdrawer_menuitem_collapsebutton'>
                {showNavChildren ? (
                  this.isCollapsed(page) ? (
                    <ChevronUpIcon />
                  ) : (
                    <ChevronDownIcon />
                  )
                ) : (
                  <RightArrowIcon />
                )}
              </div>
            )}
          </ListItem>

          {page.hasChildren === true && showNavChildren && (
            <Collapse in={this.isCollapsed(page)} timeout='auto' unmountOnExit>
              <List component='div' disablePadding>
                {this.renderChildItems(page)}
              </List>
            </Collapse>
          )}
        </div>
      ) : (
        <Fade in={true} key={index} value={page.slug}>
          <Tab
            className={
              "klaro-appbar_nav-tab" +
              (page.enabled === false ? " klaro-appbar_nav-tab--hidden" : "")
            }
            exact={false}
            value={page.slug}
            to={"/" + slug}
            label={page.name}
            disableRipple={true}
            activeClassName='active'
            onClick={this.onItemClick.bind(this)}
            component={NavLink}
          />
        </Fade>
      );
    });
  }

  render() {
    const { items, defaultItem, sx, drawerMode, currentPage } = this.props;

    return drawerMode ? (
      <div className='klaro-appdrawer-menu'>{this.renderItems(items)}</div>
    ) : (
      <Tabs
        className='klaro-appbar_nav'
        value={currentPage ? currentPage.slug : defaultItem.slug}
        centered={true}
        color='secondary'
        sx={sx}
        TabIndicatorProps={{
          sx: {
            opacity: currentPage ? 1 : 0
          }
        }}
      >
        {this.renderItems(items)}
      </Tabs>
    );
  }
}
