import { Link, Stack } from "@mui/material";
import { Component } from "react";
import { Paper } from "./Paper";
import { Utils } from "../common/Utils";

export class BillingSelectionCard extends Component {
  static defaultProps = {
    plan: null
  };

  render() {
    const { group, plan, onResetGroup } = this.props;

    return (
      <Paper className='klayo-billingselectioncard'>
        <Stack direction='row' alignItems='center' justifyContent='space-between'>
          {group && plan && (
            <div>
              You selected the <b>{group.name}</b> plan. Your{" "}
              <b>{plan.cycle.paymentLabel.toLowerCase()}</b> charge is{" "}
              <b>${Utils.formatCurrency(plan.pricePerCycle)}</b>.
            </div>
          )}

          {(!group || !plan) && <div>No plan found for your selection.</div>}
          <Link className='klayo-billingselectioncard_changelink' onClick={onResetGroup}>
            Change plan
          </Link>
        </Stack>
      </Paper>
    );
  }
}
