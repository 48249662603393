import { Data } from "../Data";
import { Notification } from "./Notification";
import { NotificationsConnection } from "./NotificationsConnection";
import { NotificationsUnread } from "./NotificationsUnread";

export class NotificationsData extends Data {
  static instance = {};
  static connection = null;

  constructor(options) {
    super({
      api: options.mode.notificationsApi,
      callback: (data) => {
        options.resolve(this, false);
      },
      ...options
    });

    this.mode = options.mode;
    this.unread = options.unread;

    this.items = [];
  }

  onDataReceived(data) {
    data.notifications.forEach((notif) => {
      const notification = new Notification(notif, this.mode);
      if (notification.isValid()) this.items.push(notification);
    });

    this.sort();
  }

  handleNotification(notif) {
    const existingNotif = this.getNotification(notif.showOnceId);
    notif.isNew = !existingNotif;
    if (existingNotif) {
      if (notif.isRead() && !existingNotif.isRead()) this.setAsRead(existingNotif, true);
    } else {
      if (notif.isValid()) {
        this.items.push(notif);
        this.sort();
        //this.unread.update(1);    //let server tell us
      }
    }

    this.onNotificationReceived(notif, this.unread.count);
  }

  onNotificationReceived(notif, count) {}

  sort() {
    this.items.sort((a, b) => new Date(b.date) - new Date(a.date));
  }

  setAsRead(notif, fromServer) {
    notif.setAsRead();
    //if(fromServer) this.unread.update(-1);    //let server tell us
  }

  getNotification(showOnceId) {
    return this.items.find((n) => n.showOnceId === showOnceId);
  }

  static startNotifications(context) {
    return new Promise((resolve, reject) => {
      NotificationsConnection.get(context)
        .then((connection) => {
          connection.onNotificationReceived = (notif, numUnread) => {
            NotificationsData.get(context, notif.dashboardMode)
              .then((n) => {
                n.unread.set(numUnread);

                if (!n.hasInit) {
                  resolve(n);
                  n.hasInit = true;
                }

                Promise.allSettled([this]).then((e) => {
                  n.handleNotification(notif);
                });
              })
              .catch((e) => reject(e))
              .finally();
          };
        })
        .catch((e) => reject(e));
    });
  }

  static get(context, mode) {
    return new Promise((resolve, reject) => {
      if (NotificationsData.instance[mode.name])
        resolve(NotificationsData.instance[mode.name], true);
      else
        NotificationsUnread.get(context).then((unread) => {
          NotificationsData.instance[mode.name] = new NotificationsData({
            resolve,
            reject,
            context,
            mode,
            unread
          });
        });
    });
  }
}
