import { React, Component } from "react";
import { Typography, Link } from "@mui/material";

export class InlineDocumentList extends Component {
  static defaultProps = {
    size: 160
  };

  render() {
    const { documents, filter, sx, onDocumentClick } = this.props;

    return (
      <div style={{ display: "flex", ...sx }} color='text.primary' className='klayo_inlinedoclist'>
        <span>Evidence:&nbsp;</span>
        <div>
          {filter &&
            filter.length &&
            filter.map((id, index) => {
              const doc = documents.getDocument(id);
              return doc.isDeleted || doc.pendingDelete ? (
                <div key={index}>
                  <span className='klayo_inlinedoclist_deleted'>{doc.documentName}</span> (deleted)
                </div>
              ) : (
                <Link
                  key={index}
                  onClick={(e) => onDocumentClick(e, doc)}
                  sx={{ display: "block" }}
                >
                  {doc.documentName}
                </Link>
              );
            })}
        </div>
      </div>
    );
  }
}
