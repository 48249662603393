import { SettingsEmployee } from "./SettingsEmployee";
import { Data } from "../Data";

export class SettingsGroupMemberCount extends Data {
  constructor(options) {
    super({
      api: "/Group/CountEmployeesByJobDefinitionsAndLocations?" + options.params,
      callback: (data) => {
        options.resolve(this, false);
      },
      ...options
    });

    this.managerMemberOverlap = [];
  }

  onDataReceived(data) {
    this.totalEmployees = data.totalEmployees;
    data.overlapEmployees.forEach((e) => this.managerMemberOverlap.push(new SettingsEmployee(e)));
  }
}
