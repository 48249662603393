import { Stack } from "@mui/material";
import { Component, React } from "react";
import { Alert } from "../../components/Alert";
import { Dialog } from "../../components/dialogs/Dialog";

export class DeleteConfirmationDialog extends Component {
  constructor(props) {
    super(props);

    this.state = {
      error: props.error
    };
  }

  componentDidUpdate(prevProps) {
    if (this.props.error !== prevProps.error) this.setState({ error: this.props.error });
  }

  render() {
    const { theme, onClose } = this.props;
    const { error } = this.props;

    return (
      <Dialog
        open={true}
        theme={theme}
        onClose={onClose}
        title='Delete competency request'
        maxWidth='xs'
        actions={[
          {
            label: "Close",
            onClick: onClose
          }
        ]}
      >
        <Stack spacing={4}>
          {error && (
            <Alert severity='error' sx={{ width: "100%", boxSizing: "border-box" }}>
              {error}
            </Alert>
          )}
          <div>Please contact your administrator to remove a competency</div>
        </Stack>
      </Dialog>
    );
  }
}
