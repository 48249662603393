import { Box, Fade, Stack, Step, StepLabel, Stepper, Tab, Tabs } from "@mui/material";
import { Delayed, EditTemplate } from "klayowebshared";
import { get, uniqBy } from "lodash";
import { Component } from "react";
import { AppContext } from "../../common/AppContext";
import { ErrorModel } from "../../common/models/ErrorModel";
import { Alert } from "../../components/Alert";
import { Button } from "../../components/Button";
import { PaperOrDialog } from "../../components/PaperOrDialog";
import { RichTextEditor } from "../../components/RichTextEditor";
import { TablePlaceholder } from "../../components/TablePlaceholder";
import { TextField } from "../../components/TextField";
import { AttributeTable } from "../../components/table/AttributeTable";
import { RelatedTaskInAttributeDialog } from "../../components/table/RelatedTasksInAttributeDialog";
import { TasksTable } from "../../components/table/TasksTable";
import { Data } from "../../data/Data";
import { SettingsAttribute } from "../../data/settings/SettingsAttribute";
import { SettingsJob } from "../../data/settings/SettingsJob";
import { ReactComponent as AddIcon } from "../../resources/images/icons-add.svg";
import { ReactComponent as LeftIcon } from "../../resources/images/icons-arrow-left.svg";
import { ReactComponent as RightIcon } from "../../resources/images/icons-arrow-right.svg";
import { ReactComponent as AttributesIcon } from "../../resources/images/icons-attribute.svg";
import { ReactComponent as CheckListIcon } from "../../resources/images/icons-checklist.svg";
import { ReactComponent as SearchIcon } from "../../resources/images/icons-search.svg";
import { trimToLowercase } from "../../utilities";
import { AttributeSelectorDialog } from "./AttributeSelectorDialog";
import { ConfirmDeleteTaskDialog } from "./ConfirmDeleteTaskDialog";
import { TaskSelectorDialog } from "./TaskSelectorDialog";
export class AddEditJobView extends Component {
  static contextType = AppContext;

  constructor(props) {
    super(props);

    this.state = {
      job: props.job,
      saving: false,
      error: props.error,
      step: 0,
      showAttributeSelectDialog: false,
      searchTask: null,
      searchCompetency: null,
      descriptionEditorState: RichTextEditor.createEditorState(""),
      originalEditorState: RichTextEditor.createEditorState(""),
      hasEdit: false,
      isShowTasksSelectDialog: false,
      isShowRelatedTasksInCompetencyDialog: false,
      tasksHasAttribute: [],
      isRemoveRelatedTasksInCompetencyDialog: false,
      deletingRowError: null,
      selectedDeleteTask: null
    };

    this.existingJob = new SettingsJob(props.job);
  }

  componentDidMount() {
    this.loadDetails();
  }

  componentDidUpdate(prevProps) {
    if (this.props.error !== prevProps.error) this.setState({ error: this.props.error });
  }

  loadDetails() {
    const { job, step } = this.state;
    const jobDefinitionId = job ? job.jobDefinitionId : this.props.jobId;

    if (!jobDefinitionId) return;

    this.context.setLoading("loading", true);

    Data.get(this.context, "/JobDefinition/" + jobDefinitionId, {
      withCredentials: true
    })
      .then((response) => {
        const job = new SettingsJob(response.data.jobDefinition);
        this.existingJob = new SettingsJob(response.data.jobDefinition);
        this.setState({
          job,
          descriptionEditorState: RichTextEditor.createEditorState(job.description),
          originalEditorState: RichTextEditor.createEditorState(job.description)
        });
      })
      .catch((e) => {
        this.setState({ error: ErrorModel.parseServerError(e) });
      })
      .finally(() => {
        this.context.setLoading("loading", false);
      });
  }

  onNameChange(e) {
    const { job } = this.state;
    job.name = e.target.value;

    this.setState({ job });
  }

  onDescriptionChange(text) {
    const { job } = this.state;
    job.description = text;

    this.setState({ job });
  }

  onDescriptionEditorStateChange(descriptionEditorState) {
    this.setState({ descriptionEditorState });
  }

  isValid(job, step) {
    if (!job || !this.isNameValid(job)) return false;
    if (step === 0) return true;
    if (step === 1) return true;
    return this.isAttributesValid(job);
  }

  isNameValid(job) {
    return job && job.name != null && job.name.length > 2;
  }

  isAttributesValid(job) {
    if (!job) return false;
    let isValid = true;

    job.attributes.forEach((a) => {
      if (a.isParent !== true && a.isChild !== true && !a.requirementType) isValid = false;
    });

    return isValid;
  }

  onSave() {
    const { onSave, editMode, onBlockNavigation } = this.props;
    const { job, step } = this.state;

    if (!editMode && step === 0 && this.isValid(job, 0)) this.setState({ step: 1 });
    if (!editMode && step === 1) this.setState({ step: 2 });

    if (editMode && step === 1) {
      // check requirementType in each attribute in job not undefined
      if (!this.isAttributesValid(job)) {
        this.setState({ step: 2 });
        return;
      }
    }

    if (onSave && (editMode || step === 2)) {
      if (onBlockNavigation) onBlockNavigation(false, "Job");
      this.setState({ saving: true });
      onSave(job);
    }
  }

  onShowAttributeSelectDialog(e) {
    this.setState({ showAttributeSelectDialog: true });
  }

  onSearchTask(e) {
    this.setState({ searchTask: e.target.value });
  }

  onSearchCompetency(e) {
    this.setState({ searchCompetency: e.target.value });
  }

  onAttributesAdded(addedAttributes) {
    let { job } = this.state;
    let childAttrs = [];
    addedAttributes.forEach((a) => {
      a.childAttributes.forEach((c) => {
        childAttrs.push(c);
        if (
          !addedAttributes.some((a) => a.attributeDefinitionId === c.attributeDefinitionId) &&
          !job.attributes.some((a) => a.attributeDefinitionId === c.attributeDefinitionId)
        )
          addedAttributes.push(c.toStandardAttribute());
      });
    });
    job.attributes = [...job.attributes, ...addedAttributes, ...childAttrs];
    this.setState({ job, showAttributeSelectDialog: false });
  }

  onAttributesEdit(addedAttributes) {
    let { job } = this.state;
    let childAttrs = [];
    addedAttributes.forEach((a) => {
      a.childAttributes.forEach((c) => {
        childAttrs.push(c);
        if (
          !addedAttributes.some((a) => a.attributeDefinitionId === c.attributeDefinitionId) &&
          !job.attributes.some((a) => a.attributeDefinitionId === c.attributeDefinitionId)
        )
          addedAttributes.push(c.toStandardAttribute());
      });
    });
    job.attributes = [...addedAttributes, ...childAttrs];
    this.setState({ job, showAttributeSelectDialog: false });
  }

  onTabChange(e, newStep) {
    this.setState({ step: newStep });
  }

  onAttributeDelete(e, attr) {
    let { job } = this.state;
    const { regulationTasks } = job;

    // Check if the attribute is used in any task
    const isUsedInTask = regulationTasks.some((task) => {
      return task.competencies.some((c) => c.attributeDefinitionId === attr.attributeDefinitionId);
    });

    // Get list tasks has the attribute
    const tasksHasAttributeData = regulationTasks.filter((task) => {
      return task.competencies.some((c) => c.attributeDefinitionId === attr.attributeDefinitionId);
    });

    if (isUsedInTask) {
      this.setState({
        isShowRelatedTasksInCompetencyDialog: true,
        tasksHasAttribute: tasksHasAttributeData
      });
      return;
    }

    job.attributes = job.attributes.filter(
      (a) =>
        !(a.attributeId === attr.attributeId && !a.isChild) &&
        a.parentAttributeId !== attr.attributeId
    );

    this.setState({ job });
  }

  onAttributeRequirementChange(attribute, requirementType) {
    attribute.requirementType = requirementType;
    attribute.type = requirementType.label;
    this.forceUpdate();
  }

  onCloseAttributeSelectDialog() {
    this.setState({ showAttributeSelectDialog: false });
  }

  onBack() {
    const { step } = this.state;
    this.setState({ step: step - 1 });
  }

  onNavigation(e, callback) {
    const { onNavigation } = this.props;
    return onNavigation && onNavigation(e, callback);
  }

  onCancel(e, source) {
    if (source === "back") this.onNavigation(e, this.stopBlockingNavAndClose.bind(this));
    else this.stopBlockingNavAndClose(e);
  }

  stopBlockingNavAndClose(e) {
    const { onCancel, onBlockNavigation } = this.props;
    if (onBlockNavigation) onBlockNavigation(false, "Job");
    if (onCancel) onCancel(e);
  }

  rowHasAction(attr) {
    return true;
  }

  rowActionDisabled(attr) {
    let { job } = this.state;
    return (
      job.attributes.some(
        (a) => a.isParent && a.childAttributes.some((a2) => a2.attributeId === attr.attributeId)
      ) !== false
    );
  }

  onEditStateChange(hasEdit) {
    this.setState({ hasEdit });
  }

  compareName(name, search) {
    return String(name || "")
      ?.toLowerCase()
      ?.includes(trimToLowercase(search || ""));
  }

  onCloseTasksSelectDialog() {
    this.setState({ isShowTasksSelectDialog: false });
  }

  onShowTasksSelectDialog() {
    this.setState({ isShowTasksSelectDialog: true });
  }

  onTasksAdded(tasks) {
    const { job } = this.state;
    job.regulationTasks = [...tasks];
    this.setState(job);

    const listRelatedAttributes = [];

    tasks.forEach((task) => {
      task.competencies.forEach((a) => {
        listRelatedAttributes.push(a);
      });
    });

    // remove duplicate attributes
    const uniqueAttributes = uniqBy(
      [...job.attributes, ...listRelatedAttributes],
      "attributeDefinitionId"
    ).map((a) => {
      return new SettingsAttribute(a);
    });

    this.onAttributesEdit(uniqueAttributes);
    this.onCloseTasksSelectDialog();
  }

  onDeleteTask(action, selectedTask) {
    const { job } = this.state;
    const { editMode } = this.props;

    const filterRegulationTaskList = job.regulationTasks.filter((r) => r.id !== selectedTask.id);
    // Remove related competency in task
    const existingCompetencies = job.attributes;
    const deletedCompetencies = selectedTask.competencies;

    if (editMode) {
      this.setState({ selectedDeleteTask: selectedTask });
      // Check if the task is used in any competency
      const isUsedInTask = existingCompetencies.some((a) =>
        deletedCompetencies.some((d) => d.attributeDefinitionId === a.attributeDefinitionId)
      );

      if (isUsedInTask) {
        this.onShowRemoveRelatedTasksInCompetencyDialog();
        return;
      }
    }

    let filterCompetencies = [];
    filterCompetencies = existingCompetencies.filter(
      (a) => !deletedCompetencies.some((d) => d.attributeDefinitionId === a.attributeDefinitionId)
    );

    filterRegulationTaskList.forEach((task) => {
      existingCompetencies.forEach((competency) => {
        // Check if any competency is still exist in the task, push it to the filterCompetencies
        if (
          task.competencies.some(
            (c) => c.attributeDefinitionId === competency.attributeDefinitionId
          )
        ) {
          filterCompetencies.push(competency);
        }
      });
    });

    filterCompetencies = uniqBy(filterCompetencies, "attributeDefinitionId");

    this.setState({
      job: { ...job, regulationTasks: filterRegulationTaskList, attributes: filterCompetencies }
    });
  }

  onRemoveRelatedTasksInCompetency() {
    const { job, selectedDeleteTask } = this.state;
    const filterRegulationTaskList = job.regulationTasks.filter(
      (r) => r.id !== selectedDeleteTask.id
    );
    // Remove related competency in task
    const existingCompetencies = job.attributes;
    const deletedCompetencies = selectedDeleteTask.competencies;

    let filterCompetencies = [];
    filterCompetencies = existingCompetencies.filter(
      (a) => !deletedCompetencies.some((d) => d.attributeDefinitionId === a.attributeDefinitionId)
    );

    filterRegulationTaskList.forEach((task) => {
      existingCompetencies.forEach((competency) => {
        // Check if any competency is still exist in the task, push it to the filterCompetencies
        if (
          task.competencies.some(
            (c) => c.attributeDefinitionId === competency.attributeDefinitionId
          )
        ) {
          filterCompetencies.push(competency);
        }
      });
    });

    filterCompetencies = uniqBy(filterCompetencies, "attributeDefinitionId");

    this.setState({
      job: { ...job, regulationTasks: filterRegulationTaskList, attributes: filterCompetencies }
    });
    this.onHideRemoveRelatedTasksInCompetencyDialog();
  }

  onRemoveRelatedTasksButKeepCompetency() {
    const { job, selectedDeleteTask } = this.state;
    const filterRegulationTaskList = job.regulationTasks.filter(
      (r) => r.id !== selectedDeleteTask.id
    );
    this.setState({ job: { ...job, regulationTasks: filterRegulationTaskList } });
    this.onHideRemoveRelatedTasksInCompetencyDialog();
  }

  onCloseRelatedTasksInCompetencyDialog() {
    this.setState({ isShowRelatedTasksInCompetencyDialog: false });
  }

  onShowRelatedTasksInCompetencyDialog() {
    this.setState({ isShowRelatedTasksInCompetencyDialog: true });
  }

  isRegulationTasksSame(other) {
    const { job } = this.state;
    if (!other) return false;
    if (other.length !== job.regulationTasks.length) return false;

    for (const o of other) {
      const match = job.regulationTasks.find((r) => r.id === o.id);
      if (!match) return false;
    }
    return true;
  }

  isAttributesSame(other) {
    const { job } = this.state;
    if (!other) return false;
    const otherNoChildren = other.filter((a) => !a.isChild);
    if (other.length !== job.attributes.length) return false;

    for (const o of otherNoChildren) {
      const match = job.attributes.find((a) => a.attributeId === o.attributeId && !a.isChild);
      if (!match) return false;

      if (match.requirementType && o.requirementType) {
        if (match.requirementType.index !== o.requirementType.index) return false;
      }
    }
    return true;
  }

  onShowRemoveRelatedTasksInCompetencyDialog() {
    this.setState({ isRemoveRelatedTasksInCompetencyDialog: true });
  }

  onHideRemoveRelatedTasksInCompetencyDialog() {
    this.setState({ isRemoveRelatedTasksInCompetencyDialog: false });
  }

  render() {
    const {
      organization,
      user,
      theme,
      attributes,
      editMode,
      onCreate,
      onLoadAttributes,
      onShowNewAttributeDialog,
      onAllowNavigation,
      onBlockNavigation
    } = this.props;
    const {
      error,
      job,
      step,
      showAttributeSelectDialog,
      descriptionEditorState,
      hasEdit,
      saving,
      isShowTasksSelectDialog,
      isShowRelatedTasksInCompetencyDialog,
      tasksHasAttribute,
      isRemoveRelatedTasksInCompetencyDialog,
      deletingRowError,
      searchCompetency,
      searchTask
    } = this.state;

    let filterItemsAttribute = [
      { id: "search", columnField: "attribute", operatorValue: "contains", value: searchCompetency }
    ];
    let filterItemsTasks = [
      { id: "search", columnField: "name", operatorValue: "contains", value: searchTask }
    ];

    const filterCompetencies =
      job && job.attributes
        ? job.attributes?.filter((p) => {
            const matchChildren = p?.childAttributes?.some((c) =>
              this.compareName(c?.name, searchCompetency)
            );
            const matchAttribute = this.compareName(p?.name, searchCompetency);
            return matchAttribute || matchChildren;
          })
        : [];

    const filterJobTasks = get(job, "regulationTasks", []).filter((task) => {
      if (searchTask) {
        const keyword = trimToLowercase(searchTask || "");
        const taskName = trimToLowercase(task?.name || "");
        return taskName?.includes(keyword);
      } else {
        return true;
      }
    });

    return (
      <EditTemplate
        theme={theme}
        name='Job'
        onAllowNavigation={onAllowNavigation}
        onBlockNavigation={onBlockNavigation}
        onEditStateChange={this.onEditStateChange.bind(this)}
        detectEdit={!saving}
        compare={{
          existing: this.existingJob,
          editing: job,
          members: [
            { name: "name" },
            {
              name: "description",
              detectEdit: (existing, editing, isNew) => {
                if (isNew) return editing.description && editing.description.trim() !== "<p></p>";
                return (
                  existing.description !== editing.description &&
                  existing.description + "\n<p></p>" !== editing.description
                );
              }
            },
            {
              name: "regulationTasks",
              detectEdit: (existing, editing) =>
                step === 1 && !this.isRegulationTasksSame(existing.regulationTasks)
            },
            {
              name: "attributes",
              detectEdit: (existing, editing) =>
                step === 2 && !this.isAttributesSame(existing.attributes)
            }
          ]
        }}
      >
        <PaperOrDialog
          theme={theme}
          dialogToBreakpoint='md'
          disabledToBreakpoint='md'
          paperPadding={{ xs: "46px 24px", md: "60px" }}
          title={editMode ? "Job details" : "Create job"}
          titleSx={{ fontSize: { xs: "20px", md: "30px" }, fontWeight: "bold" }}
          titleSize={1}
          backButton={editMode}
          onBack={this.onCancel.bind(this)}
        >
          <Stack direction='column' spacing={4}>
            {error && (
              <Alert severity='error' sx={{ width: "100%" }}>
                {error}
              </Alert>
            )}

            {!editMode ? (
              <Stepper activeStep={step}>
                <Step sx={{ cursor: step !== 0 ? "pointer" : "default" }}>
                  <StepLabel>Job details</StepLabel>
                </Step>
                <Step sx={{ cursor: step !== 0 ? "pointer" : "default" }}>
                  <StepLabel>Tasks</StepLabel>
                </Step>
                <Step>
                  <StepLabel>Job competencies</StepLabel>
                </Step>
              </Stepper>
            ) : (
              <div>
                <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                  <Tabs
                    centered={true}
                    value={step}
                    onChange={this.onTabChange.bind(this)}
                    variant='fullWidth'
                    sx={{ width: "100%" }}
                  >
                    <Tab label='Information' value={0} />
                    <Tab label='Tasks' value={1} />
                    <Tab label='Competencies' value={2} />
                  </Tabs>
                </Box>
              </div>
            )}

            {step === 0 && (
              <Fade in={true}>
                <Stack direction='column' spacing={4}>
                  <TextField
                    label='Job title (required)'
                    placeholder='e.g. Marketing Manager'
                    autoComplete={false}
                    autoFocus={true}
                    validationMethod={(e, value) => this.isNameValid(job)}
                    validationText='Please enter a valid name (more than 2 characters)'
                    clearable={false}
                    sx={{ width: "100%" }}
                    value={job ? job.name : null}
                    onChange={this.onNameChange.bind(this)}
                  />
                  <RichTextEditor
                    label='Job description'
                    editorState={descriptionEditorState}
                    onChange={this.onDescriptionChange.bind(this)}
                    onChangeEditorState={this.onDescriptionEditorStateChange.bind(this)}
                  />
                </Stack>
              </Fade>
            )}

            {step === 1 && (
              <Fade in={true}>
                <div>
                  <Stack direction='column' spacing={2}>
                    <div>
                      <Stack
                        direction='row'
                        justifyContent='space-between'
                        alignItems='center'
                        spacing={2}
                      >
                        <TextField
                          value={searchTask}
                          dense={true}
                          placeholder='Search task'
                          autoComplete={false}
                          leadingIcon={<SearchIcon />}
                          disabled={
                            get(job, "regulationTasks", []).length > 0
                              ? job.regulationTasks.length === 0
                              : true
                          }
                          onChange={this.onSearchTask.bind(this)}
                          sx={{ minWidth: "300px" }}
                        />

                        <Button
                          onClick={this.onShowTasksSelectDialog.bind(this)}
                          size='md'
                          variant='outlined'
                          startIcon={<AddIcon />}
                          label='Add tasks'
                        />
                      </Stack>

                      <Delayed>
                        <TasksTable
                          rows={filterJobTasks}
                          pagination={false}
                          theme={theme}
                          showFooter={false}
                          rowHasAction={true}
                          filterItems={filterItemsTasks}
                          hideFirstLastBorder={true}
                          noRowsComponent={
                            <TablePlaceholder
                              text='Use Add tasks button to link tasks for this job.'
                              icon={<CheckListIcon />}
                            />
                          }
                          dense={true}
                          paper={false}
                          columns={[
                            { type: TasksTable.columns.name },
                            { type: TasksTable.columns.actions }
                          ]}
                          onPositionAction={this.onDeleteTask.bind(this)}
                          isUsingDeleteIcon={true}
                        />
                      </Delayed>
                    </div>
                  </Stack>
                </div>
              </Fade>
            )}

            {step === 2 && (
              <Fade in={true}>
                <div>
                  <Stack direction='column' spacing={2}>
                    <div>
                      <Stack
                        direction='row'
                        justifyContent='space-between'
                        alignItems='center'
                        spacing={2}
                      >
                        <TextField
                          value={searchCompetency}
                          dense={true}
                          placeholder='Search competency'
                          disabled={job && job.attributes.length === 0}
                          autoComplete={false}
                          leadingIcon={<SearchIcon />}
                          onChange={this.onSearchCompetency.bind(this)}
                          sx={{ minWidth: "300px" }}
                        />

                        <Button
                          onClick={this.onShowAttributeSelectDialog.bind(this)}
                          size='md'
                          variant='outlined'
                          startIcon={<AddIcon />}
                          label='Add competencies'
                        />
                      </Stack>
                    </div>
                    <div>
                      <AttributeTable
                        // rows={job ? job.attributes : null}
                        rows={filterCompetencies}
                        pagination={false}
                        theme={theme}
                        showFooter={false}
                        rowHasAction={this.rowHasAction.bind(this)}
                        rowActionDisabled={this.rowActionDisabled.bind(this)}
                        filterItems={filterItemsAttribute}
                        hideFirstLastBorder={true}
                        noRowsComponent={
                          <TablePlaceholder
                            text='Use Add competencies button to select competencies for this job'
                            icon={<AttributesIcon />}
                          />
                        }
                        dense={true}
                        tree={true}
                        onAttributeDelete={this.onAttributeDelete.bind(this)}
                        onRequirementChange={this.onAttributeRequirementChange.bind(this)}
                        paper={false}
                        columns={[
                          { type: AttributeTable.columns.attribute, label: "Competency" },
                          {
                            type: AttributeTable.columns.requirementType,
                            label: "Priority",
                            selectable: true,
                            selectableOnChild: false
                          },
                          {
                            type: AttributeTable.columns.requiredProficiency,
                            label: "Min Proficiency"
                          },
                          { type: AttributeTable.columns.delete }
                        ]}
                      />
                    </div>
                  </Stack>
                </div>
              </Fade>
            )}

            <Stack direction='row' justifyContent='space-between' alignItems='center' spacing={2}>
              <Button onClick={this.onCancel.bind(this)} label='Cancel' />

              <Stack direction='row' spacing={2}>
                {step > 0 && !editMode && (
                  <Button
                    variant='outlined'
                    onClick={this.onBack.bind(this)}
                    startIcon={<LeftIcon />}
                    label='Back'
                  />
                )}
                <Button
                  onClick={this.onSave.bind(this)}
                  variant='filled'
                  disabled={!this.isValid(job, step) || (editMode && !hasEdit)}
                  endIcon={!editMode && step !== 2 ? <RightIcon /> : null}
                  label={editMode ? "Save" : step !== 2 ? "Continue" : "Create"}
                />
              </Stack>
            </Stack>
          </Stack>

          {showAttributeSelectDialog && (
            <AttributeSelectorDialog
              theme={theme}
              attributes={attributes}
              excludedAttributes={job ? job.attributes : null}
              allowChildren={false}
              onLoadAttributes={onLoadAttributes}
              onShowNewAttributeDialog={onShowNewAttributeDialog}
              onClose={this.onCloseAttributeSelectDialog.bind(this)}
              onSave={this.onAttributesAdded.bind(this)}
            />
          )}

          {isShowTasksSelectDialog && (
            <TaskSelectorDialog
              theme={theme}
              existingTasks={get(job, "regulationTasks", [])}
              onClose={this.onCloseTasksSelectDialog.bind(this)}
              onSave={this.onTasksAdded.bind(this)}
              subHeader='Select one or more tasks to be added to the job'
              placeholder='Search and add'
            />
          )}

          {isShowRelatedTasksInCompetencyDialog && (
            <RelatedTaskInAttributeDialog
              type={4}
              onClose={this.onCloseRelatedTasksInCompetencyDialog.bind(this)}
              theme={theme}
              tasksHasAttribute={tasksHasAttribute}
              {...this.props}
            />
          )}

          {isRemoveRelatedTasksInCompetencyDialog && (
            <ConfirmDeleteTaskDialog
              theme={theme}
              title={`Delete`}
              content={
                <div>
                  Would you like to remove all associated competencies as well? These competencies
                  are not required by any other tasks.
                </div>
              }
              onClose={this.onHideRemoveRelatedTasksInCompetencyDialog.bind(this)}
              onConfirm={this.onRemoveRelatedTasksInCompetency.bind(this)}
              onNotConfirm={this.onRemoveRelatedTasksButKeepCompetency.bind(this)}
            />
          )}
        </PaperOrDialog>
      </EditTemplate>
    );
  }
}
